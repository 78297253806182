import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import './assets/fonts/soehne-dreiviertelfett.woff2';
import './assets/fonts/soehne-halbfett.woff2';
import './assets/fonts/soehne-buch.woff2';
import './assets/fonts/Inter-Light.ttf';
import './assets/fonts/Inter-Medium.ttf';
import './assets/fonts/Inter-Regular.ttf';
import './assets/fonts/Inter-SemiBold.ttf';
import './assets/fonts/Inter-Bold.ttf';
import './index.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StateProvider } from './Store';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <BrowserRouter>
      <StateProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <App />
      </StateProvider>
    </BrowserRouter>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useContext, useEffect } from 'react';
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Login } from './pages/login';
import { Signup } from './pages/signup';
import { ResetPassword } from './pages/resetPassword';
import Plan from './pages/plan';
import { NotFound } from './pages/notFound';
import Templates from './pages/templates';
import Template from './pages/template';
import Opportunities from './pages/dashboard';
import { bgColor, black, primary, white } from './constants/colors';
import screenSize from './assets/imgs/Screen-size.png';
import './index.css';
import { store } from './Store';
import { ChangePassword } from './pages/changePassword';
import { VerifyAccount } from './pages/VerifyAccount';
import { RedirectingPage } from './pages/RedirectingPage';
import { ChangeCompanyDetails } from './pages/ChangeBusinessDetails';
import { CreatePassword } from './pages/CreatePassword';
import ViewTemplate from './pages/viewTemplate';
import Socket from './websocket';
import { hotjar } from 'react-hotjar';
import { hotjarhjid, hotjarhjsv } from './environment';

const theme = createTheme({
  palette: { primary: { main: primary } },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '14px',
          fontFamily: `InterMedium`,
          '&:hover': {
            filter: 'brightness(12%)',
            backgroundColor: 'red',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: 'rgb(31, 31, 31) !important',
          backgroundColor: 'white !important',
          fontFamily: 'InterMedium  !important',
          fontSize: '14px  !important',
          lineHeight: '20px  !important',
          padding: '6px 4px  !important',
          display: 'flex !important',
          justifyContent: 'flex-start !important',
          alignItems: 'center !important',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontFamily: `InterMedium`,
          lineHeight: '20px',
          fontWeight: '400',
          '.MuiTypography-h4': {
            fontSize: '26px !important',
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: black,
          opacity: '0.88',
          padding: '8px 12px',
          borderRadius: '2px',
          '.MuiTooltip-arrow': {
            color: black,
            opacity: '0.88',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: `InterMedium`,
          fontColor: '#4C4C4C',
          padding: '8px 12px !important',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          padding: '8px 0',
          margin: '0',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center !important',
          fontSize: '16px',
          fontFamily: 'InterSemiBold',
          backgroundColor: `${bgColor} !important`,
          color: 'rgba(0, 0, 0, 0.6) !important',
          '&:hover': {
            border: 'none !important',
            borderRadius: '0 !important',
          },
          '&.Mui-selected': {
            backgroundColor: `${bgColor} !important`,
            color: '#009B77 !important',
            borderRadius: '0 !important',
            borderBottom: `2px solid #009B77 !important`,
            fontSize: '16px !important',
            fontFamily: 'InterSemiBold !important',
            display: 'flex !important',
            justifyContent: 'center !important',
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          '& img': {
            objectFit: 'contain',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: `InterMedium !important`,
          fontSize: '16px !important',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: `InterMedium !important`,
          backgroundColor: `${white} !important`,
          // backgroundColor: `transparent !important`,
          fontSize: '16px !important',
          // '&:disabled': {
          // color: 'red !important',
          // },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: 'none !important',
          lineHeight: '20px',
          fontSize: '14px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: 'white',
          padding: '8px, 0px, 8px, 0px',
          borderRadius: '4px',
          boxShadow: '0px 8px -8px -3px #0000001F',
          // boxShadow: '0px 8px 10px 1px #00000024',
          // boxShadow: '0px 5px 5px -3px #00000033',
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          // Commented style for MuiStepConnector-root
          ' -webkit-flex': 'none',
          '-ms-flex': '1 1 auto',
          flex: 'none',
          width: '151px',
          height: '4px',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontWeight: 'bold', // Set the desired font weight
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label': {
            color: '#3E68A8',
          },
          '& label.Mui-focused': {
            color: '#3E68A8',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#3E68A8',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#3E68A8',
            },
            '&:hover fieldset': {
              borderColor: '#3E68A8',
              borderWidth: '0.15rem',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#3E68A8',
            },
          },
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.7)',
          '& .MuiPickersCalendarHeader-label': {
            color: 'rgba(0, 0, 0, 0.7)',
          },
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: {
          width: '32px',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          display: 'flex !important',
          alignItems: 'center !important',
          justifyContent: 'center !important',
          '&.Mui-selected': {
            color: `${white} !important`,
            backgroundColor: `${primary} !important`,
            border: 'none',
            borderRadius: '4px',
          },
          '&.MuiPickersDay-today': {
            border: `1px solid ${primary} !important`,
            borderRadius: '4px',
          },
          '&.MuiPickersDay-hiddenDaySpacingFiller': {
            width: '32px',
          },
        },
      },
    },
    MuiYearCalendar: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.7)',
        },
      },
    },
    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(237, 235, 232, 1)',
        },
      },
    },
  },
});

const App = () => {
  const { pathname } = useLocation();
  const { state, dispatch } = useContext(store);
  const navigate = useNavigate();

  useEffect(() => {
    const script = document.createElement('script');
    const script2 = document.createElement('script');
    // (pathname.startsWith('/view-template') || pathname === '/templates' || pathname === '/dashboard') &&
    if (
      state.data.user.fullName &&
      state.data.user.email &&
      state.data.user.createdAt
    ) {
      script.innerHTML = `
      window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "d4vcz01m",
        name: "${state.data.user.fullName}", // Full name
        email: "${state.data.user.email}", // Email address
        created_at: "${state.data.user.createdAt}" // Signup date as a Unix timestamp
      };
    `;

      script2.innerHTML = `// We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/l5c81767'
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/l5c81767';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

      document.body.appendChild(script);
      document.body.appendChild(script2);

      const intercom = document.getElementsByClassName('intercom-app');
      const intercom2 = document.getElementsByClassName(
        'intercom-lightweight-app'
      );

      if (intercom && intercom[0]) {
        intercom[0].style.display = 'block';
      }

      if (intercom2 && intercom2[0]) {
        intercom2[0].style.display = 'block';
      }
    } else {
      const intercom = document.getElementsByClassName('intercom-app');
      const intercom2 = document.getElementsByClassName(
        'intercom-lightweight-app'
      );

      if (intercom && intercom[0]) {
        intercom[0].style.display = 'none';
      }

      if (intercom2 && intercom2[0]) {
        intercom2[0].style.display = 'none';
      }
    }

    // return () =>{
    //   document.body.removeChild(script);
    //   document.body.removeChild(script2);
    // }
  }, [state?.data?.user?.fullName, pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (hotjarhjid && hotjarhjsv) {
      hotjar.initialize(hotjarhjid, hotjarhjsv);
    }
  }, []);

  useEffect(() => {
    const userData =
      localStorage.getItem('user') || sessionStorage.getItem('user');
    const token =
      localStorage.getItem('token') || sessionStorage.getItem('token');
    // const userDataSession = sessionStorage.getItem('user');
    // const tokenSession = sessionStorage.getItem('token');

    if (store) {
      if (userData && token) {
        const parsedUserData = JSON.parse(userData);
        dispatch({
          type: 'SET_DATA',
          payload: {
            isAuthenticated: true,
            user: parsedUserData,
            accessToken: token,
          },
        });
      }
      // else if (userDataSession && tokenSession) {
      //   const parsedUserData = JSON.parse(userDataSession);
      // dispatch({
      //   type: 'SET_DATA',
      //   payload: {
      //     isAuthenticated: true,
      //     user: parsedUserData,
      //     accessToken: tokenSession,
      //   },
      //   });
      // }
      else {
        localStorage.removeItem('token');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('token');
        localStorage.removeItem('user');
        if (
          pathname.startsWith('/plan/') ||
          pathname.startsWith('/template/') ||
          pathname.startsWith('/invite-map') ||
          pathname.startsWith('/create-password') ||
          pathname.startsWith('/view-template') ||
          pathname.startsWith('/change-password') ||
          pathname.startsWith('/reset-password') ||
          pathname.startsWith('/map')
          // pathname.startsWith('/templates') ||
          // pathname.startsWith('/notification')
        ) {
          console.log('ALLOW USER');
          // Allow the user to see the page with a specific pattern (e.g., plan/123214234);
          return;
        } else {
          navigate('/login');
        }
      }
    }
  }, [dispatch]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <Socket />
        <div>
          <div className="screenSize">
            <img src={screenSize} alt="screenSize" />
            <p>Your screen is too small</p>
            <h4>Please access Inquarter from a desktop device.</h4>
          </div>
          <div className="App">
            <Routes>
              <Route path="/signup" element={<Signup />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/verify-account" element={<VerifyAccount />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/create-password" element={<CreatePassword />} />
              <Route
                path="/change-company-details"
                element={<ChangeCompanyDetails />}
              />
              <Route path="/map/:shortId" element={<Plan />} />
              <Route path="/plan/add" element={<Plan />} />
              <Route path="/plan/:id" element={<Plan />} />
              <Route path="/plan/:id/:email" element={<Plan />} />
              <Route path="/plan/:id/copylink/:copylink" element={<Plan />} />
              <Route path="/invite-map" element={<RedirectingPage />} />
              <Route path="/template/:id/:type" element={<Template />} />
              <Route path="/template/:type" element={<Template />} />
              <Route path="/template/add/:id" element={<Template />} />
              <Route path="/view-template/:id" element={<ViewTemplate />} />
              <Route
                path="/view-template/:id/:type"
                element={<ViewTemplate />}
              />
              <Route
                path="/view-template/:id/userId/:userId"
                element={<ViewTemplate />}
              />
              <Route
                path="/view-template/:id/:type/:email"
                element={<ViewTemplate />}
              />
              <Route
                path="/view-template/:id/:type/copylink/:copylink"
                element={<ViewTemplate />}
              />
              <Route path="/dashboard" element={<Opportunities />} />
              <Route path="/templates" element={<Templates />} />
              <Route path="/login" element={<Login />} />
              <Route path="/not-found" element={<NotFound />} />
              <Route path="/" element={<Login />} />
              <Route path="*" element={<Navigate replace to="/not-found" />} />
            </Routes>
          </div>
        </div>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;

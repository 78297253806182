import { Box, Dialog, Typography } from '@mui/material';
import React from 'react';
import { black, mercury, warning } from '../../constants/colors';
import DynamicButton from '../new/Button';

export const DeletePlanModal = ({
  open,
  handleClose,
  handleDelete,
  plan = true,
  shared = false,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: styles.paper }}>
      <Typography variant="h5" sx={styles.heading}>
        Delete {plan ? 'Plan' : 'Template'}
      </Typography>
      <Typography variant="h5" sx={styles.body}>
        Are you sure you want to delete this {plan ? 'plan' : 'template'}?
      </Typography>
      <Box sx={styles.btns}>
        <DynamicButton
          type="profile"
          style={styles.cancel}
          onClick={handleClose}
        >
          Cancel
        </DynamicButton>
        <DynamicButton
          type="remove"
          style={styles.delete}
          onClick={handleDelete}
        >
          Delete
        </DynamicButton>
      </Box>{' '}
    </Dialog>
  );
};

const styles = {
  paper: {
    borderRadius: '8px',
    padding: '16px 24px',
    width: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: black,
  },
  heading: {
    fontFamily: 'InterBold',
    fontSize: '20px',
    lineHeight: '32px',
    marginBottom: '12px',
    width: '100%',
    textAlign: 'left',
  },
  body: {
    fontSize: '14px',
    lineHeight: '24px',
    textAlign: 'left',
    marginBottom: '10px',
    width: '85%',
    marginLeft: 0,
    marginRight: 'auto',
  },
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px',
    width: '100%',
  },
  cancel: {
    padding: '8px 16px',
    border: ` 1px solid rgba(0, 0, 0, 0.6)`,
    height: '40px',
    fontFamily: 'InterRegular',
    color: 'rgba(0, 0, 0, 0.7)',
    '.MuiButton-outlined:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2) !important',
      color: 'rgba(0, 0, 0, 0.88) !important',
    },
  },
  delete: {
    color: 'white',
    padding: '8px 16px',
    border: `none`,
    boxShadow: 'none',
    background: warning,
    '&:hover': { background: warning },
  },
};

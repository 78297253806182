import React from 'react';
import { Box, Dialog, Typography } from '@mui/material';
import { ReactComponent as HighlightOffOutlinedIcon } from '../../assets/imgs/close.svg';
import SelectWithLabel from '../common/SelectWithLabel';
import InputWithLabel from '../common/InputWithLabel';
import { planArchiveOptions as options } from '../../utils/constants';
import { black, mercury, primary, shark } from '../../constants/colors';
import DynamicButton from '../new/Button';

export const ArchivePlanModal = ({
  open,
  archiveReason,
  selectedOption,
  handleChange,
  handleReasonChange,
  handleClose,
  selectedRow,
  archivePlan,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: styles.paper }}>
      <Typography variant="h5" sx={styles.heading}>
        Archive Plan
        <HighlightOffOutlinedIcon
          style={styles.closeIcon}
          onClick={handleClose}
        />
      </Typography>

      <SelectWithLabel
        name="archive-plan"
        value={selectedOption}
        label="Archive reason (Optional)"
        options={options}
        styles={{ marginBottom: '10px' }}
        handleChange={handleChange}
      />

      {selectedOption === 'Other' && (
        <InputWithLabel
          name="Reason"
          multiline={true}
          rows="3"
          value={archiveReason}
          placeholder="Enter reason"
          share
          handleChange={(e) => handleReasonChange(e, 'description')}
          characterLimit={30}
        />
      )}

      <Box sx={styles.btns}>
        <DynamicButton type="outlined" sx={styles.cancel} onClick={handleClose}>
          Cancel
        </DynamicButton>
        <DynamicButton
          type="contained"
          sx={styles.submit}
          onClick={archivePlan}
        >
          Archive
        </DynamicButton>
      </Box>
    </Dialog>
  );
};

const styles = {
  paper: {
    borderRadius: '8px',
    padding: '16px 24px',
    width: '560px',
  },
  heading: {
    color: black,
    fontFamily: 'InterBold',
    fontSize: '20px',
    lineHeight: '32px',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeIcon: {
    color: shark,
    fontSize: '30px',
    borderRadius: '50%',
    padding: '5px',
    cursor: 'pointer',
  },
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px',
    marginTop: '0px',
  },
  cancel: {
    color: black,
    width: '81px',
    height: '40px',
    background: mercury,
    borderRadius: '100px',
    boxShadow: 'none',
    border: `1px solid ${mercury}`,
    '&:hover': { background: mercury },
  },
  submit: {
    color: 'white',
    width: '138px',
    height: '40px',
    borderRadius: '100px',
    fontFamily: 'SoehneMedium',
    boxShadow: 'none',
    background: primary,
    '&:hover': { background: primary },
  },
};

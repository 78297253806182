import React, { useEffect, useState } from 'react';
import { Box, Typography, Container } from '@mui/material';
import styles from '../styles/resetPassword/resetPassword.module.css';
import { toast } from 'react-toastify';
import axios from 'axios';
import { BlackTextLogo } from '../assets';
import { useLocation, useNavigate } from 'react-router-dom';
import httpClient from '../config/http.service';
import { showNotification } from '../components/common/Toast';

export const VerifyAccount = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState('');
  const location = useLocation();

  useEffect(() => {
    const params = location.search;
    const urlParams = new URLSearchParams(params);
    const token = urlParams.get('token');
    if (params.length > 1) {
      if (token) {
        setToken(token);
        httpClient
          .post(
            `api/user/verify-email`,
            {
              token: token,
            }
            // {
            //   headers: {
            //     'X-Auth-Token': token,
            //     Authorization: `Bearer ${token}`,
            //   },
            // }
          )
          .then(() => {
            setTimeout(() => {
              navigate('/login');
            }, 5000);
          })
          .catch((err) =>
            showNotification('error', err?.response?.data?.message)
          );
      }
    }
  }, []);

  return (
    <Container maxWidth="xl">
      <Box className={styles.container}>
        <Box className={styles.main_container}>
          <Box className={styles.verify_box}>
            <img src={BlackTextLogo} alt="Logo" />

            <Typography
              variant="subtitle1"
              align="center"
              sx={{ marginTop: '30px', fontSize: '16px' }}
            >
              You have successfully verified your account. We will redirect you
              to the login page!
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

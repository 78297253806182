import React from "react";
import { Box } from "@mui/system";
import { Avatar, Grid, Typography } from "@mui/material";
import { ReactComponent as LinkedinIcon } from "../assets/imgs/linkedin.svg";
import { ReactComponent as MailIcon } from "../assets/imgs/mail.svg";
import { showNotification } from "../components/common/Toast";

export const Participants = ({
  customerPhoto,
  plan,
  handleArchive,
  companyPhoto,
  vendor,
  customer = null,
  company,
}) => {
  const handleEmail = (type) => {
    if (type === "customer") {
      navigator.clipboard.writeText(customer?.email);
      showNotification("success", "Copy to clipboard");
    } else {
      navigator.clipboard.writeText(vendor.email);
      showNotification("success", "Copy to clipboard");
    }
  };

  return (
    <Grid container sx={styles.main} spacing={2}>
      <Grid item xs={6} style={styles.item}>
        <Box sx={styles.leftSideBox}>
          <Box sx={styles.box}>
            <Avatar sx={styles.avatar} src={customerPhoto} />
            <Box sx={styles.company}>
              <Typography
                variant="p"
                align="left"
                style={styles.label}
                onClick={handleArchive}
              >
                Company
              </Typography>
              <Typography
                variant="p"
                align="left"
                style={styles.value}
                onClick={handleArchive}
              >
                {plan?.customerCompany.length > 20
                  ? `${plan?.customerCompany.slice(0, 20)}...`
                  : plan?.customerCompany}
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.pointOfContact}>
            <Typography
              variant="p"
              align="left"
              sx={styles.label}
              onClick={handleArchive}
            >
              Point of contact
            </Typography>
            <Typography
              variant="p"
              align="left"
              sx={styles.value}
              onClick={handleArchive}
            >
              {customer?.fullName || ""}
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.icons}>
          {plan?.mainPointCustomer?.email && (
            <MailIcon
              style={{ cursor: "pointer" }}
              onClick={() => handleEmail("customer")}
            />
          )}
          {customer?.linkedin && (
            <LinkedinIcon
              style={{ cursor: "pointer" }}
              onClick={() => window.open(customer?.linkedin, '_blank')}
            />
          )}
        </Box>
      </Grid>
      <Grid item xs={6} style={styles.item}>
        <Box sx={styles.leftSideBox}>
          <Box sx={styles.box}>
            <Avatar sx={styles.avatar} src={companyPhoto} />
            <Box sx={styles.company}>
              <Typography
                variant="p"
                align="left"
                sx={styles.label}
                onClick={handleArchive}
              >
                Vendor
              </Typography>
              <Typography
                variant="p"
                align="left"
                sx={styles.value}
                onClick={handleArchive}
              >
                {company?.name?.length > 20
                  ? `${company?.name.slice(0, 20)}...`
                  : company?.name}
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.pointOfContact}>
            <Typography
              variant="p"
              align="left"
              sx={styles.label}
              onClick={handleArchive}
            >
              Representative
            </Typography>
            <Typography
              variant="p"
              align="left"
              sx={styles.value}
              onClick={handleArchive}
            >
              {vendor?.fullName}
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.icons}>
          <MailIcon
            style={{ cursor: "pointer" }}
            onClick={() => handleEmail("vendor")}
          />
          {vendor?.linkedin && (
            <LinkedinIcon
              style={{ cursor: "pointer" }}
              onClick={() => window.open(vendor?.linkedin, "_blank")}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

const styles = {
  main: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0 0 0",
    gap: "16px",
  },
  item: {
    backgroundColor: "rgba(242, 240, 238, 1)",
    padding: "18px 16px",
    borderRadius: "8px",
    maxWidth: "49.2%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
    overflowX: "auto",
  },
  avatar: {
    borderRadius: "6px",
  },
  label: {
    fontFamily: "InterMedium",
    fontSize: "14px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.6)",
    width: 'fit-content',
  },
  value: {
    fontFamily: "InterSemiBold",
    fontSize: "16px",
    lineHeight: "24px",
    color: "rgba(0, 0, 0, 0.88)",
  },
  update: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  box: {
    display: "flex",
    gap: "15px",
    alignItems: "center",
    borderRight: "1px solid rgba(229, 224, 221, 1)",
    paddingRight: "20px",
  },
  pointOfContact: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "10px",
    // width: "68%",
  },
  icons: {
    display: "flex",
    gap: "20px",
    justifyContent: "end",
    alignItems: "center",
    width: "16%",
  },
  company: {
    display: "flex",
    flexDirection: "column",
  },
  leftSideBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  }
};

import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Grid, Menu, MenuItem, Typography } from '@mui/material';
import { ReactComponent as ArrowDown } from '../../assets/imgs/arrow_drop_down.svg';
import styles from '../../styles/plans/planEngangement.module.css';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { ReactComponent as DatePickerIcon } from '../../assets/imgs/event.svg';

export const EngangementHeader = ({
  selectedCollaborator,
  selectedDate,
  handleDateChange,
  handleCollaboratorChange,
  handleClearDate,
  collaborators,
}) => {
  const { pathname } = useLocation();
  const [sortAnchorEl, seSortAnchorEl] = useState(null);
  const open = Boolean(sortAnchorEl);
  const handleSortOpen = (event) => seSortAnchorEl(event.currentTarget);
  const handleCollaboratorClose = () => seSortAnchorEl(null);

  return (
    <Grid container className={styles.header} rowSpacing={3}>
      <Grid item className={styles.tabs}>
        <Typography className={styles.track}>Track Engagement</Typography>
      </Grid>

      <Grid item className={styles.header_btns}>
        <Box
          id="sort-button"
          className={styles.sort_btn}
          aria-controls={open ? 'basic-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleSortOpen}
        >
          <span className={styles.label}>Collaborators: </span>
          <Typography
            component="span"
            className={open ? styles.span_active : styles.span_inactive}
          >
            {selectedCollaborator?.name ?? 'All members'} <ArrowDown />
          </Typography>
        </Box>

        <Menu
          open={open}
          anchorEl={sortAnchorEl}
          onClose={handleCollaboratorClose}
          PaperProps={{
            className: styles.menu,
            sx: { width: sortAnchorEl && sortAnchorEl.offsetWidth },
          }}
          MenuListProps={{ 'aria-labelledby': 'sort-button' }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          {collaborators?.map((item) => (
            <span
              key={item.key}
              className={
                item.key === selectedCollaborator?.key
                  ? styles.selected_menu_item
                  : styles.menu_item
              }
              onClick={() => {
                handleCollaboratorChange(item);
                handleCollaboratorClose();
              }}
            >
              <span
                className={
                  item.key === selectedCollaborator?.key
                    ? styles.menu_item_name_selected
                    : styles.menu_item_name
                }
              >
                {item.name}
              </span>
              {/* <div>
                <span>{item.name}</span>
                {item.key === selectedCollaborator.key && <CheckIcon />}
              </div> */}
              {item.email && (
                <span className={styles.menu_item_email}>{item.email}</span>
              )}
            </span>
          ))}
        </Menu>

        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '170px',
            minWidth: '170px !important',
          }}
        >
          <DemoContainer
            components={['DatePicker']}
            className="dayPicker"
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '0',
              flexDirection: 'row !important',
            }}
          >
            <span className={styles.label}>Date: </span>
            <DatePicker
              value={
                selectedDate.all ? selectedDate.endDate : selectedDate.endDate
              }
              onChange={handleDateChange}
              style={{
                borderRadius: '4px',
              }}
              components={{
                OpenPickerIcon: DatePickerIcon,
              }}
              slotProps={{
                textField: {
                  size: 'small',
                  sx: {
                    height: '41px',
                    width: '170px',
                    minWidth: '170px !important',
                    marginTop: '0 !important',
                  },
                },
                field: {
                  sx: { border: 'none' },
                },
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
        {!selectedDate.all && (
          <ClearIcon sx={{ cursor: 'pointer' }} onClick={handleClearDate} />
        )}
      </Grid>
    </Grid>
  );
};

import React, { useState } from 'react';
import { Box, Container } from '@mui/material';
import SignupGrid from '../components/Signup/SignupGrid';
import styles from '../styles/signup/signup.module.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const Signup = () => {
  return (
    <Container maxWidth="xl" className={styles.main}>
      <Box className={styles.container}>
        <Box className={styles.main_container}>
          <SignupGrid />
        </Box>
      </Box>
    </Container>
  );
};

import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import { Avatar, Box, Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactComponent as NoEngagementsIcon } from '../../assets/imgs/flash_off.svg';
import { ReactComponent as NoEngagementsIconGreen } from '../../assets/imgs/flash_off_green.svg';
import {
  black6,
  black88,
  mercury,
  primary,
  white,
} from '../../constants/colors';
import { useState } from 'react';
import { useEffect } from 'react';
import { TIMELINE } from '../../utils/enums';
import {
  formatTimeTo12HourFormat,
  formatToTableDate,
  getDarkerColor,
  getInitial,
} from '../../utils/utils';
import {
  bgColorFromKey,
  colorFromKey,
  keysFromDb,
} from '../../utils/constants';
import DynamicButton from '../new/Button';

const useStyles = makeStyles(() => ({
  timelineItemRoot: {
    '&:before': {
      content: 'unset',
      display: 'none',
    },
  },
}));

export const HistoryTimeline = ({
  mockData,
  userColors,
  companies,
  loaded,
  planStatus,
  openShareModal,
}) => {
  const classes = useStyles();
  const [data, setData] = useState(null);

  const mergeArraysWithinTimeLimit = (data, timeLimit) => {
    const groupedData = {};

    data.forEach((item) => {
      const timestamp = item.timestamp;
      let merged = false;

      for (const key in groupedData) {
        const groupTimestamp = parseInt(key, 10);

        if (
          Math.abs(timestamp - groupTimestamp) <= timeLimit &&
          item.engagment &&
          item.engagment.some((eng) => eng.type === 'modified') &&
          groupedData[key].engagment &&
          groupedData[key].engagment.some((eng) => eng.type === 'modified')
        ) {
          // Merge the engagements
          groupedData[key].engagment = groupedData[key].engagment.concat(
            item.engagment.filter((eng) => eng.type === 'modified')
          );

          // Update createdAt and updatedAt if necessary
          if (item.createdAt < groupedData[key].createdAt) {
            groupedData[key].createdAt = item.createdAt;
          }
          if (item.updatedAt > groupedData[key].updatedAt) {
            groupedData[key].updatedAt = item.updatedAt;
          }

          merged = true;
          break;
        }
      }

      if (!merged) {
        groupedData[timestamp] = { ...item };
      }
    });

    const result = Object.values(groupedData);
    return result;
  };

  const groupDataByDay = (data) => {
    if (!data) {
      setData(null);
      return;
    }
    const groupedData = {};
    const filtered = data.filter((el) => el.engagment.length > 0);
    filtered.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

    const timeLimit = 5 * 60 * 1000;

    const mergedData = mergeArraysWithinTimeLimit(filtered, timeLimit);

    mergedData.forEach((item) => {
      if (
        item.engagment?.[0]?.type === TIMELINE.modified ||
        item.engagment?.[1]?.type === TIMELINE.modified ||
        item.engagment?.[2]?.type === TIMELINE.modified
      ) {
        const groupedDataByActivity = item.engagment.reduce((result, act) => {
          // Extract 'activity' from the current item
          const { activity } = act;

          // Create a new array for the activity if it doesn't exist in the result
          if (!result[activity]) {
            result[activity] = [];
          }

          // Push the current item into the corresponding activity's array
          if (act.type !== 'added' && act.type !== 'shareList')
            result[activity].push(act);

          return result;
        }, {});

        // For each activity, create a new item
        for (const activity in groupedDataByActivity) {
          const newItem = { ...item };
          newItem.engagment = [
            {
              type: 'modified-activity',
              updatedAt: item.updatedAt,
              activities: { [activity]: groupedDataByActivity[activity] },
            },
          ];

          if (groupedData[newItem.day]) {
            groupedData[newItem.day].push(newItem);
          } else {
            groupedData[newItem.day] = [newItem];
          }
        }
      } else {
        // If no activity type matches, add the item as is
        if (groupedData[item.day]) {
          groupedData[item.day].push(item);
        } else {
          groupedData[item.day] = [item];
        }
      }
    });

    return groupedData;
  };

  useEffect(() => {
    const getData = groupDataByDay(mockData);
    if (getData) setData(getData);
  }, [mockData]);

  const findUserColor = (userName) => {
    if (userColors?.length > 0) {
      const user = userColors.find((user) => user.name === userName);
      return user
        ? { color: user?.color, backgroundColor: user?.backgroundColor }
        : null;
    } else {
      return null;
    }
  };

  return (
    <Timeline
      sx={{
        alignItems: 'baseline',
        marginTop: '0px',
        padding: '0',
        width: '100%',
      }}
    >
      {data &&
        Object.entries(data).map(([day, items]) => (
          <Box sx={{ width: '100%' }} key={day}>
            <Divider
              flexItem
              colo
              sx={{
                marginTop: '20px',
                marginBottom: '38px',
                '&::before, &::after': {
                  borderColor: 'rgba(237, 235, 232, 1)',
                },
              }}
            >
              <Box style={styles.divider}>{day}</Box>
            </Divider>
            {items?.map((item, index) => {
              return (
                <TimelineItem
                  key={index}
                  classes={{ root: classes.timelineItemRoot, height: '100%' }}
                >
                  <TimelineSeparator>
                    {item.icon ? (
                      <Avatar
                        alt={item.user}
                        src={item.icon}
                        sx={{ width: 32, height: 32 }}
                      />
                    ) : (
                      <Avatar
                        sx={{
                          backgroundColor: item?.user?.fullName
                            ? findUserColor(item.user.fullName)?.backgroundColor
                            : 'transparent',
                          color: item?.user?.fullName
                            ? findUserColor(item.user.fullName)?.color
                            : 'transparent',
                          border: `1px solid ${
                            item?.user?.fullName
                              ? findUserColor(item.user.fullName)?.color
                              : 'transparent'
                          }`,
                        }}
                      >
                        {item?.user?.fullName
                          ? getInitial(item.user.fullName)
                          : ''}
                      </Avatar>
                    )}
                    {index === items.length - 1 ? null : <TimelineConnector />}
                  </TimelineSeparator>
                  {item.engagment &&
                    item.engagment.length > 0 &&
                    item.engagment.map((eng) => {
                      if (eng.type === TIMELINE.deleted) {
                        return (
                          <TimelineContent>
                            <Box sx={styles.displayCenter}>
                              <Typography style={styles.type}>
                                {item.user.fullName}
                              </Typography>
                              {item.engagment[0].subactivity != undefined &&
                              (item.engagment[0].subactivity === '' ||
                                item.engagment[0].subactivity !== '') ? (
                                item.engagment[0].subactivity === '' ? (
                                  <Typography
                                    marginLeft={'5px'}
                                    style={styles.regularText}
                                  >
                                    deleted a subactivity from the activity{' '}
                                    <span style={styles.type}>
                                      {item.engagment[0].activity}
                                    </span>{' '}
                                    from stage{' '}
                                    <span style={styles.plan}>
                                      {item.engagment[0].stage}
                                    </span>{' '}
                                    <span style={styles.time}>
                                      {item.updatedAt
                                        ? formatTimeTo12HourFormat(
                                            item.updatedAt
                                          )
                                        : ''}
                                    </span>
                                  </Typography>
                                ) : (
                                  <Typography
                                    marginLeft={'5px'}
                                    style={styles.regularText}
                                  >
                                    deleted a subactivity
                                    <span style={styles.deleted}>
                                      {item.engagment[0].subactivity}
                                    </span>{' '}
                                    from the activity{' '}
                                    <span style={styles.type}>
                                      {item.engagment[0].activity}
                                    </span>{' '}
                                    from stage{' '}
                                    <span style={styles.plan}>
                                      {item.engagment[0].stage}
                                    </span>{' '}
                                    <span style={styles.time}>
                                      {item.updatedAt
                                        ? formatTimeTo12HourFormat(
                                            item.updatedAt
                                          )
                                        : ''}
                                    </span>
                                  </Typography>
                                )
                              ) : item.engagment[0].activity != undefined &&
                                (item.engagment[0].activity !== '' ||
                                  item.engagment[0].activity === '') ? (
                                item.engagment[0].activity !== '' ? (
                                  <Typography
                                    marginLeft={'5px'}
                                    style={styles.regularText}
                                  >
                                    deleted a activity
                                    <span style={styles.deleted}>
                                      {item.engagment[0].activity}
                                    </span>{' '}
                                    from the{' '}
                                    <span style={styles.plan}>
                                      {item.engagment[0].stage}
                                    </span>{' '}
                                    <span style={styles.time}>
                                      {item.updatedAt
                                        ? formatTimeTo12HourFormat(
                                            item.updatedAt
                                          )
                                        : ''}
                                    </span>
                                  </Typography>
                                ) : (
                                  <Typography
                                    marginLeft={'5px'}
                                    style={styles.regularText}
                                  >
                                    deleted a activity from the{' '}
                                    <span style={styles.plan}>
                                      {item.engagment[0].stage}
                                    </span>{' '}
                                    <span style={styles.time}>
                                      {item.updatedAt
                                        ? formatTimeTo12HourFormat(
                                            item.updatedAt
                                          )
                                        : ''}
                                    </span>
                                  </Typography>
                                )
                              ) : (
                                item.engagment[0].stage && (
                                  <Typography
                                    marginLeft={'5px'}
                                    style={styles.regularText}
                                  >
                                    deleted a stage{' '}
                                    <span style={styles.deleted}>
                                      {item.engagment[0].stage}
                                    </span>{' '}
                                    <span style={styles.time}>
                                      {item.updatedAt
                                        ? formatTimeTo12HourFormat(
                                            item.updatedAt
                                          )
                                        : ''}
                                    </span>
                                  </Typography>
                                )
                              )}
                            </Box>
                          </TimelineContent>
                        );
                      }

                      if (
                        eng.type === TIMELINE.vendorTeam ||
                        eng.type === TIMELINE.customerTeam
                      ) {
                        if (eng.subType === TIMELINE.deleted) {
                          return (
                            <TimelineContent>
                              <Box sx={styles.displayCenter}>
                                <Typography style={styles.type}>
                                  {item?.user?.fullName}
                                </Typography>
                                <Typography
                                  marginLeft={'5px'}
                                  style={styles.regularText}
                                >
                                  deleted user{' '}
                                  <span style={styles.type}>
                                    {eng.user.name}
                                  </span>{' '}
                                  from the{' '}
                                  {eng.type === TIMELINE.vendorTeam
                                    ? companies?.vendor?.length > 0
                                      ? `${companies.vendor[0]} team`
                                      : 'vendor team'
                                    : companies?.customer?.length > 0
                                    ? `${companies.customer[0]} team`
                                    : 'customer team'}
                                  <span style={styles.time}>
                                    {item.updatedAt
                                      ? formatTimeTo12HourFormat(item.updatedAt)
                                      : ''}
                                  </span>
                                </Typography>
                              </Box>
                            </TimelineContent>
                          );
                        }
                      }

                      if (eng.type === TIMELINE.shareList) {
                        if (eng?.subType === TIMELINE.deleted) {
                          return (
                            <TimelineContent>
                              <Box sx={styles.displayCenter}>
                                <Typography style={styles.type}>
                                  {item?.user?.fullName}
                                </Typography>
                                <Typography
                                  marginLeft={'5px'}
                                  style={styles.regularText}
                                >
                                  removed user{' '}
                                  <span style={styles.type}>
                                    {eng.user[0].fullName}
                                  </span>{' '}
                                  from the share list.
                                  <span style={styles.time}>
                                    {item.updatedAt
                                      ? formatTimeTo12HourFormat(item.updatedAt)
                                      : ''}
                                  </span>
                                </Typography>
                              </Box>
                            </TimelineContent>
                          );
                        } else if (eng?.subType === TIMELINE.added) {
                          return (
                            <TimelineContent>
                              <Box sx={styles.displayCenter}>
                                <Typography style={styles.type}>
                                  {item?.user?.fullName}
                                </Typography>
                                <Typography
                                  marginLeft={'5px'}
                                  style={styles.regularText}
                                >
                                  added a new user{' '}
                                  <span style={styles.type}>
                                    {eng.user.fullName}
                                  </span>{' '}
                                  to the share list.
                                  <span style={styles.time}>
                                    {item.updatedAt
                                      ? formatTimeTo12HourFormat(item.updatedAt)
                                      : ''}
                                  </span>
                                </Typography>
                              </Box>
                            </TimelineContent>
                          );
                        } else if (
                          eng?.subType === TIMELINE.shared ||
                          eng?.subType === TIMELINE.addedShared
                        ) {
                          return (
                            <TimelineContent>
                              <Box sx={styles.displayCenter}>
                                <Typography style={styles.type}>
                                  {item?.user?.fullName}
                                </Typography>
                                <Typography
                                  marginLeft={'5px'}
                                  style={styles.regularText}
                                >
                                  {eng?.subType === TIMELINE.addedShared
                                    ? 'added and share this MAP with '
                                    : 'shared this MAP with '}
                                  <span style={styles.type}>
                                    {eng.user.fullName}
                                  </span>
                                  .
                                  <span style={styles.time}>
                                    {item.updatedAt
                                      ? formatTimeTo12HourFormat(item.updatedAt)
                                      : ''}
                                  </span>
                                </Typography>
                              </Box>
                            </TimelineContent>
                          );
                        } else if (eng?.subType === TIMELINE.modified) {
                          return (
                            <TimelineContent>
                              <Box sx={styles.displayCenter}>
                                <Typography style={styles.type}>
                                  {item?.user?.fullName}
                                </Typography>
                                <Typography
                                  marginLeft={'5px'}
                                  style={styles.regularText}
                                >
                                  modified share list:
                                  <span style={styles.time}>
                                    {item.updatedAt
                                      ? formatTimeTo12HourFormat(item.updatedAt)
                                      : ''}
                                  </span>
                                </Typography>
                              </Box>
                              <Box sx={styles.activityBox}>
                                {eng?.changed?.email && (
                                  <Box
                                    style={styles.activityWrapper}
                                    key={index}
                                  >
                                    <Typography sx={styles.activityTitle}>
                                      Email
                                    </Typography>
                                    <Box sx={styles.activityChange}>
                                      <Typography sx={styles.old}>
                                        {eng.changed.email.old !== ''
                                          ? eng.changed.email.old
                                          : '-'}{' '}
                                      </Typography>
                                      <Typography sx={styles.new}>
                                        {eng.changed.email.new}
                                      </Typography>{' '}
                                    </Box>
                                  </Box>
                                )}
                                {eng?.changed?.company && (
                                  <Box
                                    style={styles.activityWrapper}
                                    key={index}
                                  >
                                    <Typography sx={styles.activityTitle}>
                                      Company
                                    </Typography>
                                    <Box sx={styles.activityChange}>
                                      <Typography sx={styles.old}>
                                        {eng.changed.company.old !== ''
                                          ? eng.changed.company.old
                                          : '-'}
                                      </Typography>
                                      <Typography sx={styles.new}>
                                        {eng.changed.company.new}
                                      </Typography>{' '}
                                    </Box>
                                  </Box>
                                )}
                                {eng?.changed?.fullName && (
                                  <Box
                                    style={styles.activityWrapper}
                                    key={index}
                                  >
                                    <Typography sx={styles.activityTitle}>
                                      Full Name
                                    </Typography>
                                    <Box sx={styles.activityChange}>
                                      <Typography sx={styles.old}>
                                        {eng.changed.fullName.old !== ''
                                          ? eng.changed.fullName.old
                                          : '-'}
                                      </Typography>
                                      <Typography sx={styles.new}>
                                        {eng.changed.fullName.new}
                                      </Typography>{' '}
                                    </Box>
                                  </Box>
                                )}
                                {eng?.changed?.title && (
                                  <Box
                                    style={styles.activityWrapper}
                                    key={index}
                                  >
                                    <Typography sx={styles.activityTitle}>
                                      Title
                                    </Typography>
                                    <Box sx={styles.activityChange}>
                                      <Typography sx={styles.old}>
                                        {eng.changed.title.old !== ''
                                          ? eng.changed.title.old
                                          : '-'}
                                      </Typography>
                                      <Typography sx={styles.new}>
                                        {eng.changed.title.new}
                                      </Typography>{' '}
                                    </Box>
                                  </Box>
                                )}
                                <Divider light />
                              </Box>
                            </TimelineContent>
                          );
                        }
                      }

                      if (eng.type === TIMELINE.added) {
                        if (eng?.subType === TIMELINE.team) {
                          return (
                            <TimelineContent>
                              <Box sx={styles.displayCenter}>
                                <Typography style={styles.type}>
                                  {item?.user?.fullName}
                                </Typography>
                                <Typography
                                  marginLeft={'5px'}
                                  style={styles.regularText}
                                >
                                  added{' '}
                                  <span style={styles.type}>{eng.user}</span> to
                                  the{' '}
                                  <span style={styles.type}>
                                    {eng.teamType === TIMELINE.vendor
                                      ? companies?.vendor?.length > 0
                                        ? `${companies.vendor[0]}`
                                        : 'vendor'
                                      : companies?.customer?.length > 0
                                      ? `${companies.customer[0]}`
                                      : 'customer'}
                                  </span>{' '}
                                  team {eng.activity ? 'on' : ''}
                                  <span style={styles.type}>
                                    {eng.activity ? ` ${eng.activity}` : ''}
                                  </span>
                                  <span style={styles.time}>
                                    {item.updatedAt
                                      ? formatTimeTo12HourFormat(item.updatedAt)
                                      : ''}
                                  </span>
                                </Typography>
                              </Box>
                            </TimelineContent>
                          );
                        } else {
                          if (item.engagment.length === 1) {
                            return (
                              <TimelineContent>
                                <Box sx={styles.displayCenter}>
                                  <Typography style={styles.type}>
                                    {item.user.fullName}
                                  </Typography>
                                  {item.engagment[0].subactivity ||
                                  item.engagment[0].subactivity === '' ? (
                                    <Typography
                                      marginLeft={'5px'}
                                      style={styles.regularText}
                                    >
                                      added a new subactivity{' '}
                                      <span style={styles.type}>
                                        {item.activity}
                                      </span>{' '}
                                      to the activity{' '}
                                      <span style={styles.type}>
                                        {item.engagment[0].activity}
                                      </span>{' '}
                                      in stage{' '}
                                      <span style={styles.plan}>
                                        {item.engagment[0].stage}
                                      </span>{' '}
                                      <span style={styles.time}>
                                        {item.updatedAt
                                          ? formatTimeTo12HourFormat(
                                              item.updatedAt
                                            )
                                          : ''}
                                      </span>
                                    </Typography>
                                  ) : item.engagment[0].activity ||
                                    item.engagment[0].activity === '' ? (
                                    <Typography
                                      marginLeft={'5px'}
                                      style={styles.regularText}
                                    >
                                      added a new activity{' '}
                                      <span style={styles.type}>
                                        {' '}
                                        {item.activity}
                                      </span>{' '}
                                      to the{' '}
                                      <span style={styles.plan}>
                                        {item.engagment[0].stage}
                                      </span>{' '}
                                      stage
                                      <span style={styles.time}>
                                        {item.updatedAt
                                          ? formatTimeTo12HourFormat(
                                              item.updatedAt
                                            )
                                          : ''}
                                      </span>
                                    </Typography>
                                  ) : (
                                    item.engagment[0].stage && (
                                      <Typography
                                        marginLeft={'5px'}
                                        style={styles.regularText}
                                      >
                                        added a new stage{' '}
                                        <span style={styles.plan}>
                                          {item.engagment[0].stage}
                                        </span>{' '}
                                        <span style={styles.time}>
                                          {item.updatedAt
                                            ? formatTimeTo12HourFormat(
                                                item.updatedAt
                                              )
                                            : ''}
                                        </span>
                                      </Typography>
                                    )
                                  )}
                                </Box>
                              </TimelineContent>
                            );
                          }
                        }
                      }

                      if (eng.type === 'added-teams') {
                        return Object.entries(eng.teams).map(([name, act]) => (
                          <TimelineContent>
                            <Box sx={styles.displayCenter}>
                              <Typography style={styles.type}>
                                {item?.user?.fullName}
                              </Typography>
                              <div
                                marginLeft={'5px'}
                                style={{
                                  ...styles.regularText,
                                  display: 'flex',
                                  alignItems: 'center',
                                  paddingLeft: '5px',
                                }}
                              >
                                added users:
                                <div
                                  style={{
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                  }}
                                >
                                  {act.map((el, index) => {
                                    return (
                                      <span style={styles.type}>
                                        {el.user}{' '}
                                        {index !== act.length - 1 ? ',' : ''}
                                      </span>
                                    );
                                  })}
                                </div>
                                to the
                                <span
                                  style={{
                                    ...styles.type,
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                  }}
                                >
                                  {' '}
                                  {eng.teams[name] &&
                                  eng.teams[name][0]?.teamType ===
                                    TIMELINE.vendor
                                    ? companies?.vendor?.length > 0
                                      ? ` ${companies.vendor[0]} `
                                      : 'vendor'
                                    : companies?.customer?.length > 0
                                    ? ` ${companies.customer[0]} `
                                    : 'customer'}
                                </span>
                                team
                                <span style={styles.time}>
                                  {item.updatedAt
                                    ? formatTimeTo12HourFormat(item.updatedAt)
                                    : ''}
                                </span>
                              </div>
                            </Box>
                          </TimelineContent>
                        ));
                      }

                      if (eng.type === 'modified-activity') {
                        return (
                          <Box
                            style={{ ...styles.marginBotom10, width: '100%' }}
                          >
                            <Box
                              sx={styles.modifiedActivity}
                              style={styles.regularText}
                            >
                              <Typography style={styles.type}>
                                {item.user.fullName}
                              </Typography>{' '}
                              <Typography
                                marginLeft={'5px'}
                                marginRight={'5px'}
                                style={styles.regularText}
                              >
                                {Object.values(eng.activities)[0][0]?.activity
                                  ? 'updated'
                                  : 'added'}{' '}
                                an activity
                              </Typography>
                              <Typography style={styles.type}>
                                {Object.values(eng.activities)[0][0]
                                  ?.activity || '-'}
                              </Typography>
                              <Typography
                                marginLeft={'5px'}
                                style={styles.regularText}
                              >
                                in the{' '}
                              </Typography>
                              <Typography
                                style={{
                                  ...styles.plan,
                                  marginLeft: '5px',
                                  marginRight: '5px',
                                }}
                              >
                                {Object.values(eng.activities)[0][0]?.stage}
                              </Typography>
                              stage
                              <Typography style={styles.time}>
                                {eng.updatedAt
                                  ? formatTimeTo12HourFormat(eng.updatedAt)
                                  : '-'}
                              </Typography>
                            </Box>
                            {Object.entries(eng.activities).map(
                              ([name, activity]) => (
                                <TimelineContent style={{ width: '99%' }}>
                                  <Box sx={styles.activityBox}>
                                    <Typography style={styles.title}>
                                      {Object.values(eng.activities)[0][0]
                                        ?.subactivity ||
                                      Object.values(eng.activities)[0][0]
                                        ?.subactivity === ''
                                        ? 'Sub activity'
                                        : Object.values(eng.activities)[0][0]
                                            ?.activity ||
                                          Object.values(eng.activities)[0][0]
                                            ?.activity === ''
                                        ? 'Activity'
                                        : 'Stage'}
                                    </Typography>
                                    {activity?.map((single, index) => {
                                      if (
                                        single.new !== 'On Track' &&
                                        single.new !== 'Overdue' &&
                                        single.old !== 'Overdue'
                                      )
                                        return (
                                          <Box
                                            sx={{
                                              ...styles.activityWrapper,
                                              borderBottom:
                                                activity.length > 1 &&
                                                activity.filter(
                                                  (single) =>
                                                    single.new !== 'On Track' &&
                                                    single.new !== 'Overdue' &&
                                                    single.old !== 'Overdue'
                                                ).length >= 2
                                                  ? `1px solid ${mercury}`
                                                  : 'none',
                                            }}
                                            key={index}
                                          >
                                            <Typography
                                              sx={styles.activityTitle}
                                            >
                                              {single.property
                                                ? keysFromDb[single.property]
                                                : '-'}
                                            </Typography>
                                            <Box sx={styles.activityChange}>
                                              {single.property ===
                                              TIMELINE.status ? (
                                                <Typography
                                                  sx={{
                                                    ...styles.oldStatus,
                                                    backgroundColor:
                                                      !single.old ||
                                                      single.old === '' ||
                                                      single.old === 'On track'
                                                        ? 'transparent'
                                                        : bgColorFromKey[
                                                            single.old
                                                          ] || '#=',
                                                    color:
                                                      !single.old ||
                                                      single.old === '' ||
                                                      single.old === 'On track'
                                                        ? 'transparent'
                                                        : colorFromKey[
                                                            single.old
                                                          ] || '#EDF6F7',
                                                  }}
                                                >
                                                  {!single.old ||
                                                  single.old === '' ||
                                                  single.old === 'On track'
                                                    ? ''
                                                    : keysFromDb[single.old]}
                                                </Typography>
                                              ) : single.property ===
                                                  TIMELINE.targetDate ||
                                                single.property ===
                                                  TIMELINE.targetDate ? (
                                                <Typography
                                                  sx={{
                                                    ...styles.old,
                                                    textDecorationLine:
                                                      single.old
                                                        ? 'line-through'
                                                        : 'none',
                                                  }}
                                                >
                                                  {single.old
                                                    ? formatToTableDate(
                                                        single.old
                                                      )
                                                    : ''}
                                                </Typography>
                                              ) : (
                                                <Typography sx={styles.old}>
                                                  {!single.old ||
                                                  single.old === ''
                                                    ? ''
                                                    : single.property ===
                                                      TIMELINE.tag
                                                    ? keysFromDb[single.old]
                                                    : single.old}
                                                </Typography>
                                              )}
                                              {single.property ===
                                              TIMELINE.status ? (
                                                <Typography
                                                  sx={{
                                                    ...styles.newStatus,
                                                    height:
                                                      single.new ===
                                                        'On track' && '0',
                                                    backgroundColor:
                                                      single.new === 'On track'
                                                        ? 'transparent'
                                                        : bgColorFromKey[
                                                            single.new
                                                          ] || '#EDF6F7',
                                                    color:
                                                      single.new === 'On track'
                                                        ? 'transparent'
                                                        : colorFromKey[
                                                            single.new
                                                          ] || '#EDF6F7',
                                                    marginTop:
                                                      !single.old ||
                                                      single.old === '' ||
                                                      single.old === 'On track'
                                                        ? '0'
                                                        : single.new ===
                                                          'On track'
                                                        ? '-11px'
                                                        : '8px',
                                                  }}
                                                >
                                                  {single.new &&
                                                  single.new !== '' &&
                                                  single.new !== 'On track'
                                                    ? `${
                                                        keysFromDb[single.new]
                                                      }`
                                                    : ''}
                                                </Typography>
                                              ) : single.property ===
                                                  TIMELINE.targetDate ||
                                                single.property ===
                                                  TIMELINE.targetDate ? (
                                                <Typography
                                                  sx={{
                                                    ...styles.new,
                                                    marginTop: single.old
                                                      ? '8px'
                                                      : '0',
                                                  }}
                                                >
                                                  {single.new
                                                    ? formatToTableDate(
                                                        single.new
                                                      )
                                                    : '-'}
                                                </Typography>
                                              ) : (
                                                <Typography
                                                  sx={{
                                                    ...styles.new,
                                                    marginTop:
                                                      single.property !==
                                                        TIMELINE.tag &&
                                                      (!single.old ||
                                                        single.old === '')
                                                        ? '0'
                                                        : '8px',
                                                  }}
                                                >
                                                  {single.property ===
                                                  TIMELINE.tag
                                                    ? keysFromDb[single.new]
                                                    : single.new}
                                                </Typography>
                                              )}
                                            </Box>
                                          </Box>
                                        );
                                    })}
                                    {activity.length > 1 &&
                                      activity.filter(
                                        (single) =>
                                          single.new !== 'On Track' &&
                                          single.new !== 'Overdue' &&
                                          single.old !== 'Overdue'
                                      ).length >= 2 && <Divider light />}
                                  </Box>
                                </TimelineContent>
                              )
                            )}
                          </Box>
                        );
                      }
                    })}

                  {item.type === TIMELINE.shared && (
                    <TimelineContent>
                      <span style={styles.type}>{item.user}</span>
                      <span style={{ marginRight: '5px' }}>
                        {' '}
                        shared this MAP with
                      </span>

                      {item.sharedWith?.map((shared, index) => {
                        if (index === item.sharedWith.length - 1) {
                          return (
                            <span key={shared.user} style={styles.type}>
                              and {shared.user}
                              <span style={styles.time}>{item.updatedAt}</span>
                            </span>
                          );
                        } else {
                          return (
                            <span key={shared.user} style={styles.type}>
                              {shared.user}
                              {index === item.sharedWith?.length - 2
                                ? ' '
                                : ','}
                            </span>
                          );
                        }
                      })}
                    </TimelineContent>
                  )}
                </TimelineItem>
              );
            })}
          </Box>
        ))}
      {loaded && !data && (
        <Box sx={styles.noStage}>
          <Box sx={styles.noStageIcon}>
            {planStatus !== 'Draft' ? (
              <NoEngagementsIcon />
            ) : (
              <NoEngagementsIconGreen />
            )}
          </Box>
          <Box sx={styles.noStageText}>
            <Typography sx={styles.value}>No engagement found</Typography>
            <Typography sx={styles.label}>
              {planStatus !== 'Draft'
                ? 'There’s no data for selected filter settings.'
                : 'Share your plan to start tracking engagement.'}
            </Typography>
          </Box>
          {planStatus === 'Draft' && (
            <Box>
              <DynamicButton
                disabled={false}
                type="outlined"
                style={{
                  backgroundColor: '#F6F5F4',
                }}
                onClick={openShareModal}
              >
                Share plan
              </DynamicButton>
            </Box>
          )}
        </Box>
      )}
    </Timeline>
  );
};

const styles = {
  noStage: {
    width: '100%',
    height: 'auto',
    padding: '80px 0px',
    borderRadius: '8px',
    gap: '12px',
    // boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noStageIcon: {
    width: '64px',
    height: '64px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noStageText: {},
  value: {
    fontFamily: 'InterSemiBold',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.88)',
    textAlign: 'center',
    marginBottom: '4px',
  },
  label: {
    fontFamily: 'InterRegular',
    fontSize: '14px',
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.7)',
    textAlign: 'center',
  },
  divider: {
    padding: '8px 16px',
    backgroundColor: 'rgba(237, 235, 232, 1)',
    border: `none`,
    borderRadius: '100px',
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'InterMedium',
  },
  type: {
    fontFamily: 'InterBold',
    fontSize: '16px',
    lineHeight: '24px',
    color: black88,
  },
  displayCenter: { display: 'flex', alignItems: 'center' },
  regularText: {
    color: 'rgba(0, 0, 0, 0.7)',
    fontFamily: 'InterRegular',
    fontSize: '16px',
    lineHeight: '24px',
  },
  marginBotom10: {
    marginBottom: '10px',
  },
  modifiedActivity: {
    display: 'flex',
    alignItems: 'center',
    padding: '3px 16px',
  },
  plan: {
    fontFamily: 'InterBold',
    fontSize: '16px',
    lineHeight: '24px',
    color: black88,
  },
  time: {
    fontFamily: 'InterRegular',
    fontSize: '14px',
    lineHeight: '20px',
    color: black6,
    marginLeft: '10px',
  },
  deleted: {
    fontFamily: 'InterSemiBold',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'rgba(204, 0, 48, 1)',
    marginLeft: '5px',
  },
  title: {
    fontFamily: 'InterBold',
    fontSize: '14px',
    lineHeight: '20px',
    color: primary,
  },
  activityBox: {
    backgroundColor: white,
    padding: '24px',
    gap: '8px',
    // width: '1100px',
    border: `1px solid ${mercury}`,
    borderRadius: '8px',
    marginTop: '0',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
  },
  activityWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
  },
  activityTitle: {
    fontFamily: 'InterMedium',
    fontSize: '14px',
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.7)',
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: '4px',
    padding: '6px 12px',
  },
  activityChange: {
    marginLeft: '24px',
  },
  old: {
    lineHeight: '24px',
    fontSize: '16px',
    textDecorationLine: 'line-through',
    fontFamily: 'InterRegular',
    color: 'rgba(0, 0, 0, 0.45)',
    maxWidth: '1200px',
    overflow: 'auto',
    wordBreak: 'break-word',
  },
  oldStatus: {
    backgroundColor: '#F3EBFF',
    lineHeight: '24px',
    fontSize: '14px',
    padding: '2px 8px',
    textDecorationLine: 'line-through',
    fontFamily: 'InterRegular',
    color: '#330471',
    borderRadius: '4px',
    maxWidth: '1200px',
    overflow: 'auto',
    wordBreak: 'break-word',
  },
  new: {
    lineHeight: '24px',
    fontSize: '16px',
    fontFamily: 'InterRegular',
    color: black88,
    maxWidth: '1200px',
    overflow: 'auto',
    wordBreak: 'break-word',
  },
  newStatus: {
    lineHeight: '20px',
    fontSize: '14px',
    padding: '6px 12px',
    fontFamily: 'InterMedium',
    width: 'max-content',
    borderRadius: '4px',
    maxWidth: '1200px',
    overflow: 'auto',
    wordBreak: 'break-word',
  },
};

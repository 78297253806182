import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, Typography } from '@mui/material';
import { NotFoundAvatar } from '../assets';
import styles from '../styles/notFound/notFound.module.css';
import DynamicButton from '../components/new/Button';

export const NotFound = () => {
  return (
    <Container maxWidth="lg" className={styles.container}>
      <Grid container columnSpacing={9} rowSpacing={3} className={styles.grid}>
        <Grid item className={styles.grid_item_1} xs={12} md={3}>
          <img src={NotFoundAvatar} alt="Not Found" />
        </Grid>
        <Grid item className={styles.grid_item_2} xs={12} md={7}>
          <Typography variant="h3" className={styles.heading}>
            Oops! We couldn’t find the requested page 😢
          </Typography>
          <Typography variant="body1" className={styles.subheading}>
            We’re sorry, the requested page cannot be found, please go back to
            the homepage.
          </Typography>
          <Link to="/" className={styles.link}>
            <DynamicButton type="contained" className={styles.home_btn}>
              Go home
            </DynamicButton>
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import Stage from './Stage';
import { StrictModeDroppable } from '../common/StrictModeDroppable';
import { bgColor, lochinvar, primary, white } from '../../constants/colors';
import DynamicButton from '../new/Button';
import { ReactComponent as NoStageIcon } from '../../assets/imgs/noStage.svg';
import { store } from '../../Store';

const ActivitySection = ({
  data,
  stages,
  durationOptions,
  handleExpand,
  onDragEnd,
  onActivityDragEnd,
  onSubActivityDragEnd,
  handleAddNewActivity,
  handleRemoveActivity,
  handleDeleteStage,
  handleDuplicateStage,
  handleAddTeamMember,
  handleStageActionsOpen,
  handleStageActionsClose,
  handleActivityFieldsChange,
  handleSubActivityChange,
  handleAddNewSubActivity,
  handleRemoveSubActivity,
  handleExpandSubActivities,
  handleRemoveTeamMember,
  handleTeamMembersModalOpen,
  handleEditStageTitle,
  template = false,
  handleCreateNewStage,
  userExist = true,
  handleCreate,
  isSharing,
  addUserToTeam,
  handleServiceRemove,
  handleClearInput,
  vendorCompany,
  handlePostSale,
  isPageLoaded = true,
  isView = false,
  handleLoginAndRedirect,
  isVendor = false,
}) => {
  const [canUpdateTemplate, setCanUpdateTemplate] = useState(false);

  const { state } = useContext(store);

  useEffect(() => {
    if (template) {
      const user = state?.data?.user?._id || false;
      const templateOwner = data?.userId?._id || data?.userId;

      if (user && user + '' === templateOwner + '') {
        setCanUpdateTemplate(true);
      } else {
        setCanUpdateTemplate(false);
      }
    }
  }, [data]);

  return (
    <Box sx={styles.main}>
      <Box
        sx={stages && stages.length ? styles.container : styles.containerAuto}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <StrictModeDroppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {stages && stages?.length > 0 ? (
                  stages.map((item, index) => (
                    <Draggable
                      key={index}
                      draggableId={`${index}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <Stage
                          key={index}
                          plan={data}
                          stage={item}
                          stageIndex={index}
                          provided={provided}
                          durationOptions={durationOptions}
                          handleExpand={handleExpand}
                          handleAddNewActivity={handleAddNewActivity}
                          handleRemoveActivity={handleRemoveActivity}
                          handleStageActionsOpen={handleStageActionsOpen}
                          handleStageActionsClose={handleStageActionsClose}
                          handleDuplicateStage={handleDuplicateStage}
                          handleEditStageTitle={handleEditStageTitle}
                          handleDeleteStage={handleDeleteStage}
                          onActivityDragEnd={onActivityDragEnd}
                          onSubActivityDragEnd={onSubActivityDragEnd}
                          handleAddTeamMember={handleAddTeamMember}
                          handleActivityFieldsChange={
                            handleActivityFieldsChange
                          }
                          handleSubActivityChange={handleSubActivityChange}
                          handleAddNewSubActivity={handleAddNewSubActivity}
                          handleRemoveSubActivity={handleRemoveSubActivity}
                          handleExpandSubActivities={handleExpandSubActivities}
                          handleRemoveTeamMember={handleRemoveTeamMember}
                          handleTeamMembersModalOpen={
                            handleTeamMembersModalOpen
                          }
                          template={template}
                          handleCreateNewStage={handleCreateNewStage}
                          isSharing={isSharing}
                          addUserToTeam={addUserToTeam}
                          handleServiceRemove={handleServiceRemove}
                          handleClearInput={handleClearInput}
                          vendorCompany={vendorCompany}
                          handlePostSale={handlePostSale}
                          canUpdateTemplate={canUpdateTemplate}
                          isVendor={isVendor}
                        />
                      )}
                    </Draggable>
                  ))
                ) : template ? (
                  isView ? (
                    <Box sx={styles.noStage}>
                      <Box sx={styles.noStageIcon}>
                        <NoStageIcon />
                      </Box>
                      <Box sx={styles.noStageText}>
                        <Typography sx={styles.value}>
                          Login to view template
                        </Typography>
                      </Box>
                      <DynamicButton
                        type="contained"
                        onClick={handleLoginAndRedirect}
                      >
                        Login
                      </DynamicButton>
                    </Box>
                  ) : isPageLoaded ? (
                    <Box sx={styles.noStage}>
                      <Box sx={styles.noStageIcon}>
                        <NoStageIcon />
                      </Box>
                      <Box sx={styles.noStageText}>
                        <Typography sx={styles.value}>
                          You don't have any sales stages
                        </Typography>
                        <Typography sx={styles.label}>
                          Add sales stages below to create a template
                        </Typography>
                      </Box>
                      <DynamicButton
                        disabled={template ? !canUpdateTemplate : isSharing}
                        type="contained"
                        onClick={handleCreateNewStage}
                      >
                        Add stage
                      </DynamicButton>
                    </Box>
                  ) : null
                ) : null}
                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>

        {!isSharing && data?.stages?.length > 0 && (
          <Box
            sx={styles.newStageBtn}
            disabled={template ? !canUpdateTemplate : isSharing}
            onClick={() =>
              (template && !canUpdateTemplate) || isSharing
                ? console.log('Not allowed')
                : handleCreateNewStage()
            }
          >
            New stage
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ActivitySection;

const styles = {
  main: {
    '@media screen and (max-width: 1390px)': {
      overflowX: 'auto',
    },
  },
  container: {
    py: '20px',
    minWidth: '800px',
    background: bgColor,
  },
  containerAuto: {
    py: '20px',
    background: bgColor,
  },
  header: {
    my: '16px',
    padding: '18px 0px',
    borderRadius: '4px',
    backgroundColor: lochinvar,
    color: 'white',
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'SoehneBold',
  },
  newStageBtn: {
    backgroundColor: bgColor,
    borderRadius: '4px',
    color: 'rgba(0, 155, 119, 1)',
    border: '1px dashed rgba(0, 155, 119, 1)',
    width: '99.8%',
    height: '72px',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'InterSemiBold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      filter: 'brightness(99%)',
    },
  },
  addIcon: { color: primary },
  noStage: {
    width: '100%',
    height: 'auto',
    padding: '80px 0px',
    borderRadius: '8px',
    gap: '24px',
    backgroundColor: white,
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noStageIcon: {
    width: '64px',
    height: '64px',
    borderRadius: '10px',
    backgroundColor: 'rgba(242, 240, 238, 1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noStageText: {},
  value: {
    fontFamily: 'InterSemiBold',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.88)',
    textAlign: 'center',
  },
  label: {
    fontFamily: 'InterRegular',
    fontSize: '14px',
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.7)',
    textAlign: 'center',
    maxWidth: '292px',
    marginTop: '5px',
  },
};

import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { store } from '../../Store';
import { Logo } from '../../assets';
import { ReactComponent as TemplatesLogo } from '../../assets/imgs/templates.svg';
import { ReactComponent as TemplatesLogoActive } from '../../assets/imgs/templateTabActive.svg';
import { Typography } from '@mui/material';
import { black, mercury, primary } from '../../constants/colors';

const DashboardSidebar = (props) => {
  const navigate = useNavigate();
  const { state } = useContext(store);
  const [active, setActive] = useState(
    props.active ? props.active : 'opportunities'
  );

  return (
    <>
      <Box style={styles.sidebar}>
        <Link to="/dashboard" style={{ marginBottom: '10px' }}>
          <Typography variant="h6" component="div" style={styles.logo}>
            <img src={Logo} alt="Logo" style={styles.logoImg} />
          </Typography>
        </Link>
        <Box
          style={styles.item}
          onClick={() => {
            setActive('opportunities');
            navigate('/dashboard');
          }}
        >
          <Box
            sx={active === 'opportunities' ? styles.activeIcon : styles.icon}
          >
            <AssignmentIcon
              sx={{
                fill: active === 'opportunities' ? primary : black,
                opacity: active === 'opportunities' ? '1' : '0.6',
              }}
            />
          </Box>

          <Typography variant="h6" component="div" style={styles.text}>
            Opportunities
          </Typography>
        </Box>
        <Box
          style={styles.item}
          onClick={() => {
            setActive('templates');
            navigate('/templates');
          }}
        >
          <Box sx={active === 'templates' ? styles.activeIcon : styles.icon}>
            {active === 'templates' ? (
              <TemplatesLogoActive
                style={{ width: '23.98px', height: '23.98px' }}
              />
            ) : (
              <TemplatesLogo style={{ width: '23.98px', height: '23.98px' }} />
            )}
          </Box>

          <Typography variant="h6" component="div" style={styles.text}>
            Templates
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default DashboardSidebar;

const styles = {
  sidebar: {
    backgroundColor: '#F6F5F4',
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px',
    cursor: 'pointer',
  },
  icon: {
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: 'rgba(142, 128, 113, 0.08)',
      transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  },
  activeIcon: {
    backgroundColor: 'rgba(142, 128, 113, 0.08)',
    borderRadius: '8px',
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontFamily: 'InterMedium',
    fontSize: '12px',
    opacity: 0.7,
  },
  logoImg: {
    width: '28px',
    height: '32px',
  },
};

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';
import PlanDropdown from './PlanDropdown';
import InputWithLabel from '../common/InputWithLabel';
import { BlackTextLogo, ImageWithIllustration } from '../../assets';
import styles from '../../styles/signup/signupGrid.module.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { showNotification } from '../common/Toast';

const options = [
  {
    name: 'Standard Plan',
    value: 'standard',
    price: 'Free',
    billingType: 'Free forever',
  },
  {
    name: 'Pro Plan',
    value: 'pro',
    price: '$15',
    billingType: 'Billed monthly',
  },
];

const SignupGrid = ({}) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    password: '',
    plan: options[0].value,
  });

  const handleChangeData = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const registerUser = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}api/user/register`, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        company: 'Inquarter',
        pricing: '64818db9288eb00db89952e7',
        email: formData.email,
        password: formData.password,
      })
      .then((res) => {
        showNotification(
          'success',
          'Successfully registered user! Please check your email!'
        );
        navigate('/login');
      })
      .catch((err) => showNotification('error', err?.response?.data?.message));
  };

  const handleChange = () => {};
  return (
    <Grid container spacing={2} className={styles.main}>
      <Grid item xs={12} lg={4.5} md={5} columnSpacing={4}>
        <Box textAlign="center">
          <img src={BlackTextLogo} alt="logo" width="220px" />
          <Typography variant="h4" className={styles.heading}>
            Sign up
          </Typography>
        </Box>

        <Box>
          <PlanDropdown
            options={options}
            handleChange={handleChange}
            value={formData.plan}
            disabled
          />

          <Grid container spacing={0} columnSpacing={2}>
            <Grid item xs={12} md={6}>
              <InputWithLabel
                type="text"
                label="First Name"
                name="firstName"
                value={formData.firstName}
                placeholder="First name"
                handleChange={handleChangeData}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputWithLabel
                type="text"
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                placeholder="Last name"
                handleChange={handleChangeData}
              />
            </Grid>
          </Grid>

          <InputWithLabel
            type="text"
            label="Company Name"
            name="companyName"
            value={'Inquarter'}
            placeholder="Company name"
            handleChange={handleChangeData}
            disabled
          />
          <InputWithLabel
            type="email"
            label="Business Email Address"
            name="email"
            value={formData.email}
            placeholder="johndoe@companyname.com"
            handleChange={handleChangeData}
          />
          <InputWithLabel
            type="password"
            label="Password"
            name="password"
            value={formData.password}
            placeholder="Enter Password"
            handleChange={handleChangeData}
          />

          <Button
            type="submit"
            variant="contained"
            fullWidth
            className={styles.signup_btn}
            onClick={registerUser}
          >
            Sign up
          </Button>
        </Box>
        <Box className={styles.login}>
          <Link to="/login" className={styles.login_btn}>
            Login
          </Link>
        </Box>
      </Grid>
      <Grid item lg={6} md={7} sx={{ display: { xs: 'none', md: 'block' } }}>
        <img
          src={ImageWithIllustration}
          alt="signup"
          className={styles.signup_image}
        />
      </Grid>
    </Grid>
  );
};

export default SignupGrid;

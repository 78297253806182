import { Box, Divider, Typography } from '@mui/material';
import { ReactComponent as ClockIcon } from '../../assets/imgs/clock.svg';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ShareIcon from '@mui/icons-material/Share';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { ReactComponent as BellIcon } from '../../assets/imgs/notifications.svg';
import styles from '../../styles/dashboardHeader.module.css';
import { countUnreadObjects, notificationTime } from '../../utils/utils';
import { useEffect, useState } from 'react';
import { primary } from '../../constants/colors';
import httpClient from '../../config/http.service';

export const Notification = ({
  notifications,
  setNotifications,
  openPlan,
  openNotificationList,
  setOpenNotificationList,
  handleNotificationButtonClick,
}) => {
  const [notificationLength, setNotificationsLength] = useState(0);

  useEffect(() => {
    setNotificationsLength(
      notifications?.length ? countUnreadObjects(notifications) : 0
    );
  }, [notifications]);

  const markAsRead = () => {
    httpClient
      .patch(`api/notification/update`, { notifications })
      .then((res) => {
        const getUnread = notifications;
        getUnread.map((el) => (el.opened = true));
        setNotifications(getUnread);
        setNotificationsLength(0);
        setOpenNotificationList(false);
      })
      .catch((err) => console.log('err'));
  };

  return (
    <Box className={styles.notifications}>
      <BellIcon
        className={styles.alerts_icon}
        onClick={handleNotificationButtonClick}
      />
      {notificationLength !== 0 && (
        <span className={styles.alert}>
          {countUnreadObjects(notifications)}
        </span>
      )}
      {openNotificationList && (
        <Box className={styles.notificationList}>
          <Box className={styles.heading}>
            <Typography className={styles.title}>Notifications</Typography>
            <Typography className={styles.markAsRead} onClick={markAsRead}>
              Mark all as read
            </Typography>
          </Box>
          <Divider className={styles.divider}></Divider>
          <Box className={styles.listHeight}>
            {notifications?.length > 0 ? (
              notifications.map((el, index) => (
                <Box
                  className={el.opened ? styles.single : styles.singleUnread}
                  key={index}
                >
                  {el.subType === 'modified' && (
                    <Box className={styles.box}>
                      <EditIcon style={{ color: primary }} />

                      {el.data[0]?.type !== 'shareList' ? (
                        <Box className={styles.boxContent}>
                          <Typography className={styles.content}>
                            {el.user?.fullName || el.user?.email} modified{' '}
                            {el.data[0]?.activity ? 'activity ' : ''}
                            {el.data[0]?.activity || '-'} in{' '}
                            {el.data[0]?.stage || '-'}
                          </Typography>
                          <Box className={styles.bottomNotification}>
                            <Typography
                              onClick={() => openPlan(el.plan?._id)}
                              className={styles.viewButton}
                            >
                              {el.plan
                                ? el.plan.name.length > 30
                                  ? el.plan.name.slice(0, 30) + '...'
                                  : el.plan.name
                                : ''}
                            </Typography>
                            <Typography className={styles.viewButtonDate}>
                              <span className={styles.dot}>a</span>
                              {el.createdAt &&
                                notificationTime(new Date(el.createdAt))}
                            </Typography>
                          </Box>
                        </Box>
                      ) : (
                        <Box className={styles.boxContent}>
                          <Typography className={styles.content}>
                            {el.user?.fullName || el.user?.email} modified share
                            list
                          </Typography>
                          <Box className={styles.bottomNotification}>
                            <Typography
                              onClick={() => openPlan(el.plan?._id)}
                              className={styles.viewButton}
                            >
                              {el.plan
                                ? el.plan.name.length > 30
                                  ? el.plan.name.slice(0, 30) + '...'
                                  : el.plan.name
                                : ''}
                            </Typography>
                            <Typography className={styles.viewButtonDate}>
                              <span className={styles.dot}>a</span>
                              {el.createdAt &&
                                notificationTime(new Date(el.createdAt))}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  )}
                  {el.subType === 'planView' && (
                    <Box className={styles.box}>
                      <VisibilityIcon style={{ color: primary }} />

                      <Box className={styles.boxContent}>
                        <Typography className={styles.content}>
                          {el.user?.fullName || el.user?.email} from{' '}
                          {
                            el.data.find(
                              (shared) => shared?.email === el.user?.email
                            )?.company
                          }{' '}
                          viewed plan {/* {el.plan?.name} */}
                        </Typography>
                        <Box className={styles.bottomNotification}>
                          <Typography
                            onClick={() => openPlan(el.plan?._id)}
                            className={styles.viewButton}
                          >
                            {el.plan
                              ? el.plan.name.length > 30
                                ? el.plan.name.slice(0, 30) + '...'
                                : el.plan.name
                              : ''}
                          </Typography>
                          <Typography className={styles.viewButtonDate}>
                            <span className={styles.dot}>a</span>
                            {el.createdAt &&
                              notificationTime(new Date(el.createdAt))}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {el.subType === 'copyLink' && (
                    <Box className={styles.box}>
                      <VisibilityIcon style={{ color: primary }} />

                      <Box className={styles.boxContent}>
                        <Typography className={styles.content}>
                          Someone viewed your plan via Copy link
                        </Typography>
                        <Box className={styles.bottomNotification}>
                          <Typography
                            onClick={() => openPlan(el.plan?._id)}
                            className={styles.viewButton}
                          >
                            {el.plan
                              ? el.plan.name.length > 30
                                ? el.plan.name.slice(0, 30) + '...'
                                : el.plan.name
                              : ''}
                          </Typography>
                          <Typography className={styles.viewButtonDate}>
                            <span className={styles.dot}>a</span>
                            {el.createdAt &&
                              notificationTime(new Date(el.createdAt))}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {el.subType === 'overdue' && (
                    <Box className={styles.box}>
                      <AccessAlarmIcon style={{ color: primary }} />

                      <Box className={styles.boxContent}>
                        <Typography className={styles.content}>
                          {el.data[0]?.activity || '-'} activity is due in 24
                          hours
                          {/* {el.dueDate} */}
                        </Typography>
                        <Box className={styles.bottomNotification}>
                          <Typography
                            onClick={() => openPlan(el.plan?._id)}
                            className={styles.viewButton}
                          >
                            {el.plan
                              ? el.plan.name.length > 30
                                ? el.plan.name.slice(0, 30) + '...'
                                : el.plan.name
                              : ''}
                          </Typography>
                          <Typography className={styles.viewButtonDate}>
                            <span className={styles.dot}>a</span>
                            {el.createdAt &&
                              notificationTime(new Date(el.createdAt))}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {el.subType === 'shared' && (
                    <Box className={styles.box}>
                      <ShareIcon style={{ color: primary }} />

                      <Box className={styles.boxContent}>
                        <Typography className={styles.content}>
                          {el.user?.fullName || el.user?.email} from{' '}
                          {
                            el.data.find(
                              (shared) => shared?.email === el.user?.email
                            )?.company
                          }{' '}
                          shared your plan
                        </Typography>
                        <Box className={styles.bottomNotification}>
                          <Typography
                            onClick={() => openPlan(el.planId)}
                            className={styles.viewButton}
                          >
                            {el.plan
                              ? el.plan.name.length > 30
                                ? el.plan.name.slice(0, 30) + '...'
                                : el.plan.name
                              : ''}
                          </Typography>
                          <Typography className={styles.viewButtonDate}>
                            <span className={styles.dot}>a</span>
                            {el.createdAt &&
                              notificationTime(new Date(el.createdAt))}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              ))
            ) : (
              <Typography className={styles.noNotifications}>
                There is no notifications
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

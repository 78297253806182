import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import SubActivity from './SubActivity';
import { StrictModeDroppable } from '../common/StrictModeDroppable';
import MultiColorSelectInput from '../common/MultiColorSelectInput';
import {
  planStatusOptions,
  planTagOptions,
  planTagOptionsVendor,
} from '../../utils/constants';
import { ReactComponent as EditTeamsIcon } from '../../assets/imgs/editTeams.svg';
import { ReactComponent as CheckMarkIcon } from '../../assets/imgs/check_circle-20.svg';
import targetDateCheck from '../../utils/targetDateCheck';
import {
  lightGray,
  primary,
  white,
  warning,
  shark,
  mercury,
  wildSand,
  black20,
  black88,
  black6,
} from '../../constants/colors';
import DynamicButton from '../new/Button';
import AddIcon from '@mui/icons-material/Add';
import CustomMultiSelect from '../../modals/CustomMultiselect';
import { formatToCompletionDate } from '../../utils/utils';
import { ActivityNameInput } from '../common/ActivityNameInput';
import { ActivityDurationSelectInput } from '../common/ActivityDurationSelectInput';
import { ActivityTag } from '../common/ActivityTag';
import { ActivityTextarea } from '../common/ActivityTextarea';
import { ReactComponent as TeamIcon } from '../../assets/imgs/teamIcon.svg';
import { ActivityDatePicker } from '../common/ActivityDatePicker';
import removeIcon from '../../assets/imgs/IconButtonRemove.svg';

const Activity = ({
  plan,
  activity,
  activityIndex,
  stageIndex,
  provided,
  durationOptions,
  totalActivities,
  handleAddTeamMember,
  handleRemoveActivity,
  onSubActivityDragEnd,
  handleChange,
  handleSubActivityChange,
  handleAddNewSubActivity,
  handleRemoveSubActivity,
  handleExpandSubActivities,
  handleRemoveTeamMember,
  handleTeamMembersModalOpen,
  template = false,
  isSharing = false,
  addUserToTeam,
  handleServiceRemove,
  handleClearInput,
  vendorCompany,
  canUpdateTemplate = false,
  isVendor = false,
}) => {
  const [team, setTeam] = useState('customerTeam');
  const [openShareModal, setOpenShareModal] = useState(false);
  const [customerTeam, setCustomerTeam] = useState('');
  const [vendorTeam, setVendorTeam] = useState('');
  const [showMore, setShowMore] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [hoveringDate, setHoveringDate] = useState(false);
  const completedActivities = activity.subActivities.reduce(function (
    total,
    value
  ) {
    return value.status === 'completed' ? total + 1 : total;
  },
  0);

  const getName = (companyName, type) => {
    let name = '';
    if (companyName) {
      const team = companyName;
      if (team.length > 21) {
        name = companyName.slice(0, 18) + '...';
      } else {
        name = companyName;
      }
    } else {
      name = `${type} team`;
    }
    return name;
  };

  const getInitials = (member) => {
    let initials = '';
    if (member && member.name) {
      initials += member.name.charAt(0).toUpperCase();
    }
    return initials;
  };

  const getMemeberName = (member) => {
    let name = '';
    if (member && member.name) {
      if (member.name.length >= 14) {
        if (window.innerWidth < 1340) name = member.name.slice(0, 11) + '...';
        else name = member.name;
      } else {
        name = member.name;
      }
    }
    return name;
  };

  useEffect(() => {
    const customer = getName(plan?.customerCompany, 'Customer');
    const vendor = getName(vendorCompany?.name, 'Vendor');
    setCustomerTeam(customer);
    setVendorTeam(vendor);
  }, [plan, vendorCompany]);

  return (
    <>
      {(isVendor || activity?.tag !== 'vendor_internal_not_shared') && (
        <Box
          sx={styles.main}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div style={styles.activityWrapper}>
            <div style={{ flex: '1' }}>
              <ActivityNameInput
                readOnly={(template && !canUpdateTemplate) || isSharing}
                name="name"
                value={activity.name}
                placeholder="Untitled activity"
                styles={
                  activity.status === 'completed'
                    ? {
                        ...styles.completedActivity,
                        padding: '0',
                        marginTop: '0',
                        height: '32px',
                      }
                    : {
                        padding: '0',
                        marginTop: '0',
                        height: '32px',
                      }
                }
                placeholderStyles={styles.placeholderStylesLarger}
                handleChange={(e) => handleChange(e, stageIndex, activityIndex)}
                activity
              />
            </div>
            <div style={{ height: '32px' }}>
              <ActivityDurationSelectInput
                readOnly={(template && !canUpdateTemplate) || isSharing}
                name="duration"
                minutesShorthand={true}
                value={activity.duration}
                options={durationOptions}
                handleChange={(e) => handleChange(e, stageIndex, activityIndex)}
                disabled={template}
              />
            </div>
            <div
              style={{
                marginLeft: '8px',
                backgroundColor:
                  activity?.tag === 'vendor_internal'
                    ? black88
                    : activity?.tag === ''
                    ? 'transparent'
                    : 'rgba(0, 0, 0, 0.05)',
                borderRadius: '16px',
              }}
            >
              <ActivityTag
                name="tag"
                value={activity.tag}
                readOnly={(template && !canUpdateTemplate) || isSharing}
                options={isVendor ? planTagOptionsVendor : planTagOptions}
                menuItemStyles={styles.menuItemStyles}
                handleChange={(e) => handleChange(e, stageIndex, activityIndex)}
                disabled={template}
              />
            </div>
          </div>
          <Grid
            container
            columnSpacing={2}
            style={{
              flexWrap: 'initial',
              height: showMore || expanded ? 'auto' : '70px',
              display: 'grid',
              gridTemplateColumns: '2fr 1fr 1fr 2fr 208px',
            }}
          >
            <Grid item xs style={{ paddingLeft: '20px', minHeight: '70px' }}>
              <ActivityTextarea
                showMore={showMore}
                label="Description"
                rows="5"
                readOnly={(template && !canUpdateTemplate) || isSharing}
                multiline={true}
                name="description"
                value={activity.description}
                placeholder="No details"
                setExpanded={setExpanded}
                setShowMore={setShowMore}
                expanded={expanded}
                styles={
                  activity.status === 'completed'
                    ? {
                        ...styles.activityDesc,
                        ...styles.completedActivity,
                        marginTop: '4px',
                        marginBottom: '0',
                        minHeight: showMore && expanded ? '200px' : '35px',
                        height:
                          showMore || expanded
                            ? window.innerWidth > 1430
                              ? '165px'
                              : '200px'
                            : '35px',
                        padding: '4px 8px 0 8px',
                      }
                    : {
                        ...styles.activityDesc,
                        marginTop: '4px',
                        marginBottom: '0',
                        minHeight:
                          showMore || expanded ? 'fit-content' : '35px',
                        height:
                          showMore || expanded
                            ? window.innerWidth > 1430
                              ? 'fit-content'
                              : 'fit-content'
                            : '35px',
                      }
                }
                handleChange={(e) => handleChange(e, stageIndex, activityIndex)}
                description
              />
            </Grid>
            <Grid
              item
              textAlign="left"
              style={{ marginLeft: '16px', paddingLeft: '0' }}
            >
              <Typography
                style={{
                  ...styles.heading,
                  color: template ? 'black' : black6,
                  marginBottom: template
                    ? '0'
                    : activity.customerTeam?.length > 0
                    ? '8px'
                    : '0',
                  opacity: template ? '0.2' : '1',
                }}
              >
                <TeamIcon />{' '}
                {customerTeam?.length > 6 && window.innerWidth < 1130
                  ? customerTeam?.slice(0, 8) + '...'
                  : customerTeam?.length > 12 && window.innerWidth < 1230
                  ? customerTeam?.slice(0, 8) + '...'
                  : customerTeam?.length > 12 && window.innerWidth < 1330
                  ? customerTeam?.slice(0, 12) + '...'
                  : customerTeam?.length > 14 && window.innerWidth < 1430
                  ? customerTeam?.slice(0, 10) + '...'
                  : customerTeam?.length > 14 && window.innerWidth < 1530
                  ? customerTeam?.slice(0, 12) + '...'
                  : customerTeam}
              </Typography>
              {activity.customerTeam?.length === 0 ||
              (activity.customerTeam?.length > 0 &&
                activity.customerTeam[0].name === '') ? (
                <Box sx={{ width: '100%' }}>
                  {/* {!isSharing && ( */}
                  <DynamicButton
                    disabled={isSharing || template}
                    type="outlined"
                    style={{
                      width: window.innerWidth < 1300 ? '104%' : '100%',
                      border: 'none',
                      paddingLeft: '8px',
                      justifyContent: 'flex-start',
                    }}
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setTeam('customerTeam');
                      setOpenShareModal(true);
                    }}
                    borderDisabled
                  >
                    Add team
                  </DynamicButton>
                  {/* )} */}
                </Box>
              ) : (
                <Box sx={styles.memberInputDiv}>
                  {showMore ? (
                    activity.customerTeam.map((member, index) => (
                      <Box key={index} sx={styles.singleRowMember}>
                        <Box
                          sx={[
                            styles.circle,
                            {
                              backgroundColor:
                                member?.backgroundColor || primary,
                              width: '25.05px',
                            },
                          ]}
                        >
                          {getInitials(member)}
                        </Box>
                        <Typography style={styles.item}>
                          {getMemeberName(member)}
                        </Typography>
                      </Box>
                    ))
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Box sx={{ display: 'flex' }}>
                        {activity.customerTeam
                          .slice(0, 6)
                          .map((member, index) => (
                            <Box
                              sx={[
                                styles.circle,
                                {
                                  backgroundColor:
                                    member?.backgroundColor || primary,
                                  marginLeft: index === 0 ? '0px' : '-8px',
                                },
                              ]}
                            >
                              {' '}
                              {getInitials(member)}{' '}
                            </Box>
                          ))}
                      </Box>
                      {activity.customerTeam.length > 6 && (
                        <Tooltip
                          sx={{
                            fontSize: '12px',
                            width: '100%',
                          }}
                          title={
                            activity.customerTeam?.length > 0 && (
                              <Box
                                sx={{
                                  opacity: '0.88',
                                }}
                              >
                                {activity.customerTeam.map((user) => (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontFamily: 'InterRegular',
                                        fontSize: '14px',
                                        textAlign: 'center',
                                        color: white,
                                      }}
                                    >
                                      {user.name}
                                    </Typography>
                                  </Box>
                                ))}
                              </Box>
                            )
                          }
                          arrow
                        >
                          <Box sx={{ ...styles.preview, width: '65px' }}>
                            <Typography sx={styles.moreUsers}>
                              <strong>
                                +{activity.customerTeam.length - 5}
                              </strong>{' '}
                              more
                            </Typography>
                          </Box>
                        </Tooltip>
                      )}
                    </Box>
                  )}
                  {!isSharing && (
                    <Typography
                      style={styles.editTeamBtn}
                      onClick={() => {
                        setTeam('customerTeam');
                        setOpenShareModal(true);
                      }}
                    >
                      <EditTeamsIcon /> Edit team
                    </Typography>
                  )}
                </Box>
              )}
            </Grid>
            <Grid
              item
              textAlign="left"
              style={{ marginLeft: '16px', paddingLeft: '0' }}
            >
              <Typography
                style={{
                  ...styles.heading,
                  color: template ? 'black' : black6,
                  marginBottom: template
                    ? '0'
                    : activity.vendorTeam?.length > 0
                    ? '8px'
                    : '0',
                  opacity: template ? '0.2' : '1',
                }}
              >
                <TeamIcon />
                {vendorTeam?.length > 6 && window.innerWidth < 1130
                  ? vendorTeam?.slice(0, 8) + '...'
                  : vendorTeam?.length > 6 && window.innerWidth < 1230
                  ? vendorTeam?.slice(0, 8) + '...'
                  : vendorTeam?.length > 12 && window.innerWidth < 1330
                  ? vendorTeam?.slice(0, 12) + '...'
                  : vendorTeam?.length > 14 && window.innerWidth < 1430
                  ? vendorTeam?.slice(0, 10) + '...'
                  : vendorTeam?.length > 14 && window.innerWidth < 1530
                  ? vendorTeam?.slice(0, 12) + '...'
                  : vendorTeam}
              </Typography>
              {activity.vendorTeam?.length === 0 ||
              (activity.vendorTeam?.length > 0 &&
                activity.vendorTeam[0].name === '') ? (
                <Box sx={{ width: '100%' }}>
                  {/* {!isSharing && ( */}
                  <DynamicButton
                    disabled={isSharing || template}
                    type="outlined"
                    style={{
                      width: window.innerWidth < 1230 ? '104%' : '100%',
                      border: 'none',
                      paddingLeft: '8px',
                      justifyContent: 'flex-start',
                    }}
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setTeam('vendorTeam');
                      setOpenShareModal(true);
                    }}
                    borderDisabled
                  >
                    Add team
                  </DynamicButton>
                  {/* )} */}
                </Box>
              ) : (
                <Box sx={styles.memberInputDiv}>
                  {showMore ? (
                    activity.vendorTeam.map((member, index) => (
                      <Box key={index} sx={styles.singleRowMember}>
                        <Box
                          sx={[
                            styles.circle,
                            {
                              backgroundColor:
                                member.backgroundColor || primary,
                              width: '25.05px',
                            },
                          ]}
                        >
                          {' '}
                          {getInitials(member)}{' '}
                        </Box>
                        <Typography style={styles.item}>
                          {getMemeberName(member)}
                        </Typography>
                      </Box>
                    ))
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Box sx={{ display: 'flex' }}>
                        {activity.vendorTeam
                          .slice(0, 6)
                          .map((member, index) => (
                            <Tooltip
                              title={member?.name || ''}
                              placement="top"
                              arrow
                            >
                              <Box
                                sx={[
                                  styles.circle,
                                  {
                                    backgroundColor:
                                      member?.backgroundColor || primary,
                                    marginLeft: index === 0 ? '0px' : '-8px',
                                  },
                                ]}
                              >
                                {' '}
                                {getInitials(member)}{' '}
                              </Box>
                            </Tooltip>
                          ))}
                      </Box>
                      {activity.vendorTeam.length > 6 && (
                        <Tooltip
                          sx={{
                            fontSize: '12px',
                            width: '100%',
                          }}
                          title={
                            activity.vendorTeam?.length > 0 && (
                              <Box
                                sx={{
                                  opacity: '0.88',
                                }}
                              >
                                {activity.vendorTeam.map((user) => (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontFamily: 'InterRegular',
                                        fontSize: '14px',
                                        textAlign: 'center',
                                        color: white,
                                      }}
                                    >
                                      {user.name}
                                    </Typography>
                                  </Box>
                                ))}
                              </Box>
                            )
                          }
                          arrow
                        >
                          <Box sx={{ ...styles.preview, width: '65px' }}>
                            <Typography sx={styles.moreUsers}>
                              <strong>+{activity.vendorTeam.length - 5}</strong>{' '}
                              more
                            </Typography>
                          </Box>
                        </Tooltip>
                      )}
                    </Box>
                  )}
                  {!isSharing && (
                    <Typography
                      style={styles.editTeamBtn}
                      onClick={() => {
                        setTeam('vendorTeam');
                        setOpenShareModal(true);
                      }}
                    >
                      <EditTeamsIcon /> Edit team
                    </Typography>
                  )}
                </Box>
              )}
            </Grid>

            <div
              style={{
                display: 'grid',
                marginLeft: '16px',
                gridTemplateColumns: '50% 50%',
                gap: '16px',
              }}
            >
              <Grid
                item
                onMouseEnter={() => setHoveringDate(true)}
                onMouseLeave={() => setHoveringDate(false)}
              >
                <ActivityDatePicker
                  hoveringDate={hoveringDate}
                  label="Target date"
                  name="targetDate"
                  value={template ? null : activity.targetDate}
                  inputStyles={{
                    ...styles.targetDateInput,
                    // minWidth: '130px !important',
                    border: 'none',
                    '.MuiOutlinedInput-input': {
                      padding: '6px',
                      fontSize: '14px !important',
                      fontFamily: 'InterRegular !important',
                    },
                    '.MuiOutlinedInput-input.Mui-disabled': {
                      color: black6,
                      '-webkit-text-fill-color': black6,
                    },
                  }}
                  slotPropsStyles={styles.dateSlotPropsStyles}
                  helperText={
                    activity?.status !== 'completed' &&
                    targetDateCheck(activity.targetDate)
                  }
                  handleChange={(e) =>
                    handleChange(e, stageIndex, activityIndex)
                  }
                  disabled={template}
                  readOnly={isSharing}
                  overdue={targetDateCheck(activity.targetDate) !== ''}
                  activity
                />
              </Grid>
              <Grid item style={{ width: '100%' }}>
                <Box
                  sx={{
                    width: '100%',
                  }}
                >
                  <MultiColorSelectInput
                    activity
                    label="Status"
                    disabled={template}
                    readOnly={isSharing}
                    name="status"
                    value={activity.status}
                    options={planStatusOptions}
                    styles={styles.select}
                    menuItemStyles={styles.menuItemStyles}
                    helperText={
                      activity.completionDate ? (
                        <>{formatToCompletionDate(activity.completionDate)}</>
                      ) : (
                        ''
                      )
                    }
                    handleChange={(e) =>
                      handleChange(e, stageIndex, activityIndex)
                    }
                  />
                </Box>
              </Grid>
            </div>

            <Grid item textAlign="left" sx={styles.activityNotes}>
              <ActivityTextarea
                labelStyle={{
                  color: isSharing ? black20 : 'rgba(0, 0, 0, 0.7)',
                }}
                label={'Notes'}
                name="notes"
                value={activity.notes}
                multiline={true}
                placeholder="No details"
                styles={{
                  ...styles.activityDesc,
                  fontFamily: 'InterRegular',
                  marginTop: '4px',
                  marginBottom: '0',
                  minHeight: showMore || expanded ? 'fit-content' : '35px',
                  height: showMore || expanded ? 'fit-content' : '35px',
                  padding: '4px 8px 0 0',
                  wordBreak: 'break-all',
                }}
                handleChange={(e) => handleChange(e, stageIndex, activityIndex)}
                readOnly={isSharing}
                disabled={template}
                characterLimit={135}
                notes
                activity
                setExpanded={setExpanded}
                setShowMore={setShowMore}
                expanded={expanded}
              />
            </Grid>
          </Grid>

          <Button
            disabled={(template && !canUpdateTemplate) || isSharing}
            sx={{
              ...styles.showMore,
              width: showMore && expanded ? '92px !important' : 'auto',
              marginLeft:
                activity.subActivities?.length === 0 && '5px !important',
              // marginLeft: (showMore || expanded) && '-4px !important',
            }}
            onClick={() => {
              setShowMore(!showMore);
              setExpanded(!expanded);
            }}
          >
            {showMore ? 'Show less' : 'Show more'}
          </Button>
          {activity.subActivities.length > 0 && (
            <Grid
              container
              columnSpacing={4}
              style={{ width: '100%', marginLeft: '0' }}
            >
              <Box sx={styles.subActivityHeadingBox}>
                {activity.subActivities.length > 0 && (
                  <Box
                    sx={{
                      ...styles.expandBtnBox,
                      width: '40px',
                      // paddingLeft: '32px',
                    }}
                    onClick={() =>
                      handleExpandSubActivities(stageIndex, activityIndex)
                    }
                  >
                    {activity.collapseSubActivity ? (
                      <ExpandMore sx={styles.collapseIcon} />
                    ) : (
                      <ArrowForwardIosIcon sx={styles.forwardIcon} />
                    )}
                  </Box>
                )}

                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '97%',
                    height: '56px',
                    paddingRight: '40px',
                  }}
                >
                  <Typography
                    component="span"
                    variant="p"
                    style={styles.subActivityHeading}
                    onClick={() =>
                      handleExpandSubActivities(stageIndex, activityIndex)
                    }
                  >
                    Sub activities{' '}
                  </Typography>

                  <span
                    style={{
                      ...styles.completedActivityPill,
                      width: 'max-content',
                      padding: '6px 12px',
                      gap: '5px',
                    }}
                  >
                    <CheckMarkIcon />
                    {completedActivities + '/' + activity.subActivities.length}
                    &nbsp;{' completed'}
                  </span>
                </Box>
              </Box>
            </Grid>
          )}

          <Collapse in={activity.collapseSubActivity} timeout="auto">
            <DragDropContext
              onDragEnd={(result) =>
                onSubActivityDragEnd(result, stageIndex, activityIndex)
              }
            >
              <StrictModeDroppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {activity.subActivities.map((item, index) => (
                      <Draggable
                        isDragDisabled={isSharing}
                        key={index}
                        draggableId={`${index}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <SubActivity
                            provided={provided}
                            subActivity={item}
                            stageIndex={stageIndex}
                            activityIndex={activityIndex}
                            subActivityIndex={index}
                            handleChange={handleSubActivityChange}
                            handleRemoveSubActivity={handleRemoveSubActivity}
                            isSharing={isSharing}
                            template={template}
                            handleClearInput={handleClearInput}
                            lastItem={
                              activity?.subActivities?.length - 1 === index
                            }
                            setExpanded={setExpanded}
                            setShowMore={setShowMore}
                            expanded={expanded}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </StrictModeDroppable>
            </DragDropContext>
          </Collapse>

          {activity.subActivities.length === 0 && (
            <Divider
              style={{
                marginBottom: '20px',
                marginTop: activity.subActivities.length === 0 ? '20px' : '0',
              }}
            />
          )}
          <Box
            sx={{
              ...styles.newFooterActivity,
              mt: activity.subActivities.length < 1 ? '24px' : '0px',
              flexDirection: 'row',
            }}
          >
            <DynamicButton
              disabled={(template && !canUpdateTemplate) || isSharing}
              type="outlined"
              style={styles.underlineBtn}
              onClick={() =>
                isSharing
                  ? console.log('Not allowed')
                  : handleAddNewSubActivity(stageIndex, activityIndex)
              }
              startIcon={<AddIcon />}
            >
              Add sub activity
            </DynamicButton>
            {totalActivities > 1 && (
              <Typography
                variant="p"
                style={styles.removeActivityBtn}
                onClick={() =>
                  isSharing
                    ? {}
                    : handleRemoveActivity(activityIndex, stageIndex)
                }
              >
                <img src={removeIcon} alt="trash" className="small-image" />
              </Typography>
            )}
          </Box>
          <Box style={styles.dragIcon} {...provided.dragHandleProps}>
            <DragIndicatorIcon />
          </Box>
          <CustomMultiSelect
            addUserToTeam={addUserToTeam}
            open={openShareModal}
            activity={activity}
            team={team}
            plan={plan}
            stageIndex={stageIndex}
            activityIndex={activityIndex}
            handleClose={() => setOpenShareModal(false)}
            handleServiceRemove={handleServiceRemove}
            vendorCompany={vendorCompany}
          />
        </Box>
      )}{' '}
    </>
  );
};

export default Activity;

const styles = {
  main: {
    mt: '20px',
    mb: '16px',
    borderRadius: '8px',
    padding: '24px 16px',
    border: `1px solid ${mercury}`,
    marginLeft: '10px',
    position: 'relative',
    background: 'white',
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
  },
  activityWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    marginBottom: '24px',
  },
  input: {
    background: white,
    marginTop: '8px',
    height: '17px',
    fontSize: '14px !important',
  },
  activityDesc: {
    fontSize: '14px',
    color: black88,
    lineHeight: '20px',
  },
  activityNotes: {
    width: '208px',
    marginLeft: '16px',
    paddingLeft: '0 !important',
    minWidth: '208px',
  },
  select: {
    height: '36px',
    width: '100%',
    padding: '0',
  },
  newFooterActivity: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  underlineBtn: {
    cursor: 'pointer',
  },
  removeActivityBtn: {
    color: '#CC0030',
    fontSize: '16px',
    lineHeight: '24px',
    cursor: 'pointer',
    width: 'fit-content',
    alignSelf: 'center',
    fontFamily: 'InterSemiBold',
    padding: '8px 0 8px 20px',
    '&:hover': {
      color: '#CC0030',
      filter: 'brightness(99%)',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  subActivityHeading: {
    fontSize: '16px',
    lineHeight: '24px',
    color: 'rgba(31, 31, 31, 1)',
    fontFamily: 'InterSemiBold',
    display: 'flex',
    alignItems: 'center',
    width: '13%',
    cursor: 'pointer',
    height: 'inherit',
    marginLeft: '10px',
  },
  subActivityHeadingBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    mt: '10px',
    mb: '30px',
  },
  expandBtnBox: { display: 'flex', alignItems: 'center', cursor: 'pointer' },
  expandBtn: {
    fontSize: '14px',
    lineHeight: '20px',
    color: primary,
    textDecoration: 'underline',
    fontFamily: 'SoehneRegular',
  },
  forwardIcon: { color: shark, fontSize: '15px', marginLeft: '4px' },
  collapseIcon: { color: shark, fontSize: '26px', marginLeft: '2px' },
  subActivityPill: {
    background: wildSand,
    borderRadius: '71px',
    width: 'fit-content',
    padding: '4px 8px',
    color: lightGray,
    fontSize: '12px',
    lineHeight: '16px',
    mb: '24px',
    cursor: 'pointer',
    ml: '45px',
  },
  // completedActivity: { textDecoration: 'line-through', color: lightGray },
  placeholderStyles: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    // color: 'rgba(0, 0, 0, 0.45)',
  },
  placeholderStylesLarger: {
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '32px',
    color: black20,
  },
  completedActivityPill: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontFamily: 'InterMedium',
    lineHeight: '20px',
    padding: '2px 4px',
    borderRadius: '100px',
    marginLeft: '10px',
    color: black6,
  },
  completedActivityPillNumber: { fontFamily: 'SoehneBold' },
  dragIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '-18px',
    position: 'absolute',
    background: white,
    borderRadius: '4px',
    border: '1px solid #E5E5E5',
    top: '45%',
    left: 0,
    width: '34px',
    height: '34px',
    cursor: 'drag',
  },
  removeIcon: {
    color: warning,
    fontSize: '16px',
    cursor: 'pointer',
    width: '24px',
    height: '24px',
  },
  // memberInputDiv: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   gap: '5px',
  //   mb: '12px',
  // },
  memberInputDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    padding: '0',
    position: 'relative',
    zIndex: 0,
    borderRadius: '4px',
  },
  singleRowMember: {
    width: '100%',
    flexDirection: 'row',
    display: 'flex',
    gap: '8px',
  },
  circle: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
    border: '1px solid white',
  },
  item: {
    fontFamily: 'InterRegular',
    fontSize: '14px',
    lineHeight: '20px',
    color: 'rgba(31, 31, 31, 1)',
    width: '100%',
    textAlign: 'left',
    wordBreak: 'break-all',
  },
  editTeamBtn: {
    fontFamily: 'InterMedium',
    fontSize: '14px',
    lineHeight: '20px',
    color: 'rgba(0, 155, 119, 1)',
    width: '100%',
    textAlign: 'left',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  moreUsers: {
    fontFamily: 'InterRegular',
    fontSize: '12px',
    lineHeight: '20px',
    color: black6,
    width: '100%',
    textAlign: 'right',
    cursor: 'pointer',
  },
  otherPillBox: { display: 'flex', alignItems: 'center', gap: '14px' },
  otherPill: {
    fontSize: '12px',
    background: 'rgba(0, 0, 0, 0.04)',
    borderRadius: '71px',
    color: lightGray,
    p: '4px 8px',
    cursor: 'pointer',
    width: '100%',
  },
  dateSlotPropsStyles: {
    openPickerButton: { sx: { ml: '-8px' } },
  },
  targetDateInput: { fontSize: '14px' },
  heading: {
    color: 'rgba(0, 0, 0, 0.7)',
    fontFamily: 'InterMedium',
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    // minWidth: '134px',
    // overflow: 'auto',
  },
  marginDuration: { margin: '0', padingTop: '8px 0' },
  durationAndTag: {
    display: 'flex',
    flexDirection: 'column',
    '@media screen and (min-width: 1430px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  showMore: {
    marginTop: '2px !important',
    padding: '8px !important',
    gap: '4px',
    textTransform: 'none !important',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0, 0.06) !important',
      color: 'rgba(0,0,0, 0.7) !important',
      transition: 'none',
    },
    '&:disabled': {
      backgroundColor: 'white !important',
      color: 'rgba(0,0,0, 0.2) !important',
      transition: 'none',
    },
  },
};

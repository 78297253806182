import {
  bgColor,
  black,
  mercury,
  primary,
  warning,
  white,
} from '../../constants/colors';

export const styles = {
  paginationDivider: { mt: '20px', border: '1px solid #E9ECEC' },
  paginationBox: { display: 'flex', justifyContent: 'center', mt: '20px' },
  pagination: {
    '.MuiSelected': {
      backgroundColor: `${primary} !important`,
      color: 'white',
    },
  },
  pagination_row: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
  noDataText: {
    fontFamily: 'InterBold',
    textAlign: 'center',
    color: 'rgb(0,0,0, 0.45)',
  },
  noDataBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '64px',
  },
  noStage: {
    width: '100%',
    height: 'auto',
    padding: '80px 0px',
    borderRadius: '8px',
    gap: '24px',
    backgroundColor: white,
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noStageIcon: {
    width: '64px',
    height: '64px',
    borderRadius: '10px',
    backgroundColor: 'rgba(242, 240, 238, 1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noStageText: {},
  value: {
    fontFamily: 'InterSemiBold',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.88)',
    textAlign: 'center',
  },
  label: {
    fontFamily: 'InterRegular',
    fontSize: '14px',
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.7)',
    textAlign: 'center',
  },
  paper: {
    width: '100%',
    overflow: 'auto',
    marginTop: '16px',
    maxHeight: '850px',
    backgroundColor: bgColor,
    boxShadow: 'none',
    marginBottom: '30px',
  },
  table: {
    width: '99.8%',
    margin: '2px auto',
    maxHeight: 80,
    display: 'table',
    backgroundColor: white,
    borderCollapse: 'collapse',
    borderSpacing: '0',
    overflow: 'auto',
    borderRadius: '8px',

    // boxShadow: '0px 1px 3px 0px #00000033!important',
    boxShadow:
      '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 1px 2px -1px rgba(0, 0, 0, 0.12)',
    // '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  tableHeader: {},
  tableRow: {
    color: 'inherit',
    display: 'table-row',
    verticalAlign: 'middle',
    height: '48px',
    outline: '0',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  headerCell: {
    fontFamily: 'InterMedium',
    lineHeight: '20px',
    display: 'table-cell',
    verticalAlign: 'middle',
    padding: '0',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.6)',
    borderBottom: `1px solid rgba(0, 0, 0, 0.05) !important`,
  },
  bodyCell: {
    fontFamily: 'InterRegular',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    display: 'table-cell',
    verticalAlign: 'inherit',
    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
    // border: 'none',
    padding: '0',
    color: 'rgba(31, 31, 31, 1)',
  },
  bodyRow: {
    cursor: 'pointer',
    height: '72px',
  },
  dotWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '6px 0',
    borderRadius: '16px',
    gap: '8px',
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'InterMedium',
    width: '72px',
    margin: '0 auto',
  },
  greenDot: {
    height: '8px',
    width: '8px',
    borderRadius: '50%',
    backgroundColor: '#01C070',
    marginRight: '5px',
  },
  redDot: {
    height: '8px',
    width: '8px',
    borderRadius: '50%',
    backgroundColor: warning,
    marginRight: '5px',
  },
  collaborator: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
  },
  avatar: {
    width: '48px',
    height: '48px',
    color: black,
    fontSize: '16px',
    fontFamily: 'SoehneBold',
    marginLeft: '16px',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    color: 'rgba(0, 0, 0, 0.88)',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'InterRegular',
    marginBottom: '4px',
  },
  detailsText: {
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'InterRegular',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  lastViewed: {
    fontFamily: 'InterRegular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: black,
  },
  vendor: {
    padding: '0 8px 0 16px',
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'InterMedium',
    color: 'rgba(0, 0, 0, 0.88)',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '122px',
    height: '40px',
    borderRadius: '0',
    marginLeft: '0',
    paddingLeft: '20px',
  },
  select: {
    width: '122px',
    borderRadius: '8px',
  },
  userTypeNormal: {
    color: primary,
    backgroundColor: 'rgba(240, 255, 241, 1)',
    padding: '0 8px 0 16px',
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'InterMedium',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '40px',
    borderRadius: '0',
    width: '122px',
    paddingLeft: '20px',
  },
  userTypeOthers: {
    color: 'rgba(132, 67, 2, 1)',
    backgroundColor: 'rgba(254, 242, 230, 1)',
    padding: '0 8px 0 16px',
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'InterMedium',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '40px',
    borderRadius: '0',
    paddingLeft: '20px',
  },
  checkmark: {
    marginRight: '0',
    marginLeft: 'auto',
    color: primary,
  },
  you: {
    position: 'absolute',
    top: 0,
    right: 16,
    color: '#FFD96F',
    zIndex: 99,
    backgroundColor: white,
    borderRadius: '50%',
    width: '15px',
    height: '15px',
  },
  tooltip: {
    color: primary,
    width: '15px',
    height: '15px',
    padding: 0,
  },
  viewCount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  relativeDiv: {
    position: 'relative',
  },
  absoluteDiv: {
    position: 'absolute',
    right: '70px',
    backgroundColor: 'white',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    width: '188px',
    boxShadow: '0px 8px 10px 1px rgba(0, 0, 0, 0.14)',
  },
  text: {
    padding: '12px 16px',
    width: '155px',
    textAlign: 'left',
  },
};

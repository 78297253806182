import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Container } from '@mui/material';
import InputWithLabel from '../components/common/InputWithLabel';
import styles from '../styles/resetPassword/resetPassword.module.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { showNotification } from '../components/common/Toast';
import DynamicButton from '../components/new/Button';

const PasswordRegex =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?!.*\W)(?!.* ).{8,16}$/;

export const ChangePassword = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const location = useLocation();

  useEffect(() => {
    const params = location.search;
    const urlParams = new URLSearchParams(params);
    const token = urlParams.get('token');
    if (params.length > 1) {
      const encodedString = token;
      if (encodedString) {
        const decodedString = atob(encodedString);
        const obj = JSON.parse(decodedString);
        setToken(token);
        setEmail(obj.email);
      }
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'password') setPassword(value);
    else setConfirmPassword(value);
  };

  const updatePassword = async () => {
    await axios
      .patch(`${process.env.REACT_APP_API_URL}api/user/forgot-password`, {
        email,
        newPassword: password,
        confirmPassword: confirmPassword,
        token,
      })
      .then(() => {
        navigate('/login', { replace: true });
        showNotification('success', 'Successfully changed password!');
      })
      .catch((err) => showNotification('error', err));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (password === confirmPassword && PasswordRegex.test(password)) {
        updatePassword();
      } else if (!PasswordRegex.test(password)) {
        showNotification(
          'info',
          'Password must be at 8-16 characters, 1 uppercase, 1 lowercase letter and 1 number and no special characters'
        );
      } else if (password !== confirmPassword) {
        showNotification('error', 'Password must match');
      }
    }
  };

  const resetPassword = () => {
    if (password === confirmPassword && PasswordRegex.test(password)) {
      updatePassword();
    } else if (!PasswordRegex.test(password)) {
      showNotification(
        'info',
        'Password must be at 8-16 characters, 1 uppercase, 1 lowercase letter and 1 number and no special characters'
      );
    } else if (password !== confirmPassword) {
      showNotification('error', 'Password must match');
    }
  };

  return (
    <Container maxWidth="xl">
      <Box className={styles.container}>
        <Box className={styles.main_container}>
          <Box className={styles.reset_box}>
            <Typography variant="h4" align="center" className={styles.heading}>
              Change Password
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              sx={{ marginTop: '30px' }}
            >
              Enter your email address and we will send you instructions to
              reset your password.
            </Typography>

            <form className={styles.form}>
              <InputWithLabel
                type="password"
                label="New Password"
                name="password"
                value={password}
                placeholder="Enter password"
                handleChange={(e) => handleChange(e)}
                onKeyDown={handleKeyDown}
              />
              <InputWithLabel
                type="password"
                label="Confirm Password"
                name="confirmPassword"
                value={confirmPassword}
                placeholder="Enter password"
                handleChange={(e) => handleChange(e)}
                onKeyDown={handleKeyDown}
              />
              <DynamicButton
                type="containedCenter"
                className={styles.reset_btn}
                fullWidth
                onClick={resetPassword}
              >
                Change Password
              </DynamicButton>

              <Box className={styles.login}>
                <Link to="/login" className={styles.login_btn}>
                  Login
                </Link>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

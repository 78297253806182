module.exports.PLAN_ACCESS = {
  fullName: 'fullName',
  count: 'count',
  boolean: 'boolean',
  status: 'status',
  lastViewed: 'lastViewed',
  userType: 'userType',
  Others: 'Others',
  Vendor: 'Vendor',
  avatar: 'avatar',
  Customer: 'Customer',
  viewed: 'viewed',
  viewNumber: 'viewNumber',
  company: 'company',
};

module.exports.PLAN_TAB = {
  maps: 'maps',
  share: 'share',
  archived: 'archived',
};

module.exports.SETTINGS_TAB = {
  profile: 'profile',
  feedback: 'feedback',
  preferences: 'preferences',
};

module.exports.PLAN_FIELDS = {
  status: 'status',
  completed: 'completed',
  duration: 'duration',
  map: 'map',
  engangement: 'engangement',
  access: 'access',
  customerCompany: 'customerCompany',
  custom: 'custom',
  vendor: 'vendor',
  targetDate: 'targetDate',
};

module.exports.SETTINGS_TAB = {
  profile: 'profile',
  feedback: 'feedback',
  preferences: 'preferences',
};

module.exports.TEMPLATES_TAB = {
  myTemplates: 'myTemplates',
  shared: 'shared',
};

module.exports.SORT_DIRECTION = {
  asc: 'asc',
  desc: 'desc',
};

module.exports.TIMELINE = {
  added: 'added',
  shared: 'shared',
  deleted: 'deleted',
  modified: 'modified',
  text: 'string',
  targetDate: 'targetDate',
  closeDate: 'closeDate',
  status: 'status',
  date: 'date',
  tag: 'tag',
  team: 'team',
  shareList: 'shareList',
  vendorTeam: 'vendorTeam',
  customerTeam: 'customerTeam',
  addedShared: 'added-shared',
  vendor: 'vendor',
};

import React, { useContext, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { eventConstants } from '../store/constants';
import { wsUrl } from '../environment';
import { store } from '../Store';

const PING_INTERVAL = 30 * 1000; // 30 sec
const PONG_INTERVAL = 10 * 1000; // 10 sec

const Socket = () => {
  const { state, dispatch } = useContext(store);
  let socket;
  let live = true;
  let reconnectTime = 500;
  // const store = useSelector(state => state)

  //   const dispatch = useDispatch();

  let pingInterval;
  let pongInterval;

  const ping = () => {
    try {
      socket.send(JSON.stringify({ ping: true }));
      pongInterval = setTimeout(pongNotReceived, PONG_INTERVAL);
    } catch (err) {
      //
    }
  };

  const pongNotReceived = () => {
    socket.close();
  };

  const disconnect = () => {
    live = false;
    if (socket) {
      socket.close();
    }
  };

  const connect = (username) => {
    console.log(new Date() + ' Connecting to WebSocket');
    live = true;
    socket = new WebSocket(`${wsUrl}/ws/${username}`);
    console.log('SOCKET', socket);

    socket.onopen = () => {
      console.log(new Date() + ' Connected to WebSocket');
      reconnectTime = 1000;
      if (pingInterval) clearInterval(pingInterval);
      pingInterval = setInterval(ping, PING_INTERVAL);
    };

    socket.onclose = (reason) => {
      console.log(new Date() + ' Closing WebSocket', reason);
      if (!live) return;
      setTimeout(() => {
        if (live) connect(username);
      }, reconnectTime);
    };

    socket.onmessage = (message) => {
      try {
        console.log('message Notification', message);
        const event = JSON.parse(message.data);
        if (event) {
          if (event.pong === true) {
            clearTimeout(pongInterval);
            return;
          } else {
            let not = false;
            if (state.notification?.length > 0)
              not = state.notification[0].notification;
            else if (state.notification == true) not = false;
            else if (state.notification == false) not = true;

            dispatch({
              type: 'SET_NOTIFICATION',
              payload: {
                notification: not,
              },
            });
          }
        }
      } catch (err) {
        console.log('socket error parsing websocket data');
      }
    };

    socket.onerror = (err) => {
      console.log(new Date() + ' Websocket error', err);
      reconnectTime *= 2;
      if (reconnectTime > 30000) {
        reconnectTime = 30000;
      }
    };
  };

  useEffect(() => {
    if (state.data.user && state.data.user.email) {
      connect(state.data.user.email);
    } else {
      disconnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.data.user]);

  useEffect(() => {
    return () => disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
};

export default Socket;

import React, { useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Dialog,
  Divider,
  Grid,
  InputLabel,
  Typography,
} from '@mui/material';
import { ReactComponent as HighlightOffOutlinedIcon } from '../assets/imgs/close.svg';
import { ReactComponent as AddPhoto } from '../assets/imgs/add_photo_alternate.svg';
import InputWithLabel from '../components/common/InputWithLabel';
import { black, gallery, mercury, primary, shark } from '../constants/colors';
import DynamicButton from '../components/new/Button';
import { formatNumberWithCurrency } from '../utils/utils';
import AppDatePicker from '../components/common/AppDatePicker';
import { getOrientation } from 'get-orientation/browser';
import { getRotatedImage } from './canvasUtils';
import { ResizeImage } from './ResizeImage';
import { showNotification } from '../components/common/Toast';
import httpClient from '../config/http.service';

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export const CreatePlanStep1 = ({
  open,
  handleClose,
  data,
  setData,
  createPlan,
  customerPhoto,
  setCustomerPhoto,
  customerPhotoDetails,
  setCustomerPhotoDetails,
  createFromTemplate = false,
  template,
  edit = false,
  editPlan,
  cancelEdit,
  currency = null,
  original = false,
  setOriginal = false,
}) => {
  const [valueForModal, setValueForModal] = useState('');
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [initialImageSrc, setInitialImageSrc] = useState(null);

  useEffect(() => {
    if (edit && customerPhoto) {
      setImageSrc(customerPhoto);
      setInitialImageSrc(customerPhoto);
    }
  }, [customerPhoto]);

  useEffect(() => {
    let withCurrency = currency ? `${currency}0` : '$0';
    if (data?.value?.amount) {
      let currency = withCurrency || data?.value?.currency || '$';
      withCurrency = currency + '' + data?.value?.amount;
      if (withCurrency) {
        let formatted = formatNumberWithCurrency(data?.value?.amount, currency);

        setValueForModal(formatted);
      }
    } else {
      setValueForModal(withCurrency);
    }
  }, [currency]);

  useEffect(() => {
    let withCurrency = currency ? `${currency}0` : '$0';
    if (data?.value?.amount) {
      let currency = data?.value?.currency || '$';
      withCurrency = currency + '' + data?.value?.amount;
      if (withCurrency) {
        let formatted = formatNumberWithCurrency(data?.value?.amount, currency);

        setValueForModal(formatted);
      }
    } else {
      setValueForModal(withCurrency);
    }
  }, [data]);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onFileChange(e.dataTransfer.files[0]);
    }
  };

  const handleChangeUpload = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      onFileChange(e.target.files[0]);
    }
  };
  const onButtonClick = () => {
    inputRef.current.click();
  };

  const removePhoto = () => {
    if (data?._id) {
      httpClient
        .delete('api/plan/remove-plan-avatar', {
          data: {
            _id: data._id,
          },
        })
        .then(() => {
          setCustomerPhotoDetails(null);
          setCustomerPhoto(null);
          setImageSrc(null);
          const newPlan = data;
          newPlan.hasAvatar = false;
          setData(newPlan);
        })
        .catch((err) =>
          showNotification('error', err?.response?.data?.message)
        );
    } else {
      setCustomerPhotoDetails(null);
      setCustomerPhoto(null);
      setImageSrc(null);
    }
  };

  const handleValue = (e) => {
    let withCurrency = data?.value?.currency + '' + e.target.value;
    if (withCurrency) {
      let formatted = formatNumberWithCurrency(
        e.target.value,
        data?.value?.currency
      );
      setData({
        ...data,
        value: {
          ...data?.value,
          amount: e.target.value.substring(1).replace(/[.,]/g, ''),
        },
      });
      setValueForModal(formatted);
    }
  };

  const onFileChange = async (fileForResize) => {
    if (fileForResize) {
      const file = fileForResize;
      let imageDataUrl = await readFile(file);

      try {
        // apply rotation if needed
        const orientation = await getOrientation(file);
        const rotation = ORIENTATION_TO_ANGLE[orientation];
        if (rotation) {
          imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
        }
      } catch (e) {
        console.warn('failed to detect the orientation');
      }
      setInitialImageSrc(imageDataUrl);
      setImageSrc(imageDataUrl);
      setCustomerPhotoDetails(fileForResize);
    }
  };

  const cancelResizing = () => {
    if (edit) {
      if (data?.hasAvatar) {
        // setCustomerPhoto(initialImageSrc);
        setOriginal(!original);
      } else {
        setInitialImageSrc(null);
        setImageSrc(null);
      }
    } else {
      if (customerPhoto) setImageSrc(initialImageSrc);
      else setImageSrc(null);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: styles.paper }}>
      {createFromTemplate ? (
        <Typography variant="h5" sx={styles.heading}>
          <div style={styles.fromTemplate}>
            <span style={styles.primary}>Create plan from template</span>
            <span style={styles.secondary}>{template?.name || 'Template'}</span>
          </div>
          <Box sx={styles.closeIconBox}>
            <HighlightOffOutlinedIcon
              style={styles.closeIcon}
              onClick={handleClose}
            />
          </Box>
        </Typography>
      ) : (
        <Typography variant="h5" sx={styles.heading}>
          {edit ? 'Edit plan details' : 'Create plan'}
          <Box sx={styles.closeIconBox}>
            <HighlightOffOutlinedIcon
              style={styles.closeIcon}
              onClick={handleClose}
            />
          </Box>
        </Typography>
      )}
      <Box style={styles.scrollableBox}>
        <Divider />
        <Typography variant="h5" sx={styles.title}>
          Opportunity details
        </Typography>
        <Typography variant="h5" sx={styles.subtitle}>
          Your deal value and close date will not be shared with your customer
          or prospect.
        </Typography>
        <InputWithLabel
          label="Plan title"
          name="name"
          value={data?.name}
          placeholder={`Leave blank to autogenerate "X & X Mutual Action Plan"`}
          handleChange={(e) => setData({ ...data, name: e.target.value })}
        />
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <InputWithLabel
              label="Deal value"
              name="value"
              value={valueForModal}
              placeholder="$20 000"
              handleChange={handleValue}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel
              shrink
              sx={{
                '&.Mui-focused': { color: black },
                color: black,
                opacity: '0.6',
                width: '100%',
                fontFamily: 'InterMedium',
                marginBottom: '8px',
              }}
            >
              Target close date
            </InputLabel>
            <AppDatePicker
              inputStyles={{
                border: `1px solid ${gallery}`,
                height: '50px',
                '.MuiOutlinedInput-input': {
                  padding: '6px 14px',
                },
                '.MuiOutlinedInput-notchedOutline': {
                  marginTop: '0',
                },
                '&:hover': {
                  border: '1px solid rgba(0, 0, 0, 0.45)',
                  borderColor: 'rgba(0,0,0,0.45',
                  transition:
                    'border-color  400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                },
              }}
              createOrEditModal
              label=""
              disablePast
              name="closeDate"
              value={data?.closeDate ?? ''}
              handleChange={(e) =>
                setData({ ...data, closeDate: e.target.value })
              }
            />
          </Grid>
        </Grid>
        <Typography variant="h5" sx={styles.title}>
          Company details
        </Typography>
        <Typography variant="h5" sx={styles.subtitle}>
          Enter the company name and upload the logo of your customer or
          prospect.
        </Typography>
        <InputWithLabel
          label="Company name*"
          name="customerCompany"
          value={data?.customerCompany}
          placeholder=""
          styles={{ marginBottom: '5px' }}
          handleChange={(e) =>
            setData({ ...data, customerCompany: e.target.value })
          }
        />
        <Box sx={styles.uploadBox}>
          <InputLabel
            shrink
            sx={{
              '&.Mui-focused': { color: black },
              color: black,
              opacity: '0.6',
              width: '100%',
              fontFamily: 'InterMedium',
              marginTop: '20px',
            }}
          >
            Logo
          </InputLabel>
          {imageSrc ? (
            customerPhoto ? (
              <Box sx={styles.hasImage}>
                <Avatar sx={styles.avatar} src={customerPhoto} />
                <Box sx={styles.rightSide}>
                  <Typography variant="h5" sx={styles.subtitle}>
                    {customerPhotoDetails?.name ?? 'image.png'}
                  </Typography>
                  <Box sx={styles.btnsWrapper}>
                    <DynamicButton
                      type="outlined"
                      onClick={() => setCustomerPhoto(null)}
                    >
                      Change
                    </DynamicButton>
                    <DynamicButton type="outlined" onClick={removePhoto}>
                      Remove
                    </DynamicButton>
                  </Box>
                </Box>
              </Box>
            ) : (
              <div style={{ height: '500px' }}>
                <ResizeImage
                  imageSrc={imageSrc}
                  cancelResizing={cancelResizing}
                  setCustomerPhoto={setCustomerPhoto}
                  setCustomerPhotoDetails={setCustomerPhotoDetails}
                  data={data}
                  setData={setData}
                />
              </div>
            )
          ) : (
            <Box sx={styles.dragNdrop}>
              <form
                id="form-file-upload"
                onDragEnter={handleDrag}
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  ref={inputRef}
                  type="file"
                  id="input-file-upload"
                  multiple={false}
                  onChange={handleChangeUpload}
                />
                <label
                  id="label-file-upload"
                  htmlFor="input-file-upload"
                  className={dragActive ? 'drag-active' : ''}
                >
                  <div style={styles.div}>
                    <AddPhoto />
                    <Typography variant="h5" sx={styles.drag}>
                      <span style={{ opacity: '0.6' }}>
                        Drag and drop an image, or{' '}
                      </span>
                      <span style={{ color: primary, opacity: '1' }}>
                        Browse
                      </span>
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={styles.minimum}
                      onClick={onButtonClick}
                    >
                      Minimum width 128px. Max 2MB.
                    </Typography>
                  </div>
                </label>
                {dragActive && (
                  <div
                    id="drag-file-element"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  ></div>
                )}
              </form>
            </Box>
          )}
        </Box>

        <Typography variant="h5" sx={{ ...styles.title, marginTop: '55px' }}>
          Main point of contact
        </Typography>
        <Typography variant="h5" sx={styles.subtitle}>
          Enter the name and job title of the main point of contact at your
          customer or prospect. Email can be added later and will be needed
          before you share your plan.
        </Typography>
        <InputWithLabel
          label="Full name*"
          name="fullName"
          value={data?.mainPointCustomer?.fullName}
          placeholder=""
          handleChange={(e) =>
            setData({
              ...data,
              mainPointCustomer: {
                ...data?.mainPointCustomer,
                fullName: e.target.value,
              },
            })
          }
        />
        <InputWithLabel
          label="Job title*"
          name="jobTitle"
          value={data?.mainPointCustomer?.jobTitle}
          placeholder=""
          handleChange={(e) =>
            setData({
              ...data,
              mainPointCustomer: {
                ...data?.mainPointCustomer,
                jobTitle: e.target.value,
              },
            })
          }
        />
        <InputWithLabel
          label="Work email address"
          name="email"
          value={data?.mainPointCustomer?.email}
          placeholder=""
          handleChange={(e) =>
            setData({
              ...data,
              mainPointCustomer: {
                ...data?.mainPointCustomer,
                email: e.target.value,
              },
            })
          }
        />
        <InputWithLabel
          label="Linkedin URL"
          name="linkedin"
          value={data?.mainPointCustomer?.linkedin}
          placeholder=""
          handleChange={(e) =>
            setData({
              ...data,
              mainPointCustomer: {
                ...data?.mainPointCustomer,
                linkedin: e.target.value,
              },
            })
          }
        />
        <Divider />
        {edit ? (
          <Box sx={styles.btns}>
            <DynamicButton type="outlined" onClick={cancelEdit}>
              Cancel
            </DynamicButton>
            <DynamicButton
              type="contained"
              onClick={editPlan}
              disabled={
                !(
                  data?.customerCompany !== '' &&
                  data?.mainPointCustomer?.fullName !== '' &&
                  data?.mainPointCustomer?.jobTitle !== ''
                )
              }
            >
              Save changes
            </DynamicButton>
          </Box>
        ) : (
          <Box sx={styles.btns}>
            <DynamicButton
              type="contained"
              onClick={createPlan}
              disabled={
                !(
                  data?.customerCompany &&
                  data?.customerCompany !== '' &&
                  data?.mainPointCustomer &&
                  data?.mainPointCustomer?.fullName !== '' &&
                  data?.mainPointCustomer?.jobTitle !== ''
                )
              }
            >
              Continue
            </DynamicButton>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

const styles = {
  paper: {
    borderRadius: '8px',
    padding: '15px',
    width: '620px',
    height: '90vh',
  },
  scrollableBox: {
    overflowY: 'auto',
    padding: '15px',
  },
  heading: {
    color: 'rgba(0, 0, 0, 0.88)',
    fontFamily: 'InterBold',
    fontSize: '20px',
    lineHeight: '28px',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    color: 'rgba(0, 0, 0, 0.88)',
    fontFamily: 'InterBold',
    fontSize: '24px',
    lineHeight: '32px',
    marginTop: '24px',
  },
  subtitle: {
    fontFamily: 'InterRegular',
    fontSize: '14px',
    lineHeight: '20px',
    marginTop: '5px',
    marginBottom: '20px',
    color: 'rgba(0, 0, 0, 0.88)',
  },
  closeIconBox: {
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(142, 128, 113, 0.08)',
    },
  },
  closeIcon: {
    color: shark,
    fontSize: '30px',
    padding: '5px',
    cursor: 'pointer',
  },
  hasImage: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '15px',
  },
  div: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px',
    padding: '24px',
  },
  drag: {
    fontFamily: 'InterMedium',
    fontSize: '14px',
    lineHeight: '20px',
    color: black,
  },
  minimum: {
    fontFamily: 'InterRegular',
    fontSize: '12px',
    lineHeight: '16px',
    color: 'rgba(0, 0, 0, 0.7)',
  },
  avatar: {
    height: '105px',
    width: '105px',
    borderRadius: '6px',
    border: 'none',
  },
  btnsWrapper: {
    display: 'flex',
    gap: '10px',
  },
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px',
    marginTop: '20px',
  },
  cancel: {
    color: black,
    width: '81px',
    height: '40px',
    background: mercury,
    borderRadius: '100px',
    boxShadow: 'none',
    border: `1px solid ${mercury}`,
    '&:hover': { background: mercury },
  },
  template: {
    color: 'white',
    height: '40px',
    borderRadius: '100px',
    boxShadow: 'none',
  },
  fromTemplate: {
    display: 'flex',
    flexDirection: 'column',
  },
  primary: {
    fontFamily: 'InterMedium',
    fontSize: '16px',
    lineHeight: '24px',
    color: primary,
  },
  secondary: {
    fontFamily: 'InterBold',
    fontSize: '20px',
    lineHeight: '28px',
    color: 'rgba(0, 0, 0, 0.88)',
  },
};

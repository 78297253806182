import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Dialog, Typography, Avatar } from '@mui/material';
import { ReactComponent as HighlightOffOutlinedIcon } from '../../assets/imgs/close.svg';
import { ReactComponent as NoShareList } from '../../assets/imgs/noShareList.svg';
import { ReactComponent as DeleteShared } from '../../assets/imgs/deleteShared.svg';
import {
  getDarkerColor,
  getInitialsFromString,
  randomColor,
  removeDuplicatesUsersByEmail,
} from '../../utils/utils';
import {
  bgColor,
  black,
  lochinvar,
  mercury,
  primary,
  shark,
  white,
} from '../../constants/colors';
import httpClient from '../../config/http.service';
import { showNotification } from '../common/Toast';
import DynamicButton from '../new/Button';
import Select, { components } from 'react-select';
import { store } from '../../Store';

const { MultiValueRemove } = components;

const initialOptions = [
  { value: 'isTitle', label: 'Suggestion', isTitle: true, isDisabled: true },
];

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    minWidth: '455px',
    border: state.isFocused
      ? `2px solid ${primary} !important`
      : `2px solid rgba(0, 0, 0, 0.2)`,
    boxShadow: state.isFocused ? '0 0 0 1px green' : 'none',
    backgroundColor: white,
    gap: '8px',
    borderRadius: '4px',
    minHeight: '40px',
    color: 'rgba(0, 0, 0, 0.6)',
    paddingRight: '60px',
  }),
  placeholder: (provided) => ({
    ...provided,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'white' : 'orange',
    color: state.isSelected ? 'white' : 'purple',
    ':hover': {
      background: bgColor,
      color: 'rgba(0, 0, 0, 0.88)',
    },
    borderRadius: '4px',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'red' : 'orange',
    backgroundColor: state.isSelected ? 'green' : 'purple',
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: 'rgba(0, 0, 0, 0.88)',
  }),
  multiValueRemove: (styles, state) => ({
    ...styles,
    fontSize: '30px',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    display: 'none', // Hide the indicators container
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none', // Hide the dropdown indicator
  }),
  menuList: (provided) => ({
    ...provided,
    overflow: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    maxHeight: '450px',
  }),
};

const CustomOption = ({ innerProps, label, value, data }) => {
  if (data.isTitle) {
    return (
      <div
        disable
        {...innerProps}
        style={{
          fontFamily: 'InterMedium',
          color: 'rgba(0, 0, 0, 0.6)',
          fontSize: '14px',
          lineHeight: '20px',
          padding: '10px 15px',
        }}
      >
        {label}
      </div>
    );
  } else {
    return (
      <div
        {...innerProps}
        style={{
          display: 'flex',
          padding: '10px 15px',
          gap: '10px',
          marginBottom: '10px',
          cursor: 'pointer',
        }}
      >
        <Avatar
          style={{
            marginRight: '5px',
            color: getDarkerColor(data.color || randomColor()),
            backgroundColor: data.color || randomColor(),
            width: '40px',
            height: '40px',
          }}
        >
          {getInitialsFromString(label)}
        </Avatar>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
          <span
            style={{
              color: 'rgba(0, 0, 0, 0.88)',
              fontFamily: 'InterRegular',
              fontSize: '16px',
            }}
          >
            {label}
          </span>
          <span
            style={{
              color: 'rgba(0, 0, 0, 0.7)',
              fontFamily: 'InterRegular',
              fontSize: '14px',
            }}
          >
            {value}
          </span>
        </div>
      </div>
    );
  }
};

const CustomMultiValueRemove = (props) => {
  return (
    <MultiValueRemove {...props}>
      <HighlightOffOutlinedIcon
        style={{ width: '11px', height: '11px', cursor: 'pointer' }}
      />
    </MultiValueRemove>
  );
};

const CustomClearIndicator = (props) => null;

export const ShareTemplateModal = ({
  open,
  handleClose,
  setShared,
  template,
  setTemplate,
  templateId,
}) => {
  const { state } = useContext(store);
  const [serviceList, setServiceList] = useState([
    { email: '', color: randomColor(), fullName: '' },
  ]);
  const [sharedList, setSharedList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [newUser, setNewUser] = useState({ value: '', label: '', color: '' });
  const [options, setOptions] = useState([]);
  const [inputValueUser, setInputValueUser] = useState('');
  const [company, setCompany] = useState(null);
  const selectRef = useRef(null);

  useEffect(() => {
    if (template) {
      getSharedList();
      getTemplate(template?._id ? template._id : templateId);
    }
  }, [template]);

  const getCompany = async (companyId) => {
    if (companyId) {
      httpClient
        .get(`api/company/get`, { params: { _id: companyId } })
        .then(async (res) => {
          setCompany(res.data);
        })
        .catch((err) => {
          // showNotification('error', err?.response?.data?.message)
        });
    }
  };

  const getTemplate = (templateId) => {
    httpClient
      .get(`api/template/get`, { params: { _id: templateId } })
      .then((res) => {
        setSharedList(res.data.shareList);

        if (res.data.vendor) getCompany(res.data.vendor);
      })
      .catch((err) => {
        showNotification('error', err?.response?.data?.message);
      });
  };

  const getUsers = () => {
    const vendorId =
      template?.vendor ||
      state?.data?.user?.company ||
      template?.userId?.company;

    if (template && vendorId) {
      httpClient
        .get(`api/user/get-template-users?company_id=${vendorId}`)
        .then((response) => {
          let newArray = initialOptions;
          response.data?.map((el) => {
            if (el._id + '' !== state?.data?.user?._id + '') {
              newArray.push({
                value: el.email,
                label: el.fullName,
                color: el.color || randomColor(),
              });
            }
          });

          if (response.data[0].email === '' || response.data.length === 0) {
            setOptions([
              {
                value: 'isTitle',
                label: 'No suggestions',
                isTitle: true,
                isDisabled: true,
              },
            ]);
          } else
            setOptions(
              newArray && newArray.length > 0
                ? removeDuplicatesUsersByEmail(newArray)
                : newArray
            );
        })
        .catch((err) => {
          // showNotification('error', err?.response?.data?.message)
        });
    }
  };

  useEffect(() => {
    if (template) getUsers();
  }, [template]);

  const getSharedList = () => {
    setSharedList(template?.shareList ?? []);
    setShared(template?.shareList ?? []);
  };

  const shareTemplate = () => {
    let newList;
    let newServiceList = [];
    if (selectedOptions.length > 0) {
      selectedOptions.map((el) => {
        newServiceList.push({
          email: el.value,
          fullName: el.label,
          color: el.color || randomColor(),
        });
      });
    } else {
      newServiceList.push({
        email: newUser.value,
        fullName: newUser.label,
        color: newUser.color || randomColor(),
      });
    }

    if (template?.shareList?.length > 0 && template.shareList[0].email !== '')
      newList = [...template.shareList, ...newServiceList];
    else newList = [...newServiceList];

    newServiceList.map((user) => {
      httpClient
        .post('api/template/send-invite', {
          _id: templateId || template._id,
          email: user.email,
          shareList: newList,
        })
        .then((res) => {
          const obj = res.data;
          obj.value = {
            amount: res.data.value?.amount || 0,
            currency: res.data.value?.currency || 'dollar',
          };
          obj.vendor = '';
          obj.vendorRepresentative = '';
          setTemplate(obj);
          setSharedList(res.data.shareList);
          setShared(res.data.shareList);
          setServiceList([{ email: '', color: '', fullName: '' }]);
          showNotification('success', 'Successfully shared template!');
          setSelectedOptions([]);
          handleClose();
        })
        .catch((err) =>
          showNotification('error', err?.response?.data?.message)
        );
    });
    setNewUser({ value: '', label: '', color: '' });
  };

  const handleServiceRemove = (userEmail) => {
    const removeFromShareList = sharedList.filter(
      (el) => el.email !== userEmail
    );
    httpClient
      .patch('api/template/update-share-list', {
        _id: template._id || templateId,
        shareList: removeFromShareList,
      })
      .then((res) => {
        const obj = res.data;
        obj.value = {
          amount: res.data.value?.amount || 0,
          currency: res.data.value?.currency || 'dollar',
        };
        obj.vendor = '';
        obj.vendorRepresentative = '';
        showNotification(
          'success',
          `Successfully removed user with email ${userEmail}`
        );
        setTemplate(obj);
        setShared(removeFromShareList);
      })
      .catch((err) => {
        // showNotification('error', err?.response?.data?.message)
      });
  };

  const handleInputChange = (inputValue, { action }) => {
    if (action === 'input-change') {
      let modifiedValue = inputValue;

      // if (inputValue.includes('@') && company && company.name) {
      //   const companyNameWithoutSpaces = company.name?.replace(/\s/g, '');
      //   modifiedValue =
      //     inputValue + companyNameWithoutSpaces.toLowerCase() + '.com';

      //   const newOption = {
      //     value: modifiedValue,
      //     label: modifiedValue,
      //   };
      //   setInputValueUser('');
      //   let newArray = [...selectedOptions, newOption];
      //   setNewUser({ value: '', label: '', color: '' });
      //   setSelectedOptions(newArray);
      // } else {
      modifiedValue = inputValue;
      const newOption = {
        value: modifiedValue,
        label: modifiedValue,
        color: randomColor(),
      };
      setInputValueUser(modifiedValue);
      setNewUser(newOption);
    }
    // }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (newUser.label !== '' && newUser.value !== '') {
        let newArray = [...selectedOptions, newUser];
        setNewUser({ value: '', label: '' });
        setSelectedOptions(newArray);
      }
      event.target.blur();
      setInputValueUser('');
      selectRef.current.focus();
    }
  };

  const handleSelect = (data) => {
    setSelectedOptions(data);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOptions([]);
        handleClose();
        setSelectedOptions([]);
        setInputValueUser('');
      }}
      PaperProps={{ sx: styles.paper }}
    >
      <Typography variant="h5" sx={styles.heading}>
        Share template
        <Box sx={styles.closeIconBox}>
          <HighlightOffOutlinedIcon
            style={styles.closeIcon}
            onClick={() => {
              setOptions([]);
              handleClose();
              setSelectedOptions([]);
              setInputValueUser('');
            }}
          />
        </Box>
      </Typography>

      <Box style={{ ...styles.inputBox }} className="share-template-modal">
        <Box style={{ position: 'relative' }}>
          <Select
            ref={selectRef}
            options={options || []}
            placeholder="Enter colleague emails who are Inquarter users"
            value={selectedOptions}
            onChange={handleSelect}
            onInputChange={handleInputChange}
            inputValue={inputValueUser}
            onKeyDown={handleKeyDown}
            styles={customStyles}
            isSearchable
            isMulti
            captureMenuScroll={false}
            components={{
              Option: CustomOption,
              MultiValueRemove: CustomMultiValueRemove,
              ClearIndicator: CustomClearIndicator,
            }}
          />
          {/* <span style={styles.absolute}>@google.com</span> */}
        </Box>

        <DynamicButton
          onClick={shareTemplate}
          type="contained"
          disabled={newUser?.value === '' && selectedOptions?.length < 1}
        >
          Invite
        </DynamicButton>
      </Box>
      {sharedList?.length > 0 && sharedList[0].email !== '' ? (
        <Box>
          <Typography sx={styles.sharedWith}>
            {sharedList.length} members have access
          </Typography>
          {template?.shareList?.map((user, index) => {
            return (
              <Box style={styles.row} key={index}>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Avatar
                    className={styles.profile_avatar}
                    sx={{
                      backgroundColor: user.color,
                      color: getDarkerColor(user.color),
                    }}
                  >
                    {user?.email
                      ? getInitialsFromString(user.fullName)
                      : user?.email?.length > 0
                      ? user.email?.charAt(0).toUpperCase()
                      : ''}
                  </Avatar>
                  <Typography sx={styles.username}>{user?.fullName}</Typography>
                </Box>
                <DeleteShared
                  onClick={() => {
                    handleServiceRemove(user.email);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            );
          })}
        </Box>
      ) : (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '252px',
          }}
        >
          <Box
            style={{
              width: '64px',
              height: '64px',
              borderRadius: '10px',
              backgroundColor: 'rgba(246, 245, 244, 1)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <NoShareList />
          </Box>
          <Typography
            style={{
              color: 'rgba(0, 0, 0, 0.7)',
              fontFamily: 'InterSemiBold',
              fontSize: '16px',
              lineHeight: '24px',
              marginTop: '15px',
            }}
          >
            Not shared
          </Typography>
        </Box>
      )}
    </Dialog>
  );
};

const styles = {
  paper: {
    borderRadius: '8px',
    padding: '32px',
    width: '598px',
    // overflow: 'hidden',
    overflow: 'visible',
  },
  heading: {
    color: 'rgba(0, 0, 0, 0.88)',
    fontFamily: 'InterBold',
    fontSize: '20px',
    lineHeight: '28px',
    marginBottom: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subheading: {
    color: black,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '24px',
  },
  sharedWith: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: 'InterMedium',
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '24px',
    marginTop: '5px',
  },
  closeIconBox: {
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(142, 128, 113, 0.08)',
    },
  },
  closeIcon: {
    color: shark,
    fontSize: '30px',
    cursor: 'pointer',
  },
  btns: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '24px',
    marginTop: '24px',
  },
  cancel: {
    color: black,
    width: '81px',
    height: '40px',
    background: mercury,
    borderRadius: '100px',
    boxShadow: 'none',
    border: `1px solid ${mercury}`,
    '&:hover': { background: mercury },
  },
  template: {
    color: 'white',
    width: '100%',
    height: '40px',
    borderRadius: '100px',
    boxShadow: 'none',
  },
  addNew: {
    fontFamily: 'InterRegular',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    textDecorationLine: 'underline',
    color: lochinvar,
    alignSelf: 'flex-start',
    marginBottom: '20px',
    cursor: 'pointer',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '20px',
  },
  inputBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  input: {
    marginBottom: '0',
    marginRight: '20px',
  },
  red: { color: 'red', cursor: 'pointer' },
  removeIcon: { color: primary, marginLeft: '40px' },
  username: {
    marginLeft: '20px',
    color: 'rgba(0, 0, 0, 0.88)',
    fontFamily: 'InterRegular',
    fontSize: '16px',
    lineHeight: '24px',
  },
  absolute: {
    position: 'absolute',
    color: 'rgba(0, 0, 0, 0.6)',
    right: '20px',
    top: '25%',
  },
};

import { Box } from '@mui/material';
import React from 'react';
import { primary } from '../../constants/colors';
import { ReactComponent as PlanIcon } from '../../assets/imgs/planIcon.svg';
import { ReactComponent as EngagementIcon } from '../../assets/imgs/engagement.svg';
import { ReactComponent as AccessIcon } from '../../assets/imgs/access.svg';
import { ReactComponent as PlanIconActive } from '../../assets/imgs/planIconActive.svg';
import { ReactComponent as EngagementIconActive } from '../../assets/imgs/engagementActive.svg';
import { ReactComponent as AccessIconActive } from '../../assets/imgs/accessActive.svg';

const btns = [
  { tab: 1, name: 'Plan', icon: <PlanIcon />, activeIcon: <PlanIconActive /> },
  {
    tab: 2,
    name: 'Engagement',
    icon: <EngagementIcon />,
    activeIcon: <EngagementIconActive />,
  },
  {
    tab: 3,
    name: 'Access',
    icon: <AccessIcon />,
    activeIcon: <AccessIconActive />,
  },
];

const PlanTabs = ({ tab, handleChange }) => {
  return (
    <Box sx={styles.main}>
      <Box sx={styles.btnBox}>
        {btns.map((item) => {
          const active = tab === item.tab;
          return (
            <Box
              key={item.name}
              sx={active ? styles.activeBtn : styles.btn}
              onClick={!active ? () => handleChange(item.tab) : () => {}}
            >
              <Box sx={styles.icons}>
                {active ? item.activeIcon : item.icon}
              </Box>
              <Box>&nbsp;&nbsp;{item.name}</Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default PlanTabs;

const styles = {
  main: {
    marginTop: '30px',
    borderRadius: '8px',
    overflowX: 'scroll',
    marginBottom: '30px',
  },
  btnBox: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: '32px',
    borderBottom: '1px solid rgba(229, 225, 221, 1)',
  },
  name: {
    display: 'flex',
    alignItems: 'center',
  },
  activeBtn: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    height: '48px',
    borderRadius: '0',
    backgroundColor: '#F6F5F4',
    boxShadow: 'none',
    fontFamily: 'InterSemiBold',
    fontSize: '16px',
    lineHeight: '24px',
    color: primary,
    borderBottom: '3px solid rgba(0, 155, 119, 1)',
    '&:hover': {
      borderBottom: '3px solid rgba(0, 155, 119, 1)',
    },
  },
  btn: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    height: '48px',
    borderRadius: '6px',
    backgroundColor: '#F6F5F4',
    boxShadow: 'none',
    fontFamily: 'InterSemiBold',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.6)',
    '&:hover': {
      boxShadow: 'none',
      color: 'rgba(0, 0, 0, 0.7)',
    },
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
  },
};

import { warning } from '../constants/colors';

const targetDateCheck = (date) => {
  const d1 = date ? new Date(date) : new Date();
  const d2 = new Date();

  const diff = d1.getTime() - d2.getTime();
  const difference = Math.ceil(diff / (1000 * 60 * 60 * 24));

  if (difference < 0) {
    return (
      <span style={{ color: warning }}>
        <span>{`${Math.abs(difference)}`}</span>{' '}
        {`${Math.abs(difference) > 1 ? 'days' : 'day'}`} Overdue
      </span>
    );
  } else if (date && difference === 0) {
    return <span style={{ color: warning }}>Due today</span>;
  } else if (difference <= 5 && difference > 0) {
    return (
      <span style={{ color: warning }}>
        {`Due in`}{' '}
        <span style={{ fontWeight: 'bolder' }}>{`${difference}`}</span>{' '}
        {`${difference > 1 ? 'days' : 'day'}`}
      </span>
    );
  }

  return '';
};

export default targetDateCheck;

import React from 'react';
import {
  Box,
  Chip,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { primary, swamp } from '../../constants/colors';

const PlanDropdown = ({ options, value, handleChange }) => {
  return (
    <FormControl fullWidth sx={{ marginBottom: '25px' }}>
      <Select
        id="plan-dropdown"
        variant="outlined"
        name="plan"
        value={value}
        onChange={handleChange}
        sx={{
          '&.Mui-focused fieldset': {
            border: '4px solid #0000000d !important',
            borderRadius: '8px',
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: '4px solid #0000000d !important',
          },
          '& .Mui-select-outlined': {
            paddingTop: '8px !important',
            paddingBottom: '8px !important',
          },
        }}
      >
        {options.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            sx={{
              '&.Mui-selected': {
                backgroundColor: 'rgba(0, 0, 0, 0.07) !important',
              },
              '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04) !important' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: '16px',
                    mb: '4px',
                    color: swamp,
                    fontWeight: '700',
                  }}
                >
                  {item.name}
                </Typography>
                <Typography sx={{ fontSize: '12px' }}>
                  {item.billingType}
                </Typography>
              </Box>
              <Chip
                sx={{
                  background: '#E6F3F4',
                  color: primary,
                  fontWeight: '700',
                }}
                label={item.price}
              />
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PlanDropdown;

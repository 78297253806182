import React from 'react';
import { Avatar, Box, Dialog, Typography } from '@mui/material';
import { ReactComponent as HighlightOffOutlinedIcon } from '../assets/imgs/close.svg';
import DynamicButton from '../components/new/Button';
import { shark } from '../constants/colors';
import { getInitialsFromString } from '../utils/utils';
import TextAreaLabel from '../components/common/TextArea';

export const SaveUserToShareList = ({
  open,
  handleClose,
  edit = false,
  saveMember,
  inviteMember,
  data,
  setData,
  editUser,
  setCustomMessage,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: styles.paper }}>
      <Typography variant="h5" sx={styles.heading}>
        Send invite
        <HighlightOffOutlinedIcon
          style={styles.closeIcon}
          onClick={handleClose}
        />
      </Typography>

      <Box style={{ ...styles.left, marginBottom: '20px' }}>
        <Box sx={styles.left}>
          <Avatar
            className={styles.profile_avatar}
            sx={{
              color: data.color,
              backgroundColor: data.backgroundColor,
            }}
          >
            {data?.name !== ''
              ? getInitialsFromString(data.name || data.fullName || '')
              : ''}
          </Avatar>
          <Box sx={styles.nameAndEmail}>
            <Typography sx={styles.username}>
              {data?.name || data?.fullName || ''}
            </Typography>
            <Typography sx={styles.email}>{data?.email}</Typography>
          </Box>
        </Box>
        <Typography sx={styles.username}>{data?.title}</Typography>
        <DynamicButton type="text" onClick={editUser}>
          Edit
        </DynamicButton>
      </Box>

      <TextAreaLabel
        label="Custom message"
        name="customMessage"
        multiline={true}
        rows={6}
        characterLimit={350}
        value={data.customMessage}
        placeholder="Enter message"
        styles={{
          marginBottom: '0',
          height: '110px',
          border: '1px solid rgba(0, 0, 0, 0.2)',
        }}
        handleChange={(e) => {
          setCustomMessage(e.target.value);
          setData({ ...data, customMessage: e.target.value });
        }}
        share
      />

      <Box sx={styles.btns}>
        <DynamicButton
          type="outlined"
          sx={styles.template}
          onClick={handleClose}
        >
          Cancel
        </DynamicButton>
        <DynamicButton
          type="contained"
          sx={styles.template}
          onClick={inviteMember}
        >
          Send invite
        </DynamicButton>
      </Box>
    </Dialog>
  );
};

const styles = {
  paper: {
    borderRadius: '8px',
    padding: '32px',
    width: '598px',
  },
  heading: {
    color: 'rgba(0, 0, 0, 0.88)',
    fontFamily: 'InterBold',
    fontSize: '20px',
    lineHeight: '28px',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeIcon: {
    color: shark,
    fontSize: '30px',
    padding: '5px',
    cursor: 'pointer',
  },
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px',
    marginTop: '26px',
  },
  username: {
    marginLeft: '20px',
    color: 'rgba(0, 0, 0, 0.88)',
    fontFamily: 'InterRegular',
    fontSize: '16px',
    lineHeight: '24px',
  },
  email: {
    marginLeft: '20px',
    color: 'rgba(0, 0, 0, 0.7)',
    fontFamily: 'InterRegular',
    fontSize: '14px',
    lineHeight: '20px',
  },
  left: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};

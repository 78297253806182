import React from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { Box, Collapse, Typography } from '@mui/material';
import { ReactComponent as ExpandMore } from '../../assets/imgs/expand_more.svg';
import { ReactComponent as ExpandLess } from '../../assets/imgs/expand_less.svg';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Activity from './Activity';
import AppMenu from '../common/AppMenu';
import { StrictModeDroppable } from '../common/StrictModeDroppable';
import {
  black,
  chardon,
  foam,
  primary,
  scorpion,
  shark,
  warning,
  white,
  wildSand,
} from '../../constants/colors';
import DynamicButton from '../new/Button';
import AddIcon from '@mui/icons-material/Add';

const Stage = ({
  plan,
  stage,
  stageIndex,
  provided,
  durationOptions,
  handleExpand,
  handleAddNewActivity,
  handleRemoveActivity,
  handleStageActionsOpen,
  handleStageActionsClose,
  handleDuplicateStage,
  handleDeleteStage,
  handleAddTeamMember,
  onActivityDragEnd,
  onSubActivityDragEnd,
  handleActivityFieldsChange,
  handleSubActivityChange,
  handleAddNewSubActivity,
  handleRemoveSubActivity,
  handleExpandSubActivities,
  handleRemoveTeamMember,
  handleTeamMembersModalOpen,
  handleEditStageTitle,
  handleCreateNewStage,
  template = false,
  isSharing = false,
  addUserToTeam,
  handleServiceRemove,
  handleClearInput,
  vendorCompany,
  handlePostSale,
  canUpdateTemplate = false,
  isVendor = false,
}) => {
  const completedActivities = stage?.activities?.reduce(function (
    total,
    value
  ) {
    if (isVendor) {
      return value.status === 'completed' ? total + 1 : total;
    }
    return value.status === 'completed' &&
      value.tag !== 'vendor_internal_not_shared'
      ? total + 1
      : total;
  },
  0);

  const totalActivities = isVendor
    ? stage?.activities?.length
    : stage?.activities?.reduce(function (total, value) {
        return value.tag !== 'vendor_internal_not_shared' ? total + 1 : total;
      }, 0);

  const completedSubActivities = () => {
    let total = 0;
    let completed = 0;
    stage.activities.forEach((item) =>
      item.subActivities.forEach((sub) => {
        if (isVendor || item.tag !== 'vendor_internal_not_shared') {
          ++total;
          completed += sub.status === 'completed' ? 1 : 0;
        }
      })
    );
    return { total, completed };
  };

  return (
    <>
      {totalActivities ? (
        <Box
          sx={{
            ...styles.main,
            backgroundColor: stage.open ? 'rgba(237, 234, 232, 1)' : white,
            boxShadow: stage.open
              ? 'none'
              : '0px 1px 3px 0px rgba(0, 0, 0, 0.2)',
          }}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Box sx={styles.header}>
            <Typography variant="p" style={styles.heading}>
              <span
                style={stage.open ? styles.dragIcon : styles.closedDragIcon}
                {...provided.dragHandleProps}
              >
                <DragIndicatorIcon />
              </span>
              {stage.open ? (
                <ExpandLess
                  style={styles.expandIcon}
                  onClick={() => handleExpand(stageIndex)}
                />
              ) : (
                <ExpandMore
                  style={styles.forwardIcon}
                  onClick={() => handleExpand(stageIndex)}
                />
              )}
            </Typography>
            <Box
              sx={styles.rightSide}
              style={{ cursor: 'pointer' }}
              onClick={() => handleExpand(stageIndex)}
            >
              <span
                style={{ ...styles.heading, cursor: 'pointer' }}
                onDoubleClick={() =>
                  handleEditStageTitle(stageIndex, stage.name)
                }
              >
                {stage.name}
              </span>
              <Box style={{ display: 'flex' }}>
                <span
                  style={
                    completedActivities === stage.activities.length
                      ? {
                          ...styles.activityPill,
                          ...styles.completedActivityPill,
                        }
                      : styles.activityPill
                  }
                >
                  <>
                    {!template && completedActivities + '/'}
                    {totalActivities}
                    &nbsp;{' '}
                    {stage.activities.length === 1 ? 'activity' : 'activities'}
                  </>
                </span>
                {!stage.open &&
                  (completedActivities !== stage.activities.length ||
                    completedSubActivities().completed !==
                      completedSubActivities().total) &&
                  completedSubActivities().total !== 0 && (
                    <span
                      style={
                        completedActivities === stage.activities.length
                          ? {
                              ...styles.activityPill,
                              ...styles.inCompleteActivityPill,
                            }
                          : styles.activityPill
                      }
                    >
                      {!template && completedSubActivities().completed + '/'}
                      {completedSubActivities().total}
                      &nbsp;{' '}
                      {completedSubActivities().total === 1
                        ? 'sub-activity'
                        : 'sub-activities'}
                    </span>
                  )}
              </Box>
            </Box>
            {isSharing ? null : (
              <MoreHorizIcon
                sx={{
                  ...styles.optionsBtn,
                  background: stage.open ? 'rgba(237, 234, 232, 1' : 'none',
                  width: '5%',
                }}
                fontSize="small"
                onClick={(e) => handleStageActionsOpen(e, stageIndex)}
              />
            )}
            <AppMenu
              open={
                template && !canUpdateTemplate
                  ? false
                  : Boolean(stage.actionsEl)
              }
              anchorEl={stage.actionsEl}
              options={[
                {
                  name: 'Edit stage title',
                  handleClick: () => {
                    handleEditStageTitle();
                    handleStageActionsClose();
                  },
                },
                {
                  name: 'Duplicate Stage',
                  handleClick: () => handleDuplicateStage(stageIndex),
                },
                {
                  name: stage.postSale
                    ? 'Unmark as post-sale'
                    : 'Mark as post sale',
                  handleClick: () => {
                    handlePostSale(stageIndex, stage);
                    handleStageActionsClose();
                  },
                },
                {
                  name: 'Delete stage',
                  handleClick: () => handleDeleteStage(stageIndex),
                  color: `${warning} !important`,
                },
              ]}
              handleClose={() => handleStageActionsClose()}
            />
          </Box>

          <Collapse in={stage.open} timeout="auto">
            <DragDropContext
              onDragEnd={(e) => onActivityDragEnd(e, stageIndex)}
            >
              <StrictModeDroppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {stage.activities.map((activity, index) => (
                      <Draggable
                        isDragDisabled={isSharing}
                        key={index}
                        draggableId={`${index}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <Activity
                            key={index}
                            plan={plan}
                            activityIndex={index}
                            stageIndex={stageIndex}
                            provided={provided}
                            activity={activity}
                            durationOptions={durationOptions}
                            totalActivities={stage.activities.length}
                            handleRemoveActivity={handleRemoveActivity}
                            handleAddTeamMember={handleAddTeamMember}
                            onSubActivityDragEnd={onSubActivityDragEnd}
                            handleChange={handleActivityFieldsChange}
                            handleSubActivityChange={handleSubActivityChange}
                            handleAddNewSubActivity={handleAddNewSubActivity}
                            handleRemoveSubActivity={handleRemoveSubActivity}
                            handleExpandSubActivities={
                              handleExpandSubActivities
                            }
                            handleRemoveTeamMember={handleRemoveTeamMember}
                            handleTeamMembersModalOpen={
                              handleTeamMembersModalOpen
                            }
                            handleEditStageTitle={handleEditStageTitle}
                            template={template}
                            isSharing={isSharing}
                            addUserToTeam={addUserToTeam}
                            handleServiceRemove={handleServiceRemove}
                            handleClearInput={handleClearInput}
                            vendorCompany={vendorCompany}
                            canUpdateTemplate={canUpdateTemplate}
                            isVendor={isVendor}
                          />
                        )}
                      </Draggable>
                    ))}

                    {provided.placeholder}
                  </div>
                )}
              </StrictModeDroppable>
            </DragDropContext>
            <DynamicButton
              disabled={(template && !canUpdateTemplate) || isSharing}
              type="contained"
              style={{ marginLeft: '10px' }}
              onClick={() =>
                isSharing
                  ? console.log('Not allowed')
                  : handleAddNewActivity(stageIndex)
              }
              startIcon={<AddIcon />}
            >
              Add activity
            </DynamicButton>
          </Collapse>
        </Box>
      ) : (
        ''
      )}
    </>
  );
};

export default Stage;

const styles = {
  main: {
    padding: '16px',
    borderRadius: '8px',
    marginBottom: '32px',
    position: 'relative',
  },
  heading: {
    color: black,
    fontSize: '20px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    gap: '13px',
    fontWeight: 700,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  optionsBtn: {
    color: scorpion,
    height: '25px',
    width: '25px',
    padding: '5px',
    cursor: 'pointer',
    borderRadius: '4px',
  },
  expandIcon: { color: primary, fontSize: '34px', cursor: 'pointer' },
  forwardIcon: { color: shark, fontSize: '18px', cursor: 'pointer' },
  closedDragIcon: { display: 'flex', alignItems: 'center', cursor: 'drag' },
  dragIcon: { display: 'none' },
  // addActivityBtn: {
  //   ml: '40px',
  //   color: 'black',
  //   fontFamily: 'InterMedium',
  // },
  activityPill: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'InterMedium',
    fontSize: '14px',
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.88)',
    padding: '6px 12px',
    borderRadius: '100px',
    marginLeft: '10px',
  },
  activityPillNumber: {
    fontFamily: 'InterMedium',
    fontSize: '14px',
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.88)',
  },
  completedActivityPill: {
    backgroundColor: foam,
    color: 'rgba(0, 122, 94, 1)',
  },
  inCompleteActivityPill: {
    backgroundColor: chardon,
    color: 'rgba(204, 0, 48, 1)',
  },
  rightSide: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
    width: '96%',
    marginLeft: '10px',
  },
};

import React, { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  Divider,
  Input,
  InputAdornment,
  Typography,
} from '@mui/material';
import { ReactComponent as HighlightOffOutlinedIcon } from '../assets/imgs/close.svg';
import { ReactComponent as TableImage } from '../assets/imgs/TableImage.svg';
import { ReactComponent as ArrowForward } from '../assets/imgs/arrow_forward.svg';
import { black, mercury, shark } from '../constants/colors';
import DynamicButton from '../components/new/Button';
import SearchIcon from '@mui/icons-material/Search';
import style from './selectTemplate.module.css';
import httpClient from '../config/http.service';
import { showNotification } from '../components/common/Toast';
import useDebounce from '../utils/hooks/useDebounce';

export const SelectTemplate = ({
  open,
  handleClose,
  createBlankPlan,
  goBack,
  user,
  handleSelectTemplate,
  fromTemplate = false,
}) => {
  const [filteredTemplates, setFilteredTemplates] = useState(null);
  const [initial, setInitial] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const getTemplates = () => {
    // Check with Vukasin why this condition
    // if (!user?.company) return;

    httpClient
      .get(`api/template/all`, {
        params: { _id: user },
      })
      .then((res) => {
        setFilteredTemplates(res.data);
        setInitial(res.data);
      })
      .catch((err) => {
        // if (err?.response?.data?.code !== 401) {
        // showNotification('error', err?.response?.data?.message);
        // }
      });
  };

  const getTemplatesWithSearch = () => {
    const filteredUsers = initial.filter((template) =>
      template.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredTemplates(filteredUsers);
  };

  useEffect(() => {
    if (searchTerm !== '') {
      getTemplatesWithSearch();
    } else {
      setFilteredTemplates(initial);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    getTemplates();
  }, []);

  const handleSearch = (value) => setSearchTerm(value);

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: styles.paper }}>
      <Typography variant="h5" sx={styles.heading}>
        {!fromTemplate ? 'Select template' : 'Create plan from template'}
        <HighlightOffOutlinedIcon
          style={styles.closeIcon}
          onClick={handleClose}
        />
      </Typography>
      <Input
        id="search"
        placeholder="Search templates"
        classes={{
          root: style.search_input,
          input: style.custom_input,
        }}
        disableUnderline
        onChange={(event) => handleSearch(event.target.value)}
        startAdornment={
          <InputAdornment position="start" sx={{ marginTop: '3px' }}>
            <SearchIcon className={style.input_adornment} />
          </InputAdornment>
        }
      />
      <Box sx={styles.list}>
        {filteredTemplates?.map((template) => {
          return (
            <Box sx={styles.item}
            onClick={() => handleSelectTemplate(template._id)}>
              <TableImage style={{ width: '40px', height: '40px' }} />
              <Box sx={styles.text}>
                <Typography sx={styles.name}>{template.name}</Typography>
                <Typography sx={styles.description}>
                  {template.description}
                </Typography>
              </Box>
              <ArrowForward
                style={{ cursor: 'pointer' }}
              />
            </Box>
          );
        })}
      </Box>
      {!fromTemplate && <Divider />}
      {!fromTemplate && (
        <Box sx={styles.btns}>
          <DynamicButton type="text" sx={styles.cancel} onClick={goBack}>
            Back
          </DynamicButton>
          <DynamicButton
            type="outlined"
            sx={styles.template}
            onClick={createBlankPlan}
          >
            Create blank plan
          </DynamicButton>
        </Box>
      )}
    </Dialog>
  );
};

const styles = {
  paper: {
    borderRadius: '8px',
    padding: '32px',
    width: '579px',
  },
  heading: {
    color: black,
    opacity: '0.88',
    fontFamily: 'InterBold',
    fontSize: '20px',
    lineHeight: '28px',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeIcon: {
    color: shark,
    fontSize: '30px',
    padding: '5px',
    cursor: 'pointer',
  },
  list: {
    marginTop: '30px',
    height: '60vh',
    overflow: 'auto',
    marginBottom: '40px',
    padding: '0 10px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '30px',
    cursor: 'pointer'
  },
  text: {
    width: '84%',
  },
  name: {
    fontFamily: 'InterRegular',
    fontSize: '16px',
    lineHeight: '24px',
    color: black,
    opacity: '0.88',
  },
  description: {
    fontFamily: 'InterRegular',
    fontSize: '14px',
    lineHeight: '20px',
    color: black,
    opacity: '0.7',
  },
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px',
    marginTop: '20px',
  },
  cancel: {
    color: black,
    width: '81px',
    height: '40px',
    background: mercury,
    borderRadius: '100px',
    boxShadow: 'none',
    border: `1px solid ${mercury}`,
    '&:hover': { background: mercury },
  },
  template: {
    color: 'white',
    height: '40px',
    borderRadius: '100px',
    boxShadow: 'none',
  },
};

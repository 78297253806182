import React, { useContext, useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import { ReactComponent as HighlightOffOutlinedIcon } from '../assets/imgs/close.svg';
import useDebounce from '../utils/hooks/useDebounce';
import { bgColor, primary, white } from '../constants/colors';
import DynamicButton from '../components/new/Button';
import { Box, Dialog, Typography } from '@mui/material';
import { ReactComponent as NoShareList } from '../assets/imgs/noShareList.svg';
import { ReactComponent as DeleteShared } from '../assets/imgs/deleteShared.svg';
import httpClient from '../config/http.service';
import { showNotification } from '../components/common/Toast';
import './customeSelectStyle.css';
import { jobTitlesList } from '../utils/constants';
import {
  getUniqueObjectsByProperty,
  removeDuplicatesWithException,
} from '../utils/utils';
import { store } from '../Store';

const { MultiValue } = components;

const CustomMultiSelect = ({
  open,
  handleClose,
  activity,
  team,
  plan,
  addUserToTeam,
  stageIndex,
  activityIndex,
  handleServiceRemove,
  vendorCompany,
}) => {
  const { state, dispatch } = useContext(store);
  const selectRef = useRef(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedJobTitle, setSelectedJobTitle] = useState(null);
  const [finalResult, setFinalResult] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [titleOptions, setTitleOptions] = useState(jobTitlesList);
  const [inputValue, setInputValue] = useState('');
  let [inputValueJobTitle, setInputValueJobTitle] = useState('');
  const debouncedSearchTerm = useDebounce(inputValue, 300);
  const debouncedSearchTermJobTitle = useDebounce(inputValueJobTitle, 300);

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '80%',
    }),
    control: (provided, state) => ({
      ...provided,
      width: '100%',
      height: '100%',
      border: state.isFocused
        ? `2px solid rgba(0, 0, 0, 0.2) !important`
        : `2px solid rgba(0, 0, 0, 0.2)`,
      boxShadow: 'none',
      backgroundColor: white,
      gap: '8px',
      borderRadius: '4px',
      minHeight: '40px',
      color: 'rgba(0, 0, 0, 0.6)',
      // paddingRight: '60px',
    }),
    MultiValueContainer: (provided, state) => ({
      border: state.isFocused
        ? `2px solid rgba(0, 0, 0, 0.2) !important`
        : `2px solid rgba(0, 0, 0, 0.2)`,
    }),
    placeholder: (provided) => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? white : white,
      color: state.isSelected ? white : 'purple',
      ':hover': {
        background: bgColor,
        color: 'rgba(0, 0, 0, 0.88)',
      },
      borderRadius: '4px',
    }),
    singleValue: (provided, state) => ({
      ...provided,
    }),
    input: (provided, state) => {
      const backgroundColor =
        state.selectProps?.value?.length > 0 &&
        state.selectProps.value[state.selectProps.value.length - 1]
          ?.jobTitle === '' &&
        state.selectProps.value[state.selectProps.value.length - 1]
          ?.fullName !== ''
          ? 'rgba(0, 0, 0, 0.05)'
          : 'transparent';

      return {
        ...provided,
        '& input': {
          backgroundColor: `${backgroundColor} !important`,
          fontSize: '14px !important',
          fontFamily: 'InterRegular !important',
          padding:
            finalResult?.length === 0
              ? `7px 8px 7px 0px !important`
              : '7px 8px 7px 7px !important',
          borderRadius: '4px !important',
        },
      };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: 'rgba(0, 0, 0, 0.88)',
    }),
    menuList: (styles, { data }) => ({
      ...styles,
      boxShadow: '0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
      maxHeight: '450px',
      overflow: 'auto',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'none', // Hide the indicators container
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: 'none', // Hide the dropdown indicator
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: white, // Change the background color here
    }),
  };

  const getUsers = (vendorName, customerName, representative, planId) => {
    if (!vendorName || !customerName) return;

    httpClient
      .get(`api/user/get-company-users`, {
        params: {
          customerName: customerName,
          vendor: vendorName,
          representative,
          planId,
        },
      })
      .then((response) => {
        let arrayOfUsers = [
          {
            value: 'isTitle',
            label: 'Select a team member or create new',
            isTitle: true,
            isDisabled: true,
          },
        ];

        if (team === 'customerTeam') {
          let representativeExist = activity[team]?.find(
            (el) => el.name === plan.mainPointCustomer.fullName
          );
          if (!representativeExist) {
            arrayOfUsers.push({
              value: plan.mainPointCustomer.fullName,
              label: plan.mainPointCustomer.fullName,
              title: plan.mainPointCustomer.jobTitle,
            });
          }

          response.data.customerUsers?.map((customer) => {
            let exist = activity[team]?.find((el) => el.name === customer.name);
            if (!exist) {
              if (plan.mainPointCustomer.fullName !== customer.name) {
                arrayOfUsers.push({
                  value: customer.name,
                  label: customer.name,
                  title: customer.title,
                });
              }
            }
          });
          if (state?.suggestions?.customers?.length > 0) {
            state.suggestions.customers.map((customer) => {
              let representativeExist = activity[team]?.find(
                (el) => el.name === customer.fullName
              );
              if (!representativeExist) {
                arrayOfUsers.push({
                  value: customer.fullName,
                  label: customer.fullName,
                  title: customer.title,
                });
              }
            });
          }
          const unique = getUniqueObjectsByProperty(arrayOfUsers, 'value');
          setUserOptions(unique);
        } else {
          httpClient
            .get(`api/user/get-by-name-id`, {
              params: { user: plan.vendorRepresentative },
            })
            .then((res) => {
              const vendorRep = { ...res.data };

              if (vendorRep && vendorRep.fullName && vendorRep.jobTitle) {
                let representativeExist = activity[team]?.find(
                  (el) => el.name === vendorRep.fullName
                );
                if (!representativeExist) {
                  arrayOfUsers.push({
                    value: vendorRep.fullName,
                    label: vendorRep.fullName,
                    title: vendorRep.jobTitle,
                  });
                }
              }

              response.data.vendorUsers?.map((vendor) => {
                let exist = activity[team]?.find(
                  (el) => el.name === vendor.name
                );
                if (!exist) {
                  if (vendorRep?.fullName !== vendor.name) {
                    arrayOfUsers.push({
                      value: vendor.name,
                      label: vendor.name,
                      title: vendor.title,
                    });
                  }
                }
              });
              if (state?.suggestions?.vendors?.length > 0) {
                state.suggestions.vendors.map((vendor) => {
                  let representativeExist = activity[team]?.find(
                    (el) => el.name === vendor.fullName
                  );
                  if (!representativeExist) {
                    arrayOfUsers.push({
                      value: vendor.fullName,
                      label: vendor.fullName,
                      title: vendor.title,
                    });
                  }
                });
              }
              const vendorUserIndex = arrayOfUsers.findIndex(
                (user) => user._id === plan?.vendorId
              );

              if (vendorUserIndex > -1) {
                const [vendorUser] = arrayOfUsers.splice(vendorUserIndex, 1);
                arrayOfUsers.unshift(vendorUser);
              }

              const unique = getUniqueObjectsByProperty(arrayOfUsers, 'value');
              setUserOptions(unique);
            })
            .catch((err) => {});
        }
      })
      .catch((err) => {
        //   if (
        //     err?.response?.data?.message !==
        //     `There is no company with name ${customerName}`
        //   ) {
        //     showNotification('error', err?.response?.data?.message);
        //   }
      });
  };

  useEffect(() => {
    if (plan && plan.vendor && plan.customerCompany);
    getUsers(
      plan.vendor,
      plan.customerCompany,
      plan.vendorRepresentative,
      plan._id
    );
  }, [plan?.vendor, plan?.customerCompany, open, activity]);

  useEffect(() => {
    let updatedUserOptions = [...userOptions];
    if (!userOptions.some((user) => user.value.includes(inputValue))) {
      updatedUserOptions.push({
        value: `Create ${inputValue}`,
        label: `${inputValue}`,
      });
    } else {
      if (userOptions.some((user) => user.value.includes(inputValue))) {
        let removeCurrent = userOptions.filter(
          (el) => !el.value.includes(inputValue)
        );
        updatedUserOptions = removeCurrent;
        updatedUserOptions.push({
          value: `Create ${inputValue}`,
          label: `${inputValue}`,
        });
      }
    }

    const unique = removeDuplicatesWithException(
      updatedUserOptions,
      'value',
      'Create'
    );
    let withoutCreate = unique;
    if (inputValue === '') {
      withoutCreate.pop();
      setUserOptions(withoutCreate);
    } else setUserOptions(unique);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const updatedJobTitleOptions = [...titleOptions];
    if (!titleOptions.some((user) => user.value.includes(inputValueJobTitle))) {
      updatedJobTitleOptions.push({
        value: `Add job title ${inputValueJobTitle}`,
        label: `Add job title ${inputValueJobTitle}`,
      });
    }
    const unique = removeDuplicatesWithException(
      updatedJobTitleOptions,
      'value',
      'Add job'
    );

    let withoutCreate = unique;
    if (inputValue === '') {
      withoutCreate.pop();
      setTitleOptions(withoutCreate);
    } else setTitleOptions(unique);
  }, [debouncedSearchTermJobTitle]);

  const isUserAlreadySelected = (user, array) => {
    if (user && user.label && user.title) {
      return array.some(
        (item) => item.fullName === user.label && item.jobTitle === user.title
      );
    } else return false;
  };

  const handleUserChange = (choosenUser) => {
    const isAlreadySelected = isUserAlreadySelected(
      choosenUser[finalResult.length],
      finalResult
    );

    if (isAlreadySelected) {
      showNotification('error', 'Already selected');
      return;
    }

    const newObj = {
      value: `${
        choosenUser[finalResult?.length]?.value?.includes('Create')
          ? inputValue
          : choosenUser[finalResult?.length]?.label
      } is ${
        choosenUser[finalResult?.length]?.title &&
        choosenUser[finalResult?.length]?.title !== ''
          ? choosenUser[finalResult?.length]?.title
          : '(Enter Job Title)'
      }`,
      label:
        choosenUser[finalResult?.length]?.title &&
        choosenUser[finalResult?.length]?.title !== ''
          ? ''
          : `inserting`,
      fullName: `${
        choosenUser.value?.includes('Create')
          ? inputValue
          : choosenUser[finalResult?.length]?.label
      }`,
      jobTitle: `${
        choosenUser[finalResult?.length]?.title &&
        choosenUser[finalResult?.length]?.title !== ''
          ? choosenUser[finalResult?.length]?.title
          : ''
      }`,
      company:
        team === 'customerTeam'
          ? plan?.customerCompany
          : vendorCompany
          ? vendorCompany.name
          : plan?.vendor,
      userType: team === 'customerTeam' ? 'Customer' : 'Vendor',
    };

    if (
      choosenUser[finalResult?.length]?.title &&
      choosenUser[finalResult?.length]?.title !== ''
    ) {
      setInputValue('');
      setSelectedUser(null);
    } else {
      setSelectedUser(choosenUser[finalResult?.length]);
      setInputValueJobTitle('Job title');
    }

    let newArray = finalResult;
    if (newObj.fullName !== 'undefined') {
      newArray.push(newObj);
      setFinalResult(newArray);
      setSelectedJobTitle(choosenUser[finalResult?.length]?.title || null);
      let withoutCreate = userOptions.filter(
        (el) => !el.value.includes('Create')
      );
      let withoutSelected = withoutCreate.filter(
        (el) => el.value !== newObj.fullName
      );
      setUserOptions(withoutSelected);
      if (choosenUser[finalResult?.length]?.title) {
        setInputValue('');
        return;
      } else {
        setInputValueJobTitle('Job title');
      }
    }
  };

  useEffect(() => {
    if (inputValueJobTitle === 'Job title') {
      const inputEl = selectRef?.current?.inputRef;
      if (inputEl) {
        inputEl.focus();
        inputEl.setSelectionRange(0, 0); // Set cursor to the beginning
      }
    }
  }, [inputValueJobTitle]);

  const handleKeyDownUser = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (inputValue === '') {
        showNotification('error', 'User cannot be empty');
        return;
      }
      const isAlreadySelected = finalResult.find(
        (el) => el.fullName === inputValue
      );

      if (isAlreadySelected) {
        showNotification('error', 'Already selected');
        return;
      }

      setSelectedUser({
        value: `Create ${inputValue}`,
        label: inputValue,
        company:
          team === 'customerTeam'
            ? plan?.customerCompany
            : vendorCompany?.name
            ? vendorCompany.name
            : plan?.vendor,
        userType: team === 'customerTeam' ? 'Customer' : 'Vendor',
      });
      const newObj = {
        value: `${inputValue} is (Enter Job Title)`,
        label: `inserting`,
        fullName: inputValue,
        jobTitle: '',
        company:
          team === 'customerTeam'
            ? plan?.customerCompany
            : vendorCompany?.name
            ? vendorCompany.name
            : plan?.vendor,
        userType: team === 'customerTeam' ? 'Customer' : 'Vendor',
      };
      let newArray = finalResult;
      if (newObj.fullName !== 'undefined') {
        newArray.push(newObj);
        setFinalResult(newArray);
        setSelectedJobTitle(null);
        let withoutCreate = userOptions.filter(
          (el) => !el.value.includes('Create')
        );
        let withoutSelected = withoutCreate.filter(
          (el) => el.value !== newObj.fullName
        );
        setUserOptions(withoutSelected);
        selectRef.current.openMenu();
        setInputValueJobTitle('Job title');
      }
    }
  };

  const handleKeyDownOption = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (inputValue === '') {
        showNotification('error', 'User cannot be empty');
        return;
      }
      if (inputValueJobTitle === '' || inputValueJobTitle === 'Job title') {
        showNotification('error', 'Job title cannot be empty');
        return;
      }
      const newObj = {
        value: `${
          selectedUser?.value.includes('Create')
            ? inputValue
            : selectedUser?.value
        } is ${inputValueJobTitle}`,
        label: `${
          selectedUser?.value.includes('Create')
            ? inputValue
            : selectedUser?.value
        } is ${inputValueJobTitle}`,
        fullName: `${
          selectedUser.value?.includes('Create')
            ? inputValue
            : selectedUser[finalResult?.length]?.value
        }`,
        jobTitle: `${inputValueJobTitle}`,
        company:
          team === 'customerTeam'
            ? plan?.customerCompany
            : vendorCompany?.name
            ? vendorCompany.name
            : plan?.vendor,
        userType: team === 'customerTeam' ? 'Customer' : 'Vendor',
      };

      // setSelectedJobTitle(selectedJobTitle[finalResult.length]?.value);
      let filtered = finalResult.filter((el) => el.label !== 'inserting');
      let newArray = filtered;
      if (newObj.jobTitle !== 'undefined') newArray.push(newObj);
      setFinalResult(newArray);
      setSelectedJobTitle(null);
      setInputValue('');
      setInputValueJobTitle('');
      let withoutCreate = titleOptions.filter(
        (el) => !el.value.includes('Add job title')
      );
      setTitleOptions(withoutCreate);
      setSelectedUser(null);
    }
  };

  const handleJobTitleChange = (selectedJobTitle) => {
    if (!selectedUser) {
      return;
    }

    if (selectedJobTitle && selectedJobTitle.length > 0) {
      const newObj = {
        value: `${
          selectedUser?.value.includes('Create')
            ? inputValue
            : selectedUser?.value
        } is ${
          selectedJobTitle[finalResult.length]?.value?.includes('Add job title')
            ? inputValueJobTitle
            : selectedJobTitle[finalResult.length]?.value
        }`,
        label: `${
          selectedUser?.value.includes('Create')
            ? inputValue
            : selectedUser?.value
        } is ${
          selectedJobTitle[finalResult.length]?.value?.includes('Add job title')
            ? inputValueJobTitle
            : selectedJobTitle[finalResult.length]?.value
        }`,
        fullName: `${
          selectedUser.value?.includes('Create')
            ? inputValue
            : selectedUser[finalResult?.length]?.value
        }`,
        jobTitle: `${
          selectedJobTitle[finalResult.length]?.value?.includes('Add job title')
            ? inputValueJobTitle
            : selectedJobTitle[finalResult.length]?.value
        }`,
        company:
          team === 'customerTeam'
            ? plan?.customerCompany
            : vendorCompany?.name
            ? vendorCompany.name
            : plan?.vendor,
        userType: team === 'customerTeam' ? 'Customer' : 'Vendor',
      };

      setSelectedUser(null);
      // setSelectedJobTitle(selectedJobTitle[finalResult.length]?.value);
      let filtered = finalResult.filter((el) => el.label !== 'inserting');
      let newArray = filtered;
      if (newObj.jobTitle !== 'undefined') newArray.push(newObj);
      setFinalResult(newArray);
      setSelectedJobTitle(null);
      setInputValue('');
      setInputValueJobTitle('');
      let withoutCreate = titleOptions.filter(
        (el) => !el.value.includes('Add job title')
      );
      setTitleOptions(withoutCreate);
    } else {
      if (finalResult?.length > 0) {
        finalResult[finalResult.length - 1].jobTitle = inputValueJobTitle;
        let filtered = finalResult.filter((el) => el.jobTitle !== '');
        setFinalResult(filtered);
        setSelectedUser(null);
        setInputValueJobTitle(null);
      }
    }
  };

  const handleInputChange = (inputValue, { action }) => {
    if (action === 'input-change') {
      if (selectedUser?.value?.includes('Create')) {
        const jobTitleString = 'Job title';
        let modifiedString = '';
        if (inputValue.includes('Job title')) {
          modifiedString = inputValue.replace(jobTitleString, '').trim();
        } else {
          modifiedString = inputValue;
        }

        setInputValueJobTitle(modifiedString);
      } else setInputValue(inputValue);
    }
  };

  const formatOptionLabel = ({ value, label }) => <div>{value}</div>;

  const CustomMultiValueRemove = (props) => {
    const removeUser = (e) => {
      e.stopPropagation();
      const updatedFinalResult = props.selectProps.value.filter(
        (user) => user.value !== props.data.value
      );
      props.selectProps.onChange(updatedFinalResult);
      setFinalResult(updatedFinalResult);
      getUsers(plan.vendor, plan.customerCompany);
      setInputValue('');
      setInputValueJobTitle('');
    };
    return (
      <components.MultiValueRemove {...props}>
        <Box
          onClick={removeUser}
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <HighlightOffOutlinedIcon
            style={{
              width: '11px',
              height: '11px',
              cursor: 'pointer',
            }}
          />
        </Box>
      </components.MultiValueRemove>
    );
  };

  const CustomOption = ({ innerProps, label, value, data, isFocused }) => {
    if (data.isTitle) {
      return (
        <div
          // disable
          {...innerProps}
          style={{
            fontFamily: 'InterMedium',
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: '14px',
            lineHeight: '20px',
            padding: '10px 15px',
            backgroundColor: white,
          }}
        >
          {label}
        </div>
      );
    } else {
      const words = value?.split(' ');
      const startIndex = words?.findIndex(
        (word) => word === 'title' || word === 'Create'
      );
      return (
        <div
          {...innerProps}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px 15px',
            cursor: 'pointer',
            backgroundColor: isFocused ? '#F5F5F5' : white,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '3px',
              backgroundColor: isFocused ? '#F5F5F5' : white,
            }}
          >
            <span
              style={{
                color: 'rgba(0, 0, 0, 0.88)',
                backgroundColor: isFocused ? '#F5F5F5' : white,
                fontFamily: 'InterRegular',
                fontSize: '16px',
              }}
            >
              {data.title && data.title !== '' ? label : ''}
            </span>
            {data.title && data.title !== '' ? (
              <span
                style={{
                  color: 'rgba(0, 0, 0, 0.7)',
                  fontFamily: 'InterRegular',
                  fontSize: '14px',
                }}
              >
                {data.title && data.title !== '' ? data.title : value}
              </span>
            ) : (
              <span
                style={{
                  color: 'rgba(0, 0, 0, 0.7)',
                  fontFamily: 'InterRegular',
                  fontSize: '16px',
                }}
              >
                {words.slice(0, startIndex + 1).join(' ')}{' '}
                {words.slice(startIndex + 1).map((word, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: 'bold',
                      color: 'rgba(0, 0, 0, 0.88)',
                      fontFamily: 'InterRegular',
                      fontSize: '16px',
                    }}
                  >
                    {word}{' '}
                  </span>
                ))}
              </span>
            )}
          </div>
        </div>
      );
    }
  };

  const CustomClearIndicator = (props) => null;

  const CustomMultiValue = (props) => {
    const { data } = props;

    return (
      <MultiValue
        {...props}
        style={{ backgroundColor: 'rgba(245, 245, 245, 1)' }}
      >
        <div
          style={{
            color: 'rgba(0, 0, 0, 0.88)',
            fontSize: '14px',
            fontFamily: 'InterRegular',
            backgroundColor: 'rgba(245, 245, 245, 1)',
            borderRadius: '4px',
            padding: '4px 8px',
          }}
        >
          {data.fullName}{' '}
          <span
            style={{
              color: 'rgba(0, 155, 119, 1)',
              fontSize: '14px',
              fontFamily: 'InterMedium',
            }}
          >
            is
          </span>{' '}
          {data.jobTitle}
        </div>
      </MultiValue>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        setInputValue('');
        setFinalResult([]);
        setSelectedUser(null);
      }}
      PaperProps={{ sx: styles.paper }}
      classes={{
        paper: 'dialog-paper',
      }}
    >
      <Box style={styles.header}>
        <Box style={styles.titles}>
          <Typography variant="h4" style={styles.team}>
            {team === 'customerTeam'
              ? plan?.customerCompany || 'Customer'
              : vendorCompany.name || plan?.vendor || 'Vendor'}{' '}
            team
          </Typography>
          <Typography style={styles.activityName}>
            {activity?.name || 'Activity Name'}
          </Typography>
        </Box>
        <Box sx={styles.closeIconBox}>
          <HighlightOffOutlinedIcon
            style={styles.closeIcon}
            onClick={() => {
              handleClose();
              setInputValue('');
              setFinalResult([]);
              setSelectedUser(null);
            }}
          />
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 16px 0 24px',
        }}
      >
        <Select
          ref={selectRef}
          options={
            !selectedUser
              ? userOptions
              : selectedUser?.title
              ? userOptions
              : titleOptions
          }
          value={finalResult}
          onChange={
            !selectedUser
              ? handleUserChange
              : selectedUser?.title
              ? handleUserChange
              : handleJobTitleChange
          }
          formatOptionLabel={formatOptionLabel}
          isClearable
          isSearchable
          isMulti
          onKeyDown={!selectedUser ? handleKeyDownUser : handleKeyDownOption}
          placeholder="Search or add name"
          components={{
            Option: CustomOption,
            MultiValueRemove: CustomMultiValueRemove,
            ClearIndicator: CustomClearIndicator,
            MultiValue: CustomMultiValue,
          }}
          onInputChange={handleInputChange}
          inputValue={
            selectedUser?.value?.includes('Create')
              ? inputValueJobTitle
              : inputValue
          }
          onFocus={function (e) {
            var val = inputValueJobTitle;
            inputValueJobTitle = '';
            inputValueJobTitle = val;
          }}
          styles={customStyles}
        />
        <DynamicButton
          type="contained"
          disabled={finalResult.length === 0}
          onClick={() => {
            handleJobTitleChange();
            addUserToTeam(
              stageIndex,
              activityIndex,
              team,
              activity,
              finalResult,
              vendorCompany
            );
            setFinalResult([]);
            setInputValueJobTitle('');
            setInputValue('');
            setSelectedUser(null);
            setSelectedJobTitle(null);
          }}
        >
          Add
        </DynamicButton>
      </Box>
      {activity[team].length > 0 && activity[team][0].name !== '' ? (
        <Box style={{ marginBottom: '20px' }}>
          <Typography style={styles.sharedWith}>
            {activity[team].length > 0 && activity[team][0].name !== ''
              ? `${activity[team].length} members`
              : 'No members +'}
          </Typography>
          <Box style={{ padding: '0 10px' }}>
            {activity[team].length > 0 && activity[team][0].name !== ''
              ? activity[team]?.map((user, index) => {
                  return (
                    <Box sx={styles.row} key={index}>
                      <Box style={styles.userBox}>
                        <Typography style={styles.name}>
                          {user?.name}
                        </Typography>
                        <Typography style={styles.title}>
                          {user?.title}
                        </Typography>
                      </Box>
                      <DeleteShared
                        onClick={() => {
                          handleServiceRemove(
                            stageIndex,
                            activityIndex,
                            team,
                            activity,
                            user
                          );
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    </Box>
                  );
                })
              : null}
          </Box>
        </Box>
      ) : (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '252px',
          }}
        >
          <Box
            style={{
              width: '64px',
              height: '64px',
              borderRadius: '10px',
              backgroundColor: 'rgba(246, 245, 244, 1)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <NoShareList />
          </Box>
          <Typography
            style={{
              color: 'rgba(0, 0, 0, 0.7)',
              fontFamily: 'InterSemiBold',
              fontSize: '16px',
              lineHeight: '24px',
              marginTop: '15px',
            }}
          >
            No one assigned
          </Typography>
        </Box>
      )}
    </Dialog>
  );
};

export default CustomMultiSelect;

const styles = {
  paper: {
    borderRadius: '8px',
    width: '600px',
    minHeight: '396px',
  },
  dialogPaper: {
    overflow: 'inherit !important',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '15px',
    padding: '12px 16px 12px 24px',
  },
  team: {
    color: primary,
    fontFamily: 'InterSemiBold',
    fontSize: '16px',
    lineHeight: '24px',
    wordBreak: 'break-all',
  },
  activityName: {
    color: 'rgba(0, 0, 0, 0.88)',
    fontFamily: 'InterBold',
    fontSize: '20px',
    lineHeight: '28px',
  },
  sharedWith: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: 'InterMedium',
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '15px',
    marginTop: '25px',
    padding: '0 16px 0 24px',
  },
  closeIconBox: {
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(142, 128, 113, 0.08)',
    },
  },
  closeIcon: {
    cursor: 'pointer',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
    padding: '8px 16px',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'rgba(244, 244, 244, 1)',
      cursor: 'pointer',
    },
  },
  userBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  name: {
    color: 'rgba(0, 0, 0, 0.88)',
    fontFamily: 'InterRegular',
    fontSize: '16px',
    lineHeight: '24px',
    width: '100%',
  },
  title: {
    color: 'rgba(0, 0, 0, 0.7)',
    fontFamily: 'InterRegular',
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
  },
};

import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import DashboardSidebar from '../components/common/DashboardSidebar';
import { PlanListing } from '../components/Plans/PlanListing';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as ArchivedPlanIcon } from '../assets/imgs/archive-icon.svg';
import { ReactComponent as ArchivedPlanActiveIcon } from '../assets/imgs/archive-icon-active.svg';
import { ReactComponent as MAPIcon } from '../assets/imgs/plans.svg';
import { ReactComponent as MAPActiveIcon } from '../assets/imgs/plans-active.svg';
import { colorsForShareList, sortPinned } from '../utils/constants';
import { ArchivePlanModal } from '../components/Plans/ArchivePlanModal';
import { DeletePlanModal } from '../components/Plans/DeletePlanModal';
import {
  alabaster,
  black,
  lightGray,
  primary,
  scorpion,
  shark,
  silver,
  text,
  warning,
  white,
} from '../constants/colors';
import {
  countProgress,
  formatToTableDate,
  formatToTableDateWithHours,
  getNumericValue,
  getShareList,
  randomColor,
  sortPlans,
} from '../utils/utils';
import { PLAN_TAB } from '../utils/enums';
import httpClient from '../config/http.service';
import { store } from '../Store';
import DynamicButton from '../components/new/Button';
import withDashboardHeader from '../hoc/withDashboardHeader';
import DashboardHeader from '../components/common/DashboardHeader';
import { showNotification } from '../components/common/Toast';
import { CreatePlanStep1 } from '../modals/CreatePlanStep1';
import { SelectTemplate } from '../modals/SelectTemplate';
import useDebounce from '../utils/hooks/useDebounce';

const Opportunities = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(store);
  const getActiveState = useLocation();
  const [defCurrency, setDefCurrency] = useState('');
  const [active, setActive] = useState(getActiveState?.state?.active ?? 'maps');
  const [plans, setPlans] = useState(null);
  const [shared, setShared] = useState(null);
  const [archived, setArchived] = useState(null);
  const [showNewPlanModal, setShowNewPlanModal] = useState(false);
  const [showArchivePlanModal, setShowArchivePlanModal] = useState(false);
  const [showDeletePlanModal, setShowDeletePlanModal] = useState(false);
  const [selectedArchiveOption, setSelectedArchiveOption] = useState('');
  const [archiveReason, setArchiveReason] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [customerPhoto, setCustomerPhoto] = useState(null);
  const [customerPhotoDetails, setCustomerPhotoDetails] = useState(null);
  const [openTemplateSelection, setOpenTemplateSelection] = useState(false);
  const [newPlan, setNewPlan] = useState({
    name: '',
    customerCompany: '',
    vendor: state?.data?.user?.company || '',
    mainPointCustomer: {
      fullName: '',
      email: '',
      jobTitle: '',
    },
    templateId: '',
    // value: { currency: state?.data?.user?.defaultCurrency || '$', amount: 0 },
    value: { currency: defCurrency, amount: 0 },
    closeDate: '',
  });

  const [selectedSortDirection, setSelectedSortDirection] = useState({
    name: 'Ascending',
    key: 'asc',
  });
  const [selectedSortOption, setSelectedSortOption] = useState({
    name: 'Close date',
    key: 'closeDate',
  });
  const [planForTemplate, setPlanForTemplate] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [update, setUpdate] = useState(false);
  const [initialPlans, setInitialPlans] = useState(null);
  const [viewOnly, setViewOnly] = useState(false);
  const [viewAndEdit, setViewAndEdit] = useState(false);
  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    if (state?.data?.user?.defaultCurrency) {
      setDefCurrency(state.data.user.defaultCurrency);
      const planWithNewCurrency = {
        ...newPlan,
        value: { currency: state.data.user.defaultCurrency },
      };
      setNewPlan(planWithNewCurrency);
    }
  }, [state?.data?.user?.defaultCurrency]);

  const handlePlanModalClose = () => {
    setShowNewPlanModal(false);
    setNewPlan({
      name: '',
      customerCompany: '',
      vendor: state?.data?.user?.company || '',
      mainPointCustomer: {
        fullName: '',
        email: '',
        jobTitle: '',
      },
      templateId: '',
      // value: { currency: state?.data?.user?.defaultCurrency || '$', amount: 0 },
      value: { currency: defCurrency, amount: 0 },
      closeDate: new Date(),
    });
  };

  useEffect(() => {
    if (searchTerm !== '') {
      getPlansWithSearch();
    } else {
      setPlans(initialPlans);
    }
  }, [debouncedSearchTerm]);

  const getPlansWithSearch = () => {
    const filteredUsers = initialPlans?.filter((plan) =>
      plan.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setPlans(filteredUsers);
  };

  const getCustomerPhoto = (planId) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`api/file/get/${planId}?width=64&height=64`)
        .then((res) => {
          const imageData = new Uint8Array(res.data);
          const blob = new Blob([imageData], { type: 'image/jpeg' });
          const imageUrl = URL.createObjectURL(blob);

          resolve(imageUrl);
        });
      // .catch((err) => {
      //   if (err?.response?.data?.code !== 401)
      //     showNotification('error', err?.response?.data?.message);
      //   reject(err);
      // });
    });
  };

  const removeDuplicates = (array1, array2) => {
    return array2.filter((item) => !array1.includes(item));
  };

  const getPlans = async () => {
    try {
      const res = await httpClient.get(`api/plan/all`, {
        params: { archived: false },
      });
      const shareList = getShareList(res.data);
      dispatch({
        type: 'SET_USERS',
        payload: {
          shareList,
        },
      });
      const valueToString = res.data;
      let newPlans = [];

      for (let el of valueToString) {
        let obj = {};
        obj._id = el._id + '';
        obj.closeDate = el.closeDate ? formatToTableDate(el.closeDate) : '-';
        if (el.hasAvatar) {
          obj.logo = await getCustomerPhoto(el._id);
        } else {
          obj.logo = '';
        }
        obj.stakeholders = el.stakeholders;
        let view = [];
        el?.stakeholders?.viewed?.map((el) => {
          if (el.fullName) view.push(el.fullName);
        });
        let nonView = [];
        el?.stakeholders?.invited?.map((el) => {
          if (el.fullName) nonView.push(el.fullName);
        });
        if (nonView.length > 0 && view.length > 0) {
          nonView = removeDuplicates(view, nonView);
        }
        obj.viewed = {
          view: [...view],
          nonView: [...nonView],
        };
        obj.progress = countProgress(el);
        obj.title = el.name;
        obj.updatedAt = formatToTableDateWithHours(el.updatedAt);
        obj.value = el.value;
        obj.status = el.status ?? 'Draft';
        obj.shareList = el.shareList;
        obj.shortId = el.shortId;
        obj.customerCompany = el.customerCompany;
        obj.vendor = el.vendor;
        newPlans.push(obj);
      }
      const sortedArray = sortPlans(
        selectedSortOption.key,
        newPlans,
        selectedSortDirection.key
      );
      setPlans(sortedArray);
      setInitialPlans(sortedArray);
    } catch (err) {
      // showNotification('error', err?.response?.data?.message);
    }
  };

  const getArchivedPlans = async (companyId) => {
    try {
      const res = await httpClient.get(`api/plan/all`, {
        params: { archived: true },
      });
      const valueToString = res.data;
      let newPlans = [];

      for (let el of valueToString) {
        let obj = {};
        obj.archiveDate = formatToTableDate(el.archiveDate);
        obj.archiveReason = el.archiveReason;
        if (el.hasAvatar) {
          obj.logo = await getCustomerPhoto(el._id);
        } else {
          obj.logo = '';
        }
        obj.owner = '';
        obj.progress = countProgress(el);
        obj.stakeholders = el.stakeholders;
        obj.viewed = {
          view: [],
          nonView: [],
        };
        obj.title = el.name;
        obj.value = el.value;
        obj._id = el._id + '';
        newPlans.push(obj);
      }

      setArchived(newPlans);
    } catch (err) {
      // showNotification('error', err?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (state?.data?.user?.company) {
      getPlans(state.data.user.company);
      getArchivedPlans(state.data.user.company);
    }
  }, [state?.data, update]);

  const handleArchivePlanModalClose = () => {
    setShowArchivePlanModal(false);
    setSelectedArchiveOption('');
    if (selectedArchiveOption === 'Other') setArchiveReason('');
  };

  const handleDeletePlanModalClose = () => setShowDeletePlanModal(false);

  const handleArchiveOptionChange = (e) => {
    setSelectedArchiveOption(e.target.value);
    if (selectedArchiveOption !== 'Other') setArchiveReason(e.target.value);
  };

  const handleArchiveReasonChange = ({ target }) => {
    setArchiveReason(target.value);
  };
  const onClickRow = (obj, index) => {
    setSelectedRow(obj);
  };

  const handleDelete = (id) => {
    setShowDeletePlanModal(false);
  };

  const handleSortChange = (item) => {
    setSelectedSortOption(item);
    if (active === PLAN_TAB.maps) {
      const sortedArray = sortPlans(item.key, plans, selectedSortDirection.key);
      const withPin = sortedArray.sort(sortPinned);
      setPlans(withPin);
    } else if (active === PLAN_TAB.archived) {
      const sortedArray = sortPlans(
        item.key,
        archived,
        selectedSortDirection.key
      );
      setArchived(sortedArray);
    }
  };

  const handleDirectionChange = (item) => {
    setSelectedSortDirection(item);
    if (active === PLAN_TAB.maps) {
      const sortedArray = sortPlans(selectedSortOption.key, plans, item.key);
      const withPin = sortedArray.sort(sortPinned);
      setPlans(withPin);
    } else if (active === PLAN_TAB.archived) {
      const sortedArray = sortPlans(selectedSortOption.key, archived, item.key);
      setArchived(sortedArray);
    }
  };

  useEffect(() => {
    if (plans?.length > 0) {
      if (active === PLAN_TAB.maps) {
        const sortedArray = sortPlans(
          selectedSortOption.key,
          plans,
          selectedSortDirection.key
        );
        setPlans(sortedArray);
      } else if (active === PLAN_TAB.archived) {
        const sortedArray = sortPlans(
          selectedSortOption.key,
          archived,
          selectedSortDirection.key
        );
        setArchived(sortedArray);
      }
    }
  }, [active]);

  const createPlan = (templateId, fromTemplate) => {
    // console.log('newPlan', newPlan);
    // console.log(
    //   'NEW PLAN.value',
    //   newPlan,
    //   getNumericValue(newPlan.value.amount)
    // );
    // newPlan.value.amount = getNumericValue(newPlan.value.amount);
    const obj = {
      name: newPlan.name,
      customerCompany: newPlan.customerCompany,
      vendor: state?.data?.user?.company || '',
      vendorColor: colorsForShareList[0].color,
      vendorBackgroundColor: colorsForShareList[0].backgroundColor,
      mainPointCustomer: newPlan.mainPointCustomer,
      templateId: templateId || '',
      value: newPlan.value,
      closeDate:
        newPlan.closeDate !== '' ? newPlan.closeDate?.toISOString() : '',
    };
    let newFile = new FormData();
    if (customerPhotoDetails) {
      const newName = new File([customerPhotoDetails], 'customerimage2', {
        type: customerPhotoDetails.type,
      });
      newFile.append('image', newName);
      newFile.append('plan', JSON.stringify(obj));
    } else {
      newFile.append('image', {});
      newFile.append('plan', JSON.stringify(obj));
    }
    httpClient
      .post('api/plan/new', newFile)
      .then((response) => {
        //need to open modal 2 to choose template;
        if (fromTemplate) {
          showNotification(
            'success',
            'Created a new plan from template!',
            'dark',
            'bottom-right'
          );
          navigate(`/plan/${response.data._id}`);
        } else {
          showNotification(
            'success',
            'Created a blank plan!',
            'dark',
            'bottom-right'
          );
          setRedirecting(true);
          navigate(`/plan/${response.data._id}`);
        }
        setOpenTemplateSelection(false);
        setUpdate(!update);
      })
      .catch((err) => {
        // showNotification('error', err?.response?.data?.message);
      });
  };

  const goBackToStepOne = () => {
    setShowNewPlanModal(true);
    setOpenTemplateSelection(false);
  };

  const createBlankPlan = () => {
    createPlan(null, false);
  };

  const handleSelectTemplate = (templateId) => {
    newPlan.value.amount = getNumericValue(newPlan?.value?.amount || 0);
    setNewPlan({ ...newPlan, templateId: templateId });
    createPlan(templateId, true);
  };

  const handleSearch = (e) => setSearchTerm(e.target.value);

  return (
    <Grid sx={styles.main}>
      {state?.data?.user?._id && (
        <Grid item xs={1} sx={{ width: '96px', maxWidth: '96px !important' }}>
          <DashboardSidebar />
        </Grid>
      )}
      <Grid
        item
        xs={11}
        sx={{ padding: '0 30px', borderLeft: '1px solid #DBDFDF' }}
      >
        <Box sx={styles.container}>
          <Box sx={styles.header}>
            <DashboardHeader
              viewOnly={viewOnly}
              viewAndEdit={viewAndEdit}
              title={'Opportunities'}
              handleSearch={handleSearch}
              listOfPlans={true}
            />
            <DynamicButton
              type="contained"
              onClick={() => setShowNewPlanModal(true)}
              disabled={false}
              startIcon={<AddIcon />}
              style={{ margin: '20px 0' }}
            >
              Create new
            </DynamicButton>
            <Grid container sx={styles.tabs}>
              <Grid item sx={styles.tabsItem}>
                <Box
                  onClick={() => setActive('maps')}
                  sx={active === PLAN_TAB.maps ? styles.activeBox : styles.box}
                >
                  {active === PLAN_TAB.maps ? <MAPActiveIcon /> : <MAPIcon />}
                  <Typography
                    sx={
                      active === PLAN_TAB.maps
                        ? styles.subheadingText
                        : styles.inactive
                    }
                  >
                    All plans
                  </Typography>
                </Box>
                <Box
                  onClick={() => setActive('archived')}
                  sx={active === PLAN_TAB.maps ? styles.box : styles.activeBox}
                >
                  {active === PLAN_TAB.archived ? (
                    <ArchivedPlanActiveIcon />
                  ) : (
                    <ArchivedPlanIcon />
                  )}
                  <Typography
                    sx={
                      active === PLAN_TAB.archived
                        ? styles.subheadingText
                        : styles.inactive
                    }
                  >
                    Archived
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <PlanListing
            opportunities={true}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            onClickRow={onClickRow}
            active={active}
            plans={plans}
            setPlans={setPlans}
            sharedPlans={shared}
            setSharedPlans={setShared}
            archivedPlans={archived}
            setArchivedPlans={setArchived}
            update={update}
            setUpdate={setUpdate}
            planForTemplate={planForTemplate}
            setPlanForTemplate={setPlanForTemplate}
            handleSortChange={handleSortChange}
            setSelectedSortOption={setSelectedSortOption}
            handleDirectionChange={handleDirectionChange}
            sortDirectionMaps={selectedSortDirection}
            setDirectionMaps={setSelectedSortDirection}
            viewOnly={viewOnly}
            viewAndEdit={viewAndEdit}
            handleCreate={() => {
              setShowNewPlanModal(true);
            }}
          />
          <DeletePlanModal
            open={showDeletePlanModal}
            handleClose={handleDeletePlanModalClose}
            handleDelete={handleDelete}
          />

          <ArchivePlanModal
            open={showArchivePlanModal}
            archiveReason={archiveReason}
            selectedOption={selectedArchiveOption}
            handleChange={handleArchiveOptionChange}
            handleReasonChange={handleArchiveReasonChange}
            handleClose={handleArchivePlanModalClose}
            selectedRow={selectedRow}
          />
          <CreatePlanStep1
            data={newPlan}
            setData={setNewPlan}
            open={showNewPlanModal}
            handleClose={handlePlanModalClose}
            createPlan={() => {
              setOpenTemplateSelection(true);
              setShowNewPlanModal(false);
            }}
            customerPhoto={customerPhoto}
            setCustomerPhoto={setCustomerPhoto}
            customerPhotoDetails={customerPhotoDetails}
            setCustomerPhotoDetails={setCustomerPhotoDetails}
            createFromTemplate={false}
            // currency={state?.data?.user?.defaultCurrency}
            currency={defCurrency}
          />
          <SelectTemplate
            open={openTemplateSelection}
            handleClose={() => setOpenTemplateSelection(false)}
            createBlankPlan={createBlankPlan}
            goBack={goBackToStepOne}
            user={state?.data?.user}
            handleSelectTemplate={handleSelectTemplate}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default withDashboardHeader(Opportunities);

const styles = {
  main: {
    backgroundColor: `#F6F5F4 !important`,
    minHeight: '100vh !important',
    display: 'flex',
    // maxWidth: '100%',
    width: '100%',
  },
  container: { marginTop: '15px' },
  header: {
    display: 'flex !important',
    justifyContent: 'space-between !important',
    flexWrap: 'wrap !important',
    alignItems: 'center',
    marginBottom: '25px',
  },
  heading: {
    fontSize: '20px',
    lineHeight: '24px',
    color: text,
  },
  subheading: {
    background: white,
    boxShadow:
      '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
    borderRadius: 6,
    padding: '10px 14px',
    gap: 8,
    cursor: 'pointer',
  },
  subheadingText: {
    fontFamily: 'InterSemiBold',
    fontSize: '16px',
    lineHeight: '24px',
    color: primary,
    cursor: 'pointer',
    marginLeft: '7px',
  },
  inactive: {
    fontSize: '16px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: 'InterSemiBold',
    cursor: 'pointer',
    marginLeft: '7px',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.75)',
    },
  },
  addBtn: {
    fontSize: '14px',
    lineHeight: '32px',
    borderRadius: '24px',
    height: '40px',
    padding: '10px 20px',
    boxShadow: 'none',
    marginRight: '10px',
    gap: '13px',
    marginTop: '20px',
    '& svg': { fontSize: '18px' },
    '@media screen and (min-width: 670px)': {
      marginTop: '0',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px',
  },
  editIcon: { width: '22px', height: '22px', cursor: 'pointer' },
  outlinedStarIcon: { color: scorpion, fontSize: '30px', cursor: 'pointer' },
  deleteIcon: { color: warning, fontSize: '26px', cursor: 'pointer' },
  opportunities: {
    borderRight: `1px solid ${silver}`,
    paddingRight: 20,
    marginRight: 20,
    color: primary,
    pointer: 'cursor',
    fontSize: '20px',
    fontWeight: 700,
  },
  tabs: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    paddingVertical: '20px',
    padding: '9px',
    '@media screen and (min-width: 500px)': {
      justifyContent: 'space-between',
    },
  },
  tabsItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    borderBottom: '1px solid #E5E1DD',
    '@media screen and (min-width: 500px)': {
      flexDirection: 'row',
    },
    '@media screen and (min-width: 1150px)': {
      marginBottom: '0px',
    },
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '12px 0',
    marginRight: '20px',
  },
  activeBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 0',
    marginRight: '20px',
    borderBottom: `2px solid ${primary}`,
  },
  header_btns: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginLeft: '20px',
    '@media screen and (min-width: 500px)': {
      marginLeft: '0',
    },
  },
  sort_btn: {
    backgroundColor: alabaster,
    padding: '5px 16px',
    borderRadius: 100,
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: '8px',
    marginHorizontal: '8px',
    cursor: 'pointer',
    color: text,
    height: 40,
    marginRight: '30px',
  },
  sort_btn_text: {
    color: black,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 10,
  },
  sort_btn_icon: {
    fontSize: 25,
    color: shark,
  },
  menu: {
    borderRadius: 8,
  },
  menu_item: {
    backgroundColor: 'white',
    fontSize: '1rem',
    lineHeight: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '31px',
  },
  selected_menu_item: {
    backgroundColor: 'white',
    fontSize: '1rem',
    lineHeight: 20,
    justifyContent: 'space-between',
    alignItems: 'center',
    color: primary,
    height: '31px',
  },
  menu_item_icon: {
    fontSize: 14,
    color: primary,
  },
  add_btn: {
    fontSize: 14,
    lineHeight: 20,
    borderRadius: 24,
    height: 40,
    padding: '10px 20px',
    shadowOffset: { width: 0, height: 0 },
    shadowColor: 'transparent',
    shadowOpacity: 0,
    shadowRadius: 0,
    elevation: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 13,
    fontFamily: 'SoehneBold',
  },
  add_btn_icon: {
    fontSize: 18,
  },
  span: { display: 'flex', marginLeft: '20px', lineHeight: '26px' },
};

import React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

export const TemplateDetails = ({ template, user, owner,isPageLoaded }) => {
  return (
    <Box sx={styles.main}>
      <Box sx={styles.box}>
        <Typography variant="h4" align="left" sx={styles.label}>
          Owner
        </Typography>
        {isPageLoaded && <Typography variant="h4" align="left" sx={styles.value}>
          {owner?._id === user?._id
            ? 'You'
            : template?.userId?.fullName
            ? template?.userId?.fullName
            : 'Someone else'}
        </Typography>}
      </Box>
      <Box sx={styles.description}>
        <Typography variant="h4" align="left" sx={styles.label}>
          Description
        </Typography>
        <Typography variant="h4" align="left" sx={styles.value}>
          {template?.description}
        </Typography>
      </Box>
    </Box>
  );
};

const styles = {
  main: {
    width: '98%',
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '20px 0 0 0',
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: 'rgba(242, 240, 238, 1)',
  },

  label: {
    fontFamily: 'InterMedium',
    fontSize: '14px',
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  value: {
    fontFamily: 'InterSemiBold',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.88)',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px solid rgba(229, 224, 221, 1)',
    width: 'max-content',
    paddingRight: '30px',
    gap: '5px',
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '30px',
    gap: '5px',
  },
};

import React from 'react';
import { Box, Dialog, Typography } from '@mui/material';
import { ReactComponent as HighlightOffOutlinedIcon } from '../../assets/imgs/close.svg';
import { black, mercury, shark, wildSand } from '../../constants/colors';
import DynamicButton from '../new/Button';

export const SendInviteModal = ({
  open,
  handleClose,
  type,
  user,
  handleSend,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: styles.paper }}>
      <Typography variant="h5" sx={styles.heading}>
        {type} invite
        <HighlightOffOutlinedIcon
          style={styles.closeIcon}
          onClick={handleClose}
        />
      </Typography>
      <Typography variant="h5" sx={styles.subheading}>
        {type} invite to {user}
      </Typography>
      <Box sx={styles.btns}>
        <DynamicButton
          type="contained"
          sx={styles.cancel}
          onClick={handleClose}
        >
          Cancel
        </DynamicButton>
        <DynamicButton
          type="contained"
          sx={styles.template}
          onClick={handleSend}
        >
          {type}
        </DynamicButton>
      </Box>
    </Dialog>
  );
};

const styles = {
  paper: {
    borderRadius: '8px',
    padding: '32px',
    width: '458px',
  },
  heading: {
    color: black,
    fontSize: '20px',
    lineHeight: '32px',
    marginBottom: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 700,
  },
  subheading: {
    color: black,
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: '34px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 500,
  },
  closeIcon: {
    color: shark,
    fontSize: '30px',
    background: wildSand,
    borderRadius: '50%',
    padding: '5px',
    cursor: 'pointer',
  },
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px',
  },
  cancel: {
    color: black,
    width: '81px',
    height: '40px',
    background: mercury,
    borderRadius: '100px',
    boxShadow: 'none',
    border: `1px solid ${mercury}`,
    fontFamily: 'SoehneBold',
    '&:hover': { background: mercury },
  },
  template: {
    color: 'white',
    width: '138px',
    height: '40px',
    borderRadius: '100px',
    boxShadow: 'none',
    fontFamily: 'SoehneBold',
  },
};

import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  FormControl,
  FormHelperText,
  InputBase,
  Typography,
} from '@mui/material';
import NumericFormatCustom from './NumericFormatCustom';
import { alabaster, black6, gallery } from '../../constants/colors';
import descriptionIcon from '../../assets/imgs/description-20.svg';
import notesIcon from '../../assets/imgs/notes-20.svg';
import '../../styles/plans/customeTextarea.css';

const BootstrapInput = styled(({ inputRef, ...otherProps }) => (
  <InputBase {...otherProps} inputRef={inputRef} />
))(
  ({
    theme,
    disabled,
    multiline,
    subActivity,
    showMore,
    expanded,
    notes,
    isHovered,
  }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    'textarea.MuiInputBase-input[aria-hidden="true"]': {
      display: 'none',
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      border: `none`,
      fontSize: '14px !important',
      width: '99%',
      padding: subActivity ? '8px 8px 0 8px' : '0',
      wordBreak: subActivity && notes && 'break-all',
      gap: '4px',
      marginTop: '5px',
      transition: isHovered
        ? 'none !important'
        : theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
          ]),
      fontFamily: multiline
        ? 'InterRegular !important'
        : 'InterMedium !important',
      backgroundColor: isHovered && 'initial !important',
      '&:hover': {
        // backgroundColor: 'rgba(142, 128, 113, 0.02) !important',
        backgroundColor: 'initial !important',
        // border:
        //   !showMore && !expanded && !notes && '1px solid rgba(0, 0, 0, 0.45)',
        paddingBottom: !showMore && !expanded && !notes && '3px',
        // borderColor: disabled ? gallery : 'rgba(0, 0, 0, 0.45)',
      },
      '&:focus': { borderColor: disabled ? gallery : 'rgba(0, 155, 119, 1)' },
      '&:active': { borderColor: disabled ? gallery : 'rgba(0, 155, 119, 1)' },
    },
  })
);

export const ActivityTextarea = ({
  type = 'text',
  field,
  name,
  value,
  min = 0,
  label,
  styles,
  placeholderStyles = {},
  rows = 4,
  helperText,
  placeholder = '',
  required = false,
  multiline = false,
  disabled = false,
  handleChange,
  onKeyDown,
  labelStyle,
  subActivity = false,
  notes = false,
  characterLimit = 200,
  description,
  readOnly = false,
  share = false,
  inputRef,
  showMore = false,
  setExpanded,
  setShowMore,
  expanded,
  setFocused,
  focused,
}) => {
  const [characterCount, setCharacterCount] = useState(value?.length || 0);
  const [isHovered, setIsHovered] = useState(false);

  const getRows = (limit, type) => {
    if (type === 'notes') {
      if (limit <= 20) return 1;
      else if (limit <= 40) return 2;
      else if (limit <= 60) return 3;
      else if (limit <= 90) return 4;
      else if (limit <= 115) return 5;
      else return 6;
    } else {
      if (limit <= 40) return 1;
      else if (limit <= 80) return 2;
      else if (limit <= 120) return 3;
      else if (limit <= 160) return 4;
      else if (limit <= 180) return 5;
      else return 6;
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (characterLimit > 0) {
      if (!subActivity) {
        if (inputValue.length > 80) {
          setShowMore(true);
        } else {
          setShowMore(false);
        }
      }

      if (inputValue.length <= characterLimit) {
        setCharacterCount(inputValue.length);
        handleChange(e);
      }
    } else {
      handleChange(e);
    }
  };

  return (
    <FormControl
      variant="standard"
      fullWidth
      sx={{
        marginBottom: styles?.marginBottom || '25px',
        position: 'relative',
        width: subActivity && notes ? '100%' : share ? '100%' : '100%',
        overflowX: share && 'hidden',
      }}
    >
      {!subActivity && (
        <Box
          style={{ ...textareaStyles.label, opacity: disabled ? '0.2' : '1' }}
        >
          <img src={description ? descriptionIcon : notesIcon} alt="Icon" />
          {label && (
            <Typography
              sx={{
                ...labelStyle,
                '&.Mui-hover': {
                  color: 'rgba(0, 0, 0, 0.45)',
                },
                '&.Mui-focused': {
                  color: 'rgba(0, 155, 119, 1)',
                },
                '&.Mui-active': {
                  color: 'rgba(0, 155, 119, 1)',
                },
                color: disabled ? 'black' : 'rgba(0, 0, 0, 0.6)',
                width: '100%',
                textAlign: 'left',
              }}
            >
              {label}
            </Typography>
          )}
        </Box>
      )}
      <Box
        onMouseEnter={() => {
          if (!disabled && !readOnly) {
            setIsHovered(true);
          }
        }}
        onMouseLeave={() => {
          if (!disabled && !readOnly) {
            setIsHovered(false);
          }
        }}
        sx={{
          boxShadow:
            ((showMore || expanded) && !notes) ||
            (focused && characterCount > 0)
              ? 1
              : 0,
          marginTop: '4px',
          marginRight: subActivity && '16px',
          borderRadius: '8px',
          '&:hover': {
            // border:
            //   (showMore || expanded) &&
            //   !notes &&
            //   '1px solid rgba(0, 0, 0, 0.45)',
            backgroundColor: 'rgba(142, 128, 113, 0.08) !important',
          },
        }}
      >
        <BootstrapInput
          onKeyDown={onKeyDown}
          type={type}
          disabled={disabled}
          value={value}
          name={name}
          id={name}
          inputRef={inputRef}
          rows={
            subActivity
              ? characterCount < 23
                ? 1
                : 2
              : notes
              ? getRows(characterCount, 'notes')
              : getRows(characterCount, 'desc')
          }
          multiline={multiline}
          required={required}
          placeholder={
            !disabled && !readOnly && (expanded || focused) ? '' : placeholder
          }
          onFocus={() => {
            if (!disabled && !readOnly) {
              if (!subActivity) {
                setExpanded(true);
                if (value.length > 80) setShowMore(true);
              } else {
                setFocused(true);
              }
            }
          }}
          onBlur={() => {
            if (!disabled && !readOnly) {
              if (!subActivity) {
                setExpanded(false);
                setShowMore(false);
              } else {
                setFocused(false);
              }
            }
          }}
          onMouseEnter={() => {
            if (!disabled && !readOnly) {
              subActivity && setFocused(true);
            }
          }}
          onMouseLeave={() => {
            if (!disabled && !readOnly) {
              subActivity && setFocused(false);
            }
          }}
          inputProps={{
            readOnly: readOnly,
            style: {
              backgroundColor: alabaster,
              position: 'relative',
              ...styles,
            },
            min,
          }}
          {...(field === 'number' && { inputComponent: NumericFormatCustom })}
          sx={{
            '& ::placeholder': placeholderStyles,
            padding: subActivity ? '0' : '0 8px',
            width: '100%',
            '&:hover': {
              backgroundColor: 'none',
            },
          }}
          onChange={handleInputChange}
          className="custom-input activity-textarea"
          subActivity={subActivity}
          showMore={showMore}
          expanded={expanded}
          notes={notes}
          isHovered={isHovered}
        />
        {helperText && (
          <FormHelperText sx={{ marginTop: '-1px' }}>
            {helperText}
          </FormHelperText>
        )}

        {multiline &&
          characterLimit > 0 &&
          (showMore || (focused && characterCount > 0)) && (
            <FormHelperText
              sx={{
                ...textareaStyles.helperText,
                marginBottom: subActivity && notes && '0',
              }}
            >
              {`${characterCount}/${characterLimit}`}
            </FormHelperText>
          )}
      </Box>
    </FormControl>
  );
};

const textareaStyles = {
  label: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    paddingLeft: '4px',
    width: '183px',
  },
  helperText: {
    fontSize: '12px',
    color: black6,
    fontWeight: 400,
    lineHeight: '16px',
    marginLeft: '8px',
    marginBottom: '4px',
  },
};

import React, { useEffect, useState } from 'react';
import { Box, Typography, Container } from '@mui/material';
import NewLogo from '../assets/imgs/redirectinglogo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import httpClient from '../config/http.service';
import { black, mercury, primary } from '../constants/colors';
import { showNotification } from '../components/common/Toast';

export const RedirectingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = location.search;
    const urlParams = new URLSearchParams(params);
    const token = urlParams.get('token');
    if (params.length > 1) {
      if (token) {
        const decodedString = atob(token);
        const obj = JSON.parse(decodedString);
        redirectUser(
          obj.type,
          token,
          obj.planId ? obj.planId : obj._id,
          obj.email,
          obj._id
        );
      }
    }
  }, []);
  const redirectUser = (type, token, id, email, userId) => {
    if (type === 'plan') {
      httpClient
        .post(`api/${type}/accept-invite`, {
          token: token,
        })
        .then((response) => {
          setTimeout(() => {
            navigate(`/${type}/${response.data._id}/${token}`);
          }, 3000);
        })
        .catch((err) =>
          showNotification('error', err?.response?.data?.message)
        );
    } else {
      httpClient
        .get(`api/template/get`, { params: { _id: id } })
        .then((response) => {
          let exist = false;
          response.data.shareList?.map((el) => {
            if (el.email === email) exist = true;
          });

          if (exist) navigate(`/view-template/${id}/userId/${userId}`);
          else
            showNotification(
              'error',
              'You have no longer access to this template'
            );
        })
        .catch((err) =>
          showNotification('error', err?.response?.data?.message)
        );
    }
  };

  return (
    <Container maxWidth="xl">
      <Box style={styles.container}>
        <Box style={styles.main_container}>
          <Box style={styles.verify_box}>
            <img src={NewLogo} alt="Logo" width={284} height={68}/>

            <Typography
              variant="subtitle1"
              align="center"
              sx={{ marginTop: '30px', fontSize: '16px' }}
            >
              We're redirecting you to the Inquarter App! Wait a few seconds.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

const styles = {
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px',
    marginTop: '40px',
  },
  cancel: {
    color: black,
    width: '81px',
    height: '40px',
    background: mercury,
    borderRadius: '100px',
    boxShadow: 'none',
    border: `1px solid ${mercury}`,
    '&:hover': { background: mercury },
  },
  confirm: {
    color: 'white',
    minWidth: '115px',
    height: '40px',
    borderRadius: '100px',
    boxShadow: 'none',
    background: primary,
    '&:hover': { background: primary },
  },
  container: {
    minHeight: '100vh',
  },
  main_container: {
    height: '80vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  verify_box: {
    padding: '30px 30px',
    width: '550px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow:
      '0px 32px 64px rgba(0, 0, 0, 0.02), ' +
      '0px 16px 32px rgba(0, 0, 0, 0.02), ' +
      '0px 8px 16px rgba(0, 0, 0, 0.02), ' +
      '0px 4px 8px rgba(0, 0, 0, 0.02), ' +
      '0px 2px 4px rgba(0, 0, 0, 0.02), ' +
      '1px 0px 2px rgba(0, 0, 0, 0.02)',
  },
};

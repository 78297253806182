import React, { useRef, useState } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Box, FormHelperText, Tooltip, Typography } from '@mui/material';
import { warning } from '../../constants/colors';
import calendar from '../../assets/imgs/calendar20dp.svg';
import { ReactComponent as CloseIcon } from '../../assets/imgs/close10.svg';

export const ActivityDatePicker = ({
  name,
  value,
  disabled,
  disablePast = false,
  handleChange,
  slotPropsStyles,
  inputStyles,
  helperText,
  overdue = false,
  readOnly = false,
  createOrEditModal = false,
  labelStyle,
  label,
  activity = false,
  hoveringDate,
}) => {
  const [open, setOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);

  return (
    <>
      {activity && (
        <Box style={{ ...styles.label, opacity: disabled ? '0.2' : '1' }}>
          <img src={calendar} alt="Icon" />
          <Typography
            sx={{
              ...labelStyle,
              '&.Mui-hover': {
                color: 'rgba(0, 0, 0, 0.45)',
              },
              '&.Mui-focused': {
                color: 'rgba(0, 155, 119, 1)',
              },
              '&.Mui-active': {
                color: 'rgba(0, 155, 119, 1)',
              },
              color: disabled ? 'black' : 'rgba(0, 0, 0, 0.6)',
              width: '100%',
              textAlign: 'left',
            }}
          >
            {label}
          </Typography>
        </Box>
      )}
      <Box sx={(open || hoveringDate) && styles.box}>
        {open || (currentValue && currentValue !== '') ? (
          <DesktopDatePicker
            open={readOnly || disabled ? false : open}
            value={
              open
                ? !dayjs(value).isValid()
                  ? null
                  : dayjs(value)
                : value && currentValue !== null
                ? dayjs(value)
                : 'No date'
            }
            format="DD MMM, YYYY"
            disabled={disabled}
            readOnly={readOnly}
            onClose={() => setOpen(false)}
            className={`multiColorSelectInput desktopPicker`}
            sx={{
              backgroundColor: 'inherit !important',
              marginTop: activity && '4px',
              width: open || hoveringDate ? 'auto' : 'auto',
              maxWidth: '150px',
              marginBottom: '0',
              height: createOrEditModal ? 'auto' : '36px',

              '& .MuiInputBase-root': {
                height: '40px !important',
                fontSize: '14px !important',
                fontFamily: 'InterRegular !important',
                paddingRight: createOrEditModal || disabled ? '14px' : '0',
                width: '100%',
                color: overdue && !activity && warning,

                ...inputStyles,
              },
              '& .Mui-focused fieldset': {
                marginTop: '1px',
              },
              '.MuiOutlinedInput-notchedOutline': {
                marginTop: '1px',
              },
              // '& .MuiInputAdornment-root': {
              //   width: '35px !important',
              // },
            }}
            disablePast={disablePast}
            onChange={(newValue) => {
              setCurrentValue(newValue);
              handleChange({ target: { name, value: newValue } });
            }}
            slotProps={{
              inputAdornment: {
                style: styles.disable,
              },
              textField: {
                placeholder: 'No date',
                onClick: () => setOpen(true),
              },

              ...slotPropsStyles,
            }}
          />
        ) : (
          <span
            className="customNoDate"
            style={{ marginTop: activity && '5px' }}
            onClick={() => setOpen(true)}
          >
            No date
          </span>
        )}

        {(open || hoveringDate) &&
        value &&
        value !== '' &&
        currentValue !== '' ? (
          <Tooltip title="Clear date" placement="top" arrow>
            <Box sx={styles.boxCloseIcon}>
              <CloseIcon
                onClick={() => {
                  setCurrentValue('');
                  handleChange({ target: { name, value: '' } });
                }}
                style={{
                  ...styles.closeIcon,
                  marginBottom: activity && '-4px',
                }}
              />
            </Box>
          </Tooltip>
        ) : null}
      </Box>
      {helperText && (
        <FormHelperText sx={styles.helperText}>{helperText}</FormHelperText>
      )}
    </>
  );
};

const styles = {
  box: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    paddingLeft: '4px',
  },
  closeIcon: {
    backgroundColor: 'rgba(142, 128, 113, 0.08)',
    padding: '10px',
    borderRadius: '50%',
    marginLeft: '-32px',
  },
  disable: {
    display: 'none',
  },
  boxCloseIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    marginLeft: '-2px',
    zIndex: 9999,
    cursor: 'pointer',
  },
  helperText: { fontSize: '12px', lineHeight: '16px' },
  // noDate: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   height: '24px',
  //   padding: '6px',
  //   fontSize: '14px',
  //   fontFamily: 'InterRegular',
  // },
};

import React, { useRef, useState } from 'react';
import {
  Box,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { alabaster, lightGray } from '../../constants/colors';
import '../../styles/plans/customeTextarea.css';
import statusIcon from '../../assets/imgs/statusIcon.svg';

const selectStyles = {
  label: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    paddingLeft: '4px',
    width: 'calc(100% - 4px)',
  },
  select: {
    background: alabaster,
    color: lightGray,
    '& .MuiInputBase-input': {
      padding: '0 !important',
    },
    '&.Mui-focused fieldset': {
      border: 'none !important',
      height: '40px',
    },
    '&.Mui-focused:hover': {
      boxShadow: 1,
    },
    justifyContent: 'center',
  },
  menuItem: {
    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04) !important' },
    justifyContent: 'center',
    borderRadius: '4px !important',
    marginBottom: '10px !important',
  },
  menuProps: {
    MenuListProps: {
      sx: {
        padding: '0 8px',
        borderRadius: '4px !important',
      },
    },
  },
  selectValuePill: {
    borderRadius: '0',
    // width: '100%',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '6px',
    fontSize: '14px',
    letterSpacing: '0',
    border: 'none',
  },
  helperText: { fontSize: '11px' },
  disable: { display: 'none' },
};

const MultiColorSelectInput = ({
  label,
  name,
  value = '',
  options,
  styles,
  menuItemStyles,
  helperText,
  handleChange,
  fullWidth = false,
  disabled,
  readOnly,
  labelStyle,
  activity = false,
}) => {
  const statusRef = useRef();
  const [dropdownVisible, setDropdownVisible] = useState(false);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          paddingLeft: '4px',
          width: 'calc(100% - 4px)',
          opacity: disabled ? '0.2' : '1',
        }}
      >
        {activity && <img src={statusIcon} alt="Icon" />}
        <Typography
          sx={{
            ...labelStyle,
            '&.Mui-hover': {
              color: 'rgba(0, 0, 0, 0.45)',
            },
            '&.Mui-focused': {
              color: 'rgba(0, 155, 119, 1)',
            },
            '&.Mui-active': {
              color: 'rgba(0, 155, 119, 1)',
            },
            color: disabled ? 'black' : 'rgba(0, 0, 0, 0.6)',
            width: '100%',
            textAlign: 'left',
          }}
        >
          {label}
        </Typography>
      </Box>
      <Box
        onClick={() => setDropdownVisible(!dropdownVisible)}
        sx={{
          padding: '4px 8px',
          marginLeft: !activity && '4px',
          '&:hover': {
            backgroundColor: 'rgba(142, 128, 113, 0.08)',
            padding: '4px 8px',
            // marginTop: activity && '4px',
            borderRadius: '4px',
          },
        }}
      >
        <Select
          disabled={disabled}
          displayEmpty
          variant="outlined"
          name={name}
          value={value}
          onChange={handleChange}
          fullWidth={fullWidth}
          className="multiColorSelectInput"
          ref={statusRef}
          placeholder="No status"
          onClose={() => {
            if (!disabled && !readOnly) {
              setDropdownVisible(false);
              statusRef.current.classList.remove('Mui-focused');
              statusRef.current.previousSibling?.classList.remove(
                'Mui-focused'
              );
            }
          }}
          onOpen={() => {
            if (!disabled && !readOnly) {
              setDropdownVisible(true);
              statusRef.current.classList?.add('Mui-focused');
              statusRef.current.previousSibling?.classList.add('Mui-focused');
            }
          }}
          sx={{
            backgroundColor: 'transparent !important',
            // marginTop: activity && '4px',
            height: '32px',
            '& .MuiMenu-paper': {
              // boxShadow: '0px 8px 10px 1px rgba(0, 0, 0, 0.14)',
            },
            '& .MuiSelect-icon': {
              transition: 'none',
              transform: 'rotate(0deg)',
            },
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none !important',
            },
            '.MuiOutlinedInput-notchedOutline:hover': {
              border: 'none !important',
            },
            '.MuiOutlinedInput-input': {
              marginBottom: dropdownVisible && '4px',
            },
            '.MuiOutlinedInput-input:focus': {
              border: 'none',
            },
            '.MuiOutlinedInput-input:hover': {
              // border: !disabled && `1px solid rgba(0,0,0,0.45)`,
            },
            border: 'none !important',
            '&:hover': {
              fontSize: '10px',
              border: 'none !important',
              // boxShadow: value !== '' && 1,
              boxShadow: 'none',
              marginTop: activity && '0',
            },

            ...selectStyles.select,
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: dropdownVisible
                ? '1px solid black !important'
                : 'none !important',
              marginTop: '0',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline:hover': {
              border: 'none !important',
            },
            ...styles,
          }}
          slotProps={{
            inputAdornment: {
              sx: selectStyles.disable,
            },
          }}
          MenuProps={selectStyles.menuProps}
          inputProps={{ readOnly: readOnly, IconComponent: () => null }}
          renderValue={(selected) => {
            const option = options.find((o) => o.value === selected);
            return (
              <Box
                sx={{
                  ...selectStyles.selectValuePill,
                  backgroundColor: option.color ? option.color : 'transparent',
                  color: option.fontColor
                    ? option.fontColor
                    : disabled
                    ? 'rgba(0, 0, 0, 0.2)'
                    : 'rgba(0, 0, 0, 0.38)',
                  '-webkit-text-fill-color':
                    value === '' && !disabled
                      ? 'rgba(0, 0, 0, 0.38)'
                      : disabled && 'rgba(0, 0, 0, 0.2)',
                  fontFamily: option.fontColor ? 'InterMedium' : 'InterRegular',
                  height: '32px',
                }}
              >
                {option.name}
              </Box>
            );
          }}
        >
          {options.map((item) => (
            <MenuItem
              key={item.value}
              value={item.value}
              sx={{
                ...selectStyles.menuItem,
                ...menuItemStyles,
                backgroundColor: item.color
                  ? `${item.color} !important`
                  : 'transparent !important',
                color: item.fontColor ? `${item.fontColor} !important` : 'auto',
                '&.Mui-selected': {
                  backgroundColor: item.color
                    ? `${item.color} !important`
                    : 'transparent !important',
                },
              }}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {helperText && (
        <FormHelperText
          sx={{
            ...selectStyles.helperText,
            marginTop: activity ? '4px' : '-10px',
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};

export default MultiColorSelectInput;

import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { EngangementHeader } from '../Plans/EngangementHeader';
import { sortCollaboratorHistory, randomColor } from '../../utils/utils';
import { black, lightGray, mercury, white } from '../../constants/colors';
import { HistoryTimeline } from './Timeline';
import httpClient from '../../config/http.service';
import { showNotification } from '../common/Toast';
import { colorsForShareList } from '../../utils/constants';

export const Engangement = ({ planId, vendor, plan, openShareModal }) => {
  const [loaded, setLoaded] = useState(false);
  const [history, setHistory] = useState([]);
  const [selectedDate, setSelectedDate] = useState({
    end: new Date().toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }),
    all: true,
    fromDate: new Date(),
  });
  const [collaborators, setCollaborators] = useState(null);
  const [selectedCollaborator, setSelectedCollaborator] = useState(null);
  const [userColors, setUserColors] = useState(null);
  const [companies, setCompanies] = useState([]);

  const getHistory = () => {
    setLoaded(false);
    if (selectedDate?.end) {
      const params = {
        _id: planId,
        fromDate: selectedDate.end,
      };

      if (selectedCollaborator && selectedCollaborator?.key !== 'all')
        params.userEmail = selectedCollaborator.email;
      httpClient
        .get(`api/plan/engagments`, {
          params,
        })
        .then((res) => {
          if (res?.data?.length) {
            setHistory(res.data);
          } else {
            setHistory(null);
          }
          setLoaded(true);
        })
        .catch((err) => {
          showNotification('error', err?.response?.data?.message);
          setLoaded(true);
        });
    }
  };

  const getCollaborators = async () => {
    await httpClient
      .get(`api/plan/access`, { params: { _id: planId } })
      .then((res) => {
        let colors = [
          {
            name: vendor.fullName,
            color: colorsForShareList[0].color,
            backgroundColor: colorsForShareList[0].backgroundColor,
          },
        ];

        let formattedUsers = [
          { name: 'All Members', key: 'all' },
          { name: vendor?.fullName, email: vendor?.email, key: vendor?.email },
        ];
        res.data.map((user) => {
          formattedUsers.push({
            name: user.fullName,
            email: user.email,
            key: user.email,
          });
        });
        res.data.map((user) => {
          colors.push({
            name: user.fullName,
            color: user?.color || randomColor(),
            backgroundColor: user?.backgroundColor,
          });
        });
        setCollaborators(formattedUsers);

        if (colors.length) setUserColors(colors);

        const userTypeCompanyMap = res.data.reduce((map, user) => {
          const userType = user?.userType?.toLowerCase();
          if (!map[userType]) {
            map[userType] = [];
          }
          if (!map[userType]?.includes(user.company)) {
            map[userType].push(user.company);
          }
          return map;
        }, {});

        setCompanies(userTypeCompanyMap);
      })
      .catch((err) => {
        //   if (err?.response?.data?.code !== 401)
        //     showNotification('error', err?.response?.data?.message);
      });
  };

  useEffect(() => {
    if (planId) {
      if (!collaborators) getCollaborators(plan);
      getHistory();
    }
  }, [selectedDate, selectedCollaborator, plan?.vendorColor, planId, plan]);

  const handleCollaboratorChange = (item) => {
    setSelectedCollaborator(item);
  };

  const handleDateChange = (item) => {
    const originalDate = new Date(item);
    const options = {
      weekday: 'long',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    };
    const formattedDate = originalDate.toLocaleDateString('en-US', options);
    setSelectedDate({ end: formattedDate, all: false, fromDate: item });
    if (selectedCollaborator) {
      const sortedArray = sortCollaboratorHistory(
        selectedCollaborator,
        history,
        {
          endDate: new Date(item),
          all: false,
        }
      );
      setHistory(sortedArray);
    }
  };

  const handleClearDate = () => {
    setSelectedDate({
      end: new Date(),
      all: true,
      fromDate: new Date(),
    });
    const sortedArray = sortCollaboratorHistory(selectedCollaborator, history, {
      endDate: new Date(selectedDate.endDate),
      all: true,
    });
    setHistory(sortedArray);
  };

  return (
    <Box>
      <Box style={styles.header}>
        <EngangementHeader
          selectedCollaborator={selectedCollaborator}
          selectedDate={selectedDate}
          handleCollaboratorChange={handleCollaboratorChange}
          handleDateChange={handleDateChange}
          handleClearDate={handleClearDate}
          collaborators={collaborators}
        />
      </Box>
      {/* {selectedCollaborator && selectedCollaborator.key !== 'all' && (
        <Box sx={styles.collaborator}>
          <Avatar sx={styles.avatar}>
            {getInitialsFromString(selectedCollaborator.name)}
          </Avatar>
          <Box sx={styles.wrapper}>
            <Typography sx={styles.name}>
              {selectedCollaborator.name}
            </Typography>
            <Box sx={styles.details}>
              <Typography sx={styles.detailsText}>
                {selectedCollaborator.email}
              </Typography>
              <Box sx={styles.dot}></Box>
              <Typography sx={styles.detailsText}>
                {selectedCollaborator.title ?? 'Sales Representative'}
              </Typography>
              <Box sx={styles.dot}></Box>
              <Typography sx={styles.detailsTextLast}>
                <Typography sx={styles.lastViewed}>Last viewed: </Typography>
                {selectedCollaborator.lastViewed ??
                  'Tuesday, 28 Aug 2023 : 10:30am'}
              </Typography>
            </Box>
          </Box>
        </Box>
      )} */}
      <Box sx={styles.timeline}>
        <HistoryTimeline
          mockData={history}
          userColors={userColors}
          companies={companies}
          loaded={loaded}
          planStatus={plan?.status}
          openShareModal={openShareModal}
        />
      </Box>
    </Box>
  );
};

const styles = {
  header: {
    height: '72px',
    marginTop: '24px',
    padding: '0 20px',
    backgroundColor: white,
    border: '1px solid #F2F4F7',
    borderRadius: '8px',
    display: 'flex',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2)',
  },
  collaborator: {
    marginTop: '16px',
    backgroundColor: white,
    padding: '12px 24px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
  },
  avatar: {
    width: '56px',
    height: '56px',
  },
  wrapper: {
    marginLeft: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    color: black,
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'SoehneBold',
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '8px',
  },
  detailsText: {
    fontSize: '14px',
    lineHeight: '20px',
    color: black,
  },
  detailsTextLast: {
    fontSize: '14px',
    lineHeight: '20px',
    color: black,
    display: 'flex',
  },
  lastViewed: {
    fontSize: '14px',
    lineHeight: '20px',
    color: lightGray,
    marginRight: '5px',
  },
  dot: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: mercury,
    marginLeft: '10px',
    marginRight: '10px',
  },
  timeline: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
};

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Avatar, Box, Dialog, Divider, Typography } from '@mui/material';
import { ReactComponent as HighlightOffOutlinedIcon } from '../../assets/imgs/close.svg';
import { ReactComponent as CopyLinkIcon } from '../../assets/imgs/copyLink.svg';
import { ReactComponent as NoShareList } from '../../assets/imgs/noShareList.svg';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { lochinvar, shark, white } from '../../constants/colors';
import { toast } from 'react-toastify';
import './nativelibrary.css'; // Import your stylesheet
import { RemoveFromShareListModal } from './RemoveFromShareListModal';
import { SendInviteModal } from './SendInviteModal';
import { store } from '../../Store';
import httpClient from '../../config/http.service';
import { showNotification } from '../common/Toast';
import DynamicButton from '../new/Button';
import { AddNewMember } from '../../modals/AddNewMember';
import { SaveUserToShareList } from '../../modals/SaveUserToShareList';
import {
  getDarkerColor,
  getInitialsFromString,
  getShareListForPlan,
  randomColor,
  removeDuplicatesUsersByFullName,
} from '../../utils/utils';
import { colorsForShareList } from '../../utils/constants';

let useClickOutside = (handler) => {
  let listRef = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!listRef.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener('mousedown', maybeHandler);

    return () => {
      document.removeEventListener('mousedown', maybeHandler);
    };
  });

  return listRef;
};

const defaultMsg = `Hey, I've created this Mutual Action Plan to ensure that we are aligned on the next steps. It's designed to be collaborative, so feel free to add or edit any activities. I look forward to discussing the plan in more detail. In the meantime, if you have any questions, please don't hesitate to get in touch.`;

const initial = {
  fullName: '',
  email: '',
  title: '',
  shared: false,
  company: '',
  customMessage: defaultMsg,
};

export const SharePlanModal = ({
  open,
  handleClose,
  plan,
  setPlan,
  type,
  company,
}) => {
  const { state, dispatch } = useContext(store);
  const [openSendModal, setOpenSendModal] = useState(false);
  const [data, setData] = useState(initial);
  const [sharePlans, setSharePlans] = useState([initial]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [userToRemove, setUserToRemove] = useState(null);
  const [openAddNewMember, setOpenAddNewMember] = useState(false);
  const [openSaveMember, setOpenSaveMember] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [customMessage, setCustomMessage] = useState(
    `Hey, I've created this Mutual Action Plan to ensure that we are aligned on the next steps. It's designed to be collaborative, so feel free to add or edit any activities. I look forward to discussing the plan in more detail. In the meantime, if you have any questions, please don't hesitate to get in touch.`
  );
  const [userForEdit, setUserForEdit] = useState({
    fullName: '',
    email: '',
    title: '',
    shared: false,
    company: '',
    customMessage: defaultMsg,
  });
  const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null);
  const [openSubMenuIndexShared, setOpenSubMenuIndexShared] = useState(null);
  const [typeOfRef, setTypeOfRef] = useState('fullName');
  const [options, setOptions] = useState([
    { value: plan?.customerCompany || '', label: plan?.customerCompany || '' },
    {
      value: company?.vendor || plan?.vendor || '',
      label: company?.vendor || plan?.vendor || '',
    },
    { value: 'Partner', label: 'Partner' },
    { value: 'Other', label: 'Other' },
  ]);

  const wrapperBoxRef = useRef();
  const [fromTop, setFromTop] = useState(0);
  const [fromLeft, setFromLeft] = useState(0);
  const [hasInvited, setHasInvited] = useState(false);
  const [hasShared, setHasShared] = useState(false);

  useEffect(() => {
    if (open) {
      document.addEventListener('click', function (e) {
        setFromTop(e.clientY - 10);
        setFromLeft(e.clientX - 210);
      });

      const doc = wrapperBoxRef?.current;
      if (doc) {
        doc.addEventListener('scroll', function (e) {
          setOpenSubMenuIndex(false);
          setOpenSubMenuIndexShared(false);
        });
      }
    }

    return () => {
      document.removeEventListener('click', function (e) {
        setFromTop(e.clientY - 10);
        setFromLeft(e.clientX - 210);
      });

      const doc = wrapperBoxRef?.current;
      if (doc) {
        doc.removeEventListener('scroll', function (e) {
          setOpenSubMenuIndex(false);
          setOpenSubMenuIndexShared(false);
        });
      }
    };
  }, [wrapperBoxRef?.current]);

  useEffect(() => {
    if ((!company || company === '') && plan) {
      httpClient
        .get(`api/company/get`, { params: { _id: plan?.vendor } })
        .then(async (response) => {
          setOptions([
            {
              value: plan?.customerCompany || '',
              label: plan?.customerCompany || '',
            },
            {
              value: response.data?.name || plan?.vendor || '',
              label: response.data?.name || plan?.vendor || '',
            },
            { value: 'Partner', label: 'Partner' },
            { value: 'Other', label: 'Other' },
          ]);
        })
        .catch((err) => {
          // showNotification('error', err?.response?.data?.message)
        });
    } else {
      setOptions([
        {
          value: plan?.customerCompany || '',
          label: plan?.customerCompany || '',
        },
        {
          value: company?.vendor || plan?.vendor || '',
          label: company?.vendor || plan?.vendor || '',
        },
        { value: 'Partner', label: 'Partner' },
        { value: 'Other', label: 'Other' },
      ]);
    }
  }, [plan]);

  let listRef = useClickOutside(() => {
    setOpenSubMenuIndex(false);
    setOpenSubMenuIndexShared(false);
  });

  useEffect(() => {
    if (plan?._id) {
      getShareList();
      getHasSharedInvited();
    }
  }, [plan?._id, plan?.shareList]);

  const getShareList = async () => {
    const newList = plan?.shareList.sort((a, b) => {
      if (a.shared && !b.shared) {
        return -1; // a should come before b
      } else if (!a.shared && b.shared) {
        return 1; // b should come before a
      } else {
        return 0; // the order doesn't matter
      }
    });
    // const newUnique = removeDuplicatesUsersByFullName(newList);
    // setSharePlans(newUnique);

    setSharePlans(newList);
  };

  const getHasSharedInvited = () => {
    let hasSharedLocal = false;
    let hasInvitedLocal = false;
    if (plan?.shareList.length) {
      for (const user of plan?.shareList) {
        if (user.shared) {
          hasSharedLocal = true;
        } else {
          hasInvitedLocal = true;
        }
        if (hasSharedLocal && hasInvitedLocal) {
          break;
        }
      }
    }
    setHasInvited(hasInvitedLocal);
    setHasShared(hasSharedLocal);
  };

  const sendInvite = (fromList = false, userToSend) => {
    let newData;
    if (!fromList) {
      newData = data;
      // newData.color = randomColor();

      if (data.email === '')
        return showNotification('error', 'Email is required');
      if (data.fullName === '')
        return showNotification('error', 'Full name is required');
      if (data.title === '')
        return showNotification('error', 'Title is required');
    } else {
      newData = userToSend;
      // newData.color = randomColor();
    }
    const shared = newData.shared;
    const exist = sharePlans?.find((el) => el.email === newData.email);
    const newArray = [...sharePlans];
    if (exist) {
      newArray?.map((el) => {
        if (el.email === newData.email) {
          el.shared = true;
        }
      });
    } else {
      const duplicate = newData;
      if (duplicate.email !== '') duplicate.shared = true;
      delete duplicate.customMessage;
      newArray.push(duplicate);
    }
    newArray.map((el) => delete el.name);
    httpClient
      .post('api/plan/send-invite', {
        _id: plan._id,
        email: newData.email,
        shareList: newArray,
        fullName: newData.fullName,
        customMessage: newData.customMessage || customMessage,
      })
      .then((res) => {
        setSharePlans(newArray);
        showNotification(
          'success',
          `Successfully ${shared ? 'resent' : 'sent'} invite to ${
            newData.email
          }`
        );
        setData({ name: '', email: '', title: '', shared: false, company: '' });
        setOpenSaveMember(false);
        setOpenSendModal(false);
        setOpenAddNewMember(false);
        setCustomMessage('');
        setPlan({ ...plan, shareList: newArray, status: 'On track' });
        getShareList();
      })
      .catch((err) => showNotification('error', err?.response?.data?.message));
  };

  const handleCopyClick = () => {
    try {
      const link = `${process.env.REACT_APP_WEB}map/${plan?.shortId}`;
      navigator.clipboard.writeText(link);
      // httpClient
      //   .post(`api/plan/short-link`, {
      //     link,
      //     planName: plan.name,
      //   })
      //   .then((res) => {

      showNotification('success', 'Link copied to clipboard');
      //   })
      //   .catch((err) => {
      //     // showNotification('error', err?.response?.data?.message)
      //   });
    } catch (error) {
      toast.error('Failed to copy link:');
    }
  };

  const handleSubMenuClick = (index) => {
    setOpenSubMenuIndex(index === openSubMenuIndex ? null : index);
  };

  const handleSubMenuClickShared = (index) => {
    setOpenSubMenuIndexShared(index === openSubMenuIndexShared ? null : index);
  };

  const deleteUser = (user) => {
    const newArray = sharePlans?.filter((el) => el._id !== user._id);
    httpClient
      .patch(`api/${type}/update-share-list`, {
        _id: plan._id,
        shareList: newArray,
        userToRemove: user,
        userToUpdate: null,
      })
      .then((res) => {
        const shareList = [{ planId: plan._id, users: newArray }];
        const obj = res.data;
        obj.vendor = plan.vendor;
        obj.vendorRepresentative =
          state?.data?.user?.firstName + ' ' + state?.data?.user?.lastName;
        setPlan(res.data);
        setSharePlans(newArray);
        const getUsers = getShareListForPlan(res.data);
        dispatch({
          type: 'SET_SUGGESTIONS',
          payload: {
            planId: plan?._id,
            customers: getUsers?.customers || [],
            vendors: getUsers?.vendors || [],
          },
        });
        showNotification(
          'success',
          `Successfully removed  ${
            user?.fullName !== '' ? user.fullName : 'user'
          } from the invite list!`
        );
        setIsEdit(false);
        setOpenSubMenuIndex(null);
        setOpenSubMenuIndexShared(null);
        setOpenDeleteModal(false);
      })
      .catch((err) => {
        // showNotification('error', err?.response?.data?.message)
      });
  };

  const saveMemberOnEdit = () => {
    const list = [...sharePlans];
    let updatedList;
    if (isEdit) {
      if (userForEdit.email !== '') {
        updatedList = list.filter((user) => user._id !== userForEdit._id);
      } else {
        updatedList = list.filter((user) => user._id !== userForEdit._id);
      }
    } else {
      updatedList = list;
    }

    const colorIndex = list.length % colorsForShareList.length;
    const colorObj = colorsForShareList[colorIndex];
    data.color = data.color || colorObj?.color;
    data.backgroundColor = data.backgroundColor || colorObj?.backgroundColor;

    delete data.name;
    updatedList.push(data);
    httpClient
      .patch(`api/${type}/update-share-list`, {
        _id: plan._id,
        shareList: updatedList,
        userToRemove: null,
        userToUpdate: data,
      })
      .then((res) => {
        setSharePlans(updatedList);

        const obj = res.data;
        obj.vendor = plan.vendor;
        obj.vendorRepresentative =
          state?.data?.user?.firstName + ' ' + state?.data?.user?.lastName;
        setPlan(res.data);
        const getUsers = getShareListForPlan(res.data);
        dispatch({
          type: 'SET_SUGGESTIONS',
          payload: {
            planId: plan?._id,
            customers: getUsers?.customers || [],
            vendors: getUsers?.vendors || [],
          },
        });
        setOpenAddNewMember(false);
        setData(initial);
        setIsEdit(false);
      })
      .catch((err) => {
        showNotification('error', err?.response?.data?.message);
      });
  };

  const handleUniqueURL = (user) => {
    const { email, fullName } = user;
    const toCrypt = {
      _id: plan._id,
      email,
      type: 'plan',
      fullName,
      planId: plan._id,
    };

    const cryptedToken = btoa(JSON.stringify(toCrypt)).toString('base64');
    const url = `${process.env.REACT_APP_WEB}invite-map?token=${cryptedToken}`;

    navigator.clipboard.writeText(url);
    showNotification('success', 'Unique url copied to clipboard');
    setOpenSubMenuIndex(null);
    setOpenSubMenuIndexShared(null);
  };

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: styles.paper }}>
      <Typography variant="h5" sx={styles.heading}>
        Share {plan?.name ? plan.name : plan?.title ? plan.title : ''}
        <Box sx={styles.closeIconBox}>
          <HighlightOffOutlinedIcon
            style={styles.closeIcon}
            onClick={() => {
              setOpenSubMenuIndex(null);
              setOpenSubMenuIndexShared(null);
              handleClose();
            }}
          />
        </Box>
      </Typography>
      <Box sx={styles.outerWrapper}>
        <Divider style={{ color: 'rgba(0, 0, 0, 0.1)' }} />
        <Box sx={styles.wrapperBox} id={'wrapperBox'} ref={wrapperBoxRef}>
          {hasInvited ? (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '14px 32px',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                borderTop: '1px solid rgba(0, 0, 0, 0.1)',
              }}
            >
              <Typography variant="h5" sx={styles.boxTitle}>
                Not yet invited
              </Typography>
              <Typography variant="h5" sx={styles.boxTitle}>
                {sharePlans?.filter(
                  (singleEmail) => singleEmail.shared === false
                ).length || '0'}{' '}
                {sharePlans?.filter(
                  (singleEmail) => singleEmail.shared === false
                ).length > 1
                  ? 'Members'
                  : 'Member'}
              </Typography>
            </Box>
          ) : (
            ''
          )}
          <Box
            sx={!hasInvited ? styles.mainBoxWithoutInivited : styles.mainBox}
          >
            {sharePlans?.length > 0 ? (
              sharePlans
                ?.filter((singleEmail) => !singleEmail.shared)
                .sort((a, b) => {
                  if (a.email && !b.email) return -1;
                  if (!a.email && b.email) return 1;

                  return 0;
                })
                .map((singleEmail, index) => {
                  if (!singleEmail.shared) {
                    return (
                      <Box
                        style={{
                          ...styles.left,
                          marginBottom: '20px',
                          // openSubMenuIndex === index &&
                          // sharePlans?.length - 1 === index
                          //   ? '80px'
                          //   : '20px',
                        }}
                      >
                        <Box
                          sx={{
                            ...styles.left,
                            width: '300px',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Avatar
                            className={styles.profile_avatar}
                            sx={{
                              backgroundColor:
                                singleEmail.backgroundColor || 'lightyellow',
                              color: singleEmail.color
                                ? getDarkerColor(singleEmail.color)
                                : 'lightblue',
                            }}
                          >
                            {singleEmail?.name !== ''
                              ? getInitialsFromString(
                                  singleEmail.name || singleEmail.fullName || ''
                                )
                              : ''}
                          </Avatar>
                          <Box sx={styles.nameAndEmail}>
                            <Typography sx={styles.username}>
                              {singleEmail?.name || singleEmail?.fullName || ''}
                            </Typography>
                            {singleEmail?.email ? (
                              <Typography sx={styles.email}>
                                {singleEmail?.email}
                              </Typography>
                            ) : (
                              <Typography
                                sx={styles.noEmail}
                                onClick={() => {
                                  setTypeOfRef('email');
                                  setOpenSubMenuIndex(null);
                                  setData(singleEmail);
                                  setUserForEdit(singleEmail);
                                  setIsEdit(true);
                                  setOpenAddNewMember(true);
                                }}
                              >
                                <span
                                  style={{
                                    color: 'rgba(0, 155, 119, 1)',
                                    fontFamily: 'InterMedium',
                                    cursor: 'pointer',
                                  }}
                                >
                                  Add email
                                </span>{' '}
                                to send invitation
                              </Typography>
                            )}
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            width: '35%',
                          }}
                        >
                          <Typography
                            style={{
                              ...styles.username,
                              color: singleEmail?.title
                                ? 'rgba(0, 0, 0, 0.88)'
                                : 'rgba(0, 0, 0, 0.45)',
                            }}
                          >
                            {singleEmail?.title || 'Job title'}
                          </Typography>
                          <Typography
                            style={{
                              ...styles.company,
                              color: singleEmail?.company
                                ? 'rgba(0, 0, 0, 0.7)'
                                : 'rgba(0, 0, 0, 0.45)',
                            }}
                          >
                            {singleEmail?.company || 'Company name'}
                          </Typography>
                        </Box>
                        <Box style={{ ...styles.left }}>
                          <DynamicButton
                            type={singleEmail?.shared ? 'text' : 'outlined'}
                            style={{ minWidth: '150px' }}
                            disabled={
                              !singleEmail.email ||
                              (singleEmail.email && singleEmail.email === '') ||
                              singleEmail.title === '' ||
                              singleEmail.fullName === ''
                            }
                            onClick={() => {
                              if (singleEmail.email !== '') {
                                if (singleEmail?.shared)
                                  sendInvite(true, singleEmail);
                                else {
                                  setOpenSaveMember(true);
                                  setData({
                                    ...singleEmail,
                                    customMessage: defaultMsg,
                                  });
                                }
                              } else {
                                showNotification(
                                  'error',
                                  'Email is required field!'
                                );
                              }
                            }}
                          >
                            {singleEmail?.shared
                              ? 'Resend Invite'
                              : 'Send Invite'}
                          </DynamicButton>
                          <Box sx={styles.relativeList}>
                            <MoreHorizIcon
                              sx={{
                                cursor: 'pointer',
                                color: 'rgba(0, 0, 0, 0.7)',
                              }}
                              aria-haspopup="true"
                              onClick={() => handleSubMenuClick(index)}
                            />

                            {openSubMenuIndex === index && (
                              <Box
                                sx={styles.absoluteList}
                                ref={listRef}
                                style={{
                                  top: `${fromTop}px`,
                                  left: `${fromLeft}px`,
                                }}
                              >
                                <Typography
                                  sx={styles.edit}
                                  onClick={() => {
                                    setTypeOfRef('fullName');
                                    setOpenSubMenuIndex(null);
                                    setData(singleEmail);
                                    setUserForEdit(singleEmail);
                                    setIsEdit(true);
                                    setOpenAddNewMember(true);
                                  }}
                                >
                                  Edit
                                </Typography>
                                <Typography
                                  sx={styles.delete}
                                  onClick={() => {
                                    setUserToRemove(singleEmail);
                                    setOpenDeleteModal(true);
                                  }}
                                >
                                  Delete
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    );
                  }
                })
            ) : (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '252px',
                }}
              >
                <Box
                  style={{
                    width: '64px',
                    height: '64px',
                    borderRadius: '10px',
                    backgroundColor: 'rgba(246, 245, 244, 1)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <NoShareList />
                </Box>
                <Typography
                  style={{
                    color: 'rgba(0, 0, 0, 0.7)',
                    fontFamily: 'InterSemiBold',
                    fontSize: '16px',
                    lineHeight: '24px',
                    marginTop: '15px',
                  }}
                >
                  Not shared
                </Typography>
              </Box>
            )}
          </Box>
          {/* {sharePlans?.length === 0 ? null : ( */}
          {hasShared ? (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '14px 32px',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                borderTop: '1px solid rgba(0, 0, 0, 0.1)',
              }}
            >
              <Typography variant="h5" sx={styles.boxTitle}>
                Invite sent
              </Typography>
              <Typography variant="h5" sx={styles.boxTitle}>
                {sharePlans?.filter(
                  (singleEmail) => singleEmail.shared === true
                ).length || '0'}{' '}
                {sharePlans?.filter(
                  (singleEmail) => singleEmail.shared === true
                ).length > 1
                  ? 'Members'
                  : 'Member'}
              </Typography>
            </Box>
          ) : (
            ''
          )}
          {/* )} */}
          {sharePlans?.length === 0 ? null : (
            <Box sx={styles.mainBox}>
              {sharePlans?.length > 0 ? (
                sharePlans.map((singleEmail, index) => {
                  if (singleEmail.shared) {
                    return (
                      <Box
                        style={{
                          ...styles.left,
                          marginBottom: '20px',
                          // openSubMenuIndex === index &&
                          // sharePlans?.length - 1 === index
                          //   ? '80px'
                          //   : '20px',
                        }}
                      >
                        <Box
                          sx={{
                            ...styles.left,
                            width: '300px',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Avatar
                            className={styles.profile_avatar}
                            sx={{
                              backgroundColor:
                                singleEmail.backgroundColor || 'lightyellow',
                              color: singleEmail.color
                                ? getDarkerColor(singleEmail.color)
                                : 'lightblue',
                            }}
                          >
                            {singleEmail?.name !== ''
                              ? getInitialsFromString(
                                  singleEmail.name || singleEmail.fullName || ''
                                )
                              : ''}
                          </Avatar>
                          <Box sx={styles.nameAndEmail}>
                            <Typography sx={styles.username}>
                              {singleEmail?.name || singleEmail?.fullName || ''}
                            </Typography>
                            {singleEmail?.email ? (
                              <Typography sx={styles.email}>
                                {singleEmail?.email}
                              </Typography>
                            ) : (
                              <Typography
                                sx={styles.noEmail}
                                onClick={() => {
                                  setTypeOfRef('email');
                                  setOpenSubMenuIndexShared(null);
                                  setData(singleEmail);
                                  setUserForEdit(singleEmail);
                                  setIsEdit(true);
                                  setOpenAddNewMember(true);
                                }}
                              >
                                <span
                                  style={{
                                    color: 'rgba(0, 155, 119, 1)',
                                    fontFamily: 'InterMedium',
                                    cursor: 'pointer',
                                  }}
                                >
                                  Add email
                                </span>{' '}
                                to send invitation
                              </Typography>
                            )}
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            width: '35%',
                          }}
                        >
                          <Typography
                            style={{
                              ...styles.username,
                              color: singleEmail?.title
                                ? 'rgba(0, 0, 0, 0.88)'
                                : 'rgba(0, 0, 0, 0.45)',
                            }}
                          >
                            {singleEmail?.title || 'Job title'}
                          </Typography>
                          <Typography
                            style={{
                              ...styles.company,
                              color: singleEmail?.company
                                ? 'rgba(0, 0, 0, 0.7)'
                                : 'rgba(0, 0, 0, 0.45)',
                            }}
                          >
                            {singleEmail?.company || 'Company name'}
                          </Typography>
                        </Box>
                        <Box style={{ ...styles.left }}>
                          <DynamicButton
                            type={singleEmail?.shared ? 'text' : 'outlined'}
                            style={{ minWidth: '150px' }}
                            disabled={
                              singleEmail.email === '' ||
                              singleEmail.title === '' ||
                              singleEmail.fullName === ''
                            }
                            onClick={() => {
                              if (singleEmail.email !== '') {
                                sendInvite(true, singleEmail);
                              } else {
                                showNotification(
                                  'error',
                                  'Email is required field!'
                                );
                              }
                            }}
                          >
                            {singleEmail?.shared
                              ? 'Resend Invite'
                              : 'Send Invite'}
                          </DynamicButton>
                          <Box sx={styles.relativeList}>
                            <MoreHorizIcon
                              sx={{
                                cursor: 'pointer',
                                color: 'rgba(0, 0, 0, 0.7)',
                              }}
                              aria-haspopup="true"
                              onClick={() => handleSubMenuClickShared(index)}
                            />

                            {openSubMenuIndexShared === index && (
                              <Box
                                sx={styles.absoluteList}
                                ref={listRef}
                                style={{
                                  top: `${fromTop}px`,
                                  left: `${fromLeft}px`,
                                }}
                              >
                                <Typography
                                  sx={styles.edit}
                                  onClick={() => {
                                    setTypeOfRef('fullName');
                                    setOpenSubMenuIndexShared(null);
                                    setData(singleEmail);
                                    setUserForEdit(singleEmail);
                                    setIsEdit(true);
                                    setOpenAddNewMember(true);
                                  }}
                                >
                                  Edit
                                </Typography>
                                {singleEmail?.email !== '' &&
                                  singleEmail?.fullName !== '' && (
                                    <Typography
                                      sx={styles.edit}
                                      onClick={() =>
                                        handleUniqueURL(singleEmail)
                                      }
                                    >
                                      Copy unique URL
                                    </Typography>
                                  )}
                                <Typography
                                  sx={styles.delete}
                                  onClick={() => {
                                    setUserToRemove(singleEmail);
                                    setOpenDeleteModal(true);
                                  }}
                                >
                                  Delete
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    );
                  }
                })
              ) : (
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '252px',
                  }}
                >
                  <Box
                    style={{
                      width: '64px',
                      height: '64px',
                      borderRadius: '10px',
                      backgroundColor: 'rgba(246, 245, 244, 1)',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <NoShareList />
                  </Box>
                  <Typography
                    style={{
                      color: 'rgba(0, 0, 0, 0.7)',
                      fontFamily: 'InterSemiBold',
                      fontSize: '16px',
                      lineHeight: '24px',
                      marginTop: '15px',
                    }}
                  >
                    Not shared
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Divider style={{ color: 'rgba(0, 0, 0, 0.1)' }} />
        <Box sx={styles.footer}>
          <Box sx={styles.leftSide}>
            <DynamicButton
              type="text"
              onClick={handleCopyClick}
              startIcon={<CopyLinkIcon />}
            >
              Copy link
            </DynamicButton>
            <Typography sx={styles.anyone}>
              Anyone with the link can view only (for sharing in CRM notes)
            </Typography>
          </Box>
          <DynamicButton
            type="contained"
            onClick={() => {
              setData(initial);
              setOpenAddNewMember(true);
            }}
          >
            Add new members
          </DynamicButton>
        </Box>
      </Box>
      <AddNewMember
        open={openAddNewMember}
        handleClose={() => setOpenAddNewMember(false)}
        edit={isEdit}
        saveMember={saveMemberOnEdit}
        inviteMember={() => {
          setOpenAddNewMember(false);
          setOpenSaveMember(true);
        }}
        data={data}
        setData={setData}
        typeOfRef={typeOfRef}
        options={options}
      />
      <SaveUserToShareList
        open={openSaveMember}
        handleClose={() => setOpenSaveMember(false)}
        edit={false}
        // saveMember={saveMember}
        inviteMember={() => sendInvite(false)}
        data={data}
        setData={setData}
        setCustomMessage={setCustomMessage}
        editUser={() => {
          setIsEdit(true);
          setOpenSaveMember(false);
          setOpenAddNewMember(true);
        }}
      />
      <RemoveFromShareListModal
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        handleDelete={() => deleteUser(userToRemove)}
        userToRemove={userToRemove?.fullName}
        type={userToRemove?.shared ? 'Resend' : 'Send'}
      />
      <SendInviteModal
        open={openSendModal}
        handleSend={() => sendInvite(false)}
        handleClose={() => setOpenSendModal(false)}
        type={data?.shared ? 'Resend' : 'Send'}
        user={data?.name}
      />
    </Dialog>
  );
};

const styles = {
  outerWrapper: {
    height: '100%',
  },
  wrapperBox: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 187px)',
    height: '86%',
  },
  paper: {
    borderRadius: '8px',
    width: '880px',
    maxWidth: '960px',
    overflowY: 'hidden',
    position: 'initial !important',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  heading: {
    color: 'rgba(0, 0, 0, 0.88)',
    fontFamily: 'InterBold',
    fontSize: '20px',
    lineHeight: '32px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 16px',
    height: '40px',
  },
  closeIconBox: {
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(142, 128, 113, 0.08)',
    },
  },
  closeIcon: {
    color: shark,
    fontSize: '30px',
    cursor: 'pointer',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '24px',
    padding: '16px 24px',
    zIndex: 1,
  },
  leftSide: {
    display: 'flex',
  },
  anyone: {
    fontFamily: 'InterRegular',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.88)',
    marginLeft: '5px',
  },
  addNew: {
    fontFamily: 'InterRegular',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    textDecorationLine: 'underline',
    color: lochinvar,
    alignSelf: 'flex-start',
    marginBottom: '20px',
    cursor: 'pointer',
  },
  red: {
    color: 'red',
    cursor: 'pointer',
  },
  username: {
    marginLeft: '20px',
    color: 'rgba(0, 0, 0, 0.88)',
    fontFamily: 'InterRegular',
    fontSize: '16px',
    lineHeight: '24px',
    wordBreak: 'break-word',
  },
  company: {
    marginLeft: '20px',
    color: 'rgba(0, 0, 0, 0.7)',
    fontFamily: 'InterRegular',
    fontSize: '14px',
    lineHeight: '20px',
    wordBreak: 'break-all',
  },
  email: {
    marginLeft: '20px',
    color: 'rgba(0, 0, 0, 0.7)',
    fontFamily: 'InterRegular',
    fontSize: '14px',
    lineHeight: '20px',
    wordBreak: 'break-word',
  },
  boxTitle: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: 'InterMedium',
    fontSize: '14px',
    lineHeight: '20px',
  },
  noEmail: {
    marginLeft: '20px',
    color: 'rgba(0, 0, 0, 0.45)',
    fontFamily: 'InterRegular',
    fontSize: '14px',
    lineHeight: '20px',
  },
  left: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mainBox: {
    // maxHeight: '550px',
    // overflow: 'auto',
    padding: '16px 32px 0 32px',
  },
  mainBoxWithoutInivited: {
    padding: 0,
  },
  relativeList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // position: 'relative',
    marginLeft: '10px',
  },
  absoluteList: {
    position: 'absolute',
    backgroundColor: white,
    // top: '35px',
    // right: '2px',
    padding: '8px 0',
    display: 'flex',
    flexDirection: 'column',
    width: '188px',
    zIndex: 2,
    boxShadow: '0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
  },
  edit: {
    color: 'rgba(0, 0, 0, 0.88)',
    fontFamily: 'InterRegular',
    fontSize: '16px',
    lineHeight: '24px',
    padding: '12px 16px 12px 16px',
    cursor: 'pointer',
    '&:hover': {
      filter: 'brightness(99%)',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  delete: {
    color: 'rgba(204, 0, 48, 1)',
    fontFamily: 'InterRegular',
    fontSize: '16px',
    lineHeight: '24px',
    padding: '12px 16px 12px 16px',
    cursor: 'pointer',
    '&:hover': {
      filter: 'brightness(99%)',
      backgroundColor: 'rgba(204, 0, 48, 0.04)',
    },
  },
};

import React, { useContext, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Avatar,
  Box,
  Input,
  InputAdornment,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SearchIcon from '@mui/icons-material/Search';
import SearchModal from './SearchModal';
import styles from '../../styles/dashboardHeader.module.css';
import { Notification } from './Notifications';
import { useEffect } from 'react';
import { store } from '../../Store';
import { getInitial } from '../../utils/utils';
import httpClient from '../../config/http.service';
import DynamicButton from '../new/Button';
import { Logo } from '../../assets';
import { showNotification } from './Toast';
import { ProfileSettingsModal } from '../../modals/ProfileSettingModal';
import { GiveFeedback } from '../../modals/GiveFeedback';
import DeleteModal from './DeleteModal';

const menuOptions = [
  { name: 'Account', type: 'profile' },
  { name: 'Give feedback', type: 'feedback' },
];

const notifications = [
  {
    user: 'Manuel Snr',
    type: 'modified',
    activity: 'Activity 1',
    stage: 'Plan & Discover Phase',
    plan: 'Workday and Manuel Snr Mutual Action Plan',
    planId: '123',
    createdAt: 1689595727,
    read: false,
  },
  {
    user: 'Pero Peric',
    type: 'viewed',
    company: 'Hyperether',
    activity: 'Activity 1',
    stage: 'Plan & Discover Phase',
    plan: 'Workday and Manuel Snr Mutual Action Plan',
    planId: '321',
    createdAt: 1689509327,
    read: false,
  },
  {
    user: 'Manuel Snr',
    type: 'dueDate',
    activity: 'Activity 1',
    stage: 'Plan & Discover Phase',
    plan: 'Workday and Manuel Snr Mutual Action Plan',
    planId: '321',
    dueDate: '24 hours',
    createdAt: 1689509327,
    read: false,
  },
  {
    user: 'Vukasin Mac',
    type: 'shared',
    company: 'Hyperether',
    activity: 'Activity 1',
    stage: 'Plan & Discover Phase',
    plan: 'Workday and Manuel Snr Mutual Action Plan',
    planId: '321',
    dueDate: '24 hours',
    createdAt: 1689595727,
    read: false,
  },
  {
    user: 'Manuel Snr',
    type: 'modified',
    activity: 'Activity 2',
    stage: 'Initial',
    plan: 'Workday and Manuel Snr Mutual Action Plan',
    planId: '123',
    createdAt: 1681039881,
    read: false,
  },
  {
    user: 'Manuel Snr',
    type: 'modified',
    activity: 'Activity 2',
    stage: 'Initial',
    plan: 'Workday and Manuel Snr Mutual Action Plan',
    planId: '123',
    createdAt: 1681039881,
    read: false,
  },
  {
    user: 'Manuel Snr',
    type: 'modified',
    activity: 'Activity 2',
    stage: 'Initial',
    plan: 'Workday and Manuel Snr Mutual Action Plan',
    planId: '123',
    createdAt: 1681039881,
    read: false,
  },
];

const DashboardHeader = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id,userId } = useParams();
  const { state } = useContext(store);
  const [user, setUser] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);
  const { title, handleSearch } = props;
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [openNotificationList, setOpenNotificationList] = useState(false);
  const [notificationsList, setNotificationsList] = useState(notifications);
  const [loading, setLoading] = useState(true);
  const [openProfileSettings, setOpenProfileSettings] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(
    state?.data?.userImage ? state.data.userImage : null
  );
  const [userExist, setUserExist] = useState(false);
  const [comment, setComment] = useState('');
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const componentRef = useRef();

  const { email: planSharing } = useParams();
  const [isSharingAndEdit, setIsSharingAndEdit] = useState(
    planSharing && planSharing !== '' ? true : false
  );

  const closeNotification = () => {
    setOpenNotificationList(false);
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        closeNotification();
      }
    };

    document.addEventListener('click', handleDocumentClick);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const handleNotificationButtonClick = () => {
    // Toggle the open state of the Notification component
    setOpenNotificationList((prevOpen) => !prevOpen);
  };

  const getNotifications = () => {
    let params = {}

    if(state?.data?.user?._id && !isSharingAndEdit){
      params = { loggedUser: state?.data?.user?._id }
    } else if(isSharingAndEdit){
      params.userToken = props.planSharing
    }
    
    httpClient
    .get(`api/notification/all`, { params })
    .then((res) => {
      setNotificationsList(res.data);
    })
    .catch((err) => {
      // showNotification('error', err?.response?.data?.message)
    });
  };

  useEffect(() => {
    getNotifications();
  }, [state?.data?.user, state?.notification, isSharingAndEdit]);

  const leaveComment = () => {
    if (comment !== '') {
      httpClient
        .post(`api/feedback/insert`, {
          feedback: comment,
        })
        .then(() => {
          showNotification(
            'success',
            'Feedback successfully sent!',
            'dark',
            'bottom-right'
          );
          setComment('');
          setOpenFeedbackModal(false);
        })
        .catch((err) =>
          showNotification('error', err?.response?.data?.message)
        );
    } else showNotification('error', 'You need to insert some text!');
  };

  const getUserPhoto = async (id) => {
    httpClient
      .get(`api/file/get/${id}`)
      .then((res) => {
        const imageData = new Uint8Array(res.data);
        const blob = new Blob([imageData], { type: 'image/jpeg' });
        const imageUrl = URL.createObjectURL(blob);
        setProfilePhoto(imageUrl);
      })
      .catch((err) => {
        // if (err?.response?.data?.code !== 401)
        //   showNotification('error', err?.response?.data?.message);
      });
  };

  useEffect(() => {
    const storedRememberMe = localStorage.getItem('rememberMe');
    setRememberMe(storedRememberMe === 'true');
    setUser(state?.data?.user);
    setLoading(false);
    if (state?.data?.user?._id) setUserExist(true);
    if (state?.data?.user.hasAvatar) getUserPhoto(state.data.user._id);
    else setProfilePhoto(null);
    // if (state?.data?.isAuthenticated) setUserExist(true);
  }, [state]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (item) => {
    setAnchorEl(null);
    // BUG 54 fixed with comment
    // navigate('/dashboard', { state: { name: item.type } });
  };
  const handleSearchModalClose = () => setShowSearchModal(false);

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();

    setAnchorEl(null);
    navigate('/login');
  };

  const handleLogin = () => {
    if(pathname.startsWith('/view-template') && id && userId){
      navigate(`/login?templateId=${id}`)
    } else {
      navigate('/login')
    }
  }

  const openPlan = (plan_id) => {
    navigate(`/plan/${plan_id}`);
    setOpenNotificationList(false);
  };

  const handleItemClick = (item) => {
    if (item.type === 'profile') setOpenProfileSettings(true);
    else setOpenFeedbackModal(true);
  };

  const handleHelp = () => {
    window.open('https://intercom.help/inquarter', "_blank")
  }

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Box className={styles.main}>
        <Toolbar className={styles.toolbar}>
          {props.profileSettings ? (
            <Link to="/dashboard" className={{ marginBottom: '10px' }}>
              <Typography variant="h6" component="div" className={styles.logo}>
                <img src={Logo} alt="Logo" className={styles.logoImg} />
              </Typography>
            </Link>
          ) : (
            <Typography className={styles.planName}>
              {title ? title : ''}
            </Typography>
          )}

          {!props.isSharing && (
            <Box className={styles.configrations}>
              {userExist && props.listOfPlans && (
                <SearchIcon
                  className={styles.mobile_search_icon}
                  onClick={() => setShowSearchModal(true)}
                />
              )}

              {userExist && props.listOfPlans && (
                <Input
                  id="search"
                  placeholder="Search plans"
                  classes={{
                    root: styles.search_input,
                    input: styles.custom_input,
                  }}
                  onChange={handleSearch}
                  startAdornment={
                    <InputAdornment position="start" sx={{ marginTop: '3px' }}>
                      <SearchIcon className={styles.input_adornment} />
                    </InputAdornment>
                  }
                  sx={{
                    backgroundColor: '#f6f5f4 !important',
                    '& .MuiInputBase-input': {
                      backgroundColor: '#f6f5f4 !important',
                    },
                  }}
                />
              )}
              <Box className={styles.header_box}>
                {userExist && !isSharingAndEdit && (
                  <Box className={styles.help_btn} onClick={handleHelp}>
                    <HelpOutlineIcon />
                  </Box>
                )}
                {(userExist || props.sharedUser) && (
                  <div ref={componentRef}>
                    <Notification
                      notifications={notificationsList}
                      openPlan={openPlan}
                      openNotificationList={openNotificationList}
                      setOpenNotificationList={setOpenNotificationList}
                      setNotifications={setNotificationsList}
                      handleNotificationButtonClick={
                        handleNotificationButtonClick
                      }
                      componentRef={componentRef}
                    />
                  </div>
                )}

                {userExist && !isSharingAndEdit ? (
                  <Box className={styles.profile_btn} onClick={handleClick}>
                    <Box className={styles.profile_btn_content}>
                      <Avatar
                        className={styles.profile_avatar}
                        sx={{ height: '24px', width: '24px' }}
                        src={profilePhoto}
                      >
                        {user
                          ? getInitial(user?.firstName + ' ' + user?.lastName)
                          : ''}
                      </Avatar>
                    </Box>
                  </Box>
                ) : props.sharedUser ? (
                  <Box className={styles.profile_btn}>
                    <Box className={styles.profile_btn_content}>
                      <Avatar
                        className={styles.profile_avatar}
                        sx={{ height: '24px', width: '24px' }}
                      >
                        {props.sharedUser
                          ? getInitial(props.sharedUser?.fullName)
                          : ''}
                      </Avatar>
                    </Box>
                  </Box>
                ) : null}
                {state?.data?.user?._id && props?.isSharing && (
                  <DynamicButton
                    type="outlined"
                    sx={{ borderRadius: '100px' }}
                    onClick={() => props?.setIsSharing(false)}
                  >
                    Close Preview
                  </DynamicButton>
                )}
              </Box>

              <Menu
                open={open}
                id="profile-button"
                anchorEl={anchorEl}
                onClose={handleClose}
                PaperProps={{
                  className: styles.menu,
                  sx: {
                    width: anchorEl && anchorEl.offsetWidth,
                  },
                }}
                MenuListProps={{
                  'aria-labelledby': 'profile-button',
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              >
                {menuOptions.map((item) => (
                  <MenuItem
                    key={item.name}
                    disableTouchRipple
                    className={styles.menu_item}
                    onClick={() => handleItemClick(item)}
                    style={{ padding: '8px 12px !important' }}
                  >
                    <span>{item.name}</span>
                  </MenuItem>
                ))}
                <MenuItem
                  disableTouchRipple
                  className={styles.logout_btn}
                  onClick={() => setOpenLogoutModal(true)}
                >
                  <span>Sign out</span>
                </MenuItem>
              </Menu>
            </Box>
          )}

          {props.template && (
            <DynamicButton
              type="outlined"
              sx={{ borderRadius: '100px' }}
              onClick={handleLogin}
            >
              Login
            </DynamicButton>
          )}
        </Toolbar>
      </Box>
      <SearchModal
        open={showSearchModal}
        handleClose={handleSearchModalClose}
      />

      <ProfileSettingsModal
        open={openProfileSettings}
        handleClose={() => setOpenProfileSettings(false)}
      />
      <GiveFeedback
        open={openFeedbackModal}
        handleClose={() => setOpenFeedbackModal(false)}
        comment={comment}
        setComment={setComment}
        leaveComment={leaveComment}
      />
      <DeleteModal
        heading="Sign out"
        submitBtn="Sign out"
        text={`Are you sure you want to sign out?`}
        open={openLogoutModal}
        handleClose={() => setOpenLogoutModal(false)}
        handleSubmit={handleLogout}
      />
    </>
  );
};

export default DashboardHeader;

import React from 'react';
import { Box, Dialog, Typography } from '@mui/material';
import { ReactComponent as HighlightOffOutlinedIcon } from '../assets/imgs/close.svg';
import DynamicButton from '../components/new/Button';
import { black, gallery, lightGray, shark, white } from '../constants/colors';
import { TextField } from '@material-ui/core';

export const GiveFeedback = ({
  open,
  handleClose,
  leaveComment,
  comment,
  setComment,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: styles.paper }}>
      <Typography variant="h5" sx={styles.heading}>
        Give feedback
        <HighlightOffOutlinedIcon
          style={styles.closeIcon}
          onClick={handleClose}
        />
      </Typography>
      <Typography variant="h5" sx={styles.subheading}>
        Leave comment
      </Typography>
      <Box style={styles.textfieldBox}>
        <TextField
          style={styles.textfield}
          multiline
          rows={5}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="We'd love to hear from you"
          InputProps={{ disableUnderline: true }}
        />
        <Box sx={styles.btns}>
          <DynamicButton type="contained" onClick={leaveComment}>
            Send message
          </DynamicButton>
          <DynamicButton
            type="outlined"
            style={{ marginRight: '20px' }}
            onClick={handleClose}
          >
            Cancel
          </DynamicButton>
        </Box>
      </Box>
    </Dialog>
  );
};

const styles = {
  paper: {
    borderRadius: '8px',
    padding: '24px 32px',
    width: '598px',
  },
  closeIcon: {
    color: shark,
    fontSize: '30px',
    padding: '5px',
    cursor: 'pointer',
  },
  heading: {
    color: 'rgba(0, 0, 0, 0.88)',
    fontFamily: 'InterBold',
    fontSize: '20px',
    lineHeight: '28px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subheading: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: 'InterMedium',
    fontSize: '14px',
    lineHeight: '20px',
    marginTop: '16px',
  },
  btns: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  title: {
    color: black,
    fontSize: '20px',
    lineHeight: '32px',
    fontFamily: 'InterBold',
  },
  subtitle: {
    fontFamily: 'InterRegular',
    fontSize: '14px',
    lineHeight: '20px',
    color: black,
  },
  textfield: {
    display: 'flex',
    width: '96%',
    background: white,
    border: `1px solid ${gallery}`,
    fontFamily: 'InterRegular',
    fontSize: '14px',
    lineHeight: '20px',
    color: lightGray,
    marginTop: '12px',
    padding: '10px',
    '.MuiInput-underline:before': {
      borderBottom: 'none',
    },
  },
};

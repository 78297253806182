import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import styles from '../../styles/common/appBreadCrumbs.module.css';

const AppBreadCrumbs = ({ breadcrumbs }) => {
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon className={styles.icon} fontSize="small" />}
      className={styles.breadcrumbs}
    >
      {breadcrumbs.map((item, index) =>
        item.url ? (
          <Link key={index} color="inherit" to={item.url}>
            {item.text}
          </Link>
        ) : (
          <Typography
            key={index}
            component={index === breadcrumbs.length - 1 ? 'p' : 'span'}
          >
            {item.text}
          </Typography>
        )
      )}
    </Breadcrumbs>
  );
};

export default AppBreadCrumbs;

// import { Box } from '@mui/material';
// import { TemplateIcon } from '../assets';
// import { black } from '../constants/colors';

export const templateDecorator = (template) => {
  return {
    ...template,
    // name: (
    //   <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
    //     <img
    //       src={TemplateIcon}
    //       alt="template-icon"
    //       width="56px"
    //       height="56px"
    //     />
    //     <Box sx={{ color: black, fontFamily: 'SoehneMedium' }}>
    //       {template.name}
    //     </Box>
    //   </Box>
    // ),
  };
};

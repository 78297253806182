// Color system
export const white = '#FFFFFF';
export const black = '#1F1F1F';
export const primary = '#009B77';
export const bgColor = '#F6F5F4';
export const text = '#4C4C4C';
export const lightGray = '#7A8081';
export const red = '#9D1600';
export const warning = 'rgba(204, 0, 48, 1)';
export const paleSky = '#667085';
export const scorpion = '#5E5E5E';
export const silver = '#C7C7C7';
export const porcelain = '#F6F9F9';
export const mirage = '#1A2635';
export const lochinvar = '#268183';
export const shark = '#292D32';
export const alabaster = '#FAFAFA';
export const mercury = '#E8E8E8';
export const wildSand = '#F5F5F5';
export const foam = '#ECFEF7';
export const funGreen = '#035D3A';
export const chardon = '#FFF2F0';
export const swamp = '#002129';
export const gallery = '#EEEEEE';
export const bronze = '#604804';
export const earlyDawn = '##FFF9E9';

export const primaryBlack = 'rgba(0, 0, 0, 0.7)';
export const black6 = 'rgba(0, 0, 0, 0.6)';
export const black45 = 'rgba(0, 0, 0, 0.45)';
export const black88 = 'rgba(0, 0, 0, 0.88)';
export const black20 = 'rgba(0, 0, 0, 0.2)';
export const blackRow = 'rgba(31, 31, 31, 1)';

import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import { black } from '../../constants/colors';

const styles = {
  paper: { boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.06)', borderRadius: '8px' },
  menuItem: {
    backgroundColor: 'white !important',
    color: 'rgba(0, 0, 0, 0.7',
    fontSize: '14px',
    lineHeight: '20px',
    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04) !important' },
  },
};

const AppMenu = ({ id, options, open, anchorEl, handleClose }) => {
  return (
    <Menu
      id={id}
      elevation={0}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      PaperProps={{ sx: styles.paper }}
      style={{
        dispaly: 'flex',
        flexDirection: 'column',
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      {options.map((item, index) => (
        <MenuItem
          key={index}
          onClick={item.handleClick}
          sx={{
            ...styles.menuItem,
            color: item.color ? item.color : black,
          }}
        >
          {item.name}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default AppMenu;

import { Box, Button, Dialog, Typography } from '@mui/material';
import React from 'react';
import { black, mercury, warning } from '../../constants/colors';
import DynamicButton from '../new/Button';

const DeleteModal = ({
  heading,
  text,
  submitBtn,
  open,
  handleClose,
  handleSubmit,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: styles.paper }}>
      <Typography variant="h5" sx={styles.heading}>
        {heading}
      </Typography>
      <Typography variant="h5" sx={styles.body}>
        {text}
      </Typography>
      <Box sx={styles.btns}>
        <DynamicButton type="outlined" sx={styles.cancel} onClick={handleClose}>
          Cancel
        </DynamicButton>
        <DynamicButton
          type="remove"
          style={styles.delete}
          onClick={handleSubmit}
        >
          {submitBtn}
        </DynamicButton>
      </Box>
    </Dialog>
  );
};

export default DeleteModal;

const styles = {
  paper: {
    borderRadius: '8px',
    padding: '16px 24px',
    width: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: black,
  },
  heading: {
    fontFamily: 'InterBold',
    fontSize: '20px',
    lineHeight: '32px',
    marginBottom: '12px',
    width: '100%',
    textAlign: 'left',
  },
  body: {
    fontSize: '14px',
    lineHeight: '24px',
    textAlign: 'left',
    marginBottom: '10px',
    width: '85%',
    marginLeft: 0,
    marginRight: 'auto',
  },
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px',
    width: '100%',
  },
  cancel: {
    padding: '8px 16px',
    border: ` 1px solid rgba(0, 0, 0, 0.6)`,
    height: '40px',
    fontFamily: 'InterRegular',
    color: 'rgba(0, 0, 0, 0.7)',
    '.MuiButton-outlined:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2) !important',
      color: 'rgba(0, 0, 0, 0.88) !important',
    },
  },
  delete: {
    color: 'white',
    padding: '8px 16px',
    border: '1px solid transparent',
    boxShadow: 'none',
    background: warning,
    letterSpacing: '0',
    '&:hover': { background: warning, letterSpacing: '0' },
  },
};

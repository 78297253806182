export const planObj = {
  name: 'Customer & Vendor Mutual Action Plan test',
  // customerLogo: '',
  customerCompany: '',
  customerChampion: '',
  vendor: '',
  vendorRepresentative: '',
  closeDate: new Date(new Date().setDate(new Date().getDate() + 10)),
  value: { amount: 10000, currency: 'dollar' },
  introduction: '',
  stages: [
    // {
    //   name: 'PLAN AND DISCOVER',
    //   open: false,
    //   activities: [
    //     {
    //       name: '',
    //       description: '',
    //       duration: '',
    //       tag: '',
    //       status: '',
    //       notes: '',
    //       customerTeam: [{ name: '', email: '', title: '' }],
    //       vendorTeam: [{ name: '', email: '', title: '' }],
    //       targetDate: '',
    //       collapseSubActivity: false,
    //       completionDate: '',
    //       subActivities: [
    //         {
    //           name: 'For Manuel : Meeting between decision makers',
    //           status: 'to_be_booked',
    //           completionDate: '',
    //           targetDate: '',
    //           notes: '',
    //         },
    //         {
    //           name: 'For Manuel : Meeting between decision makers',
    //           status: 'completed',
    //           completionDate: new Date(),
    //           targetDate: '',
    //           notes: '',
    //         },
    //       ],
    //     },
    //   ],
    //   actionsEl: null,
    // },
    // {
    //   name: 'ENGAGE',
    //   open: false,
    //   activities: [
    //     {
    //       name: '',
    //       description: '',
    //       duration: '',
    //       tag: '',
    //       status: '',
    //       notes: '',
    //       customerTeam: [{ name: '', email: '', title: '' }],
    //       vendorTeam: [{ name: '', email: '', title: '' }],
    //       targetDate: '',
    //       collapseSubActivity: false,
    //       completionDate: '',
    //       subActivities: [
    //         {
    //           name: '',
    //           status: 'to_be_booked',
    //           completionDate: '',
    //           targetDate: '',
    //           notes: '',
    //         },
    //       ],
    //     },
    //   ],
    //   actionsEl: null,
    // },
    // {
    //   name: 'DECIDE',
    //   open: false,
    //   activities: [
    //     {
    //       name: '',
    //       description: '',
    //       duration: '',
    //       tag: '',
    //       status: '',
    //       notes: '',
    //       customerTeam: [{ name: '', email: '', title: '' }],
    //       vendorTeam: [{ name: '', email: '', title: '' }],
    //       targetDate: '',
    //       collapseSubActivity: false,
    //       completionDate: '',
    //       subActivities: [
    //         {
    //           name: '',
    //           status: 'to_be_booked',
    //           completionDate: '',
    //           targetDate: '',
    //           notes: '',
    //         },
    //       ],
    //     },
    //   ],
    //   actionsEl: null,
    // },
    // {
    //   name: 'CONTRACT',
    //   open: false,
    //   activities: [
    //     {
    //       name: '',
    //       description: '',
    //       duration: '',
    //       tag: '',
    //       status: '',
    //       notes: '',
    //       customerTeam: [{ name: '', email: '', title: '' }],
    //       vendorTeam: [{ name: '', email: '', title: '' }],
    //       targetDate: '',
    //       collapseSubActivity: false,
    //       completionDate: '',
    //       subActivities: [
    //         {
    //           name: '',
    //           status: 'to_be_booked',
    //           completionDate: '',
    //           targetDate: '',
    //           notes: '',
    //         },
    //       ],
    //     },
    //   ],
    //   actionsEl: null,
    // },
  ],
};

export const planObjTemplate = {
  name: 'MAP title',
  // customerLogo: '',
  customerCompany: '',
  customerChampion: '',
  vendor: '',
  vendorRepresentative: '',
  closeDate: new Date(new Date().setDate(new Date().getDate() + 10)),
  value: { amount: 10000, currency: 'dollar' },

  indtroduction: '',
  stages: [
    {
      name: 'PLAN AND DISCOVER',
      open: false,
      activities: [
        {
          name: '',
          description: '',
          duration: '',
          tag: '',
          status: '',
          notes: '',
          customerTeam: [{ name: '', email: '', title: '' }],
          vendorTeam: [{ name: '', email: '', title: '' }],
          targetDate: '',
          collapseSubActivity: false,
          completionDate: '',
          subActivities: [
            {
              name: 'For Manuel : Meeting between decision makers',
              status: 'to_be_booked',
              completionDate: '',
              targetDate: '',
              notes: '',
            },
            {
              name: 'For Manuel : Meeting between decision makers',
              status: 'completed',
              completionDate: new Date(),
              targetDate: '',
              notes: '',
            },
          ],
        },
      ],
      actionsEl: null,
    },
    {
      name: 'ENGAGE',
      open: false,
      activities: [
        {
          name: '',
          description: '',
          duration: '',
          tag: '',
          status: '',
          notes: '',
          customerTeam: [{ name: '', email: '', title: '' }],
          vendorTeam: [{ name: '', email: '', title: '' }],
          targetDate: '',
          collapseSubActivity: false,
          completionDate: '',
          subActivities: [
            {
              name: '',
              status: 'to_be_booked',
              completionDate: '',
              targetDate: '',
              notes: '',
            },
          ],
        },
      ],
      actionsEl: null,
    },
    {
      name: 'DECIDE',
      open: false,
      activities: [
        {
          name: '',
          description: '',
          duration: '',
          tag: '',
          status: '',
          notes: '',
          customerTeam: [{ name: '', email: '', title: '' }],
          vendorTeam: [{ name: '', email: '', title: '' }],
          targetDate: '',
          collapseSubActivity: false,
          completionDate: '',
          subActivities: [
            {
              name: '',
              status: 'to_be_booked',
              completionDate: '',
              targetDate: '',
              notes: '',
            },
          ],
        },
      ],
      actionsEl: null,
    },
    {
      name: 'CONTRACT',
      open: false,
      activities: [
        {
          name: '',
          description: '',
          duration: '',
          tag: '',
          status: '',
          notes: '',
          customerTeam: [{ name: '', email: '', title: '' }],
          vendorTeam: [{ name: '', email: '', title: '' }],
          targetDate: '',
          collapseSubActivity: false,
          completionDate: '',
          subActivities: [
            {
              name: '',
              status: 'to_be_booked',
              completionDate: '',
              targetDate: '',
              notes: '',
            },
          ],
        },
      ],
      actionsEl: null,
    },
  ],
};

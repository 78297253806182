import React, { useContext } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  Container,
} from '@mui/material';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import InputWithLabel from '../components/common/InputWithLabel';
import styles from '../styles/login/login.module.css';
import { store } from '../Store';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { showNotification } from '../components/common/Toast';
import DynamicButton from '../components/new/Button';

export const Login = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(store);
  const [user, setUser] = useState({ email: '', password: '' });
  const [rememberMe, setRememberMe] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const storedToken = localStorage.getItem('token');

    if (storedUser && storedToken) {
      dispatch({
        type: 'SET_DATA',
        payload: {
          isAuthenticated: true,
          user: JSON.parse(storedUser),
          accessToken: storedToken,
          userImage: '',
        },
      });
      navigate('/dashboard');
    } else if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const handleLogin = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}api/user/login`, {
        email: user.email,
        password: user.password,
      })
      .then((res) => {
        dispatch({
          type: 'SET_DATA',
          payload: {
            isAuthenticated: true,
            user: res.data?.user,
            accessToken: res.data?.token.accessToken,
            refreshToken: res.data?.token.refreshToken,
            userImage: '',
          },
        });
        if (rememberMe) {
          localStorage.setItem('user', JSON.stringify(res.data?.user));
          localStorage.setItem('token', res.data?.token.accessToken);
          localStorage.setItem('refreshToken', res.data?.token.refreshToken);
        } else {
          sessionStorage.setItem('user', JSON.stringify(res.data?.user));
          sessionStorage.setItem('token', res.data?.token.accessToken);
          sessionStorage.setItem('refreshToken', res.data?.token.refreshToken);
          localStorage.removeItem('user');
        }
        localStorage.setItem('rememberMe', rememberMe);
        showNotification('success', 'Successfully logged in!');
        const templateId = searchParams.get('templateId')
        if(templateId){
          console.log(templateId,'t')
          navigate(`/view-template/${templateId}`);
        } else {
          navigate('/dashboard');
        }
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          showNotification('error', err.response.data.message);
        }
      });
  };

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      axios
        .post(`${process.env.REACT_APP_API_URL}api/user/login`, {
          email: user.email,
          password: user.password,
        })
        .then((res) => {
          dispatch({
            type: 'SET_DATA',
            payload: {
              isAuthenticated: true,
              user: res.data?.user,
              accessToken: res.data?.token.accessToken,
              refreshToken: res.data?.token.refreshToken,
              userImage: '',
            },
          });
          if (rememberMe) {
            localStorage.setItem('user', JSON.stringify(res.data?.user));
            localStorage.setItem('token', res.data?.token.accessToken);
            localStorage.setItem('refreshToken', res.data?.token.refreshToken);
          } else {
            sessionStorage.setItem('user', JSON.stringify(res.data?.user));
            sessionStorage.setItem('token', res.data?.token.accessToken);
            sessionStorage.setItem(
              'refreshToken',
              res.data?.token.refreshToken
            );
            localStorage.removeItem('user');
          }
          localStorage.setItem('rememberMe', rememberMe);
          showNotification('success', 'Successfully logged in!');
          navigate('/dashboard');
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            showNotification('error', err.response.data.message);
          }
        });
    }
  };

  useEffect(() => {
    const existingMetaTag = document.querySelector('meta[name="description"]');

    const newMetaTag = document.createElement('meta');
    newMetaTag.name = 'description';
    newMetaTag.content = 'Login';

    if (existingMetaTag) {
      existingMetaTag.parentNode.replaceChild(newMetaTag, existingMetaTag);
    } else {
      document.head.appendChild(newMetaTag);
    }
  }, []);

  return (
    <Container maxWidth="xl">
      <Box className={styles.container}>
        <Box className={styles.main_container}>
          <Box className={styles.login_box}>
            <Typography variant="h4" align="center" className={styles.heading}>
              Log in
            </Typography>
            <Typography variant="subtitle1" align="center">
              Please enter your login credentials
            </Typography>

            <form className={styles.form}>
              <InputWithLabel
                type="email"
                label="Email"
                name="email"
                value={user.email}
                onKeyDown={handleEnter}
                placeholder="Email Address"
                handleChange={(e) =>
                  setUser({ ...user, email: e.target.value })
                }
              />
              <InputWithLabel
                type="password"
                label="Password"
                name="password"
                value={user.password}
                onKeyDown={handleEnter}
                placeholder="Enter Password"
                // passwordResetLink={true}
                handleChange={(e) =>
                  setUser({ ...user, password: e.target.value })
                }
              />

              <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <FormControlLabel
                  sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                  control={
                    <Checkbox
                      size="small"
                      checkedIcon={<CheckBoxOutlinedIcon />}
                      sx={{
                        color: 'black',
                        '&.Mui-checked': {
                          color: 'black',
                        },
                      }}
                      checked={rememberMe}
                      onChange={() => setRememberMe(!rememberMe)}
                    />
                  }
                  label="Remember Me"
                />
                  <Link style={localStyles.resetPassword} to={'/reset-password'}>Reset password</Link>
                </Box>


              <DynamicButton
                type="contained"
                style={localStyles.login}
                fullWidth
                onClick={handleLogin}
              >
                Log in
              </DynamicButton>

              {/* <Box className={styles.create_account}>
                <Link to="/signup" className={styles.create_account_btn}>
                  Create account
                </Link>
              </Box> */}
            </form>
          </Box>
          <div style={localStyles.termsAndConditions}><a style={localStyles.aTag} href='https://flat-consonant-d1b.notion.site/Terms-of-Use-48df4d9866694268bb025ece48c5d0b5' target='blank'>Terms of use</a> <a style={localStyles.aTagRight} href='https://flat-consonant-d1b.notion.site/Privacy-Policy-6c31f15032d946e8830a7dd0ca6ab736?pvs=4' target='blank'>Privacy policy</a> </div>
        </Box>
      </Box>
    </Container>
  );
};

const localStyles = {
  login: {
    padding: '10px 24px ',
    marginTop: '25px ',
    textTransform: 'none ',
    fontSize: '16px ',
    fontFamily: 'SoehneMedium ',
    width: '100%',
  },
  termsAndConditions: {
    position: 'fixed',
    bottom: '20px',
    textAlign: 'center',
    fontFamily: 'SoehneRegular',
  },
  aTag: {
    color: 'rgb(0, 155, 119)'
  },
  aTagRight: {
    color: 'rgb(0, 155, 119)',
    marginLeft: "25px"
  },
  resetPassword: {
    textDecoration: 'none', 
    color: 'black',
    fontSize: '14px',
  }
};

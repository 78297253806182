import React, { useContext, useEffect, useState } from 'react';
import { isBoolean } from 'lodash';
import { Grid } from '@mui/material';
import withDashboardHeader from '../hoc/withDashboardHeader';
import MutualActionPlan from '../components/Plan/MutualActionPlan';
import DeleteModal from '../components/common/DeleteModal';
import TeamMembersModal from '../components/Plan/TeamMembersModal';
import { reorderOnDrag } from '../utils/reorderOnDrag';
import {
  planDurationOptions,
  durationOptions as timeOptions,
} from '../utils/constants';
import { planObj } from '../mock/plan';
import { useNavigate, useParams } from 'react-router-dom';
import { EditStageTitleModal } from '../components/Plans/EditStageTitleModal';
import { PLAN_FIELDS } from '../utils/enums';
import { CreateNewStageModal } from '../components/Plans/CreateNewStageModal';
import { DeletePlanModal } from '../components/Plans/DeletePlanModal';
import httpClient from '../config/http.service';
import { store } from '../Store';
import { editShareList, getAccessList, randomColor } from '../utils/utils';
import { showNotification } from '../components/common/Toast';
import DashboardSidebar from '../components/common/DashboardSidebar';
import DashboardHeader from '../components/common/DashboardHeader';
import { CreatePlanStep1 } from '../modals/CreatePlanStep1';
import { white } from '../constants/colors';
import { TemplateDetails } from './TemplateDetails';
import { MenuNavNew } from './MenuNavNew';
import { CreateNewTemplate } from '../components/Plans/CreateNewTemplate';
import { ShareTemplateModal } from '../components/Plans/ShareTemplateModal';
import { useOnlineStatus } from '../utils/hooks/useOnlineStatus';
// import CircularProgress from '@mui/material/CircularProgress';

const ViewTemplate = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(store);
  const isConnected = useOnlineStatus();
  const [defCurrency, setDefCurrency] = useState('');
  const [plan, setPlan] = useState(null);
  const [initialTemplate, setInitialTemplate] = useState(null);
  const [selectedStage, setSelectedStage] = useState('');
  const [durationOptions, setDurationOptions] = useState(planDurationOptions);
  const [expandPlanDetails, setExpandPlanDetails] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteActivityModalOpen, setIsDeleteActivityModalOpen] =
    useState(false);
  const [teamMembersModal, setTeamMembersModal] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState({
    stageIndex: '',
    activityIndex: '',
  });
  const [selectedTeam, setSelectedTeam] = useState('');

  const [closeModal, setCloseModal] = useState(false);
  const [stageTitle, setStageTitle] = useState('');
  const [activeTab, setActiveTab] = useState('map');
  const [openCreateNewStageModal, setOpenCreateNewStageModal] = useState(false);
  const [openedStage, setOpenedStage] = useState({ index: 0, name: '' });
  const [deletePlanModal, setDeletePlanModal] = useState(false);
  const [showEditPlan, setShowEditPlan] = useState(false);
  const [showSharePlanModal, setShowSharePlanModal] = useState(false);
  const {
    id: templateId,
    email: planSharing,
    copylink: copyLink,
    userId: redirectedUser,
  } = useParams();
  const [customerImage, setCustomerImage] = useState(null);
  const [customerImageDetails, setCustomerImageDetails] = useState(null);
  const [updateMembers, setUpdateMembers] = useState(true);
  const [company, setCompany] = useState(null);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [owner, setOwner] = useState(null);
  const [openEditTemplateDetails, setOpenEditTemplateDetails] = useState(false);
  const [update, setUpdate] = useState(false);

  const AUTOEDIT_INTERVAL = 2000;
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  let debounceTimeout;
  const [isSharing, setIsSharing] = useState(
    (copyLink && copyLink !== '') || (redirectedUser && redirectedUser !== '')
      ? true
      : false
  );
  const [isSharingAndEdit, setIsSharingAndEdit] = useState(
    planSharing && planSharing !== '' ? true : false
  );

  const [planData, setPlanData] = useState({
    value: { currency: defCurrency, amount: 0 },
  });
  const [shared, setShared] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  useEffect(() => {
    if (state?.data?.user?.defaultCurrency) {
      setDefCurrency(state.data.user.defaultCurrency);
      const planWithNewCurrency = {
        ...planData,
        value: { currency: state.data.user.defaultCurrency },
      };
      setPlanData(planWithNewCurrency);
    }
  }, [state?.data?.user?.defaultCurrency]);

  const getTemplate = async (templateId) => {
    if (templateId) {
      httpClient
        .get(`api/template/get`, { params: { _id: templateId } })
        .then(async (res) => {
          httpClient
            .get(`api/company/get`, {
              params: { _id: res.data.vendor },
            })
            .then((response) => {
              const obj = res.data;
              delete obj._id;
              obj.hasAvatar = false;
              obj.name = res.data.name;
              obj.vendor = response.data.name;
              obj.shareList = res.data.shareList;
              obj.userId = res.data.userId;
              obj.vendorRepresentative =
                res.data.vendorRepresentative === state?.data?.user?._id
                  ? state?.data?.user?.firstName +
                    ' ' +
                    state?.data?.user?.lastName
                  : '';
              obj.stages?.map((el) => (el.open = false));
              obj.access = getAccessList(res.data) || '-';

              setOwner(res.data.userId);
              setPlan(obj);
            })
            .catch((err) => {
              // showNotification('error', err?.response?.data?.message)
            });
        })
        .catch((err) => {
          // showNotification('error', err?.response?.data?.message)
        });
    }
  };

  const handleLoginAndRedirect = () => {
    navigate(`/login?templateId=${templateId}`);
  };

  useEffect(() => {
    if (templateId) getTemplate(templateId);
  }, [templateId, state?.data, update]);

  const getCompany = async (companyId) => {
    if (companyId) {
      httpClient
        .get(`api/company/get`, { params: { _id: companyId } })
        .then(async (res) => {
          setCompany(res.data);
          setPlan({
            ...planObj,
            // name: `Customer & ${res.data.name} Mutual Action Plan`,
            vendor: res.data.name,
            vendorRepresentative:
              res.data.vendorRepresentative === state?.data?.user?._id
                ? state?.data?.user?.firstName +
                  ' ' +
                  state?.data?.user?.lastName
                : '',
          });
        })
        .catch((err) => {
          // showNotification('error', err?.response?.data?.message)
        });
    }
  };

  useEffect(() => {
    if (state?.data?.user?.company) {
      getCompany(state.data.user.company);
    }
  }, [state?.data?.user]);

  const handleStageTitle = (title) => {
    setStageTitle(title);
  };
  const handleCloseStage = () => {
    setCloseModal(false);
  };

  const handleChange = ({ target }) => {
    const planData = { ...plan };
    const { name, value } = target;
    const splitName = name.split('.');

    if (splitName.length > 1 && splitName[0] === 'value') {
      planData[splitName[0]][splitName[1]] = value;
    } else {
      planData[name] = value;
    }
    if (name === PLAN_FIELDS.customerCompany) {
      planData.name =
        (value.length > 0 ? value : `Customer`) +
        ' &' +
        planData.name.split('&')[1];
    }

    if (name === PLAN_FIELDS.vendor) {
      planData.name =
        planData.name.split('&')[0] +
        ' & ' +
        (value.length > 0 ? value : `Vendor`) +
        ' ' +
        'Mutual Action Plan';
    }
    setUnsavedChanges(true);
    setPlan(planData);
  };

  const handleActivityFieldsChange = (
    { target },
    stageIndex,
    activityIndex,
    teamMemberIndex,
    teamField = 'name',
    email,
    jobTitle
  ) => {
    const { name, value } = target;
    const planData = { ...plan };
    const activity = planData.stages[stageIndex].activities[activityIndex];
    const previousValue = activity[name];
    if (teamMemberIndex !== undefined) {
      if (email && teamField === 'name') {
        const capitalizedStr = value?.charAt(0).toUpperCase() + value.slice(1);
        activity[name][teamMemberIndex][teamField] = capitalizedStr;
        activity[name][teamMemberIndex]['email'] = email;
        activity[name][teamMemberIndex]['title'] = jobTitle;
        setUpdateMembers(false);
      } else {
        setUpdateMembers(false);
        activity[name][teamMemberIndex][teamField] = value;
      }
    } else activity[name] = value;
    if (name === PLAN_FIELDS.status && value === PLAN_FIELDS.completed)
      activity.completionDate = new Date().toISOString();
    else if (name === PLAN_FIELDS.status && value !== PLAN_FIELDS.completed)
      activity.completionDate = '';

    if (name === PLAN_FIELDS.duration && value === PLAN_FIELDS.custom) {
      setSelectedActivity({ stageIndex, activityIndex });
      activity.duration = previousValue;
    }
    setUnsavedChanges(true);
    setPlan(planData);
    clearTimeout(debounceTimeout);
  };

  const updatePlan = async (planObj) => {
    if (templateId) {
      planObj.vendorColor = planObj.vendorColor || randomColor();
      planObj.vendor = state?.data?.user?.company;
      planObj._id = templateId;
      planObj.userId = planObj.userId?._id
        ? planObj.userId._id
        : planObj.userId;
      delete planObj.defaultTemplate;
      delete planObj.stakeholders;
      delete planObj.archived;
      delete planObj.closeDate;
      delete planObj.introduction;
      delete planObj.hasAvatar;
      delete planObj.vendor;
      delete planObj.vendorRepresentative;
      delete planObj.value;
      delete planObj.customerChampion;
      delete planObj.customerCompany;
      delete planObj.access;
      httpClient
        .patch('api/template/update', planObj)
        .then((response) => {
          const obj = response.data;
          obj.vendor = company?.name;
          obj.vendorRepresentative =
            response.data.vendorRepresentative === state?.data?.user?._id
              ? state?.data?.user?.firstName + ' ' + state?.data?.user?.lastName
              : '';

          const differentProperties = compareObjects(plan, obj, false);

          if (differentProperties.stages) {
            const keys = Object.keys(differentProperties.stages);
            if (keys.length) {
              keys.forEach((key) => {
                const stage = differentProperties.stages[key];
                obj.stages[key].open = plan.stages[key].open;

                if (stage.activities?.length) {
                  stage.activities.forEach((act, i) => {
                    obj.stages[key].activities[i].collapseSubActivity =
                      plan.stages[key].activities[i].collapseSubActivity;
                  });
                }
              });
            }
          }
          setUnsavedChanges(false);
          setInitialTemplate(obj);
          // showNotification('success', 'Successfully edited template!');
          setShowEditPlan(false);
        })
        .catch((err) => {
          // showNotification('error', err?.response?.data?.message)
        });
    }
  };

  const getInitialTemplate = async (templateId) => {
    if (templateId) {
      httpClient
        .get(`api/template/get`, { params: { _id: templateId } })
        .then(async (res) => {
          httpClient
            .get(`api/company/get`, {
              params: { _id: res.data.vendor },
            })
            .then((response) => {
              const obj = res.data;
              delete obj._id;
              obj.hasAvatar = false;
              obj.name = res.data.name;
              obj.vendor = response.data.name;
              obj.shareList = [];
              obj.vendorRepresentative =
                res.data.vendorRepresentative === state?.data?.user?._id
                  ? state?.data?.user?.firstName +
                    ' ' +
                    state?.data?.user?.lastName
                  : '';

              setInitialTemplate(obj);
              setIsPageLoaded(true);
            })
            .catch((err) => {
              // showNotification('error', err?.response?.data?.message)
            });
        })
        .catch((err) => {
          // showNotification('error', err?.response?.data?.message)
        });
    }
  };

  useEffect(() => {
    if (templateId) getInitialTemplate(templateId);
  }, [templateId]);

  const compareObjects = (obj1, obj2, exclude = true) => {
    const differences = {};

    if (!obj1 || !obj2) {
      // Either obj1 or obj2 is null or undefined, return empty differences
      return differences;
    }

    // Iterate over the properties of obj1
    for (let key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        // Skip properties to be excluded from comparison
        if (
          key === 'defaultTemplate' ||
          key === 'stakeholders' ||
          key === 'archived' ||
          key === 'closeDate' ||
          key === 'introduction' ||
          key === 'hasAvatar' ||
          key === 'vendor' ||
          key === 'vendorRepresentative' ||
          key === 'value' ||
          key === 'updatedAt' ||
          key === '_id' ||
          key === 'access'
        ) {
          continue;
        }

        if (exclude && (key === 'open' || key === 'collapseSubActivity')) {
          continue;
        }

        // Check if the property exists in obj2
        if (obj2.hasOwnProperty(key)) {
          // Recursively compare nested objects
          if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
            const nestedDifferences = compareObjects(obj1[key], obj2[key]);
            if (
              nestedDifferences &&
              Object.keys(nestedDifferences).length > 0
            ) {
              differences[key] = nestedDifferences;
            }
          } else {
            // Compare values
            if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
              differences[key] = obj2[key];
            }
          }
        } else {
          // Property exists in obj1 but not in obj2
          differences[key] = obj2[key];
        }
      }
    }

    // Check for properties in obj2 that are not in obj1
    for (let key in obj2) {
      if (exclude && (key === 'open' || key === 'collapseSubActivity')) {
        continue;
      }
      if (
        obj2.hasOwnProperty(key) &&
        !obj1.hasOwnProperty(key) &&
        key !== 'defaultTemplate' &&
        key !== 'stakeholders' &&
        key !== 'archived' &&
        key !== 'closeDate' &&
        key !== 'introduction' &&
        key !== 'hasAvatar' &&
        key !== 'vendor' &&
        key !== 'vendorRepresentative' &&
        key !== 'value' &&
        key !== 'updatedAt' &&
        key !== '_id' &&
        key !== 'access'
      ) {
        differences[key] = obj2[key];
      }
    }

    return differences;
  };
  useEffect(() => {
    if (!isPageLoaded) return;
    if (!updateMembers) return;
    if (showEditPlan) return;
    if (isSharing) return;

    const timeout = setTimeout(() => {
      if (unsavedChanges) {
        console.log('UNSAVED CHANGES => SAVING ...');
        if (redirectedUser) {
          if (
            plan.userId === redirectedUser ||
            plan.userId._id === redirectedUser
          ) {
            updatePlan(plan);
            let shareList = [];
            if (state?.users?.shareList?.length > 0) {
              shareList = editShareList(state.users.shareList, plan);
            } else {
              shareList = plan.shareList;
            }
            dispatch({
              type: 'SET_USERS',
              payload: {
                shareList,
              },
            });
          }
        } else if (
          plan.userId === state?.data?.user?._id ||
          plan.userId._id === state?.data?.user?._id
        ) {
          updatePlan(plan);
          let shareList = [];
          if (state?.users?.shareList?.length > 0) {
            shareList = editShareList(state.users.shareList, plan);
          } else {
            shareList = plan.shareList;
          }
          dispatch({
            type: 'SET_USERS',
            payload: {
              shareList,
            },
          });
        }
      }
    }, AUTOEDIT_INTERVAL);
    return () => clearTimeout(timeout);
  }, [plan, isPageLoaded]);

  const handleSubActivityChange = (
    { target },
    stageIndex,
    activityIndex,
    subActivityIndex
  ) => {
    const { name, value } = target;
    const planData = plan;

    const subActivity =
      planData.stages[stageIndex].activities[activityIndex].subActivities[
        subActivityIndex
      ];

    if (name === PLAN_FIELDS.status && value === PLAN_FIELDS.completed)
      subActivity.completionDate = new Date().toISOString();
    else if (name === PLAN_FIELDS.status && value !== PLAN_FIELDS.completed)
      subActivity.completionDate = '';

    subActivity[name] = value;
    setUnsavedChanges(true);
    setPlan({ ...planData });
  };

  const handleExpand = (index) => {
    const allStages = plan.stages;
    allStages[index].open = !allStages[index].open;
    setPlan({ ...plan, stages: allStages });
  };

  const handleStageActionsOpen = (event, index) => {
    const allStages = [...plan.stages];
    allStages[index].actionsEl = event.currentTarget;
    setPlan({ ...plan, stages: allStages });
    setOpenedStage({ index: index, name: allStages[index].name });
  };

  const handleStageActionsClose = (newStages) => {
    const allStages = newStages ? [...newStages] : [...plan.stages];
    const index = allStages.findIndex((s) => s.actionsEl !== null);
    if (index < 0) return;
    allStages[index].actionsEl = null;
    setPlan({ ...plan, stages: allStages });
  };

  const handleAddNewActivity = (index) => {
    const allStages = [...plan.stages];
    allStages[index].activities.push({
      name: '',
      description: '',
      duration: '',
      tag: '',
      status: '',
      notes: '',
      customerTeam: [],
      vendorTeam: [],
      targetDate: '',
      subActivities: [],
    });
    setUnsavedChanges(true);
    setPlan({ ...plan, stages: allStages });
  };

  const handleRemoveActivity = () => {
    const { activityIndex, stageIndex } = selectedActivity;
    const allStages = [...plan.stages];
    allStages[stageIndex].activities.splice(activityIndex, 1);
    setUnsavedChanges(true);
    setPlan({ ...plan, stages: allStages });
    handleDeleteActivityModalClose();
  };

  const handleDuplicateStage = (index) => {
    if (plan?.stages?.length >= 8) {
      showNotification(
        'error',
        'You have created the maximum number of stages!'
      );
    } else {
      const allStages = [...plan.stages];
      const newStage = allStages[index];
      newStage.actionsEl = null;
      allStages.splice(index, 0, newStage);
      setUnsavedChanges(true);
      setPlan({ ...plan, stages: allStages });
      handleStageActionsClose(allStages);
    }
  };

  const handleDeleteStage = () => {
    const allStages = [...plan.stages];
    allStages.splice(selectedStage, 1);
    setUnsavedChanges(true);
    setPlan({ ...plan, stages: allStages });
    handleStageActionsClose(allStages);
    handleDeleteStageModalClose(true);
  };

  const handleAddTeamMember = (stageIndex, activityIndex, teamName) => {
    const allStages = [...plan.stages];
    if (allStages[stageIndex].activities[activityIndex][teamName].length >= 1) {
      handleTeamMembersModalOpen(stageIndex, activityIndex, teamName);
    }
    allStages[stageIndex].activities[activityIndex][teamName].push({
      name: '',
      email: '',
      title: '',
    });
    setUnsavedChanges(true);
    setPlan({ ...plan, stages: allStages });
  };

  const handleAddNewSubActivity = (stageIndex, activityIndex) => {
    const allStages = [...plan.stages];
    const subActivity =
      allStages[stageIndex].activities[activityIndex].collapseSubActivity;

    if (!subActivity)
      allStages[stageIndex].activities[
        activityIndex
      ].collapseSubActivity = true;

    allStages[stageIndex].activities[activityIndex].subActivities.push({
      name: '',
      status: '',
      targetDate: '',
      notes: '',
    });
    setUnsavedChanges(true);
    setPlan({ ...plan, stages: allStages });
  };

  const handleRemoveSubActivity = (
    stageIndex,
    activityIndex,
    subActivityIndex
  ) => {
    const allStages = [...plan.stages];
    allStages[stageIndex].activities[activityIndex].subActivities.splice(
      subActivityIndex,
      1
    );
    setUnsavedChanges(true);
    setPlan({ ...plan, stages: allStages });
  };

  const handleExpandSubActivities = (stageIndex, activityIndex) => {
    const allStages = [...plan.stages];
    allStages[stageIndex].activities[activityIndex].collapseSubActivity =
      !allStages[stageIndex].activities[activityIndex].collapseSubActivity;
    setPlan({ ...plan, stages: allStages });
  };

  const handleDeleteStageModalOpen = (stageIndex) => {
    setSelectedStage(stageIndex);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteStageModalClose = (isDeleted) => {
    setSelectedStage('');
    setIsDeleteModalOpen(false);
    if (!isBoolean(isDeleted)) {
      const allStages = [...plan.stages];
      const index = allStages.findIndex((s) => s.actionsEl !== null);
      if (index < 0) return;
      allStages[index].actionsEl = null;
      setPlan({ ...plan, stages: allStages });
    }
  };

  const handleDeleteActivityModalOpen = (activityIndex, stageIndex) => {
    setSelectedActivity({ stageIndex, activityIndex });
    setIsDeleteActivityModalOpen(true);
  };

  const handleEditStageTitleModal = (stageIndex, stage) => {
    if (stageIndex && stage) {
      setStageTitle(stage.name);
      setOpenedStage({ index: stageIndex, name: stage });
      setCloseModal(true);
    } else {
      setCloseModal(true);
    }
  };

  const handleDeleteActivityModalClose = () => {
    setSelectedActivity({ stageIndex: '', activityIndex: '' });
    setIsDeleteActivityModalOpen(false);
  };

  const handleRemoveTeamMember = (
    stageIndex,
    activityIndex,
    memberIndex,
    teamName
  ) => {
    const allStages = [...plan.stages];
    allStages[stageIndex].activities[activityIndex][teamName].splice(
      memberIndex,
      1
    );
    setUnsavedChanges(true);
    setPlan({ ...plan, stages: allStages });
    setUpdateMembers(true);
  };

  const handleTeamMembersModalOpen = (stageIndex, activityIndex, teamName) => {
    setTeamMembersModal(true);
    setSelectedActivity({ stageIndex, activityIndex });
    setSelectedTeam(teamName);
  };
  const handleTeamMembersModalClose = () => {
    setTeamMembersModal(false);
    setSelectedActivity({ stageIndex: '', activityIndex: '' });
    setSelectedTeam('');
    setUpdateMembers(true);
  };

  const handlePlanDetailsExpansion = () =>
    setExpandPlanDetails((prev) => !prev);

  const onDragEnd = (result) => {
    if (isSharing) return;

    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const stages = [...plan.stages];
    const items = reorderOnDrag(
      stages,
      result.source.index,
      result.destination.index
    );
    setUnsavedChanges(true);
    setPlan({ ...plan, stages: items });
  };

  const onActivityDragEnd = (result, stageIndex) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (isSharing) return;

    const allStages = [...plan.stages];
    const items = reorderOnDrag(
      allStages[stageIndex].activities,
      result.source.index,
      result.destination.index
    );

    allStages[stageIndex].activities = [...items];
    setUnsavedChanges(true);
    setPlan({ ...plan, stages: allStages });
  };

  const onSubActivityDragEnd = (result, stageIndex, activityIndex) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const allStages = plan.stages;
    const items = reorderOnDrag(
      allStages[stageIndex].activities[activityIndex].subActivities,
      result.source.index,
      result.destination.index
    );

    allStages[stageIndex].activities[activityIndex].subActivities = [...items];
    setUnsavedChanges(true);
    setPlan({ ...plan, stages: allStages });
  };

  const editTitle = () => {
    const newStage = plan;
    newStage?.stages?.map((el, index) => {
      if (el.name === openedStage.name && index === openedStage.index) {
        el.name = stageTitle;
        httpClient
          .patch('api/plan/edit-title', {
            id: plan?._id ? plan._id : '',
            stageIndex: index,
            newName: stageTitle,
          })
          .then(() => {
            showNotification('success', 'Successfully edited stage title!');
          })
          .catch((err) => {
            // showNotification('error', err?.response?.data?.message)
          });
      }
    });
    setUnsavedChanges(true);
    setPlan(newStage);
  };

  const createStage = async () => {
    const newStage = plan;
    newStage.stages.push({
      name: stageTitle,
      open: false,
      activities: [
        {
          name: '',
          description: '',
          duration: '',
          tag: '',
          status: '',
          notes: '',
          customerTeam: [],
          vendorTeam: [],
          targetDate: '',
          collapseSubActivity: false,
          completionDate: '',
          subActivities: [],
        },
      ],
      actionsEl: null,
    });
    setUnsavedChanges(true);
    setPlan(newStage);
    await updatePlan(newStage);
  };

  const handleCreateNewStage = () => {
    if (plan?.stages?.length >= 8) {
      showNotification(
        'error',
        'You have created the maximum number of stages!'
      );
    } else {
      setOpenCreateNewStageModal(true);
    }
  };

  const handleDeletePlan = () => {
    httpClient
      .delete('api/template/delete', {
        data: {
          _id: templateId,
        },
      })
      .then(() => {
        navigate('/dashboard');
        showNotification('success', 'Template successfully deleted!');
        setDeletePlanModal(false);
      })
      .catch((err) => {
        // showNotification('error', err?.response?.data?.message)
      });
  };
  const createPlan = () => {
    const stages = plan.stages;
    const obj = { ...planData };
    obj.stages = [];
    obj.stages = stages;
    obj.stages.map((el) => {
      delete el.emoji;
    });
    obj.shareList = [];
    obj.vendor = state?.data?.user?.company;
    obj.vendorRepresentative = state?.data?.user.fullName;
    obj.vendorColor = randomColor();
    obj.templateId = templateId;
    let newFile = new FormData();
    if (customerImageDetails) {
      const newName = new File([customerImageDetails], 'customerimage', {
        type: customerImage.type,
      });
      newFile.append('image', newName);
      newFile.append('plan', JSON.stringify(obj));
    } else {
      newFile.append('image', {});
      newFile.append('plan', JSON.stringify(obj));
    }

    httpClient
      .post('api/plan/new', newFile)
      .then((res) => {
        showNotification(
          'success',
          'Successfully created plan!',
          'dark',
          'bottom-right'
        );
        navigate(`/plan/${res.data._id}`);
        setShowEditPlan(false);
      })
      .catch((err) => showNotification('error', err?.response?.data?.message));
  };

  const handleSaveMembers = () => {
    setUpdateMembers(true);
    updatePlan(plan);
    let shareList = [];
    if (state?.users?.shareList?.length > 0) {
      shareList = editShareList(state.users.shareList, plan);
    } else {
      shareList = plan.shareList;
    }
    dispatch({
      type: 'SET_USERS',
      payload: {
        shareList,
      },
    });
    handleTeamMembersModalClose();
  };

  const handleCancelTeamModal = () => {
    // setPlan(modalMembers);
    setUpdateMembers(true);
    setTeamMembersModal(false);
  };

  const handleCloseSharePlanModal = () => {
    setShowSharePlanModal(false);
  };

  const editPlan = () => {
    updatePlan(plan);
  };

  const cancelEdit = () => {
    setShowEditPlan(false);
    setPlan(initialTemplate);
  };

  const addUserToTeam = (stageIndex, activityIndex, team, activity, list) => {
    setSelectedActivity({ stageIndex, activityIndex });
    setSelectedTeam(team);
    const allStages = [...plan.stages];

    const filteredList = allStages[stageIndex].activities[activityIndex][
      team
    ].filter((item) => item.name !== '');

    list.map((item) => {
      filteredList.push({
        name: item.fullName,
        title: item.jobTitle,
        email: '',
      });
    });
    allStages[stageIndex].activities[activityIndex][team] = filteredList;
    setUnsavedChanges(true);
    setPlan({ ...plan, stages: allStages });
  };

  const handleRemoveUserFromShareList = (
    stageIndex,
    activityIndex,
    team,
    activity,
    toRemove
  ) => {
    const allStages = [...plan.stages];
    let filteredArray = allStages[stageIndex].activities[activityIndex][
      team
    ].filter((el) => el.name !== toRemove.name);
    allStages[stageIndex].activities[activityIndex][team] = filteredArray;
    setUnsavedChanges(true);
    setPlan({ ...plan, stages: allStages });
  };

  const handleEditTemplate = () => {
    setOpenEditTemplateDetails(true);
  };

  // if (!isPageLoaded) return <CircularProgress /> 

  const handlePostSale = (stageIndex, stage) => {
    const allStages = [...plan.stages];
    plan.stages[stageIndex].postSale = plan.stages[stageIndex].postSale
      ? !plan.stages[stageIndex].postSale
      : true;

    setUnsavedChanges(true)
    setPlan({ ...plan, stages: allStages });
  };

  return (
    <Grid sx={styles.main}>
      {state?.data?.user?._id && (
        <Grid item xs={1} sx={{ width: '96px', maxWidth: '96px !important' }}>
          <DashboardSidebar active="templates" />
        </Grid>
      )}
      <Grid
        item
        xs={11}
        sx={{ padding: '0 30px', borderLeft: '1px solid #DBDFDF' }}
      >
        <DashboardHeader
          listOfPlans={false}
          isSharing={isSharing}
          template={redirectedUser ? true : false}
          title={
            plan &&
            plan.name &&
            plan.name !== 'Customer & Vendor Mutual Action Plan test'
              ? plan.name
              : ''
            // plan?.name ? plan.name : 'DocuSign and Goolge Mutual Action Plan'
          }
        />
        {!isSharing && !isSharingAndEdit && (
          <MenuNavNew
            user={state?.data?.user}
            handleEditTemplate={handleEditTemplate}
            isConnected={isConnected}
            plan={plan}
            handleEdit={() => setShowEditPlan(true)}
            // handleArchive={() => setShowArchivePlanModal(true)}
            handleDelete={() => setDeletePlanModal(true)}
            createPlanFromTemplate={() => setShowEditPlan(true)}
            template={true}
            shared={isSharing}
            openShareModal={() => setShowSharePlanModal(true)}
            duplicateTemplate={() => {
              setShowDuplicateModal(true);
            }}
          />
        )}
        <TemplateDetails
          template={plan}
          owner={owner}
          user={state?.data?.user}
          isPageLoaded={isPageLoaded}
        />
        {activeTab === PLAN_FIELDS.map && (
          <MutualActionPlan
            template={true}
            data={plan}
            setData={setPlan}
            durationOptions={durationOptions}
            expandPlanDetails={expandPlanDetails}
            handleChange={handleChange}
            handleExpand={handleExpand}
            onDragEnd={onDragEnd}
            onActivityDragEnd={onActivityDragEnd}
            onSubActivityDragEnd={onSubActivityDragEnd}
            handleAddNewActivity={handleAddNewActivity}
            handleRemoveActivity={handleDeleteActivityModalOpen}
            handleEditStageTitle={handleEditStageTitleModal}
            handleDeleteStage={handleDeleteStageModalOpen}
            handleDuplicateStage={handleDuplicateStage}
            handleAddTeamMember={handleAddTeamMember}
            handleStageActionsOpen={handleStageActionsOpen}
            handleStageActionsClose={handleStageActionsClose}
            handleActivityFieldsChange={handleActivityFieldsChange}
            handleSubActivityChange={handleSubActivityChange}
            handleAddNewSubActivity={handleAddNewSubActivity}
            handleRemoveSubActivity={handleRemoveSubActivity}
            handleExpandSubActivities={handleExpandSubActivities}
            handleRemoveTeamMember={handleRemoveTeamMember}
            handleTeamMembersModalOpen={handleTeamMembersModalOpen}
            handlePlanDetailsExpansion={handlePlanDetailsExpansion}
            handleCreateNewStage={handleCreateNewStage}
            customerImage={customerImage}
            setCustomerImage={setCustomerImage}
            // userExist={userExist}
            isSharing={isSharing || redirectedUser}
            addUserToTeam={addUserToTeam}
            handleServiceRemove={handleRemoveUserFromShareList}
            isPageLoaded={isPageLoaded}
            isView={!state.data.user._id}
            handleLoginAndRedirect={handleLoginAndRedirect}
            handlePostSale={handlePostSale}
          />
        )}
        <CreatePlanStep1
          data={planData}
          setData={setPlanData}
          open={showEditPlan}
          handleClose={() => setShowEditPlan(false)}
          customerPhoto={customerImage}
          setCustomerPhoto={setCustomerImage}
          customerPhotoDetails={customerImageDetails}
          setCustomerPhotoDetails={setCustomerImageDetails}
          createFromTemplate={false}
          edit={false}
          editPlan={editPlan}
          cancelEdit={cancelEdit}
          createPlan={createPlan}
          currency={defCurrency}
        />
        <DeleteModal
          heading="Delete Stage"
          submitBtn="Delete"
          text={`Are you sure you want to delete ${plan?.stages[selectedStage]?.name}?`}
          open={isDeleteModalOpen}
          handleClose={handleDeleteStageModalClose}
          handleSubmit={handleDeleteStage}
        />
        <DeleteModal
          heading="Delete Activity"
          submitBtn="Delete"
          text="Are you sure you want to remove this activity and its sub activities?"
          open={isDeleteActivityModalOpen}
          handleClose={handleDeleteActivityModalClose}
          handleSubmit={handleRemoveActivity}
        />
        {openEditTemplateDetails && (
          <CreateNewTemplate
            open={openEditTemplateDetails}
            handleClose={() => setOpenEditTemplateDetails(false)}
            template={plan}
            company={company}
            edit
            templateId={templateId}
            update={update}
            setUpdate={setUpdate}
          />
        )}

        {plan && plan.stages && (
          <TeamMembersModal
            plan={plan}
            open={teamMembersModal}
            selectedTeam={selectedTeam}
            stageIndex={selectedActivity.stageIndex}
            activityIndex={selectedActivity.activityIndex}
            handleAddTeamMember={handleAddTeamMember}
            handleRemoveTeamMember={handleRemoveTeamMember}
            handleChange={handleActivityFieldsChange}
            handleClose={handleTeamMembersModalClose}
            handleSubmit={handleSaveMembers}
            handleCancel={handleCancelTeamModal}
          />
        )}
        {closeModal && (
          <EditStageTitleModal
            stageTitle={openedStage ? openedStage.name : stageTitle}
            handleChange={handleStageTitle}
            handleClose={handleCloseStage}
            editStageTitle={editTitle}
          />
        )}
        {openCreateNewStageModal && (
          <CreateNewStageModal
            stageTitle={stageTitle}
            handleChange={handleStageTitle}
            handleClose={() => setOpenCreateNewStageModal(false)}
            createStage={createStage}
            handleEnter={() => {
              createStage();
              setOpenCreateNewStageModal(false);
            }}
          />
        )}
        {deletePlanModal && (
          <DeletePlanModal
            plan={true}
            open={deletePlanModal}
            handleClose={() => setDeletePlanModal(false)}
            handleDelete={handleDeletePlan}
          />
        )}
        <ShareTemplateModal
          template={plan}
          setTemplate={setPlan}
          open={showSharePlanModal}
          handleClose={handleCloseSharePlanModal}
          type="template"
          templateId={templateId}
          shared={shared}
          setShared={setShared}
        />
        {showDuplicateModal && (
          <CreateNewTemplate
            open={showDuplicateModal}
            handleClose={() => setShowDuplicateModal(false)}
            duplicate={true}
            template={plan}
            company={company}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default withDashboardHeader(ViewTemplate);

const styles = {
  main: {
    backgroundColor: `#F6F5F4 !important`,
    minHeight: '100vh !important',
    display: 'flex',
    // maxWidth: '100%',
    width: '100%',
  },
  container: { mt: '40px' },
  emptyBar: {
    width: '100%',
    borderRadius: '8px',
    gap: '8px',
    backgroundColor: 'rgba(242, 240, 238, 1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '88px',
    fontFamily: 'InterMedium',
    fontSize: '14px',
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  noStage: {
    width: '100%',
    height: 'auto',
    padding: '48px 0px 48px 0px',
    borderRadius: '8px',
    gap: '24px',
    backgroundColor: white,
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2)',
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noStageIcon: {
    width: '64px',
    height: '64px',
    borderRadius: '10px',
    backgroundColor: 'rgba(242, 240, 238, 1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noStageText: {},
  value: {
    fontFamily: 'InterSemiBold',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.88)',
    textAlign: 'center',
  },
  label: {
    fontFamily: 'InterRegular',
    fontSize: '14px',
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.7)',
    textAlign: 'center',
  },
};

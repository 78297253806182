import React, { useContext, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
  tableContainerClasses,
  tableClasses,
  Skeleton,
  Divider,
  Box,
  Typography,
  LinearProgress,
  Chip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  formatAmount,
  formatNumberWithCurrency,
  randomColor,
} from '../../utils/utils';
import AvatarImage from '../../assets/imgs/avatar.png';
import {
  text,
  foam,
  funGreen,
  black,
  white,
  black6,
  blackRow,
  black88,
} from '../../constants/colors';
import { store } from '../../Store';
import { ReactComponent as VisibilityIcon } from '../../assets/imgs/visibility.svg';
import { ReactComponent as VisibilityIconTooltip } from '../../assets/imgs/visibility_on.svg';
import { ReactComponent as NonVisibilityIcon } from '../../assets/imgs/visibility_off.svg';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DynamicButton from '../new/Button';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as NoStageIcon } from '../../assets/imgs/noStage.svg';
import { ReactComponent as TableImageShared } from '../../assets/imgs/sharedTemplateIcon.svg';
import { ReactComponent as TableImageNotShared } from '../../assets/imgs/notSharedTemplateIcon.svg';
import { ReactComponent as ArrowDown } from '../../assets/imgs/arrow_drop_down.svg';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.head}`]: { fontSize: '16px', color: text },
  [`&.${tableCellClasses.body}`]: { fontSize: '16px', border: '0px' },
});

const StyledTableContainer = styled(TableContainer)({
  [`&.${tableContainerClasses.root}`]: { borderRadius: '8px 8px 8px 8px' },
});

const StyledTable = styled(Table)({
  [`&.${tableClasses.root}`]: { background: 'white' },
});

const AppTable = ({
  headers,
  data = [],
  loading,
  actions,
  active,
  handleSortChange,
  sortDirectionMaps,
  setDirectionMaps,
  template = false,
  handleCreate,
}) => {
  const navigate = useNavigate();
  const { state } = useContext(store);
  const [colors, setColors] = useState(null);
  useEffect(() => {
    if (!colors) {
      const arrayOfColors = [];
      for (let i = 0; i < data?.length; i++) {
        arrayOfColors.push(randomColor());
      }
      setColors(arrayOfColors);
    }
  }, [data]);

  return (
    <>
      <StyledTableContainer
        sx={{ boxShadow: '0px 1px 3px 0px #00000033 !important' }}
      >
        <StyledTable>
          <TableHead>
            <TableRow>
              {!loading &&
                data?.length > 0 &&
                headers?.map((header, index) => (
                  <StyledTableCell
                    key={header.key + index}
                    align={header.align ? header.align : 'left'}
                    sx={{
                      minWidth: header.minWidth ? header.minWidth : 'auto',
                      fontSize: '14px !important',
                      color: `${black6} !important`,
                      width: header.key === 'updatedAt' ? '105px' : 'auto',
                      fontFamily: 'InterMedium',
                    }}
                  >
                    {active === 'maps' &&
                    (header.key === 'value' ||
                      header.key === 'closeDate' ||
                      header.key === 'title') ? (
                      <Typography
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          color: 'rgba(0, 0, 0, 0.6)!important',
                        }}
                        onClick={() => {
                          setDirectionMaps({
                            name:
                              sortDirectionMaps.key === 'asc'
                                ? 'Desceding'
                                : 'Asceding',
                            key:
                              sortDirectionMaps.key === 'asc' ? 'desc' : 'asc',
                          });
                          handleSortChange({
                            key: header.key,
                            name: header.title,
                          });
                        }}
                      >
                        {header.title}
                        <ArrowDown />
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '14px !important',
                          color: `${black6} !important`,
                          width: header.key === 'updatedAt' ? '105px' : 'auto',
                          fontFamily: 'InterMedium',
                        }}
                        onClick={() => {
                          if (header.key === 'title' || header.key === 'name') {
                            setDirectionMaps({
                              name:
                                sortDirectionMaps.key === 'asc'
                                  ? 'Desceding'
                                  : 'Asceding',
                              key:
                                sortDirectionMaps.key === 'asc'
                                  ? 'desc'
                                  : 'asc',
                            });
                            handleSortChange({
                              key: header.key,
                              name: header.title,
                            });
                          } else {
                          }
                        }}
                      >
                        {header.title}
                        {(header.key === 'title' || header.key === 'name') && (
                          <ArrowDown style={{ cursor: 'pointer' }} />
                        )}
                      </Typography>
                    )}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? (
              data?.map((single, index) => {
                return (
                  <TableRow
                    key={single._id}
                    sx={{
                      borderBottom:
                        active === 'maps' && single.pinned
                          ? `2px solid #c9c9b5`
                          : 'none',
                      backgroundColor:
                        active === 'maps' && single.pinned ? `#ffffe3` : white,
                    }}
                  >
                    {headers?.map((header, ind) => (
                      <StyledTableCell
                        key={header.key + ind}
                        align={header.align ? header.align : 'left'}
                        sx={{
                          minWidth: header.minWidth ? header.minWidth : 'auto',
                          width: header.key === 'avatar' ? '60px' : 'auto',
                        }}
                      >
                        {header.key === 'actions' ? (
                          <div>{actions(single)}</div>
                        ) : header.key === 'avatar' ? (
                          template ? (
                            single?.shareList?.length === 1 &&
                            single?.shareList[0].email === '' ? (
                              <TableImageNotShared
                                style={{
                                  marginRight: '0',
                                  minWidth: '40px',
                                }}
                              />
                            ) : (
                              <TableImageShared
                                style={{
                                  marginRight: '0',
                                  minWidth: '40px',
                                }}
                              />
                            )
                          ) : (
                            <img
                              src={single?.logo ? single.logo : AvatarImage}
                              style={styles.logo}
                              alt="Logo"
                            />
                          )
                        ) : header.key === 'title' || header.key === 'name' ? (
                          <div
                            style={styles.title}
                            onClick={() =>
                              navigate(
                                `/${!template ? 'plan' : 'view-template'}/${
                                  single?._id
                                }`,
                                {
                                  state: { data: single },
                                }
                              )
                            }
                          >
                            <Typography
                              sx={{
                                lineHeight: '24px',
                                fontSize: '16px',
                                color: blackRow,
                                fontFamily: 'InterRegular',
                              }}
                            >
                              {single[header.key]}
                            </Typography>
                          </div>
                        ) : header.key === 'stakeholders' ? (
                          <Tooltip
                            sx={{
                              fontSize: '12px',
                              width: '100%',
                            }}
                            title={
                              single['stakeholders']?.invited?.length > 0 && (
                                <Box
                                  sx={{
                                    backgroundColor: black,
                                    opacity: '0.88',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginBottom: '8px',
                                    }}
                                  >
                                    <VisibilityIconTooltip />
                                    <Divider
                                      sx={{
                                        flexGrow: 1,
                                        backgroundColor:
                                          'rgba(255, 255, 255, 0.6)',
                                        marginLeft: '5px',
                                      }}
                                    />
                                  </Box>
                                  {single['viewed']?.view.map((user) => (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontFamily: 'InterRegular',
                                          fontSize: '14px',
                                          textAlign: 'center',
                                          color: white,
                                        }}
                                      >
                                        {user}
                                      </Typography>
                                    </Box>
                                  ))}
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginBottom: '8px',
                                      marginTop: '8px',
                                    }}
                                  >
                                    <NonVisibilityIcon />
                                    <Divider
                                      sx={{
                                        flexGrow: 1,
                                        backgroundColor:
                                          'rgba(255, 255, 255, 0.6)',
                                        marginLeft: '5px',
                                      }}
                                    />
                                  </Box>

                                  {single['viewed']?.nonView.map((user) => (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontFamily: 'InterRegular',
                                          fontSize: '14px',
                                          textAlign: 'center',
                                          color: white,
                                        }}
                                      >
                                        {user}
                                      </Typography>
                                    </Box>
                                  ))}
                                </Box>
                              )
                            }
                            arrow
                          >
                            <Box sx={{ ...styles.preview, width: '80px' }}>
                              <VisibilityIcon color={black} />
                              <Typography sx={{ opacity: '0.88' }}>
                                {single?.stakeholders?.viewed?.length > 0
                                  ? single?.stakeholders?.viewed?.length ??
                                    single.stakeholders?.props?.children[0]
                                      ?.props?.children[0]?.length
                                  : ''}{' '}
                                {single?.stakeholders?.viewed?.length > 0
                                  ? 'of'
                                  : '-'}{' '}
                                {single?.stakeholders?.viewed?.length > 0
                                  ? single?.stakeholders?.invited?.length ??
                                    single.stakeholders?.props?.children[0]
                                      ?.props?.children[0]?.length
                                  : ''}
                              </Typography>
                            </Box>
                          </Tooltip>
                        ) : header.key === 'progress' ? (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {single.progress === 100 ? (
                              <Box
                                sx={{
                                  ...styles.preview,
                                  backgroundColor: 'rgba(240, 248, 255, 1)',
                                }}
                              >
                                <FiberManualRecordIcon
                                  sx={{
                                    width: '8px',
                                    height: '8px',
                                    color: 'rgba(0, 87, 153, 1)',
                                  }}
                                />
                                <Typography
                                  style={{
                                    fontFamily: 'InterMedium',
                                    color: black88,
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                  }}
                                >
                                  Completed
                                </Typography>
                              </Box>
                            ) : (
                              <>
                                <Box sx={{ width: '100%', mr: 1 }}>
                                  <LinearProgress
                                    variant="determinate"
                                    value={single.progress}
                                    sx={{
                                      borderRadius: 5,
                                      backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                      height: '8px',
                                    }}
                                  />
                                </Box>
                              </>
                            )}
                          </Box>
                        ) : header.key === 'updatedAt' ? (
                          <Typography
                            sx={{
                              fontFamily: 'InterRegular',
                              color: blackRow,
                              fontSize: '16px',
                              lineHeight: '24px',
                              minWidth: '50px',
                            }}
                          >
                            {single[header.key] || '-'}
                          </Typography>
                        ) : header.key === 'archiveDate' ? (
                          <Typography
                            sx={{
                              fontFamily: 'InterRegular',
                              color: blackRow,
                              fontSize: '16px',
                              lineHeight: '24px',
                            }}
                          >
                            {single[header.key] || '-'}
                          </Typography>
                        ) : header.key === 'archiveReason' ? (
                          <Box
                            sx={{
                              ...styles.preview,
                              backgroundColor:
                                single[header.key] === 'Draft'
                                  ? 'rgba(0, 0, 0, 0.05)'
                                  : single[header.key] === 'Overdue'
                                  ? 'rgba(255, 235, 239, 1)'
                                  : single[header.key] === 'Overdue'
                                  ? 'rgba(240, 255, 241, 1)'
                                  : 'rgba(240, 248, 255, 1)',
                            }}
                          >
                            {single[header.key] && (
                              <FiberManualRecordIcon
                                sx={{
                                  width: '8px',
                                  height: '8px',
                                  color:
                                    single[header.key] === 'Opportunity Stalled'
                                      ? 'rgba(255, 125, 0, 1)'
                                      : single[header.key] === 'Qualified out'
                                      ? 'rgba(204, 0, 48, 1)'
                                      : single[header.key] === 'Opportunity Won'
                                      ? 'rgba(0, 155, 119, 1)'
                                      : 'rgba(0,0,0, 0.6)',
                                }}
                              />
                            )}
                            <Typography>
                              {single[header.key] === 'Opportunity Stalled'
                                ? 'Stalled'
                                : single[header.key] === 'Opportunity Lost'
                                ? 'Lost'
                                : single[header.key] === 'Opportunity Won'
                                ? 'Won'
                                : single[header.key] || '-'}
                            </Typography>
                          </Box>
                        ) : header.key === 'owner' ? (
                          <Typography
                            sx={{
                              fontFamily: 'InterRegular',
                              color: blackRow,
                              fontSize: '16px',
                              lineHeight: '24px',
                            }}
                          >
                            {single[header.key] || '-'}
                          </Typography>
                        ) : header.key === 'status' ? (
                          <Box
                            sx={{
                              ...styles.preview,
                              backgroundColor:
                                single[header.key] === 'Draft'
                                  ? 'rgba(0, 0, 0, 0.05)'
                                  : single[header.key] === 'Overdue'
                                  ? 'rgba(255, 235, 239, 1)'
                                  : single[header.key] === 'On track'
                                  ? 'rgba(240, 255, 241, 1)'
                                  : 'rgba(240, 248, 255, 1)',
                            }}
                          >
                            <FiberManualRecordIcon
                              sx={{
                                width: '8px',
                                height: '8px',
                                color:
                                  single[header.key] === 'Draft'
                                    ? 'rgba(0,0,0, 0.6)'
                                    : single[header.key] === 'Overdue'
                                    ? 'rgba(204, 0, 48, 1)'
                                    : single[header.key] === 'On track'
                                    ? 'rgba(0, 155, 119, 1)'
                                    : 'rgba(0, 87, 153, 1)',
                              }}
                            />
                            <Typography
                              style={{
                                fontFamily: 'InterMedium',
                                color: black88,
                                fontSize: '14px',
                                lineHeight: '20px',
                              }}
                            >
                              {single[header.key] || '-'}
                            </Typography>
                          </Box>
                        ) : header.key === 'description' ? (
                          <Typography
                            sx={{
                              lineHeight: '24px',
                              fontSize: '16px',
                            }}
                          >
                            {single[header.key]}
                          </Typography>
                        ) : header.key === 'closeDate' ? (
                          <Typography
                            sx={{
                              color: blackRow,
                              fontFamily: 'InterRegular',
                              lineHeight: '24px',
                              fontSize: '16px',
                              minWidth: '110px',
                            }}
                          >
                            {single[header.key]}
                          </Typography>
                        ) : header.key === 'access' ? (
                          <Box
                            sx={{
                              lineHeight: '24px',
                              fontSize: '16px',
                            }}
                          >
                            {single[header.key] === 'Only you' ? (
                              <span>{single[header.key]}</span>
                            ) : (
                              <Tooltip
                                sx={{
                                  fontSize: '12px',
                                  width: '100%',
                                }}
                                title={
                                  <Box
                                    sx={{
                                      backgroundColor: black,
                                      opacity: '0.88',
                                    }}
                                  >
                                    {single['shareList'] &&
                                      single['shareList'].length > 0 &&
                                      [
                                        single.userId,
                                        ...single['shareList'],
                                      ].map((user, index) => (
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontFamily: 'InterRegular',
                                              fontSize: '14px',
                                              textAlign: 'center',
                                              color: white,
                                            }}
                                          >
                                            {user.fullName}
                                          </Typography>
                                          {user._id && (
                                            <Typography
                                              sx={{
                                                marginLeft: '15px',
                                                padding: '0 8px',
                                                backgroundColor:
                                                  'rgba(61, 61, 61, 1)',
                                                borderRadius: '24px',
                                                fontFamily: 'InterMedium',
                                                fontSize: '10px',
                                                textAlign: 'center',
                                              }}
                                            >
                                              Owner
                                            </Typography>
                                          )}
                                        </Box>
                                      ))}
                                  </Box>
                                }
                                arrow
                              >
                                <span>{single[header.key]}</span>
                              </Tooltip>
                            )}
                          </Box>
                        ) : (
                          <Typography
                            sx={{
                              fontFamily: 'InterRegular',
                              color: blackRow,
                              fontSize: '16px',
                              lineHeight: '24px',
                              width: '100px',
                            }}
                          >
                            {header.key === 'value'
                              ? single[header.key]?.amount &&
                                (single[header.key]?.amount > 0 ||
                                  single[header.key]?.amount.length > 0)
                                ? formatNumberWithCurrency(
                                    single[header.key]?.amount,
                                    single[header.key]?.currency
                                  )
                                : `-`
                              : single[header.key]}
                          </Typography>
                        )}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                );
              })
            ) : data?.length > 0 ? (
              <>
                {[...Array(data?.length).keys()].map((item) => {
                  return (
                    <TableRow key={item}>
                      {headers?.map((item1, index) => (
                        <StyledTableCell key={index}>
                          <Skeleton animation="wave" />
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minHeight: '356px',
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
      {!loading && data?.length < 1 && (
        <Box sx={styles.noStage}>
          <Box sx={styles.noStageIcon}>
            <NoStageIcon />
          </Box>
          <Box sx={styles.noStageText}>
            <Typography sx={styles.value}>
              You don't have any{' '}
              {template
                ? 'templates'
                : active === 'maps'
                ? 'plans'
                : 'archived plans'}
            </Typography>
            <Typography sx={styles.label}>
              Supercharge your sales process with ready to use{' '}
              {template ? 'templates' : 'plans'}.
            </Typography>
          </Box>
          <DynamicButton type="contained" onClick={handleCreate}>
            Create {template ? 'template' : 'plan'}
          </DynamicButton>
        </Box>
      )}
    </>
  );
};

export default AppTable;

const styles = {
  noStage: {
    width: '100%',
    height: 'auto',
    padding: '80px 0px',
    borderRadius: '8px',
    gap: '24px',
    backgroundColor: white,
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noStageIcon: {
    width: '64px',
    height: '64px',
    borderRadius: '10px',
    backgroundColor: 'rgba(242, 240, 238, 1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noStageText: {},
  value: {
    fontFamily: 'InterSemiBold',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.88)',
    textAlign: 'center',
  },
  label: {
    fontFamily: 'InterRegular',
    fontSize: '14px',
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.7)',
    textAlign: 'center',
  },
  more: {
    fontSize: '14px',
    lineHeight: '24px',
    marginLeft: '10px',
    display: 'flex',
    textAlign: 'center',
  },
  avatar: {
    marginRight: '-5px',
    width: '24px',
    height: '24px',
    fontSize: '12px',
    borderRadius: '6px',
  },
  pagination_row: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: blackRow,
    fontFamily: 'InterRegular',
    wordBreak: "break-word"
  },
  logo: {
    marginRight: '0',
    width: '40px',
    height: '40px',
    borderRadius: '6px',
  },
  preview: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    width: 'max-content',
    padding: '6px 12px',
    borderRadius: '16px',
    gap: '8px',
  },
};

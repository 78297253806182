import React, { useState } from 'react';
import DynamicButton from '../components/new/Button';
import { Box } from '@mui/system';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Typography } from '@mui/material';
import { ReactComponent as ShareIconPlan } from '../assets/imgs/share.svg';
import { ReactComponent as PreviewIcon } from '../assets/imgs/preview.svg';
import { ReactComponent as PreviewDisabledIcon } from '../assets/imgs/previewDisabled.svg';
import { ReactComponent as AutoSaveIcon } from '../assets/imgs/cloud_done.svg';
import { ReactComponent as NoInternet } from '../assets/imgs/noInternet.svg';
import { ReactComponent as DuplicateIcon } from '../assets/imgs/duplicate.svg';
import { ReactComponent as DuplicateDisabledIcon } from '../assets/imgs/duplicateDisabled.svg';
import { formatToHeaderAutosave } from '../utils/utils';
import AddIcon from '@mui/icons-material/Add';
import { bgColor, white } from '../constants/colors';
import ShareIcon from '@mui/icons-material/Share';
import ClickAwayListener from '@mui/material/ClickAwayListener';

export const MenuNavNew = ({
  plan,
  handleEdit,
  handleArchive,
  handleDelete,
  template = false,
  createPlanFromTemplate,
  shared = false,
  duplicateTemplate,
  openShareModal,
  openPreviewPlan,
  isConnected = true,
  handleEditTemplate,
  user,
  showClosePreview,
  closePreviewPlan
}) => {
  const [openMenu, setOpenMenu] = useState(false);

  const handleClickAway = () => {
    setOpenMenu(false);
  };

  return (
    showClosePreview ? (<><ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={styles.main}>
        {!template && (
          <Box sx={styles.btns}>
            <DynamicButton
              disabled={false}
              type="outlined"
              startIcon={<PreviewIcon />}
              style={{
                backgroundColor: '#F6F5F4',
              }}
              onClick={closePreviewPlan}
            >
              Close Preview
            </DynamicButton>
          </Box>
        )}
      </Box>
    </ClickAwayListener></>) :
    (<ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={styles.main}>
        {!template ? (
          <Box sx={styles.btns}>
            <DynamicButton
              disabled={shared}
              type="contained"
              startIcon={<ShareIconPlan />}
              onClick={openShareModal}
            >
              Share
            </DynamicButton>
            <DynamicButton
              disabled={shared}
              type="outlined"
              startIcon={shared ? <PreviewDisabledIcon /> : <PreviewIcon />}
              style={{
                backgroundColor: '#F6F5F4',
              }}
              onClick={openPreviewPlan}
            >
              Preview
            </DynamicButton>
            <DynamicButton
              disabled={shared}
              type="outlined"
              style={{
                backgroundColor: '#F6F5F4',
                position: 'relative',
                minWidth: '40px',
                width: '40px',
              }}
              onClick={() =>
                shared ? console.log('Now allowed') : setOpenMenu(!openMenu)
              }
            >
              <MoreHorizIcon />
              {openMenu && (
                <Box sx={styles.menu}>
                  <Typography
                    variant="h4"
                    align="left"
                    sx={styles.edit}
                    onClick={handleEdit}
                  >
                    Edit plan details
                  </Typography>
                  <Typography
                    variant="h4"
                    align="left"
                    sx={styles.edit}
                    onClick={handleArchive}
                  >
                    Archive plan
                  </Typography>
                  <Typography
                    variant="h4"
                    align="left"
                    sx={styles.delete}
                    onClick={handleDelete}
                  >
                    Delete plan
                  </Typography>
                </Box>
              )}
            </DynamicButton>
          </Box>
        ) : (
          <Box sx={styles.btns}>
            <DynamicButton
              type="contained"
              startIcon={
                <AddIcon
                  sx={{
                    color:
                      plan?.stages?.length < 1 || shared
                        ? 'rgba(0, 0, 0, 0.1) !important'
                        : white,
                    fontSize: '24px',
                  }}
                />
              }
              onClick={createPlanFromTemplate}
              disabled={plan?.stages?.length < 1 || shared}
            >
              Create plan
            </DynamicButton>
            {!shared ? (
              <DynamicButton
                type="outlined"
                style={{
                  backgroundColor: bgColor,
                  '&:disabled': { backgroundColor: `${bgColor} !important` },
                }}
                startIcon={<ShareIcon />}
                disabled={plan?.stages?.length < 1}
                onClick={openShareModal}
              >
                Share
              </DynamicButton>
            ) : (
              <DynamicButton
                type="outlined"
                style={{
                  backgroundColor: bgColor,
                }}
                startIcon={
                  shared ? <DuplicateDisabledIcon /> : <DuplicateIcon />
                }
                disabled={plan?.stages?.length < 1 || shared}
                onClick={duplicateTemplate}
              >
                Duplicate
              </DynamicButton>
            )}
            {!shared && (
              <DynamicButton
                type="outlined"
                style={{ backgroundColor: '#F6F5F4', position: 'relative' }}
                onClick={() => setOpenMenu(!openMenu)}
              >
                <MoreHorizIcon />
                {openMenu && (
                  <Box sx={styles.menu}>
                    {plan?.userId?._id === user?._id && (
                      <Typography
                        variant="h4"
                        align="left"
                        sx={styles.edit}
                        onClick={handleEditTemplate}
                      >
                        Edit template details
                      </Typography>
                    )}
                    <Typography
                      variant="h4"
                      align="left"
                      sx={styles.edit}
                      onClick={duplicateTemplate}
                    >
                      Create copy
                    </Typography>
                    {plan?.userId?._id === user?._id && (
                      <Typography
                        variant="h4"
                        align="left"
                        sx={styles.delete}
                        onClick={handleDelete}
                      >
                        Delete template
                      </Typography>
                    )}
                  </Box>
                )}
              </DynamicButton>
            )}
          </Box>
        )}
        <Box sx={styles.update}>
          <Typography variant="inherit" align="center" sx={styles.date}>
            {plan?.updatedAt
              ? formatToHeaderAutosave(plan.updatedAt)
              : 'Last updated: x'}
          </Typography>
          {isConnected ? <AutoSaveIcon /> : <NoInternet />}
        </Box>
      </Box>
    </ClickAwayListener>)
  );
};

const styles = {
  main: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  btns: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  update: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  date: {
    fontFamily: 'InterRegular',
    fontSize: '14px',
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  menu: {
    boxShadow: '0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
    padding: '8px 0',
    borderRadius: '4px',
    position: 'absolute',
    backgroundColor: 'white',
    top: '45px',
    left: '-1px',
    width: '200px',
    zIndex: 1,
  },
  edit: {
    fontFamily: 'InterRegular',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.88)',
    padding: '12px 16px',
    '&:hover': {
      backgroundColor: 'rgba(250, 250, 250, 250.88)',
    },
  },
  delete: {
    fontFamily: 'InterRegular',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'rgba(204, 0, 48, 1)',
    padding: '12px 16px',
    '&:hover': {
      backgroundColor: 'rgba(250, 250, 250, 250.88)',
    },
  },
};

import React, { useState } from 'react';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import { black6, lightGray, primary } from '../../constants/colors';
import '../../styles/plans/customeTextarea.css';
import timerLarger from '../../assets/imgs/timer.svg';

export const ActivityDurationSelectInput = ({
  name,
  value = '',
  options,
  styles,
  menuItemStyles,
  handleChange,
  fullWidth = false,
  minutesShorthand = false,
  disabled = false,
  readOnly = false,
  helperText,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Select
        open={disabled || readOnly ? false : open}
        disabled={disabled}
        displayEmpty
        variant="outlined"
        name={name}
        value={value}
        readOnly={readOnly}
        onChange={handleChange}
        fullWidth={fullWidth}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        onClick={() => setOpen(!open)}
        sx={{
          ...selectStyles.select,
          ...styles,
          paddingLeft: value === '' ? '0' : '2px',
          backgroundColor:
            value === '' ? 'transparent' : 'rgba(0, 0, 0, 0.05) !important',
          '.MuiOutlinedInput-notchedOutline': {
            border: value === '' && 'none !important',
          },
        }}
        MenuProps={selectStyles.menuProps}
        inputProps={{ IconComponent: () => null }}
        startAdornment={
          <img src={timerLarger} alt="Timer" className="small-image" />
        }
        renderValue={(selected) => {
          const option = options.find((o) => o.value === selected);
          return (
            <Box
              sx={{
                ...selectStyles.sx,
                padding: value === '' ? '0' : '6px 8px 6px 0',
                borderTopRightRadius: '14px',
                borderBottomRightRadius: '14px',
                backgroundColor:
                  value === '' ? 'transparent' : 'rgba(0, 0, 0, 0.05)',
                color:
                  option.value !== ''
                    ? 'rgba(0, 0, 0, 0.88)'
                    : 'rgba(0, 0, 0, 0.45)',
              }}
            >
              {typeof selected === 'string'
                ? selected.toString().includes('m') && minutesShorthand
                  ? `${selected.toString().split('m')[0]} mins`
                  : option?.name === 'Duration'
                  ? ''
                  : option?.name
                : ''}
            </Box>
          );
        }}
      >
        {options.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            sx={{
              ...selectStyles.menuItem,
              ...menuItemStyles,
            }}
          >
            {item?.name}
          </MenuItem>
        ))}
      </Select>
      {helperText && (
        <Typography sx={selectStyles.helperText}>{helperText}</Typography>
      )}
    </>
  );
};

const selectStyles = {
  sx: {
    padding: '6px 12px 6px 8px',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: 'InterRegular',
    fontSize: '14px',
    lineHeight: '20px',
  },
  select: {
    color: lightGray,
    borderRadius: '16px',
    border: 'none',
    '.MuiOutlinedInput-input': {
      padding: '0',
    },
    '.MuiSelect-select': {
      borderRadius: '16px',
      paddingRight: '0 !important',
    },
    justifyContent: 'center',
  },
  menuItem: {
    color: 'rgb(122, 128, 129)',
    mb: '10px',
    justifyContent: 'center',
    borderRadius: '8px',
    fontSize: '12px',
    '&.Mui-selected': {
      color: primary,
      backgroundColor: 'transparent',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08) !important',
    },
  },
  menuProps: {
    MenuListProps: { sx: { padding: '10px', minHeight: 'auto' } },
    IconComponent: { sx: { padding: '0 !important' } },
  },
  helperText: {
    fontSize: '12px',
    color: black6,
    fontWeight: 400,
    lineHeight: '16px',
  },
};

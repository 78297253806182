import React from 'react';
import { Box, Grid } from '@mui/material';

const styles = {
  main: {
    backgroundColor: `#DBDFDF !important`,
    minHeight: '100vh !important',
    display: 'flex !important',
  },
};

const withDashboardHeader = (Component) => {
  return () => (
    <Box sx={styles.main}>
      <Grid container spacing={2} sx={{ margin: '0' }}>
        <Component />
      </Grid>
    </Box>
  );
};

export default withDashboardHeader;

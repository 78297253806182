import { createContext, useReducer } from 'react';

const initialState = {
  data: {
    isAuthenticated: false,
    user: {},
    permissions: [],
    userImage: '',
  },
  users: [
    {
      planId: '',
      users: [],
    },
  ],
  suggestions: [
    {
      planId: '',
      customers: [],
      vendors: [],
    },
  ],
  shareListTemplate: [
    {
      templateId: '',
      users: [],
    },
  ],
  notification: [
    {
      notification: false,
    },
  ],
};

export const store = createContext(initialState);

const { Provider } = store;

export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_DATA':
        return {
          ...state,
          data: action.payload,
        };
      case 'SET_SUGGESTIONS':
        return {
          ...state,
          suggestions: action.payload,
        };
      case 'SET_SHARE_LIST_TEMPLATE':
        return {
          ...state,
          shareList: action.payload,
        };
      case 'SET_USERS':
        return {
          ...state,
          users: action.payload,
        };
      case 'SET_NOTIFICATION':
        return {
          ...state,
          notification: action.payload,
        };
      case 'LOGOUT':
        return {
          ...state,
          data: initialState.data,
        };
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

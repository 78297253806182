const env = process.env.REACT_APP_ENVIROMENT || 'qa';
let url;
let wsUrl;
let hotjarhjid;
let hotjarhjsv
if (env === 'prod') {
  url = 'https://app.inquarter.com/';
  wsUrl = 'wss://app.inquarter.com';
} else if (env === 'produk') {
  url = 'https://app.inquarter.co.uk/';
  wsUrl = 'wss://app.inquarter.co.uk';
} else if (env === 'qa') {
  hotjarhjid = 3669843;
  hotjarhjsv = 6;
  url = 'https://inquarter.hyperether.com/';
  wsUrl = 'wss://inquarter.hyperether.com';
} else {
  url = 'http://localhost:3000';
  wsUrl = 'ws://localhost:3000';
}

export { url, wsUrl, hotjarhjid, hotjarhjsv };

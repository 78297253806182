import { Box, Chip, LinearProgress, Typography } from '@mui/material';
import { black, foam, funGreen } from '../constants/colors';

export const planDecorator = (plan) => {
  return {
    ...plan,
    name: (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <img
          src="https://www.transparentpng.com/thumb/google-logo/colorful-google-logo-transparent-clipart-download-u3DWLj.png"
          alt="company-logo"
          width="56px"
          height="56px"
        />
        <Box sx={{ color: black, fontFamily: 'SoehneMedium' }}>
          {plan.name ? plan.name : plan.title}
        </Box>
      </Box>
    ),
    stakeholders: (
      <Box>
        <Typography variant="body1" sx={{ mb: '8px' }}>
          {plan?.stakeholders?.invited} invited
        </Typography>
        <Typography variant="body1" sx={{ fontFamily: 'SoehneMedium' }}>
          {plan?.stakeholders?.viewed} viewed
        </Typography>
      </Box>
    ),
    progress: (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          px: '25px',
        }}
      >
        {plan.progress === 100 ? (
          <Chip
            label="Completed"
            sx={{
              color: funGreen,
              background: foam,
              width: '100%',
              height: '30px',
            }}
          />
        ) : (
          <>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress
                variant="determinate"
                value={plan.progress}
                sx={{ borderRadius: 5 }}
              />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                plan.progress
              )}%`}</Typography>
            </Box>
          </>
        )}
      </Box>
    ),
  };
};

import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  black,
  mercury,
  primary,
  warning,
  white,
  wildSand,
} from '../../constants/colors';
import {
  Avatar,
  Box,
  IconButton,
  MenuItem,
  Select,
  Skeleton,
  Tooltip,
  Typography,
  Pagination,
  Divider,
  tableContainerClasses,
  tableClasses,
} from '@mui/material';
import {
  getDarkerColor,
  getInitialsFromString,
  getShareListForPlan,
  planAccessColumns,
} from '../../utils/utils';
import { ReactComponent as ArrowDown } from '../../assets/imgs/arrow_drop_down.svg';
import { ReactComponent as ArrowDownOthers } from '../../assets/imgs/arrow_drop_down_others.svg';
import { ReactComponent as ArrowDownPartner } from '../../assets/imgs/arrow_drop_down_partner.svg';
import { ReactComponent as ArrowDownBlack } from '../../assets/imgs/arrow_drop_down_black.svg';
import { ReactComponent as ArrowDownGreen } from '../../assets/imgs/arrow_drop_down_green.svg';
import { ReactComponent as NoStageIcon } from '../../assets/imgs/noStage.svg';
import { ReactComponent as OptionHor } from '../../assets/imgs/Option.svg';
import { useState } from 'react';
import StarIcon from '@mui/icons-material/Star';
import { styles } from '../../styles/plans/planAccessStyles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { PLAN_ACCESS } from '../../utils/enums';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useContext } from 'react';
import { store } from '../../Store';
import httpClient from '../../config/http.service';
import { EditPlanAccess } from '../../modals/EditPlanAccess';
import { showNotification } from '../common/Toast';
import DeleteModal from '../common/DeleteModal';
import { useParams } from 'react-router-dom';
import DynamicButton from '../new/Button';
import { styled } from '@mui/material/styles';

const rights = ['Customer', 'Vendor', 'Partner', 'Others'];
export const PlanAccess = ({ plan, isSharing, planId, company }) => {
  const { state, dispatch } = useContext(store);
  const {
    id: paramsPlanId,
    email: planSharing,
    copylink: copyLink,
  } = useParams();
  const [data, setData] = useState([]);
  const [user, setUser] = useState(state?.data?.user || null);
  const [openActions, setOpenActions] = useState({});
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [update, setUpdate] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [page, setPage] = useState(1);
  const itemsPerPage = 8;
  const totalPages = Math.ceil(data?.length / itemsPerPage);
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedPlans = data?.slice(startIndex, endIndex) ?? [];
  const [loading, setLoading] = useState(true)

  const [options] = useState([
    { value: plan?.customerCompany || '', label: plan?.customerCompany || '' },
    {
      value: company?.vendor || plan?.vendor || '',
      label: company?.vendor || plan?.vendor || '',
    },
    { value: 'Partner', label: 'Partner' },
    { value: 'Other', label: 'Other' },
  ]);

  const handleOptionHorClick = (index) => {
    setOpenActions((prevOpenActions) => ({
      ...prevOpenActions,
      [index]: !prevOpenActions[index], // Toggle the open status for the clicked row
    }));
  };

  const collaborator = false;

  useEffect(() => {
    if (planId || plan?._id) getPlanAccess(planId || plan._id);
  }, [planId, update]);

  const getPlanAccess = (id) => {
    setLoading(true)
    httpClient
      .get(`api/plan/access`, { params: { _id: id } })
      .then((res) => {
        const sortedArray = res.data.sort((a, b) => {
          console.log(
            'RES DATA I PLAN VENDOR',
            a._id,
            plan,
            plan.vendorRepresentativeId,
            plan.vendorRepresentative
          );
          // Compare if the _id matches the plan._id
          if (
            a._id === plan.vendorRepresentativeId ||
            a._id === plan.userId?._id
          )
            return -1; // a comes first
          if (
            b._id === plan.vendorRepresentativeId ||
            b._id === plan.userId?._id
          )
            return 1; // b comes first
          return 0; // Keep the original order if neither matches
        });

        setData(sortedArray);
        setTimeout(() => {
          setLoading(false)
        }, 10);
      })
      .catch((err) => {
        setLoading(false)
        //   if (err?.response?.data?.code !== 401)
        //     showNotification('error', err?.response?.data?.message);
      });
  };

  const updateShareList = (share, type, user) => {
    httpClient
      .patch(`api/plan/update-share-list`, {
        _id: plan._id,
        shareList: share.shareList,
        userToRemove: type === 'delete' ? user : '',
        userToUpdate: type === 'delete' ? '' : user,
      })
      .then((res) => {
        const getUsers = getShareListForPlan(res.data);
        dispatch({
          type: 'SET_SUGGESTIONS',
          payload: {
            planId: plan?._id,
            customers: getUsers?.customers || [],
            vendors: getUsers?.vendors || [],
          },
        });
        setUpdate(!update);
      })
      .catch((err) => {
        // showNotification('error', err?.response?.data?.message)
      });
  };

  const handleChangeUserType = (event, user, row) => {
    const selectedValue = event.target.value;
    const updatedPlanAccessRows = data.map((single) => {
      if (single.fullName === row.fullName) single.userType = selectedValue;
      return single;
    });
    const share = plan;
    let userToUpdate;
    share.shareList?.map((user) => {
      if (user.fullName === row.fullName) {
        user.userType = selectedValue;
        userToUpdate = user;
      }
    });
    updateShareList(share, 'update', userToUpdate);

    setData(updatedPlanAccessRows);
  };

  const editMember = () => {
    const updatedPlanAccessRows = data.map((single) => {
      if (
        single.fullName === selectedUser.fullName ||
        single.email === selectedUser.email
      ) {
        single.email = selectedUser.email;
        single.fullName = selectedUser.fullName;
        single.title = selectedUser.title;
        single.company = selectedUser.company;
      }
      return single;
    });
    const share = plan;
    share.shareList?.map((user) => {
      if (user.email === selectedUser.email) {
        user.email = selectedUser.email;
        user.fullName = selectedUser.fullName;
        user.title = selectedUser.title;
        user.company = selectedUser.company;
      }
    });

    setData(updatedPlanAccessRows);
    updateShareList(share, 'update', selectedUser);
    setOpenEditModal(false);
    setOpenActions({});
  };

  const handleDeleteUser = () => {
    const newArray = data?.filter(
      (el) => el.fullName !== selectedUser.fullName
    );
    updateShareList(newArray, 'delete', selectedUser);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const StyledTableContainer = styled(TableContainer)({
    [`&.${tableContainerClasses.root}`]: {
      borderRadius: '8px 8px 8px 8px',
      boxShadow:
        '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 1px 2px -1px rgba(0, 0, 0, 0.12)',
    },
  });

  const StyledTable = styled(Table)({
    [`&.${tableClasses.root}`]: {
      background: 'white',
      boxShadow:
        '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 1px 2px -1px rgba(0, 0, 0, 0.12)',
    },
  });

  return (
    <Paper sx={styles.paper}>
      <StyledTableContainer
        sx={{ boxShadow: '0px 1px 3px 0px #00000033 !important' }}
      >
        <StyledTable>
          {/* <Table
          stickyHeader
          aria-label="sticky table"
          // style={{ borderSpacing: '4px 0' }}
        > */}
          <TableHead sx={styles.tableHeader}>
            <TableRow sx={styles.tableRow}>
              {planAccessColumns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{
                    minWidth:
                      column.key === 'actions'
                        ? store?.data?.user?._id
                          ? isSharing
                            ? 0
                            : column.minWidth
                          : 0
                        : column.minWidth,
                  }}
                  sx={{
                    ...styles.headerCell,
                    width:
                      column.id === PLAN_ACCESS.lastViewed ? '130px' : 'auto',
                    paddingLeft:
                      column.id === PLAN_ACCESS.name
                        ? '20px'
                        : column.id === PLAN_ACCESS.lastViewed
                        ? '30px'
                        : '0',
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: column.align,
                      height: '48px',
                      fontSize: '14px',
                      color: 'rgba(0, 0, 0, 0.6)',
                      fontFamily: 'InterMedium',
                    }}
                  >
                    {column.id === PLAN_ACCESS.count && !planSharing ? (
                      <div style={styles.viewCount}>
                        {column.label}{' '}
                        <Tooltip
                          arrow
                          title="This is the estimated  number of times a member has viewed this Mutual action plan"
                          placement="top"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: white,
                                color: black,
                                width: '170px',
                                height: 'auto',
                                textAlign: 'center',
                                '& .MuiTooltip-arrow': {
                                  color: white,
                                },
                              },
                            },
                          }}
                        >
                          <IconButton>
                            <ErrorOutlineIcon sx={styles.tooltip} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    ) : planSharing &&
                      column.id === PLAN_ACCESS.status ? null : planSharing &&
                      column.id ===
                        PLAN_ACCESS.lastViewed ? null : planSharing &&
                      column.id === PLAN_ACCESS.viewed ? null : planSharing &&
                      column.id === PLAN_ACCESS.viewNumber ? null : (
                      column.label
                    )}
                    {column.id === PLAN_ACCESS.fullName && <ArrowDown />}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
            {!loading && data.length ? (
              displayedPlans?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.name}
                    sx={styles.bodyRow}
                  >
                    {planAccessColumns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          sx={{
                            ...styles.bodyCell,
                          }}
                        >
                          {column.format &&
                          typeof value === PLAN_ACCESS.number ? (
                            column.format(value)
                          ) : column.id === PLAN_ACCESS.fullName ? (
                            <Box sx={styles.collaborator}>
                              <Box sx={styles.wrapper}>
                                <Typography sx={styles.name}>
                                  {value} {value === user.fullName && '(you)'}
                                </Typography>
                                <Typography sx={styles.detailsText}>
                                  {row?.email}
                                </Typography>
                              </Box>
                            </Box>
                          ) : column.id === PLAN_ACCESS.lastViewed ? (
                            planSharing ? null : (
                              <Box
                                marginRight={'40px'}
                                style={{ paddingLeft: '30px' }}
                              >
                                <Typography
                                  sx={{
                                    ...styles.lastViewed,
                                    marginRight: value === '' ? '35px' : '0',
                                  }}
                                  align="left"
                                >
                                  {value !== '' ? value : '-'}
                                </Typography>
                                <Typography
                                  sx={styles.detailsText}
                                  align="left"
                                >
                                  {row['time']}
                                </Typography>
                              </Box>
                            )
                          ) : column.id === PLAN_ACCESS.userType ? (
                            <Select
                              disabled={!state?.data?.user?._id}
                              labelId="demo-multiple-name-label"
                              id="demo-multiple-name"
                              value={row['userType']}
                              onChange={(e) => {
                                handleChangeUserType(e, row['userType'], row);
                                row['userType'] = e.target.value;
                              }}
                              disableUnderline={true}
                              IconComponent={(props) =>
                                row['userType'] === 'Customer' ? (
                                  <ArrowDownGreen
                                    {...props}
                                    style={{
                                      fontSize: '1.5rem',
                                      marginLeft: '20px',
                                      right: '7px !important',
                                    }}
                                  />
                                ) : row['userType'] === 'Vendor' ? (
                                  <ArrowDownBlack
                                    {...props}
                                    style={{
                                      fontSize: '1.5rem',
                                      marginLeft: '20px',
                                      right: '7px !important',
                                    }}
                                  />
                                ) : row['userType'] === 'Others' ? (
                                  <ArrowDownOthers
                                    {...props}
                                    style={{
                                      fontSize: '1.5rem',
                                      marginLeft: '20px',
                                      right: '7px !important',
                                    }}
                                  />
                                ) : (
                                  <ArrowDownPartner
                                    {...props}
                                    style={{
                                      fontSize: '1.5rem',
                                      marginLeft: '20px',
                                      right: '7px !important',
                                    }}
                                  />
                                )
                              }
                              variant="standard"
                              sx={{
                                height: '40px',
                                width: '122px',
                                '& .MuiSelect-select:focus': {
                                  backgroundColor: 'transparent',
                                },
                                '& .MuiInputBase-input': {
                                  height: '100%',
                                  fontFamily: `InterMedium !important`,
                                  padding: '0 24px 0 0',
                                  backgroundColor:
                                    row['userType'] === PLAN_ACCESS.Others
                                      ? 'rgb(254, 242, 230) !important'
                                      : row['userType'] === PLAN_ACCESS.Vendor
                                      ? 'rgba(0, 0, 0, 0.05) !important'
                                      : row['userType'] === PLAN_ACCESS.Customer
                                      ? 'rgb(240, 255, 241) !important'
                                      : 'rgb(240, 255, 241) !important',
                                  fontSize: '16px !important',
                                },
                              }}
                              renderValue={(selected) => (
                                <span
                                  style={
                                    selected === PLAN_ACCESS.Others
                                      ? styles.userTypeOthers
                                      : selected == PLAN_ACCESS.Vendor
                                      ? styles.vendor
                                      : styles.userTypeNormal
                                  }
                                >
                                  {selected}
                                </span>
                              )}
                            >
                              {rights.map((name, index) => (
                                <MenuItem key={name + index} value={name}>
                                  <div
                                    style={{
                                      fontFamily: 'InterMedium',
                                      textAlign: 'center',
                                      color:
                                        name === PLAN_ACCESS.Others
                                          ? '#946114'
                                          : name === PLAN_ACCESS.Vendor
                                          ? black
                                          : primary,
                                      backgroundColor:
                                        name === PLAN_ACCESS.Others
                                          ? '#F6F2EE'
                                          : name === PLAN_ACCESS.Vendor
                                          ? `${wildSand}`
                                          : '#F2F7F7',
                                      borderRadius: '50px',
                                      padding: '5px 10px',
                                      width: '100%',
                                      borderBottom:
                                        index === rights.length - 1
                                          ? 'none'
                                          : `1px solid ${mercury}`,
                                    }}
                                  >
                                    {name}{' '}
                                  </div>
                                </MenuItem>
                              ))}
                            </Select>
                          ) : column.id ===
                            PLAN_ACCESS.title ? null : planSharing &&
                            column.id === PLAN_ACCESS.viewNumber ? null : (
                            <span
                              style={{
                                fontFamily:
                                  column.id === PLAN_ACCESS.company
                                    ? 'InterRegular'
                                    : 'InterMedium',
                              }}
                            >
                              {column.id === PLAN_ACCESS.company
                                ? row.company || '-'
                                : value}
                            </span>
                          )}
                          {column.type === PLAN_ACCESS.boolean ? (
                            planSharing ? null : value ? (
                              <span
                                style={{
                                  ...styles.dotWrapper,
                                  backgroundColor: 'rgba(240, 255, 241, 1)',
                                }}
                              >
                                <VisibilityIcon style={{ color: primary }} />
                                Yes
                              </span>
                            ) : (
                              <span
                                style={{
                                  ...styles.dotWrapper,
                                  backgroundColor: 'rgba(204, 0, 48, 0.1)',
                                }}
                              >
                                <VisibilityOffIcon
                                  style={{ color: 'rgba(204, 0, 48, 1)' }}
                                />
                                No
                              </span>
                            )
                          ) : column.id === PLAN_ACCESS.avatar ? (
                            <Box sx={{ position: 'relative' }}>
                              <Avatar
                                sx={{
                                  ...styles.avatar,
                                  color: row['color']
                                    ? getDarkerColor(row['color'])
                                    : 'black',
                                  backgroundColor: row['backgroundColor'],
                                  // border: `1px solid ${getDarkerColor(
                                  //   row['color'] || 'black'
                                  // )}`,
                                }}
                              >
                                {getInitialsFromString(row?.fullName || '')}
                              </Avatar>
                              {row?.fullName === user.name && (
                                <StarIcon sx={styles.you} />
                              )}
                            </Box>
                          ) : null}
                          {state?.data?.user?._id &&
                            !isSharing &&
                            column.key === 'actions' && (
                              <div>
                                <OptionHor
                                  onClick={() => handleOptionHorClick(index)}
                                />

                                {openActions[index] && (
                                  <div style={styles.absoluteDiv}>
                                    <Typography
                                      sx={{
                                        ...styles.text,
                                        color: 'rgba(0, 0, 0, 0.88)',
                                        cursor: 'pointer',
                                        '&:hover': {
                                          backgroundColor:
                                            'rgba(0, 0, 0, 0.04)',
                                        },
                                      }}
                                      onClick={() => {
                                        setSelectedUser(row);
                                        setOpenEditModal(true);
                                      }}
                                    >
                                      Edit
                                    </Typography>
                                    <Typography
                                      sx={{
                                        ...styles.text,
                                        color: warning,
                                        cursor: 'pointer',
                                        '&:hover': {
                                          backgroundColor:
                                            'rgba(204, 0, 48, 0.1)',
                                        },
                                      }}
                                      onClick={() => {
                                        setSelectedUser(row);
                                        setOpenDeleteModal(true);
                                      }}
                                    >
                                      Delete
                                    </Typography>
                                  </div>
                                )}
                              </div>
                            )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            ) : !loading &&  !data.length ? (
              <Box sx={styles.noStage}>
              <Box sx={styles.noStageIcon}>
                <NoStageIcon />
              </Box>
              <Box sx={styles.noStageText}>
                <Typography sx={styles.label}>
                  Your share list is empty, or no user has viewed the plan yet.
                </Typography>
              </Box>
            </Box>
            ) :
            (
              <>
                {[...Array(10).keys()].map((item) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      sx={styles.bodyRow}
                    >
                      {planAccessColumns.map((item1, index) => (
                        <TableCell>
                          <Skeleton animation="wave" />
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </>
            )}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
      {openDeleteModal && (
        <DeleteModal
          heading="Delete user"
          submitBtn="Delete"
          text={`Are you sure you want to delete ${selectedUser.fullName} from share list?`}
          open={openDeleteModal}
          handleClose={() => {
            setOpenDeleteModal(false);
            setSelectedUser(null);
          }}
          handleSubmit={handleDeleteUser}
        />
      )}
      {openEditModal && (
        <EditPlanAccess
          open={openEditModal}
          handleClose={() => setOpenEditModal(false)}
          data={selectedUser}
          setData={setSelectedUser}
          options={options}
          saveMember={editMember}
        />
      )}
      {!loading && data.length < 1 && (
        <Box sx={styles.noStage}>
          <Box sx={styles.noStageIcon}>
            <NoStageIcon />
          </Box>
          <Box sx={styles.noStageText}>
            <Typography sx={styles.label}>
              Your share list is empty, or no user has viewed the plan yet.
            </Typography>
          </Box>
        </Box>
      )}
      {data?.length > 0 && (
        <Box sx={{ marginTop: '20px' }}>
          <Divider sx={styles.paginationDivider} />
          <Box style={styles.pagination_row}>
            {page > 1 ? (
              <DynamicButton
                type="text"
                style={{
                  borderColor: '#EBEBEB',
                  backgroundColor: 'rgb(246, 245, 244)',
                }}
                onClick={() => setPage(page - 1)}
              >
                Previous
              </DynamicButton>
            ) : (
              <div style={{ width: '130px' }}></div>
            )}
            <Pagination
              // count={data.length}
              count={totalPages}
              page={page}
              hidePrevButton
              hideNextButton
              style={styles.pagination}
              // className={stylesPlan.bgPagination}
              sx={{
                // backgroundColor: 'rgb(246, 245, 244)',
                '.Mui-selected': {
                  backgroundColor: `${primary} !important`,
                  color: 'white',
                },
              }}
              onChange={handlePageChange}
            />
            {page < totalPages ? (
              <DynamicButton
                type="text"
                style={{
                  borderColor: '#EBEBEB',
                  backgroundColor: 'rgb(246, 245, 244)',
                }}
                onClick={() => setPage(page + 1)}
              >
                Next
              </DynamicButton>
            ) : (
              <div style={{ width: '86px', marginLeft: '20px' }}></div>
            )}
          </Box>
        </Box>
      )}
    </Paper>
  );
};

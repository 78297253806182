import React from 'react';
import { styled } from '@mui/material/styles';
import { FormControl, InputBase } from '@mui/material';
import NumericFormatCustom from './NumericFormatCustom';
import { alabaster, black88 } from '../../constants/colors';
import '../../styles/plans/customeTextarea.css';

const BootstrapInput = styled(({ inputRef, ...otherProps }) => (
  <InputBase {...otherProps} inputRef={inputRef} />
))(({ theme, disabled, multiline, activity, subActivity }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    border: `none`,
    fontSize: 16,
    width: '99%',
    padding: '12px 16px',
    marginTop: '5px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    fontFamily: multiline
      ? 'InterRegular !important'
      : 'InterMedium !important',
  },
}));

export const ActivityNameInput = ({
  type = 'text',
  field,
  name,
  value,
  min = 0,
  styles,
  placeholderStyles = {},
  placeholder = '',
  required = false,
  multiline = false,
  disabled = false,
  handleChange,
  onKeyDown,
  subActivity = false,
  activity = false,
  readOnly = false,
  share = false,
  inputRef,
}) => {
  const handleInputChange = (e) => {
    handleChange(e);
  };

  return (
    <FormControl
      variant="standard"
      fullWidth
      sx={{
        marginBottom: styles?.marginBottom,
        position: 'relative',
        width: '100%',
        overflowX: share && 'hidden',
      }}
    >
      <BootstrapInput
        onKeyDown={onKeyDown}
        type={type}
        disabled={disabled}
        value={value}
        name={name}
        id={name}
        inputRef={inputRef}
        multiline={multiline}
        required={required}
        placeholder={placeholder}
        inputProps={{
          readOnly: readOnly,
          style: {
            backgroundColor: alabaster,
            position: 'relative',
            ...styles,
          },
          min,
        }}
        activity={activity}
        subActivity={subActivity}
        {...(field === 'number' && { inputComponent: NumericFormatCustom })}
        sx={{
          width: '100%',
          fontSize: '24px',
          '.MuiInputBase-input': {
            fontSize: '24px !important',
            fontWeight: '700',
            color: `${black88} !important`,
          },
          '& ::placeholder': placeholderStyles,
        }}
        onChange={handleInputChange}
        className="custom-input"
      />
    </FormControl>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import { isBoolean } from 'lodash';
import { Grid, Typography } from '@mui/material';
import withDashboardHeader from '../hoc/withDashboardHeader';
import PlanTabs from '../components/Plan/PlanTabs';
import MutualActionPlan from '../components/Plan/MutualActionPlan';
import DeleteModal from '../components/common/DeleteModal';
import TeamMembersModal from '../components/Plan/TeamMembersModal';
import { reorderOnDrag } from '../utils/reorderOnDrag';
import {
  colorsForShareList,
  colorsForTeam,
  planDurationOptions,
  durationOptions as timeOptions,
} from '../utils/constants';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { EditStageTitleModal } from '../components/Plans/EditStageTitleModal';
import { HorizontalLabelPositionBelowStepper } from '../components/Stepper';
import { Engangement } from '../components/Plan/Engangement';
import { PlanAccess } from '../components/Plan/PlanAccess';
import { PLAN_FIELDS } from '../utils/enums';
import { CreateNewStageModal } from '../components/Plans/CreateNewStageModal';
import { DeletePlanModal } from '../components/Plans/DeletePlanModal';
import { ArchivePlanModal } from '../components/Plans/ArchivePlanModal';
import { SharePlanModal } from '../components/Plans/SharePlan.';
import httpClient from '../config/http.service';
import { store } from '../Store';
import {
  checkDoesExist,
  editShareList,
  getCustomOptions,
  getNumericValue,
  getShareListForPlan,
  randomColor,
} from '../utils/utils';
import { showNotification } from '../components/common/Toast';
import DashboardSidebar from '../components/common/DashboardSidebar';
import DashboardHeader from '../components/common/DashboardHeader';
import { MenuNavNew } from './MenuNavNew';
import { CreatePlanStep1 } from '../modals/CreatePlanStep1';
import { Participants } from './Participants';
import { Box } from '@mui/system';
import { ReactComponent as NoStageIcon } from '../assets/imgs/noStage.svg';
import DynamicButton from '../components/new/Button';
import { white } from '../constants/colors';
import { SelectTemplate } from '../modals/SelectTemplate';
import ActivityDurationModal from '../components/Plan/ActivityDurationModal';
import { useOnlineStatus } from '../utils/hooks/useOnlineStatus';
import CircularProgress from '@mui/material/CircularProgress';
import targetDateCheck from '../utils/targetDateCheck';

const Plan = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(store);
  const location = useLocation();
  const [defCurrency, setDefCurrency] = useState('');
  const [tab, setTab] = useState(1);
  const isConnected = useOnlineStatus();
  const [plan, setPlan] = useState(null);
  const [initialPlan, setInitialPlan] = useState(null);
  const [selectedStage, setSelectedStage] = useState('');
  const [durationOptions, setDurationOptions] = useState(planDurationOptions);
  const [expandPlanDetails, setExpandPlanDetails] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteActivityModalOpen, setIsDeleteActivityModalOpen] =
    useState(false);
  const [teamMembersModal, setTeamMembersModal] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState({
    stageIndex: '',
    activityIndex: '',
  });
  const [activityDuration, setActivityDuration] = useState({
    time: '10',
    duration: 'm',
  });
  const [selectedTeam, setSelectedTeam] = useState('');
  const [closeModal, setCloseModal] = useState(false);
  const [stageTitle, setStageTitle] = useState('');
  const [activeTab, setActiveTab] = useState('map');
  const [openCreateNewStageModal, setOpenCreateNewStageModal] = useState(false);
  const [openedStage, setOpenedStage] = useState({ index: 0, name: '' });
  const [deletePlanModal, setDeletePlanModal] = useState(false);
  const [showArchivePlanModal, setShowArchivePlanModal] = useState(false);
  const [selectedArchiveOption, setSelectedArchiveOption] = useState('');
  const [archiveReason, setArchiveReason] = useState('');
  const [showEditPlan, setShowEditPlan] = useState(false);
  const [showSharePlanModal, setShowSharePlanModal] = useState(false);
  const {
    id: planId,
    email: planSharing,
    copylink: copyLink,
    shortId,
  } = useParams();
  const getActiveState = useLocation();
  const [customerImage, setCustomerImage] = useState(null);
  const [customerImageDetails, setCustomerImageDetails] = useState(null);
  const [updateMembers, setUpdateMembers] = useState(true);
  const [company, setCompany] = useState(null);
  const [openSelectTemplate, setOpenSelectTemplate] = useState(false);
  const [update, setUpdate] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [isSharing, setIsSharing] = useState(
    (copyLink && copyLink !== '' && copyLink !== 'engangement') || shortId
      ? true
      : false
  );
  const [isSharingAndEdit, setIsSharingAndEdit] = useState(
    planSharing && planSharing !== '' ? true : false
  );
  const AUTOEDIT_INTERVAL = 2000;
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  let debounceTimeout;
  const [companyPhoto, setCompanyPhoto] = useState(null);
  const [templateStages, setTemplateStages] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [activityDurationModal, setActivityDurationModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sharedUser, setSharedUser] = useState(null);
  const [originalPlanImage, setOriginalPlanImage] = useState(null);
  const [original, setOriginal] = useState(false);
  const [isVendor, setIsVendor] = useState(false);

  useEffect(() => {
    if (state?.data?.user?._id && plan?.vendorRepresentativeId) {
      setIsVendor(
        state.data.user._id === plan?.vendorRepresentativeId &&
          !isSharing &&
          !isSharingAndEdit
      );
    }
  }, [state?.data?.user?._id, plan, isSharing, isSharingAndEdit]);

  useEffect(() => {
    if (state?.data?.user?.defaultCurrency) {
      setDefCurrency(state.data.user.defaultCurrency);
      const planWithNewCurrency = {
        ...plan,
        value: { currency: state.data.user.defaultCurrency },
      };
      setPlan(planWithNewCurrency);
    }
  }, [state?.data?.user?.defaultCurrency]);

  useEffect(() => {
    const intercom = document.getElementsByClassName('intercom-app');
    const intercom2 = document.getElementsByClassName(
      'intercom-lightweight-app'
    );

    if (!isSharing && !isSharingAndEdit) {
      if (intercom && intercom[0]) {
        intercom[0].style.display = 'block';
      }
      if (intercom2 && intercom2[0]) {
        intercom2[0].style.display = 'block';
      }
    } else {
      if (intercom && intercom[0]) {
        intercom[0].style.display = 'none';
      }
      if (intercom2 && intercom2[0]) {
        intercom2[0].style.display = 'none';
      }
    }
  }, [isSharing, isSharingAndEdit]);

  useEffect(() => {
    if (shortId) {
      httpClient
        .get(`api/plan/get-by-short-id?shortId=${shortId}`)
        .then(async (res) => {
          if (res.data.hasAvatar) getCustomerPhoto(res.data._id);

          httpClient
            .get(`api/company/get`, { params: { _id: res.data.vendor } })
            .then(async (response) => {
              const obj = res.data;
              obj.vendorId = response.data._id;
              obj.vendorRepresentativeId = res.data.userId?._id;
              obj.vendor = response.data.name;
              obj.vendorRepresentative = res.data.vendorRepresentative;

              setCustomer(res.data.mainPointCustomer);
              getCustomerAndVendor(
                res.data.mainPointCustomer.fullName,
                res.data.userId?._id
              );
              obj.stages?.map((el) => (el.open = false));
              setCompany(response.data);
              setPlan(obj);
              setInitialPlan(obj);
              setIsPageLoaded(true);
              const withCustomOptions = getCustomOptions(obj, durationOptions);
              setDurationOptions(withCustomOptions);
              setLoading(false);
            })
            .catch((err) => {
              // showNotification('error', err?.response?.data?.message)
            });
        })
        .catch((err) => {
          // showNotification('error', err?.response?.data?.message)
        });
    }
  }, []);

  useEffect(() => {
    if (planSharing?.length > 1) {
      if (planSharing) {
        const decodedString = atob(planSharing);
        const obj = JSON.parse(decodedString);
        if (obj.activeTab === 'engangement') {
          setTab(2);
          setActiveTab('engangement');
        }
        setSharedUser(obj);
      } else {
        navigate('/login');
      }
    }
  }, [planSharing]);

  useEffect(() => {
    if (plan?._id || planId) getOriginalPlanImage(plan?._id || planId);
  }, [original]);

  const getOriginalPlanImage = async (planId) => {
    if (planId) {
      httpClient
        .get(`api/file/get/${planId}`)
        .then((res) => {
          const imageData = new Uint8Array(res.data);
          const blob = new Blob([imageData], { type: 'image/jpeg' });
          const imageUrl = URL.createObjectURL(blob);
          setOriginalPlanImage(originalPlanImage);
          setCustomerImage(imageUrl);
        })
        .catch((err) => {
          //   if (err?.response?.data?.code !== 401)
          //     showNotification('error', err?.response?.data?.message);
        });
    }
  };

  const getCustomerPhoto = async (planId) => {
    if (planId) {
      httpClient
        .get(`api/file/get/${planId}`)
        .then((res) => {
          const imageData = new Uint8Array(res.data);
          const blob = new Blob([imageData], { type: 'image/jpeg' });
          const imageUrl = URL.createObjectURL(blob);
          setCustomerImage(imageUrl);
        })
        .catch((err) => {
          //   if (err?.response?.data?.code !== 401)
          //     showNotification('error', err?.response?.data?.message);
        });
    }
  };

  const getCompanyLogo = async (companyId) => {
    httpClient
      .get(`api/file/get/${companyId}`)
      .then((res) => {
        const imageData = new Uint8Array(res.data);
        const blob = new Blob([imageData], { type: 'image/jpeg' });
        const imageUrl = URL.createObjectURL(blob);
        setCompanyPhoto(imageUrl);
      })
      .catch((err) => {
        //   if (err?.response?.data?.code !== 401)
        //     showNotification('error', err?.response?.data?.message);
      });
  };

  const getTemplate = async (templateId, onlyStage = false) => {
    if (templateId) {
      httpClient
        .get(`api/template/get`, { params: { _id: templateId } })
        .then(async (res) => {
          httpClient
            .get(`api/company/get`, {
              params: { _id: res.data.vendor },
            })
            .then((response) => {
              const obj = res.data;
              delete obj._id;
              obj.hasAvatar = false;
              obj.name = `Customer & ${response.data.name} Mutual Action Plan`;
              obj.vendor = response.data.name;
              obj.shareList = [];
              obj.vendorRepresentative =
                res.data.vendorRepresentative === state?.data?.user?._id
                  ? state?.data?.user?.firstName +
                    ' ' +
                    state?.data?.user?.lastName
                  : '';

              if (onlyStage) {
                handleSelectTemplate(res.data.stages);
                setTemplateStages(res.data.stages);
              } else {
                setPlan({ ...obj });
                // setInitialPlan(obj);
              }
            })
            .catch((err) => {
              // showNotification('error', err?.response?.data?.message)
            });
        })
        .catch((err) => {
          // showNotification('error', err?.response?.data?.message);
        });
    }
  };

  useEffect(() => {
    if (getActiveState?.state?.templateId)
      getTemplate(getActiveState.state.templateId);
  }, [getActiveState]);

  const getCompany = (companyId) => {
    if (companyId) {
      httpClient
        .get(`api/company/get`, { params: { _id: companyId } })
        .then((res) => {
          setCompany(res.data);
          if (res.data.hasAvatar) getCompanyLogo(res.data._id);
        })
        .catch((err) => {
          // showNotification('error', err?.response?.data?.message)
        });
    }
  };
  const getCustomerAndVendor = async (cus, ven) => {
    if (planId || shortId) {
      httpClient
        .get(`api/user/get-participants`, {
          params: { customerFullName: cus, vendorId: ven },
        })
        .then(async (res) => {
          setVendor(res.data.representative);
        })
        .catch((err) => {
          // showNotification('error', err?.response?.data?.message)
        });
    }
  };

  const getPlan = async () => {
    if (planId) {
      let params = { id: planId };
      if (planSharing) params.userToken = planSharing;

      httpClient
        .get(`api/plan/get`, { params: params })
        .then(async (res) => {
          const getUsers = getShareListForPlan(res.data);
          dispatch({
            type: 'SET_SUGGESTIONS',
            payload: {
              planId: planId,
              customers: getUsers?.customers || [],
              vendors: getUsers?.vendors || [],
            },
          });
          if (res.data.hasAvatar) getCustomerPhoto(planId);

          httpClient
            .get(`api/company/get`, { params: { _id: res.data.vendor } })
            .then(async (response) => {
              const obj = res.data;
              obj.vendorId = response.data._id;
              obj.vendorRepresentativeId = res.data.userId?._id;
              obj.vendor = response.data.name;
              obj.vendorRepresentative = res.data.vendorRepresentative;

              setCustomer(res.data.mainPointCustomer);
              getCustomerAndVendor(
                res.data.mainPointCustomer.fullName,
                res.data.userId?._id
              );
              obj.stages?.map((el) => (el.open = false));
              setCompany(response.data);
              setPlan(obj);
              setInitialPlan(obj);
              setIsPageLoaded(true);
              const withCustomOptions = getCustomOptions(obj, durationOptions);
              setDurationOptions(withCustomOptions);
              setLoading(false);
            })
            .catch((err) => {
              // showNotification('error', err?.response?.data?.message)
            });
        })
        .catch((err) => {
          // showNotification('error', err?.response?.data?.message)
        });
    }
  };

  useEffect(() => {
    if (planId && state?.data?.user) {
      getPlan();
    }
  }, [planId]);

  useEffect(() => {
    if (state?.data?.user?.company) {
      getCompany(plan?.vendorId || state.data.user.company);
    } else if (
      !state?.data?.isAuthenticated &&
      (plan?.vendorId || plan?.vendor)
    )
      getCompany(plan.vendorId || plan.vendor);
  }, [state?.data, plan?.vendor]);

  const handleStageTitle = (title) => {
    setStageTitle(title);
  };
  const handleCloseStage = () => {
    setCloseModal(false);
  };
  const handleTabChange = (selectedTab) => {
    setTab(selectedTab);
    if (selectedTab === 1) setActiveTab('map');
    else if (selectedTab === 2) setActiveTab('engangement');
    else if (selectedTab === 3) setActiveTab('access');
  };

  const handleChange = ({ target }) => {
    const planData = plan;
    const { name, value } = target;
    const splitName = name.split('.');

    if (splitName.length > 1 && splitName[0] === 'value') {
      planData[splitName[0]][splitName[1]] = value;
    } else {
      planData[name] = value;
    }
    if (name === PLAN_FIELDS.customerCompany) {
      planData.name =
        (value.length > 0 ? value : `Customer`) +
        ' &' +
        planData.name.split('&')[1];
    }

    if (name === PLAN_FIELDS.vendor) {
      planData.name =
        planData.name.split('&')[0] +
        ' & ' +
        (value.length > 0 ? value : `Vendor`) +
        ' ' +
        'Mutual Action Plan';
    }
    setUnsavedChanges(true);

    setPlan(planData);
  };

  const handleActivityFieldsChange = (
    { target },
    stageIndex,
    activityIndex,
    teamMemberIndex,
    teamField = 'name',
    email,
    jobTitle
  ) => {
    const { name, value } = target;
    const planData = { ...plan };
    const activity = planData.stages[stageIndex].activities[activityIndex];
    const previousValue = activity[name];

    if (teamMemberIndex !== undefined) {
      if (email && teamField === 'name') {
        const capitalizedStr = value?.charAt(0).toUpperCase() + value.slice(1);
        activity[name][teamMemberIndex][teamField] = capitalizedStr;
        activity[name][teamMemberIndex]['email'] = email;
        activity[name][teamMemberIndex]['title'] = jobTitle;
        setUpdateMembers(false);
      } else {
        setUpdateMembers(false);
        activity[name][teamMemberIndex][teamField] = value;
      }
    } else if (name === 'targetDate') {
      const date = new Date(target.value);
      activity.targetDate = date;
    } else {
      activity[name] = value;
    }

    if (name === PLAN_FIELDS.status && value === PLAN_FIELDS.completed)
      activity.completionDate = new Date().toISOString();
    else if (name === PLAN_FIELDS.status && value !== PLAN_FIELDS.completed)
      activity.completionDate = '';

    if (name === PLAN_FIELDS.duration && value === PLAN_FIELDS.custom) {
      setActivityDurationModal(true);
      setSelectedActivity({ stageIndex, activityIndex });
      activity.duration = previousValue;
    }

    // if (name === PLAN_FIELDS.targetDate) {
    //   if (
    //     targetDateCheck(new Date(activity.targetDate))?.props?.children[0] ===
    //       'Due in' ||
    //     targetDateCheck(new Date(activity.targetDate)) === ''
    //   ) {
    //     planData.status = 'On track';
    //   } else {
    //     planData.status = 'Overdue';
    //   }
    // }

    setUnsavedChanges(true);
    setPlan(planData);
    clearTimeout(debounceTimeout);
  };

  const handleClearInput = (
    name,
    stageIndex,
    activityIndex,
    subActivityIndex
  ) => {
    console.log(
      'HANDLE CELAR INPUT',
      plan.stages,
      stageIndex,
      activityIndex,
      subActivityIndex,
      name
    );
    const planData = { ...plan };
    console.log(
      ')_______________(',
      planData.stages[stageIndex].activities[activityIndex].subActivities[
        subActivityIndex
      ]
    );
    planData.stages[stageIndex].activities[activityIndex].subActivities[
      subActivityIndex
    ][name] = '';

    setUnsavedChanges(true);
    setPlan(planData);
    clearTimeout(debounceTimeout);
  };

  const handleDurationModalClose = () => {
    setActivityDurationModal(false);
    setSelectedActivity({ stageIndex: '', activityIndex: '' });
    setActivityDuration({ time: '10', duration: 'm' });
  };

  const handleDurationChange = ({ target }) => {
    const duration = { ...activityDuration };
    const { name, value } = target;
    duration[name] = value;
    setActivityDuration(duration);
  };

  const handleDurationSubmit = () => {
    const { time, duration } = activityDuration;
    const { stageIndex, activityIndex } = selectedActivity;
    const allStages = plan.stages;
    const durationExists = timeOptions.find((t) => t.value === duration);

    const name = time + ' ' + durationExists.name.toLowerCase();
    const value = time + duration;
    const newItem = { name, value };

    if (!durationOptions.find((d) => d.value === value)) {
      setDurationOptions([...durationOptions, newItem]);
    }

    allStages[stageIndex].activities[activityIndex].duration = value;
    setUnsavedChanges(true);
    setPlan({ ...plan, stages: allStages });
    handleDurationModalClose();
  };

  const updatePlan = async (planObj) => {
    if (!isConnected) return;

    if (planId) {
      planObj.vendorColor = planObj.vendorColor || colorsForShareList[0].color;
      planObj.vendorBackgroundColor =
        planObj.vendorBackgroundColor || colorsForShareList[0].backgroundColor;
      planObj.vendor =
        plan.vendorId || state?.data?.user?.company || plan.vendor;
      planObj.vendorRepresentative =
        vendor?._id ||
        plan.vendorRepresentativeId ||
        planObj.vendorRepresentative;
      planObj.userId = planObj.userId?._id
        ? planObj.userId._id
        : planObj.userId;

      const companyId = planObj.vendorId;
      const vendorRepresentativeId = planObj.vendorRepresentativeId;
      let currentPlan = { ...planObj };

      delete planObj.vendorId;
      delete planObj.vendorRepresentativeId;

      if (planSharing && planSharing !== '') planObj.token = planSharing;
      httpClient
        .patch('api/plan/update', planObj)
        .then((response) => {
          const obj = response.data;
          obj.vendorId = companyId;
          obj.vendorRepresentativeId = vendorRepresentativeId;
          obj.vendor = initialPlan.vendor || '';
          obj.vendorRepresentative =
            response.data.vendorRepresentative === state?.data?.user?._id
              ? state?.data?.user?.firstName + ' ' + state?.data?.user?.lastName
              : '';

          const differentProperties = compareObjects(plan, obj, false);

          if (differentProperties.stages) {
            const keys = Object.keys(differentProperties.stages);
            if (keys.length) {
              keys.forEach((key) => {
                const stage = differentProperties.stages[key];
                obj.stages[key].open = plan.stages[key].open;

                if (stage.activities?.length) {
                  stage.activities.forEach((act, i) => {
                    obj.stages[key].activities[i].collapseSubActivity =
                      plan.stages[key].activities[i].collapseSubActivity;
                  });
                }
              });
            }
          }
          currentPlan.updatedAt = response.data.updatedAt;
          currentPlan.shareList = response.data.shareList;
          setUnsavedChanges(false);
          setInitialPlan(obj);
          setPlan(currentPlan);
          // showNotification('success', 'Changes saved!');
          setShowEditPlan(false);
        })
        .catch((err) => {
          // showNotification('error', err?.response?.data?.message);
        });
    }
  };

  const compareObjects = (obj1, obj2, exclude = true) => {
    const differences = {};

    if (!obj1 || !obj2) {
      // Either obj1 or obj2 is null or undefined, return empty differences
      return differences;
    }

    // Iterate over the properties of obj1
    for (let key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        if (exclude && (key === 'open' || key === 'collapseSubActivity')) {
          continue;
        }
        // Check if the property exists in obj2
        if (obj2.hasOwnProperty(key)) {
          // Recursively compare nested objects
          if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
            const nestedDifferences = compareObjects(obj1[key], obj2[key]);
            if (Object.keys(nestedDifferences).length > 0) {
              differences[key] = nestedDifferences;
            }
          } else {
            // Compare values
            if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
              differences[key] = obj2[key];
            }
          }
        } else {
          // Property exists in obj1 but not in obj2
          differences[key] = obj2[key];
        }
      }
    }

    // Check for properties in obj2 that are not in obj1
    for (let key in obj2) {
      if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
        differences[key] = obj2[key];
      }
    }

    return differences;
  };

  useEffect(() => {
    if (!isPageLoaded) return;
    if (!updateMembers) return;
    if (showEditPlan) return;

    const timeout = setTimeout(() => {
      if (unsavedChanges) {
        console.log('UNSAVED CHANGES => SAVING ...');
        delete plan.updatedAt;
        updatePlan(plan);
        let shareList = [];
        if (state?.users?.shareList?.length > 0) {
          shareList = editShareList(state.users.shareList, plan);
        } else {
          shareList = plan.shareList;
        }

        dispatch({
          type: 'SET_USERS',
          payload: {
            shareList,
          },
        });
      }
    }, AUTOEDIT_INTERVAL);
    return () => clearTimeout(timeout);
  }, [plan, isPageLoaded]);

  const handleSubActivityChange = (
    { target },
    stageIndex,
    activityIndex,
    subActivityIndex
  ) => {
    const { name, value } = target;
    const planData = { ...plan };

    const subActivity =
      planData.stages[stageIndex].activities[activityIndex].subActivities[
        subActivityIndex
      ];

    if (name === PLAN_FIELDS.status && value === PLAN_FIELDS.completed)
      subActivity.completionDate = new Date().toISOString();
    else if (name === PLAN_FIELDS.status && value !== PLAN_FIELDS.completed)
      subActivity.completionDate = '';

    // if (name === PLAN_FIELDS.targetDate) {
    //   if (
    //     targetDateCheck(new Date(subActivity.targetDate))?.props
    //       ?.children[0] === 'Due in' ||
    //     targetDateCheck(new Date(subActivity.targetDate)) === ''
    //   ) {
    //     planData.status = 'On track';
    //   } else {
    //     planData.status = 'Overdue';
    //   }
    // }
    if (name === 'targetDate') {
      const date = new Date(value);
      subActivity.targetDate = date;
    } else {
      subActivity[name] = value;
    }

    setUnsavedChanges(true);
    setPlan({ ...planData });
  };

  const handleExpand = (index) => {
    const allStages = [...plan.stages];
    allStages[index].open = !allStages[index].open;
    setPlan({ ...plan, stages: allStages });
  };

  const handleStageActionsOpen = (event, index) => {
    const allStages = [...plan.stages];
    allStages[index].actionsEl = event.currentTarget;
    setPlan({ ...plan, stages: allStages });
    setOpenedStage({ index: index, name: allStages[index].name });
  };

  const handleStageActionsClose = (newStages) => {
    const allStages = newStages ? [...newStages] : [...plan.stages];
    console.log(
      'MAYBE ALWAYS TO ADD STAGES FROM PLAN ??????',
      plan.stages,
      newStages
    );
    const index = allStages.findIndex((s) => s.actionsEl !== null);
    if (index < 0) return;
    allStages[index].actionsEl = null;
    setPlan({ ...plan, stages: allStages });
  };

  const handleAddNewActivity = (index) => {
    const allStages = [...plan.stages];
    allStages[index].activities.push({
      name: '',
      description: '',
      duration: '',
      tag: '',
      status: '',
      notes: '',
      customerTeam: [],
      vendorTeam: [],
      targetDate: '',
      subActivities: [],
    });
    showNotification('success', 'Added new activity');
    setUnsavedChanges(true);
    setPlan({ ...plan, stages: allStages });
  };

  const handleRemoveActivity = () => {
    const { activityIndex, stageIndex } = selectedActivity;
    const dataParams = {
      _id: planId,
      stageIndex,
      activityIndex,
    };

    if (planSharing) dataParams.token = planSharing;

    httpClient
      .delete('api/plan/delete-stage', {
        data: dataParams,
      })
      .then((response) => {
        const obj = response.data;
        obj.vendorId = plan.vendorId;
        obj.vendorRepresentativeId = plan.vendorRepresentative;
        obj.vendor = plan.vendor || '';
        obj.vendorRepresentative = plan.vendorRepresentative;

        obj?.stages?.forEach((newStage, index) => {
          if (plan?.stages && plan?.stages[index]) {
            newStage.open = plan?.stages[index].open;
          }
        });
        setPlan(obj);
        // handleStageActionsClose(obj.stages);
        handleDeleteActivityModalClose();

        showNotification('success', 'Successfully deleted activity!');
      })
      .catch((err) => {
        // showNotification('error', err?.response?.data?.message)
      });
  };

  const handleDuplicateStage = (index) => {
    if (plan?.stages?.length >= 8) {
      showNotification(
        'error',
        'You have created the maximum number of stages!'
      );
    } else {
      const allStages = [...plan.stages];
      const newStage = allStages[index];
      newStage.actionsEl = null;
      console.log('NEW STAGE', newStage);
      allStages.splice(index, 0, newStage);
      setUnsavedChanges(true);
      setPlan({ ...plan, stages: allStages });
      handleStageActionsClose(allStages);
    }
  };

  const handleDeleteStage = () => {
    const dataParams = {
      _id: planId,
      stageIndex: selectedStage,
    };

    if (planSharing) dataParams.token = planSharing;

    httpClient
      .delete('api/plan/delete-stage', {
        data: dataParams,
      })
      .then((response) => {
        const obj = response.data;
        obj.vendorId = plan.vendorId;
        obj.vendorRepresentativeId = plan.vendorRepresentative;
        obj.vendor = plan.vendor || '';
        obj.vendorRepresentative = plan.vendorRepresentative;

        setPlan(obj);
        handleStageActionsClose(obj.stages);
        handleDeleteStageModalClose(true);

        showNotification('success', 'Successfully deleted stage!');
      })
      .catch((err) => {
        // showNotification('error', err?.response?.data?.message)
      });
  };

  const handleAddTeamMember = (stageIndex, activityIndex, teamName) => {
    const allStages = [...plan.stages];
    if (allStages[stageIndex].activities[activityIndex][teamName].length >= 1) {
      handleTeamMembersModalOpen(stageIndex, activityIndex, teamName);
    }
    allStages[stageIndex].activities[activityIndex][teamName].push({
      name: '',
      email: '',
      title: '',
    });
    setPlan({ ...plan, stages: allStages });
  };

  const handleAddNewSubActivity = (stageIndex, activityIndex) => {
    const allStages = [...plan.stages];
    const subActivity =
      allStages[stageIndex].activities[activityIndex].collapseSubActivity;

    if (!subActivity)
      allStages[stageIndex].activities[
        activityIndex
      ].collapseSubActivity = true;

    allStages[stageIndex].activities[activityIndex].subActivities.push({
      name: '',
      status: '',
      targetDate: '',
      notes: '',
    });
    setUnsavedChanges(true);
    setPlan({ ...plan, stages: allStages });
  };

  const handleRemoveSubActivity = (
    stageIndex,
    activityIndex,
    subActivityIndex
  ) => {
    const dataParams = {
      _id: planId,
      stageIndex,
      activityIndex,
      subActivityIndex,
    };
    if (planSharing) dataParams.token = planSharing;

    httpClient
      .delete('api/plan/delete-stage', {
        data: dataParams,
      })
      .then((response) => {
        const obj = response.data;
        obj.vendorId = plan.vendorId;
        obj.vendorRepresentativeId = plan.vendorRepresentative;
        obj.vendor = plan.vendor || '';
        obj.vendorRepresentative = plan.vendorRepresentative;

        obj?.stages?.forEach((newStage, index) => {
          if (plan?.stages && plan?.stages[index]) {
            newStage.open = plan?.stages[index].open;
          }
        });

        setPlan(obj);

        showNotification('success', 'Successfully deleted sub activity!');
      })
      .catch((err) => {
        // showNotification('error', err?.response?.data?.message)
      });
  };

  const handleExpandSubActivities = (stageIndex, activityIndex) => {
    const allStages = [...plan.stages];
    allStages[stageIndex].activities[activityIndex].collapseSubActivity =
      !allStages[stageIndex].activities[activityIndex].collapseSubActivity;
    setPlan({ ...plan, stages: allStages });
  };

  const handleDeleteStageModalOpen = (stageIndex) => {
    setSelectedStage(stageIndex);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteStageModalClose = (isDeleted) => {
    setSelectedStage('');
    setIsDeleteModalOpen(false);
    if (!isBoolean(isDeleted)) {
      const allStages = [...plan.stages];
      const index = allStages.findIndex((s) => s.actionsEl !== null);
      if (index < 0) return;
      allStages[index].actionsEl = null;
      setPlan({ ...plan, stages: allStages });
    }
  };

  const handleDeleteActivityModalOpen = (activityIndex, stageIndex) => {
    setSelectedActivity({ stageIndex, activityIndex });
    setIsDeleteActivityModalOpen(true);
  };

  const handleEditStageTitleModal = (stageIndex, stage) => {
    console.log('STAGE INDEX', stageIndex);
    if (stage) {
      setStageTitle(stage.name);
      setOpenedStage({ index: stageIndex, name: stage });
      setCloseModal(true);
    } else {
      setCloseModal(true);
    }
  };

  const handleDeleteActivityModalClose = () => {
    setSelectedActivity({ stageIndex: '', activityIndex: '' });
    setIsDeleteActivityModalOpen(false);
  };

  const handleRemoveTeamMember = (
    stageIndex,
    activityIndex,
    memberIndex,
    teamName
  ) => {
    const allStages = [...plan.stages];
    allStages[stageIndex].activities[activityIndex][teamName].splice(
      memberIndex,
      1
    );
    setPlan({ ...plan, stages: allStages });
    setUpdateMembers(true);
  };

  const handleTeamMembersModalOpen = (stageIndex, activityIndex, teamName) => {
    setTeamMembersModal(true);
    setSelectedActivity({ stageIndex, activityIndex });
    setSelectedTeam(teamName);
  };
  const handleTeamMembersModalClose = () => {
    setTeamMembersModal(false);
    setSelectedActivity({ stageIndex: '', activityIndex: '' });
    setSelectedTeam('');
    setUpdateMembers(true);
  };

  const handlePlanDetailsExpansion = () =>
    setExpandPlanDetails((prev) => !prev);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const stages = [...plan.stages];
    const items = reorderOnDrag(
      stages,
      result.source.index,
      result.destination.index
    );
    setUnsavedChanges(true);

    setPlan({ ...plan, stages: items });
  };

  const onActivityDragEnd = (result, stageIndex) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const allStages = [...plan.stages];
    const items = reorderOnDrag(
      allStages[stageIndex].activities,
      result.source.index,
      result.destination.index
    );

    allStages[stageIndex].activities = [...items];
    setUnsavedChanges(true);
    setPlan({ ...plan, stages: allStages });
  };

  const onSubActivityDragEnd = (result, stageIndex, activityIndex) => {
    if (!result.destination) {
      return;
    }

    const allStages = plan.stages;
    const items = reorderOnDrag(
      allStages[stageIndex].activities[activityIndex].subActivities,
      result.source.index,
      result.destination.index
    );

    allStages[stageIndex].activities[activityIndex].subActivities = [...items];
    setUnsavedChanges(true);

    setPlan({ ...plan, stages: allStages });
  };

  const editTitle = () => {
    const newStage = plan;
    newStage?.stages?.map((el, index) => {
      if (el.name === openedStage.name && index === openedStage.index) {
        el.name = stageTitle;
        httpClient
          .patch('api/plan/edit-title', {
            id: plan?._id ? plan._id : '',
            stageIndex: index,
            newName: stageTitle,
          })
          .then(() => {
            showNotification('success', 'Successfully edited stage title!');
          })
          .catch((err) => {
            // showNotification('error', err?.response?.data?.message)
          });
      }
    });
    setUnsavedChanges(true);

    setPlan({ ...newStage });
  };

  const createStage = () => {
    const newStage = plan;
    newStage.stages.push({
      name: stageTitle,
      open: false,
      activities: [
        {
          name: '',
          description: '',
          duration: '',
          tag: '',
          status: '',
          notes: '',
          customerTeam: [],
          vendorTeam: [],
          targetDate: '',
          collapseSubActivity: false,
          completionDate: '',
          subActivities: [],
        },
      ],
      actionsEl: null,
    });
    setUnsavedChanges(true);

    setPlan({ ...newStage });
  };

  const handleCreateNewStage = () => {
    if (plan?.stages?.length >= 8) {
      showNotification(
        'error',
        'You have created the maximum number of stages!'
      );
    } else {
      setOpenCreateNewStageModal(true);
    }
  };

  const handleDeletePlan = () => {
    httpClient
      .delete('api/plan/delete', {
        data: {
          id: planId,
        },
      })
      .then(() => {
        navigate('/dashboard');
        showNotification('success', 'Plan successfully deleted!');
        setDeletePlanModal(false);
      })
      .catch((err) => {
        // showNotification('error', err?.response?.data?.message)
      });
  };

  const handleArchivePlanModalClose = () => {
    setShowArchivePlanModal(false);
    setSelectedArchiveOption('');
    if (selectedArchiveOption === 'Other') setArchiveReason('');
  };

  const handleArchiveOptionChange = ({ target }) => {
    setSelectedArchiveOption(target.value);
    if (selectedArchiveOption !== 'Other' && target.value !== 'Other')
      setArchiveReason(target.value);
    else setArchiveReason('');
  };

  const handleArchiveReasonChange = ({ target }) => {
    setArchiveReason(target.value);
  };

  const archivePlan = () => {
    httpClient
      .patch('api/plan/archive', {
        id: planId,
        archived: true,
        archiveReason: archiveReason,
      })
      .then(() => {
        showNotification('success', 'Plan successfully archived!');
        setShowArchivePlanModal(false);
      })
      .catch((err) => showNotification('error', err?.response?.data?.message));
  };

  const handleSaveMembers = () => {
    setUpdateMembers(true);
    updatePlan(plan);
    let shareList = [];
    if (state?.users?.shareList?.length > 0) {
      shareList = editShareList(state.users.shareList, plan);
    } else {
      shareList = plan.shareList;
    }
    dispatch({
      type: 'SET_USERS',
      payload: {
        shareList,
      },
    });
    handleTeamMembersModalClose();
  };

  const handleCancelTeamModal = () => {
    setUpdateMembers(true);
    setTeamMembersModal(false);
  };

  const handleCloseSharePlanModal = () => {
    setShowSharePlanModal(false);
  };

  const editPlan = () => {
    plan.value.amount = getNumericValue(plan.value?.amount);
    updatePlan(plan);
    showNotification('success', 'Plan changes saved!', 'dark', 'bottom-right');
    setCustomer(plan.mainPointCustomer);
    if (plan?._id) {
      if (customerImage) {
        if (plan?.hasAvatar) return;
        else {
          const newFile = new FormData();
          if (customerImageDetails) {
            const newName = new File([customerImageDetails], 'customerimage2', {
              type: customerImageDetails.type,
            });
            newFile.append('image', newName);
            newFile.append('plan_id', plan._id);
          }

          httpClient
            .post('api/plan/add-avatar', newFile)
            .then(() => {
              setUpdate(!update);
            })
            .catch((err) => {
              // showNotification('error', err?.response?.data?.message)
            });
        }
      } else {
        httpClient
          .delete('api/plan/remove-plan-avatar', {
            data: {
              _id: plan._id,
            },
          })
          .then(() => {
            setUpdate(!update);
          })
          .catch((err) => {
            // showNotification('error', err?.response?.data?.message)
          });
      }
    }
  };

  const cancelEdit = () => {
    setShowEditPlan(false);
    setPlan(initialPlan);
  };

  const handleSelectTemplate = async (templateStage) => {
    const newObj = { ...plan };
    newObj.vendor = state?.data?.user?.company;
    newObj.vendorRepresentative = newObj.vendorRepresentativeId;
    newObj.stages = templateStage;
    newObj.userId = newObj.userId?._id ? newObj.userId._id : newObj.userId;
    delete newObj.vendorId;
    delete newObj.vendorRepresentativeId;
    httpClient
      .patch('api/plan/update', newObj)
      .then((response) => {
        const obj = response.data;
        obj.vendor = company?.name;
        obj.vendorRepresentative =
          response.data.vendorRepresentative === state?.data?.user?._id
            ? state?.data?.user?.firstName + ' ' + state?.data?.user?.lastName
            : '';
        setPlan(obj);
        setInitialPlan(obj);
        showNotification(
          'success',
          'Created a new plan from template',
          'dark',
          'bottom-right'
        );
        setOpenSelectTemplate(false);
      })
      .catch((err) => showNotification('error', err?.response?.data?.message));
  };

  const getColor = (length) => {
    const colorIndex = length % colorsForTeam.length;
    const color = colorsForTeam[colorIndex];
    return color;
  };

  const addUserToTeam = (
    stageIndex,
    activityIndex,
    team,
    activity,
    list,
    vendorTeam
  ) => {
    setSelectedActivity({ stageIndex, activityIndex });
    setSelectedTeam(team);
    const allStages = [...plan.stages];

    const filteredList = allStages[stageIndex].activities[activityIndex][
      team
    ].filter((item) => item.name !== '');
    list.map((item, index) => {
      if (item && item.jobTitle === 'Job title' && item.fullName) {
        showNotification('error', `${item.fullName} job title is empty`);
      }
      if (item.fullName && item.jobTitle && item.jobTitle !== 'Job title') {
        filteredList.push({
          name: item.fullName,
          title: item.jobTitle,
          email: '',
          backgroundColor: getColor(filteredList.length),
        });
      }
    });
    const newList = [];
    list.map((el) => {
      if (!checkDoesExist(plan.shareList, el)) {
        delete el.label;
        delete el.value;
        el.title = el.jobTitle;
        el.email = '';
        delete el.jobTitle;
        el.shared = false;
        // el.color = randomColor();
        const colorIndex = plan?.shareList?.length % colorsForShareList.length;
        const colorObj = colorsForShareList[colorIndex];
        el.color = colorObj?.color;
        el.backgroundColor = colorObj?.backgroundColor;
        el.company =
          el.company === plan.vendorId ? vendorTeam.name : el.company;

        newList.push(el);
      }
    });
    allStages[stageIndex].activities[activityIndex][team] = filteredList;
    const updatedShareList = [...plan.shareList, ...newList];

    const getUsers = getShareListForPlan(plan);

    dispatch({
      type: 'SET_SUGGESTIONS',
      payload: {
        planId: planId,
        customers: getUsers?.customers || [],
        vendors: getUsers?.vendors || [],
      },
    });
    setUnsavedChanges(true);
    setPlan({ ...plan, shareList: updatedShareList, stages: allStages });
  };
  const handleRemoveUserFromShareList = (
    stageIndex,
    activityIndex,
    team,
    activity,
    toRemove
  ) => {
    const allStages = [...plan.stages];
    let filteredArray = allStages[stageIndex].activities[activityIndex][
      team
    ].filter((el) => el.name !== toRemove.name);
    allStages[stageIndex].activities[activityIndex][team] = filteredArray;
    // setUnsavedChanges(true);

    setPlan({ ...plan, stages: allStages });

    const userForDelete = {
      _id: planId,
      stageIndex,
      activityIndex,
      userType: team,
      userId: toRemove?._id || '',
    };
    if (planSharing) userForDelete.token = planSharing;
    httpClient
      .delete('api/plan/team', {
        data: userForDelete,
      })
      .then(() => {})
      .catch((err) => {
        showNotification('info', console.log('err', err));
      });
  };

  const handlePostSale = (stageIndex, stage) => {
    const allStages = [...plan.stages];
    plan.stages[stageIndex].postSale = plan.stages[stageIndex].postSale
      ? !plan.stages[stageIndex].postSale
      : true;

    setUnsavedChanges(true);
    setPlan({ ...plan, stages: allStages });
  };

  return (
    <Grid
      sx={{
        ...styles.main,
        paddingTop: state?.data?.user?._id ? '0' : '20px',
        justifyContent: state?.data?.user?._id ? 'flex-start' : 'center',
      }}
    >
      {state?.data?.user?._id && !isSharing && !isSharingAndEdit && (
        <Grid
          item
          xs={1}
          sx={{
            width: '96px',
            maxWidth: '96px !important',
            borderRight: '1px solid #DBDFDF',
          }}
        >
          <DashboardSidebar />
        </Grid>
      )}

      {loading ? (
        <Box sx={styles.loading}>
          <CircularProgress
            sx={{ width: '70px !important', height: '70px !important' }}
          />
        </Box>
      ) : (
        <Grid
          item
          xs={11}
          sx={{
            padding: '0 30px',
            margin: '0 auto',
            // borderLeft: '1px solid #DBDFDF',
          }}
        >
          <Box sx={{ marginTop: '15px' }}>
            <DashboardHeader
              setIsSharing={setIsSharing}
              isSharing={isSharing}
              title={plan?.name ? plan.name : ''}
              sharedUser={sharedUser}
              planId={planId}
              planSharing={planSharing}
              listOfPlans={false}
            />
            {!isSharing && !isSharingAndEdit && (
              <MenuNavNew
                isConnected={isConnected}
                shared={isSharing}
                setIsSharingAndEdit={setIsSharingAndEdit}
                plan={plan}
                handleEdit={() => setShowEditPlan(true)}
                handleArchive={() => setShowArchivePlanModal(true)}
                handleDelete={() => setDeletePlanModal(true)}
                openPreviewPlan={() => setIsSharing(true)}
                openShareModal={() => setShowSharePlanModal(true)}
              />
            )}
            {isSharing ? (
              <MenuNavNew
                isConnected={isConnected}
                shared={isSharing}
                setIsSharingAndEdit={setIsSharingAndEdit}
                plan={plan}
                handleEdit={() => setShowEditPlan(true)}
                handleArchive={() => setShowArchivePlanModal(true)}
                handleDelete={() => setDeletePlanModal(true)}
                openPreviewPlan={() => setIsSharing(true)}
                openShareModal={() => setShowSharePlanModal(true)}
                showClosePreview={true}
                closePreviewPlan={() => setIsSharing(false)}
              />
            ) : (
              ''
            )}
            <Participants
              company={company}
              customerPhoto={customerImage}
              plan={plan}
              companyPhoto={companyPhoto}
              vendor={vendor}
              customer={customer}
            />
            <PlanTabs tab={tab} handleChange={handleTabChange} />
            {activeTab === 'map' ? (
              plan?.stages?.length > 1 ? (
                <HorizontalLabelPositionBelowStepper
                  key={plan?.stages}
                  steps={plan?.stages ? plan.stages : []}
                  isVendor={isVendor}
                />
              ) : (
                <Box sx={styles.emptyBar}>
                  Add at least two stages to view progress overview
                </Box>
              )
            ) : null}
            {activeTab === 'map' && plan?.stages?.length === 0 && (
              <Box sx={styles.noStage}>
                <Box sx={styles.noStageIcon}>
                  <NoStageIcon />
                </Box>
                <Box sx={styles.noStageText}>
                  <Typography sx={styles.value}>
                    You don't have any sales stages
                  </Typography>
                  <Typography sx={styles.label}>
                    Add sales stages below or create a plan from one of your
                    templates
                  </Typography>
                </Box>
                <DynamicButton
                  type="contained"
                  onClick={() => setOpenSelectTemplate(true)}
                >
                  Create from template
                </DynamicButton>
                <DynamicButton type="text" onClick={handleCreateNewStage}>
                  Add Stage
                </DynamicButton>
              </Box>
            )}
            {activeTab === PLAN_FIELDS.map && (
              <MutualActionPlan
                data={plan}
                setData={setPlan}
                durationOptions={durationOptions}
                expandPlanDetails={expandPlanDetails}
                handleChange={handleChange}
                handleExpand={handleExpand}
                onDragEnd={onDragEnd}
                onActivityDragEnd={onActivityDragEnd}
                onSubActivityDragEnd={onSubActivityDragEnd}
                handleAddNewActivity={handleAddNewActivity}
                handleRemoveActivity={handleDeleteActivityModalOpen}
                handleEditStageTitle={handleEditStageTitleModal}
                handleDeleteStage={handleDeleteStageModalOpen}
                handleDuplicateStage={handleDuplicateStage}
                handleAddTeamMember={handleAddTeamMember}
                handleStageActionsOpen={handleStageActionsOpen}
                handleStageActionsClose={handleStageActionsClose}
                handleActivityFieldsChange={handleActivityFieldsChange}
                handleSubActivityChange={handleSubActivityChange}
                handleAddNewSubActivity={handleAddNewSubActivity}
                handleRemoveSubActivity={handleRemoveSubActivity}
                handleExpandSubActivities={handleExpandSubActivities}
                handleRemoveTeamMember={handleRemoveTeamMember}
                handleTeamMembersModalOpen={handleTeamMembersModalOpen}
                handlePlanDetailsExpansion={handlePlanDetailsExpansion}
                handleCreateNewStage={handleCreateNewStage}
                customerImage={customerImage}
                setCustomerImage={setCustomerImage}
                // userExist={userExist}
                addUserToTeam={addUserToTeam}
                handleServiceRemove={handleRemoveUserFromShareList}
                handleClearInput={handleClearInput}
                isSharing={isSharing}
                vendorCompany={company}
                handlePostSale={handlePostSale}
                isVendor={isVendor}
              />
            )}
            {activeTab === PLAN_FIELDS.engangement && (
              <Engangement
                planId={planId}
                vendor={vendor}
                plan={plan}
                openShareModal={() => setShowSharePlanModal(true)}
              />
            )}
            {activeTab === PLAN_FIELDS.access && (
              <PlanAccess
                plan={plan}
                setPlan={setPlan}
                planId={planId}
                company={company}
                isSharing={isSharing}
              />
            )}
          </Box>

          <CreatePlanStep1
            data={plan}
            setData={setPlan}
            open={showEditPlan}
            handleClose={() => setShowEditPlan(false)}
            customerPhoto={customerImage}
            setCustomerPhoto={setCustomerImage}
            customerPhotoDetails={customerImageDetails}
            setCustomerPhotoDetails={setCustomerImageDetails}
            createFromTemplate={false}
            edit={true}
            editPlan={editPlan}
            cancelEdit={cancelEdit}
            currency={defCurrency}
            original={original}
            setOriginal={setOriginal}
          />
          <SelectTemplate
            open={openSelectTemplate}
            handleClose={() => setOpenSelectTemplate(false)}
            user={state?.data?.user}
            handleSelectTemplate={(e) => getTemplate(e, true)}
            fromTemplate
          />
          <DeleteModal
            heading="Delete Stage"
            submitBtn="Delete"
            text={`Are you sure you want to delete ${plan?.stages[selectedStage]?.name}?`}
            open={isDeleteModalOpen}
            handleClose={handleDeleteStageModalClose}
            handleSubmit={handleDeleteStage}
          />

          <DeleteModal
            heading="Delete Activity"
            submitBtn="Delete"
            text="Are you sure you want to remove this activity and its sub activities?"
            open={isDeleteActivityModalOpen}
            handleClose={handleDeleteActivityModalClose}
            handleSubmit={handleRemoveActivity}
          />

          {plan && plan.stages && (
            <TeamMembersModal
              plan={plan}
              open={teamMembersModal}
              selectedTeam={selectedTeam}
              stageIndex={selectedActivity.stageIndex}
              activityIndex={selectedActivity.activityIndex}
              handleAddTeamMember={handleAddTeamMember}
              handleRemoveTeamMember={handleRemoveTeamMember}
              handleChange={handleActivityFieldsChange}
              handleClose={handleTeamMembersModalClose}
              handleSubmit={handleSaveMembers}
              handleCancel={handleCancelTeamModal}
            />
          )}
          {closeModal && (
            <EditStageTitleModal
              stageTitle={openedStage ? openedStage.name : stageTitle}
              handleChange={handleStageTitle}
              handleClose={handleCloseStage}
              editStageTitle={editTitle}
            />
          )}
          {openCreateNewStageModal && (
            <CreateNewStageModal
              stageTitle={stageTitle}
              handleChange={handleStageTitle}
              handleClose={() => setOpenCreateNewStageModal(false)}
              createStage={createStage}
              handleEnter={() => {
                setOpenCreateNewStageModal(false);
                createStage();
              }}
            />
          )}
          {deletePlanModal && (
            <DeletePlanModal
              plan={true}
              open={deletePlanModal}
              handleClose={() => setDeletePlanModal(false)}
              handleDelete={handleDeletePlan}
            />
          )}
          <ArchivePlanModal
            open={showArchivePlanModal}
            archiveReason={archiveReason}
            selectedOption={selectedArchiveOption}
            handleChange={handleArchiveOptionChange}
            handleReasonChange={handleArchiveReasonChange}
            handleClose={handleArchivePlanModalClose}
            archivePlan={archivePlan}
          />
          <SharePlanModal
            plan={plan}
            setPlan={setPlan}
            open={showSharePlanModal}
            handleClose={handleCloseSharePlanModal}
            type="plan"
            company={company}
          />
          <ActivityDurationModal
            time={activityDuration.time}
            duration={activityDuration.duration}
            open={activityDurationModal}
            selectedActivity={selectedActivity}
            handleChange={handleDurationChange}
            handleClose={handleDurationModalClose}
            handleSubmit={handleDurationSubmit}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default withDashboardHeader(Plan);

const styles = {
  main: {
    backgroundColor: `#F6F5F4 !important`,
    minHeight: '100vh !important',
    display: 'flex',
    // maxWidth: '100%',
    width: '100%',
  },
  container: { mt: '40px' },
  emptyBar: {
    width: '100%',
    borderRadius: '8px',
    gap: '8px',
    backgroundColor: 'rgba(242, 240, 238, 1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '88px',
    fontFamily: 'InterMedium',
    fontSize: '14px',
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  noStage: {
    width: '100%',
    height: 'auto',
    padding: '48px 0px 48px 0px',
    borderRadius: '8px',
    gap: '24px',
    backgroundColor: white,
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2)',
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noStageIcon: {
    width: '64px',
    height: '64px',
    borderRadius: '10px',
    backgroundColor: 'rgba(242, 240, 238, 1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noStageText: {},
  value: {
    fontFamily: 'InterSemiBold',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.88)',
    textAlign: 'center',
  },
  label: {
    fontFamily: 'InterRegular',
    fontSize: '14px',
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.7)',
    textAlign: 'center',
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: ' 100%',
    height: '100vh',
  },
};

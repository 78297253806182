import React from 'react';
import Button from '@material-ui/core/Button';
import { primary, warning, white } from '../../constants/colors';
import './styles.css';

const DynamicButton = ({
  onClick,
  style,
  children,
  startIcon,
  endIcon,
  type = 'contained',
  disabled,
  component = 'button',
  ref,
  borderDisabled = false,
}) => {
  const defaultStylesContained = {
    backgroundColor: primary,
    color: white,
    fontStyle: 'normal',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 20px',
    gap: '8px',
    height: '40px',
    fontFamily: 'InterSemiBold',
    textTransform: 'unset',
    fontSize: '16px',
    boxShadow: 'none',
    lineHeight: '24px',
  };

  const defaultStylesContainedCenter = {
    ...defaultStylesContained,
    margin: 'auto',
  };

  const defaultStylesOutlined = {
    // backgroundColor: white,
    color: primary,
    fontStyle: 'normal',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 20px',
    gap: '8px',
    height: '40px',
    fontFamily: borderDisabled ? 'InterMedium' : 'InterSemiBold',
    textTransform: 'unset',
    border: `1px solid ${primary}`,
    fontSize: borderDisabled ? '14px' : '16px',
    boxShadow: 'none',
    lineHeight: borderDisabled ? '20px' : '24px',
    letterSpacing: '0',
  };

  const defaultStylesText = {
    // backgroundColor: white,
    color: primary,
    fontStyle: 'normal',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 20px',
    gap: '8px',
    height: '40px',
    fontFamily: 'InterSemiBold',
    textTransform: 'unset',
    border: `none`,
    fontSize: '16px',
    boxShadow: 'none',
    lineHeight: '24px',
    letterSpacing: '0',
  };

  const defaultStylesContainedRemove = {
    backgroundColor: warning,
    color: white,
    fontStyle: 'normal',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 20px',
    gap: '8px',
    height: '40px',
    fontFamily: 'InterSemiBold',
    textTransform: 'unset',
    fontSize: '16px',
    boxShadow: 'none',
    lineHeight: '24px',
    letterSpacing: '0',
  };

  let mergedStyles;
  if (type === 'contained')
    mergedStyles = { ...defaultStylesContained, ...style };
  else if (type === 'containedCenter')
    mergedStyles = { ...defaultStylesContainedCenter, ...style };
  else if (type === 'outlined' || type === 'profile')
    mergedStyles = { ...defaultStylesOutlined, ...style };
  else if (type === 'remove')
    mergedStyles = { ...defaultStylesContainedRemove, ...style };
  else mergedStyles = { ...defaultStylesText, ...style };

  return (
    <Button
      ref={ref}
      component={component}
      variant={type}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      style={mergedStyles}
      className={
        type === 'contained'
          ? 'button-contained'
          : type === 'outlined'
          ? `button-outlined ${borderDisabled && 'outlined-activity'}`
          : type === 'profile'
          ? 'button-profile'
          : type === 'remove'
          ? 'button-remove'
          : 'button-text'
      }
      disableRipple
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export default DynamicButton;

import {
  Avatar,
  Box,
  Dialog,
  Divider,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  alabaster,
  black,
  gallery,
  lightGray,
  primary,
} from '../constants/colors';
import { toast } from 'react-toastify';
import { validateEmail } from '../utils/utils';
import { store } from '../Store';
import httpClient from '../config/http.service';
import { CompanyDetailsModal } from '../components/Settings/CompanyDetailsModal';
import UpdateNameModal from '../components/Settings/UpdateNameModal';
import EmailSentModal from '../components/Settings/EmailSentModal';
import { showNotification } from '../components/common/Toast';
import DynamicButton from '../components/new/Button';
import ChangePasswordModal from '../components/Settings/ChangePasswordModal';
import { ReactComponent as HighlightOffOutlinedIcon } from '../assets/imgs/close.svg';
import InputWithLabel from '../components/common/InputWithLabel';
import { UpdateUserEmail } from '../components/Settings/UpdateEmail';
import { ReactComponent as AddPhoto } from '../assets/imgs/add_photo_alternate.svg';
import { getOrientation } from 'get-orientation/browser';
import { getRotatedImage } from './canvasUtils';
import { ResizeImageSettings } from './ResizeImageSettings';
import { UpdateUserJobTitle } from '../components/Settings/UpdateJobTitle';

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export const ProfileSettingsModal = ({ open, handleClose }) => {
  const { state, dispatch } = useContext(store);
  const [user, setUser] = useState();
  const [company, setCompany] = useState(null);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showCompanyDetails, setShowCompanyDetails] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showVerificationEmail, setShowVerificationEmail] = useState(false);
  const [showUpdateName, setShowUpdateName] = useState(false);
  const [showUpdateEmail, setShowUpdateEmail] = useState(false);
  const [showUpdateJobTitle, setShowUpdateJobTitle] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoDetails, setProfilePhotoDetails] = useState(null);
  const [companyPhoto, setCompanyPhoto] = useState(null);
  const [companyPhotoDetails, setCompanyPhotoDetails] = useState(null);
  const [imageSrcCompany, setImageSrcCompany] = useState(null);
  const [initialEmail, setInitialEmail] = useState('');
  const [editUrl, setEditUrl] = useState(true);

  useEffect(() => {
    if (state?.data?.user) {
      setUser(state?.data?.user);
      setInitialEmail(state?.data?.user?.email);
      if (state.data.user.hasAvatar) getUserPhoto(state.data.user._id);
    }
  }, [state, open]);

  useEffect(() => {
    if (user && user.company) {
      getCurrencies();
      getCompany(user.company);
    }
  }, [user, open]);

  const getCurrencies = () => {
    httpClient
      .get('api/currency/all')
      .then((res) => {
        setCurrencies(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.code !== 400)
          toast.info(err?.response?.data?.message);
      });
  };

  const getCompany = async (companyId) => {
    if (companyId) {
      httpClient
        .get(`api/company/get`, { params: { _id: companyId } })
        .then((res) => {
          setCompany(res.data);
          if (res.data?.hasAvatar) getCompanyLogo();
        })
        .catch((err) =>
          showNotification('error', err?.response?.data?.message)
        );
    }
  };

  const getUserPhoto = async (userId) => {
    httpClient.get(`api/file/get/${userId}`).then((res) => {
      const imageData = new Uint8Array(res.data);
      const blob = new Blob([imageData], { type: 'image/jpeg' });
      const imageUrl = URL.createObjectURL(blob);
      setImageSrc(imageUrl);
      setProfilePhoto(imageUrl);
    });
    //   .catch((err) => {
    //     if (err?.response?.data?.code !== 401)
    //       showNotification('error', err?.response?.data?.message);
    //   });
  };

  const getCompanyLogo = async () => {
    httpClient.get(`api/file/get/${user.company}`).then((res) => {
      const imageData = new Uint8Array(res.data);
      const blob = new Blob([imageData], { type: 'image/jpeg' });
      const imageUrl = URL.createObjectURL(blob);
      setImageSrcCompany(imageUrl);
      setCompanyPhoto(imageUrl);
    });
    //   .catch((err) => {
    //     if (err?.response?.data?.code !== 401)
    //       showNotification('error', err?.response?.data?.message);
    //   });
  };

  const updateName = (type = '') => {
    const objToUpdate = {
      firstName: user.firstName,
      lastName: user.lastName,
      linkedin: user.linkedin,
      jobTitle: user.jobTitle,
    };
    if (initialEmail !== user.email && type === 'email') objToUpdate.email = user.email;
    httpClient
      .patch(`api/user/update`, objToUpdate)
      .then((res) => {
        setUser(res.data);
        dispatch({
          type: 'SET_DATA',
          payload: {
            isAuthenticated: true,
            user: res.data,
            accessToken: state.data.accessToken,
            userImage: state.data.userImage,
          },
        });
        showNotification('success', 'Successfully updated details!');
        const rememberMe = localStorage.getItem('rememberMe');
        if (rememberMe == 'true') {
          localStorage.setItem('user', JSON.stringify(res.data));
        } else {
          sessionStorage.setItem('user', JSON.stringify(res.data));
        }

        setShowUpdateName(false);
        setShowUpdateJobTitle(false);
      })
      .catch((err) => {
        showNotification('error', err?.response?.data?.message);
      });
  };

  const updatePassword = (e) => {
    e.preventDefault();

    if (oldPassword === '') {
      toast.error('Please enter your old password.');
      return;
    }
    if (newPassword === '') {
      toast.error('Please enter a new password.');
      return;
    }
    if (newPassword !== confirmPassword) {
      showNotification(
        'info',
        'New password and confirm password do not match.'
      );
      return;
    }
    httpClient
      .patch(`api/user/change-password`, {
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      })
      .then((res) => {
        // Perform your password change logic here
        showNotification('success', 'Successfully updated password!');
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
      })
      .catch((err) => showNotification('error', err?.response?.data?.message));
  };

  const updateCompany = () => {
    if (validateEmail(company.email)) {
      httpClient
        .post(`api/company/send-update`, {
          name: company.name,
          email: company.email,
          _id: user.company,
        })
        .then(() => {
          showNotification(
            'success',
            'Successfully sent verify email for company details update.'
          );
          setShowCompanyDetails(false);
        })
        .catch((err) =>
          showNotification('error', err?.response?.data?.message)
        );
    } else {
      toast.error('Email is not valid');
    }
  };

  const updateCurrency = (currency) => {
    httpClient
      .patch(`api/user/update-currency`, {
        defaultCurrency: currency,
        _id: user._id,
      })
      .then((response) => {
        const rememberMe = localStorage.getItem('rememberMe');
        if (rememberMe == 'true') {
          localStorage.setItem('user', JSON.stringify(response.data));
        } else {
          sessionStorage.setItem('user', JSON.stringify(response.data));
        }
        dispatch({
          type: 'SET_DATA',
          payload: {
            isAuthenticated: true,
            user: response.data,
            accessToken: state.data.accessToken,
            userImage: state.data.userImage,
          },
        });
        setUser(response.data);
        showNotification(
          'success',
          'Successfully updated user default currency.'
        );
      })
      .catch((err) => showNotification('error', err?.response?.data?.message));
  };

  const handleChangeName = (e, inputName) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };
  const handleChangeCompany = (e, name) => {
    if (name === 'name') setCompany({ ...company, name: e.target.value });
    else setCompany({ ...company, email: e.target.value });
  };

  const handleChangePassword = (e, name) => {
    if (name === 'old') setOldPassword(e.target.value);
    else if (name === 'new') setNewPassword(e.target.value);
    else setConfirmPassword(e.target.value);
  };

  const handleUserDetails = (e) => {
    setUser({ ...user, defaultCurrency: e.target.value });
    updateCurrency(e.target.value);
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e, type) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onFileChange(e.dataTransfer.files[0], type);
    }
  };

  const handleChangeUpload = function (e, type) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      onFileChange(e.target.files[0], type);
    }
  };
  const onButtonClick = () => {
    inputRef.current.click();
  };

  const removePhoto = (type) => {
    if (type === 'user' && user?._id) {
      httpClient
        .patch('api/user/remove-avatar', {
          _id: user._id,
        })
        .then((res) => {
          setProfilePhotoDetails(null);
          setProfilePhoto(null);
          setImageSrc(null);
          const newPlan = user;
          newPlan.hasAvatar = false;
          setUser(newPlan);
          dispatch({
            type: 'SET_DATA',
            payload: {
              isAuthenticated: true,
              user: res.data,
              accessToken: state.data.accessToken,
              userImage: null,
            },
          });
        })
        .catch((err) =>
          showNotification('error', err?.response?.data?.message)
        );
    } else if (type === 'company' && company) {
      httpClient
        .patch('api/company/remove-avatar', {
          _id: company._id,
        })
        .then(() => {
          setCompanyPhotoDetails(null);
          setCompanyPhoto(null);
          setImageSrcCompany(null);
          const newPlan = company;
          newPlan.hasAvatar = false;
          setCompany(newPlan);
          getCompany(state.data?.user.company);
        })
        .catch((err) =>
          showNotification('error', err?.response?.data?.message)
        );
    }
  };

  const onFileChange = async (fileForResize, type) => {
    if (fileForResize) {
      const file = fileForResize;
      let imageDataUrl = await readFile(file);

      try {
        // apply rotation if needed
        const orientation = await getOrientation(file);
        const rotation = ORIENTATION_TO_ANGLE[orientation];
        if (rotation) {
          imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
        }
      } catch (e) {
        console.warn('failed to detect the orientation');
      }
      if (type === 'user') {
        setImageSrc(imageDataUrl);
        setProfilePhotoDetails(fileForResize);
      } else {
        setImageSrcCompany(imageDataUrl);
        setCompanyPhotoDetails(fileForResize);
      }
    }
  };

  const cancelResizing = (type) => {
    // if (data?.hasAvatar) {
    //   // setCustomerPhoto(initialImageSrc);
    //   setOriginal(!original);
    // } else {
    //   setInitialImageSrc(null);
    //   setImageSrc(null);
    // }
    if (type === 'user') {
      if (state.data.user.hasAvatar) getUserPhoto(state.data.user._id);
      else {
        setImageSrc(null);
        setProfilePhoto(null);
      }
    } else {
      if (company?.hasAvatar && user?.company) getCompanyLogo(company._id);
      else {
        setImageSrcCompany(null);
        setCompanyPhoto(null);
      }
    }
  };

  const handleEditUrl = () => {
    if (editUrl) setEditUrl(false);
    else updateName();
  };

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: styles.paper }}>
      <Box>
        <Typography variant="h5" sx={styles.heading}>
          Account
          <HighlightOffOutlinedIcon
            style={styles.closeIcon}
            onClick={() => {
              setProfilePhoto(null);
              setCompanyPhoto(null);
              handleClose();
            }}
          />
        </Typography>
        <Divider />
        <Box sx={styles.content}>
          <Typography variant="h5" sx={styles.profileSettings}>
            Profile settings
          </Typography>
          <Box sx={styles.avatarBox}>
            <Typography sx={{ ...styles.label, marginBottom: '5px' }}>
              Profile photo
            </Typography>
            {imageSrc ? (
              profilePhoto ? (
                <Box sx={styles.hasImage}>
                  <Avatar
                    sx={{ ...styles.avatar, borderRadius: '50%' }}
                    src={profilePhoto}
                  />
                  <Box sx={styles.rightSide}>
                    <Typography variant="h5" sx={styles.subtitle}>
                      {profilePhotoDetails?.name ?? 'image.png'}
                    </Typography>
                    <Box sx={styles.btnsWrapper}>
                      <DynamicButton
                        type="outlined"
                        onClick={() => {
                          setProfilePhotoDetails(null);
                          setProfilePhoto(null);
                        }}
                      >
                        Change
                      </DynamicButton>
                      <DynamicButton
                        type="outlined"
                        onClick={() => removePhoto('user')}
                      >
                        Remove
                      </DynamicButton>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <div style={{ height: '650px' }}>
                  <ResizeImageSettings
                    imageSrc={imageSrc}
                    imageSrcCompany={imageSrcCompany}
                    cancelResizing={cancelResizing}
                    setProfilePhoto={setProfilePhoto}
                    setProfilePhotoDetails={setProfilePhotoDetails}
                    setCompanyPhoto={setCompanyPhoto}
                    setCompanyPhotoDetails={setCompanyPhotoDetails}
                    data={user}
                    typeOfImage={'user'}
                    company={company}
                    setData={setUser}
                  />
                </div>
              )
            ) : (
              <Box sx={styles.dragNdrop}>
                <form
                  id="form-file-upload"
                  onDragEnter={handleDrag}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <input
                    ref={inputRef}
                    type="file"
                    id="input-file-upload"
                    multiple={false}
                    onChange={(e) => handleChangeUpload(e, 'user')}
                  />
                  <label
                    id="label-file-upload"
                    htmlFor="input-file-upload"
                    className={dragActive ? 'drag-active' : ''}
                  >
                    <div style={styles.div}>
                      <AddPhoto />
                      <Typography variant="h5" sx={styles.drag}>
                        <span style={{ opacity: '0.6' }}>
                          Drag and drop an image, or{' '}
                        </span>
                        <span style={{ color: primary, opacity: '1' }}>
                          Browse
                        </span>
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={styles.minimum}
                        onClick={onButtonClick}
                      >
                        Minimum width 128px. Max 2MB.
                      </Typography>
                    </div>
                  </label>
                  {dragActive && (
                    <div
                      id="drag-file-element"
                      onDragEnter={handleDrag}
                      onDragLeave={handleDrag}
                      onDragOver={handleDrag}
                      onDrop={(e) => handleDrop(e, 'user')}
                    ></div>
                  )}
                </form>
              </Box>
            )}
          </Box>
          <Box sx={styles.row}>
            <Box sx={styles.details}>
              <Typography sx={styles.label}>Name </Typography>
              <Typography sx={styles.value}>
                {user?.firstName ?? 'Manuel'} {user?.lastName ?? 'Snr'}
              </Typography>
            </Box>
            <DynamicButton
              type="profile"
              component="label"
              style={styles.uploadBtn}
              onClick={() => setShowUpdateName(true)}
            >
              Change name
            </DynamicButton>
          </Box>
          <Box sx={styles.row}>
            <Box sx={styles.details}>
              <Typography sx={styles.label}>Email address </Typography>
              <Typography sx={styles.value}>{user?.email}</Typography>
            </Box>
            <DynamicButton
              type="profile"
              component="label"
              style={styles.uploadBtn}
              onClick={() => setShowUpdateEmail(true)}
            >
              Change email
            </DynamicButton>
          </Box>
          <Box sx={styles.row}>
            <Box sx={styles.details}>
              <Typography sx={styles.label}>Job title </Typography>
              <Typography sx={styles.value}>{user?.jobTitle}</Typography>
            </Box>
            <DynamicButton
              type="profile"
              component="label"
              style={styles.uploadBtn}
              onClick={() => setShowUpdateJobTitle(true)}
            >
              Change job title
            </DynamicButton>
          </Box>
          <Box sx={styles.row}>
            <Box sx={styles.details}>
              <Typography sx={styles.label}>Password </Typography>
              <Typography sx={styles.value}>
                Set a password to log in to your account
              </Typography>
            </Box>
            <DynamicButton
              type="profile"
              component="label"
              style={styles.uploadBtn}
              onClick={() => setShowChangePassword(true)}
            >
              Change password
            </DynamicButton>
          </Box>
          <Box sx={{ ...styles.row, marginBottom: '15px' }}>
            <Box sx={styles.details}>
              <Typography sx={styles.label}>Linkedin profile</Typography>
              <Typography sx={styles.value}>
                Include a link to your LinkedIn profile in a Mutual Action Plan
              </Typography>
            </Box>
            <DynamicButton
              type="profile"
              component="label"
              style={styles.uploadBtn}
              onClick={handleEditUrl}
            >
              {!editUrl ? 'Save url' : 'Edit url'}
            </DynamicButton>
            {!editUrl && (
              <DynamicButton
                type="profile"
                component="label"
                style={{ ...styles.uploadBtn, marginLeft: '0' }}
                onClick={() => setEditUrl(true)}
              >
                Cancel
              </DynamicButton>
            )}
          </Box>
          <InputWithLabel
            disabled={editUrl}
            label="URL"
            name="linkedin"
            value={user?.linkedin}
            placeholder="https://www.linkedin.com/johndoe"
            handleChange={(e) =>
              setUser({
                ...user,
                linkedin: e.target.value,
              })
            }
          />

          <Typography variant="h5" sx={styles.profileSettings}>
            Company details
          </Typography>
          <Box sx={styles.avatarBox}>
            <Typography sx={{ ...styles.label, marginBottom: '5px' }}>
              Company photo
            </Typography>
            {imageSrcCompany ? (
              companyPhoto ? (
                <Box sx={styles.hasImage}>
                  <Avatar
                    sx={{ ...styles.avatar, borderRadius: '6px' }}
                    src={companyPhoto}
                  />
                  <Box sx={styles.rightSide}>
                    <Typography variant="h5" sx={styles.subtitle}>
                      {companyPhotoDetails?.name ?? 'image.png'}
                    </Typography>
                    <Box sx={styles.btnsWrapper}>
                      <DynamicButton
                        disabled={!user?.canUpdateCompany}
                        type="outlined"
                        onClick={() => {
                          setCompanyPhotoDetails(null);
                          setCompanyPhoto(null);
                        }}
                      >
                        Change
                      </DynamicButton>
                      <DynamicButton
                        disabled={!user?.canUpdateCompany}
                        type="outlined"
                        onClick={() => removePhoto('company')}
                      >
                        Remove
                      </DynamicButton>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <div style={{ height: '650px' }}>
                  <ResizeImageSettings
                    imageSrc={imageSrc}
                    imageSrcCompany={imageSrcCompany}
                    cancelResizing={cancelResizing}
                    setProfilePhoto={setProfilePhoto}
                    setProfilePhotoDetails={setProfilePhotoDetails}
                    setCompanyPhoto={setCompanyPhoto}
                    setCompanyPhotoDetails={setCompanyPhotoDetails}
                    data={user}
                    typeOfImage={'company'}
                    company={company}
                    setCompany={setCompany}
                    setData={setUser}
                  />
                </div>
              )
            ) : (
              <Box sx={styles.dragNdrop}>
                <form
                  id="form-file-upload"
                  onDragEnter={handleDrag}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <input
                    disabled={!user?.canUpdateCompany}
                    ref={inputRef}
                    type="file"
                    id="input-file-upload"
                    multiple={false}
                    onChange={(e) => handleChangeUpload(e, 'company')}
                  />
                  <label
                    id="label-file-upload"
                    htmlFor="input-file-upload"
                    className={dragActive ? 'drag-active' : ''}
                  >
                    <div style={styles.div}>
                      <AddPhoto />
                      <Typography variant="h5" sx={styles.drag}>
                        <span style={{ opacity: '0.6' }}>
                          Drag and drop an image, or{' '}
                        </span>
                        <span style={{ color: primary, opacity: '1' }}>
                          Browse
                        </span>
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={styles.minimum}
                        onClick={onButtonClick}
                      >
                        Minimum width 128px. Max 2MB.
                      </Typography>
                    </div>
                  </label>
                  {dragActive && (
                    <div
                      id="drag-file-element"
                      onDragEnter={handleDrag}
                      onDragLeave={handleDrag}
                      onDragOver={handleDrag}
                      onDrop={(e) => handleDrop(e, 'company')}
                    ></div>
                  )}
                </form>
              </Box>
            )}
          </Box>

          <Box sx={styles.row}>
            <Box sx={styles.details}>
              <Typography sx={styles.label}>Company name </Typography>
              <Typography sx={styles.value}>{company?.name ?? ''}</Typography>
            </Box>
            <DynamicButton
              disabled={!user?.canUpdateCompany}
              type="profile"
              component="label"
              style={{
                ...(!user?.canUpdateCompany
                  ? styles.uploadBtnDisabled
                  : styles.uploadBtn),
              }}
              onClick={() => setShowCompanyDetails(true)}
            >
              Change company name
            </DynamicButton>
          </Box>
          <Box sx={styles.row}>
            <Box sx={styles.details}>
              <Typography sx={styles.label}>Currency</Typography>
              <Typography sx={styles.value}>
                Your company default operating currency
              </Typography>
            </Box>
            <Select
              // disabled={!user?.canUpdateCompany}
              value={user?.defaultCurrency || ''}
              name="defaultCurrency"
              label="Choose currency"
              placeholder="Choose currency"
              onChange={handleUserDetails}
              sx={styles.select}
              MenuProps={styles.menuProps}
              style={{
                minWidth: '70px',
                backgroundColor: 'rgb(250, 250, 250)',
              }}
            >
              {currencies?.map((currency) => (
                <MenuItem
                  key={currency.symbol}
                  value={currency.symbol}
                  sx={styles.menuItem}
                >
                  {currency.symbol}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <CompanyDetailsModal
          open={showCompanyDetails}
          handleClose={() => setShowCompanyDetails(false)}
          updateCompany={updateCompany}
          handleChange={handleChangeCompany}
          company={company}
        />
        <UpdateNameModal
          open={showUpdateName}
          handleClose={() => setShowUpdateName(false)}
          updateName={updateName}
          handleChange={handleChangeName}
          user={user}
        />
        <UpdateUserEmail
          open={showUpdateEmail}
          handleClose={() => setShowUpdateEmail(false)}
          updateEmail={() => updateName('email')}
          handleChange={handleChangeName}
          user={user}
        />
        <UpdateUserJobTitle
          open={showUpdateJobTitle}
          handleClose={() => setShowUpdateJobTitle(false)}
          updateEmail={updateName}
          handleChange={handleChangeName}
          user={user}
        />
        <EmailSentModal
          open={showVerificationEmail}
          handleClose={() => setShowVerificationEmail(false)}
          email={company?.email ? company.email : ''}
        />
        <ChangePasswordModal
          open={showChangePassword}
          handleClose={() => setShowChangePassword(false)}
          oldPassword={oldPassword}
          newPassword={newPassword}
          confirmPassword={confirmPassword}
          updatePassword={updatePassword}
          handleChange={handleChangePassword}
        />
      </Box>{' '}
    </Dialog>
  );
};

const styles = {
  paper: {
    borderRadius: '8px',
    width: '711px',
    maxWidth: '711px',
    height: '90vh',
    paddingBottom: '30px',
  },
  heading: {
    color: 'rgba(0, 0, 0, 0.88)',
    fontFamily: 'InterBold',
    fontSize: '20px',
    lineHeight: '28px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 24px',
    cursor: 'pointer',
  },
  profileSettings: {
    color: 'rgba(0, 0, 0, 0.88)',
    fontFamily: 'InterBold',
    fontSize: '24px',
    lineHeight: '32px',
    marginTop: '20px',
  },
  content: {
    padding: '12px 24px',
  },
  title: {
    color: black,
    fontSize: '20px',
    lineHeight: '32px',
    fontFamily: 'InterBold',
  },
  avatarBox: {
    marginTop: '26px',
  },
  companyLogo: {
    height: '64px',
    width: '64px',
    border: `1px solid ${gallery}`,
    backgroundColor: alabaster,
    color: black,
    fontFamily: 'InterBold',
    fontSize: '12px',
    textAlign: 'center',
  },
  btnInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  uploadBtn: {
    padding: '8px 16px',
    border: ` 1px solid rgba(0, 0, 0, 0.6)`,
    height: '40px',
    fontFamily: 'InterRegular',
    color: 'rgba(0, 0, 0, 0.7)',
    '& .Mui-disabled': {
      backgroundColor: 'green !important',
      color: 'red',
    },
  },
  uploadBtnDisabled: {
    padding: '8px 16px',
    border: `1px solid rgba(0, 0, 0, 0.2)`,
    height: '40px',
    fontFamily: 'InterSemiBold',
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.2)',
  },
  imgInfo: {
    fontFamily: '"InterRegular',
    fontSize: '12px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.7)',
    marginTop: '5px',
  },
  row: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  label: {
    fontFamily: 'InterBold',
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    color: black,
  },
  value: {
    fontFamily: 'InterRegular',
    fontSize: '14px',
    lineHeight: '20px',
    color: black,
  },
  select: {
    background: alabaster,
    color: lightGray,
    paddingRight: '0',
    '&.Mui-focused fieldset legend': {
      display: 'none !important',
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${gallery} !important`,
    },
    justifyContent: 'center',
    textAlign: 'Center',
    width: '80px',
    boxShadow: '0px 8px 10px 1px rgba(0, 0, 0, 0.14)',
  },
  menuProps: {
    MenuListProps: { sx: { padding: '4px 0', width: '188px' } },
  },
  menuItem: {
    fontFamily: 'InterRegular',
    fontSize: '14px',
    padding: '10px 12px !important',
    color: 'rgba(0, 0, 0, 0.88) !important',
  },
  hasImage: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '15px',
  },
  btnsWrapper: {
    display: 'flex',
    gap: '10px',
  },
  div: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px',
    padding: '24px',
  },
  drag: {
    fontFamily: 'InterMedium',
    fontSize: '14px',
    lineHeight: '20px',
    color: black,
  },
  minimum: {
    fontFamily: 'InterRegular',
    fontSize: '12px',
    lineHeight: '16px',
    color: 'rgba(0, 0, 0, 0.7)',
  },
  avatar: {
    height: '80px',
    width: '80px',
    border: 'none',
    objectFit: 'contain',
  },
};

import React, { Fragment, useEffect, useState } from 'react';
import { Box, Button, Dialog, Grid, Typography } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import InputWithLabel from '../common/InputWithLabel';
import {
  alabaster,
  black,
  lochinvar,
  mercury,
  primary,
  shark,
  warning,
  wildSand,
} from '../../constants/colors';
import { Hint } from 'react-autocomplete-hint';
import '../../index.css';
import httpClient from '../../config/http.service';
import { showNotification } from '../common/Toast';
import DynamicButton from '../new/Button';
import useDebounce from '../../utils/hooks/useDebounce';

const TeamMembersModal = ({
  open,
  plan,
  stageIndex,
  activityIndex,
  selectedTeam,
  handleAddTeamMember,
  handleRemoveTeamMember,
  handleChange,
  handleClose,
  handleSubmit,
  handleCancel,
}) => {
  const [allUsers, setAllUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const getUsers = async () => {
    await httpClient
      .get('api/user/prediction', { params: { fullName: debouncedSearchTerm } })
      .then((res) => {
        setAllUsers(res.data);
      })
      .catch((err) => {
        //   showNotification('error', err?.response?.data?.message);
      });
  };
  useEffect(() => {
    if (searchTerm !== '') getUsers();
  }, [debouncedSearchTerm]);

  const data =
    plan?.stages[stageIndex]?.activities[activityIndex][selectedTeam];
  const activity = plan?.stages[stageIndex]?.activities[activityIndex];

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: styles.paper }}>
      <Typography variant="h5" sx={styles.heading}>
        {activity?.name?.length > 0 ? `${activity.name}` : '"Activity Name"'}{' '}
        Activity
        {selectedTeam.includes('customerTeam')
          ? plan?.customerCompany?.length
            ? plan?.customerCompany
            : 'Customer'
          : plan?.vendor}{' '}
        Team
        <HighlightOffOutlinedIcon sx={styles.closeIcon} onClick={handleClose} />
      </Typography>

      <Grid container rowSpacing={2} columnSpacing={3} sx={styles.grid}>
        {data?.map((member, index) => (
          <Fragment key={index}>
            <Grid item xs={4} sx={{ alignItems: 'center' }}>
              <Hint
                options={allUsers.map((option) => option.fullName)}
                allowEnterFill
                allowTabFill
              >
                <input
                  name={selectedTeam}
                  placeholder={'Full name'}
                  style={styles.hintInput}
                  className="placeholderStyleHint"
                  value={member.name}
                  onChange={(e) => {
                    const enteredValue = e.target.value;
                    setSearchTerm(enteredValue);
                    const suggestion = allUsers.find(
                      (suggestion) =>
                        suggestion.fullName.toLocaleLowerCase() ===
                        enteredValue.toLocaleLowerCase()
                    );
                    handleChange(
                      e,
                      stageIndex,
                      activityIndex,
                      index,
                      'name',
                      suggestion?.email ?? '',
                      suggestion?.jobTitle ?? ''
                    );
                  }}
                />
              </Hint>
            </Grid>
            <Grid item xs={4}>
              <InputWithLabel
                name={selectedTeam}
                type="email"
                value={member.email}
                placeholder={'Work email address'}
                styles={styles.input}
                handleChange={(e) =>
                  handleChange(e, stageIndex, activityIndex, index, 'email')
                }
              />
            </Grid>
            <Grid item xs={4} sx={styles.titleBox}>
              <InputWithLabel
                name={selectedTeam}
                value={member.title}
                placeholder={'Job Title'}
                styles={styles.input}
                handleChange={(e) =>
                  handleChange(e, stageIndex, activityIndex, index, 'title')
                }
              />
              {data.length > 1 && (
                <RemoveCircleOutlineIcon
                  sx={styles.removeIcon}
                  onClick={() =>
                    handleRemoveTeamMember(
                      stageIndex,
                      activityIndex,
                      index,
                      selectedTeam
                    )
                  }
                />
              )}
            </Grid>
          </Fragment>
        ))}
      </Grid>

      <Typography
        variant="subtitle2"
        sx={styles.addBtn}
        onClick={() =>
          handleAddTeamMember(stageIndex, activityIndex, selectedTeam)
        }
      >
        Add
      </Typography>

      <Box sx={styles.btns}>
        <DynamicButton
          type="contained"
          sx={styles.cancel}
          onClick={handleCancel}
        >
          Close
        </DynamicButton>
        <DynamicButton
          type="contained"
          sx={styles.submit}
          onClick={handleSubmit}
        >
          Save
        </DynamicButton>
      </Box>
    </Dialog>
  );
};

export default TeamMembersModal;

const styles = {
  paper: {
    borderRadius: '8px',
    padding: '32px',
    width: '673px',
  },
  heading: {
    color: black,
    fontFamily: 'SoehneBold',
    fontSize: '20px',
    lineHeight: '32px',
    marginBottom: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeIcon: {
    color: shark,
    fontSize: '20px',
    background: wildSand,
    borderRadius: '50%',
    padding: '5px',
    cursor: 'pointer',
    ml: '20px',
  },
  grid: {
    maxHeight: '325px',
    overflowY: 'scroll',
    pr: '20px',
    '&::-webkit-scrollbar': { width: '4px' },
    '&::-webkit-scrollbar-track': {
      backgroundColor: alabaster,
      borderRadius: '2px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: primary,
      borderRadius: '2px',
    },
  },
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px',
    marginTop: '40px',
  },
  cancel: {
    color: black,
    width: '72px',
    height: '40px',
    background: mercury,
    borderRadius: '100px',
    boxShadow: 'none',
    border: `1px solid ${mercury}`,
    '&:hover': { background: mercury },
  },
  submit: {
    color: 'white',
    width: '67px',
    height: '40px',
    borderRadius: '100px',
    fontFamily: 'SoehneMedium',
    boxShadow: 'none',
    background: primary,
    '&:hover': { background: primary },
  },
  input: { height: '20px', marginBottom: '0px', fontSize: '14px' },
  addBtn: {
    color: lochinvar,
    fontSize: '12px',
    lineHeight: '16px',
    textDecoration: 'underline',
    textAlign: 'left',
    cursor: 'pointer',
    width: 'fit-content',
    mt: '12px',
  },
  removeIcon: { color: warning, fontSize: '20px', cursor: 'pointer' },
  titleBox: { display: 'flex', alignItems: 'center', gap: '13px' },
  hintInput: {
    backgroundColor: 'rgb(250, 250, 250)',
    height: '20px',
    borderRadius: '4px',
    position: 'relative',
    border: '1px solid #EEEEEE',
    fontSize: '14px',
    width: '79%',
    padding: '12px 16px',
    transition:
      'border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontFamily: 'InterMedium',
    '::placeholder': {
      fontSize: '12px',
      color: 'red',
    },
  },
};

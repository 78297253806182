import React, { useState } from 'react';
import { Grid } from '@mui/material';
import InputWithLabel from '../common/InputWithLabel';
import MultiColorSelectInput from '../common/MultiColorSelectInput';
import { planStatusOptions } from '../../utils/constants';
import { black88, white } from '../../constants/colors';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { ActivityDatePicker } from '../common/ActivityDatePicker';
import { ActivityTextarea } from '../common/ActivityTextarea';
import targetDateCheck from '../../utils/targetDateCheck';

const SubActivity = ({
  provided,
  subActivity,
  stageIndex,
  activityIndex,
  subActivityIndex,
  handleChange,
  handleRemoveSubActivity,
  isSharing,
  template = false,
  lastItem,
}) => {
  const [focused, setFocused] = useState(false);
  const [hoveringDate, setHoveringDate] = useState(false);
  return (
    <Grid
      container
      spacing={4}
      ref={provided.innerRef}
      {...provided.draggableProps}
      sx={{
        ...styles.container,
        borderBottom: lastItem && '1px solid rgba(0, 0, 0, 0.05)',
        display: 'grid',
        gridTemplateColumns: '4fr 1fr 1fr 208px',
        marginLeft: '0',
        width: '100%',
        position: 'relative',
      }}
    >
      <div
        item
        disabled={isSharing}
        style={{
          position: 'absolute',
          top: '15px',
          left: '5px',
          width: '30px',
        }}
      >
        <span {...provided.dragHandleProps} style={styles.dragIcon}>
          <DragHandleIcon />
        </span>
      </div>
      <Grid
        item
        xs
        style={{ flexGrow: 1, maxWidth: '100%', marginLeft: '10px' }}
        sx={{ ...styles.activityNameBox, paddingTop: '15px !important' }}
      >
        <InputWithLabel
          readOnly={isSharing}
          showClearIcon
          name="name"
          value={subActivity.name}
          placeholder={'Enter Sub Activity'}
          styles={
            subActivity.status === 'completed'
              ? { ...styles.inputName, ...styles.completedActivity }
              : styles.inputName
          }
          handleChange={(e) =>
            isSharing
              ? console.log('Not allowed')
              : handleChange(e, stageIndex, activityIndex, subActivityIndex)
          }
          subActivity
          characterLimit={100}
          helperText={
            subActivity?.name?.length === 100
              ? 'Max length of the subactivity name is 100'
              : ''
          }
          clearInput={(e) => {
            handleRemoveSubActivity(
              stageIndex,
              activityIndex,
              subActivityIndex
            );
          }}
        />
      </Grid>
      <Grid
        item
        // style={{ width: '160px' }}
        sx={{
          paddingLeft: '0 !important',
          marginLeft: '16px',
          paddingTop: '16px !important',
          height: '50px',
        }}
        onMouseEnter={() => setHoveringDate(true)}
        onMouseLeave={() => setHoveringDate(false)}
      >
        <ActivityDatePicker
          hoveringDate={hoveringDate}
          name="targetDate"
          value={subActivity.targetDate}
          inputStyles={{
            ...styles.targetDateInput,
            border: !template && 'none !important',
            '&:hover': {
              border: !template && '1px solid rgba(0, 0, 0, 0.45)',
              borderColor: !template && 'rgba(0,0,0,0.45',
              transition:
                !template &&
                'border-color  400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            },
          }}
          slotPropsStyles={styles.dateSlotPropsStyles}
          handleChange={(e) =>
            handleChange(e, stageIndex, activityIndex, subActivityIndex)
          }
          overdue={targetDateCheck(subActivity.targetDate) !== ''}
          readOnly={isSharing}
          disabled={template}
        />
      </Grid>
      <Grid
        item
        sx={{
          paddingLeft: '0 !important',
          marginLeft: '16px',
          paddingTop: '12px !important',
          height: '50px',
        }}
      >
        <MultiColorSelectInput
          readOnly={isSharing}
          disabled={template}
          name="status"
          value={subActivity.status}
          options={planStatusOptions}
          styles={styles.select}
          menuItemStyles={styles.menuItemStyles}
          handleChange={(e) =>
            handleChange(e, stageIndex, activityIndex, subActivityIndex)
          }
        />
      </Grid>
      <Grid
        item
        style={{ width: '208px', marginLeft: '16px' }}
        sx={{
          paddingTop: '15px !important',
          paddingLeft: '0 !important',
        }}
      >
        <ActivityTextarea
          readOnly={isSharing}
          disabled={template}
          rows="2"
          value={subActivity.notes}
          multiline
          name="notes"
          placeholder="Enter notes"
          styles={{
            ...styles.input,
            height: 'auto',
            marginTop: -4,
          }}
          // subActivity
          characterLimit={48}
          notes
          handleChange={(e) =>
            handleChange(e, stageIndex, activityIndex, subActivityIndex)
          }
          activity
          subActivity
          focused={focused}
          setFocused={setFocused}
        />
      </Grid>
    </Grid>
  );
};

export default SubActivity;

const styles = {
  container: {
    mb: '30px',
    paddingBottom: '16px',
    borderTop: '1px solid rgba(0, 0, 0, 0.05)',
  },
  dragIcon: {
    mt: '7px',
    display: 'flex',
    height: '40px',
    alignItems: 'center',
  },
  input: {
    background: white,
    marginBottom: '0px',
    height: '15px',
    fontSize: '14px',
    marginTop: '0',
  },
  inputName: {
    background: white,
    marginBottom: '0px',
    height: '15px',
    fontSize: '14px !important',
    lineHeight: '20px',
    color: black88,
    marginTop: '0',
    border: 'none',
    marginLeft: '18px',
    padding: '12px 6px',
  },
  completedActivity: {
    textDecoration: 'line-through',
  },
  select: {
    background: white,
    // marginBottom: '5px',
    height: '36px',
    fontSize: '14px',
    width: '100%',
    textAlign: 'center',
  },
  menuItemStyles: { fontSize: '14px' },
  activityNameBox: {
    display: 'flex',
    gap: '12px',
    height: 'fit-content',
    paddingLeft: '16px !important',
  },
  removeIcon: {
    cursor: 'pointer',
    width: '20px',
    height: '20px',
    marginTop: '10px',
  },
  completeIcon: {
    color: '#53AB97',
    width: '20px',
    height: '20px',
    marginTop: '10px',
  },
  // completedActivity: { textDecoration: 'line-through', color: lightGray },
  dateSlotPropsStyles: { openPickerButton: { sx: { ml: '-8px' } } },
  targetDateInput: {
    minWidth: '135px !important',
    border: 'none',
    '.MuiOutlinedInput-input': {
      padding: '6px',
      fontSize: '14px !important',
      fontFamily: 'InterRegular !important',
    },
    '&:hover': {
      // borderColor: 'rgba(0,0,0,0.45',
      // transition: 'border-color  400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  },
};

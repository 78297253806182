import React, { useEffect, useState } from 'react';
import { Box, Container, Divider, Pagination } from '@mui/material';
import { ReactComponent as OptionHor } from '../../assets/imgs/Option.svg';

import { SaveAsTemplateModal } from './SaveAsTemplateModal';
import { ArchivePlanModal } from './ArchivePlanModal';
import { DeletePlanModal } from './DeletePlanModal';
import AppTable from '../common/AppTable';
import AppMenu from '../common/AppMenu';
import { planDecorator } from '../../utils/planDecorator';
import {
  planTableHeaders,
  sharedPlanTableHeaders,
  archivedPlansHeader,
  sortDirections,
} from '../../utils/constants';
import styles from '../../styles/plans/planListing.module.css';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useNavigate } from 'react-router-dom';
import { primary, warning } from '../../constants/colors';
import { toast } from 'react-toastify';
import { SharePlanModal } from './SharePlan.';
import { PLAN_TAB } from '../../utils/enums';
import { randomColor, returnTemplateObj } from '../../utils/utils';
import httpClient from '../../config/http.service';
import { showNotification } from '../common/Toast';
import DynamicButton from '../new/Button';
import { useContext } from 'react';
import { store } from '../../Store';
import { styled } from '@mui/styles';
const HoverableOptionHor = styled(OptionHor)({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)', // Replace 'your-desired-color' with the color you want to apply on hover
  },
});

export const PlanListing = ({
  opportunities = false,
  active,
  handleSortChange,
  plans,
  setPlans,
  sharedPlans,
  setSharedPlans,
  archivedPlans,
  setArchivedPlans,
  update,
  setUpdate,
  planForTemplate,
  setPlanForTemplate,
  handleDirectionChange,
  sortDirectionMaps,
  setDirectionMaps,
  handleCreate,
  viewOnly,
  viewAndEdit,
}) => {
  const { state } = useContext(store);
  const navigate = useNavigate();
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [showSharePlanModal, setShowSharePlanModal] = useState(false);
  const [showArchivePlanModal, setShowArchivePlanModal] = useState(false);
  const [showDeletePlanModal, setShowDeletePlanModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedArchiveOption, setSelectedArchiveOption] = useState('');
  const [archiveReason, setArchiveReason] = useState('');
  const [plansLoading, setPlansLoading] = useState(true);
  const [selectedSortOption, setSelectedSortOption] = useState({
    name: 'Title',
    key: 'title',
  });
  const [selectedSortDirection, setSelectedSortDirection] = useState(
    sortDirections[0]
  );
  const [activeRow, setActiveRow] = useState(null);
  const [template, setTemplate] = useState({
    name: '',
    description: '',
    checked: false,
  });
  const [page, setPage] = useState(1);
  const itemsPerPage = 8;
  const [totalPages, setTotalPages] = useState(
    Math.ceil(plans?.length / itemsPerPage)
  );
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedPlans = plans?.slice(startIndex, endIndex) ?? [];
  const displayedArchived = archivedPlans?.slice(startIndex, endIndex) ?? [];

  useEffect(() => {
    setTotalPages(
      active === PLAN_TAB.maps
        ? Math.ceil(plans?.length / itemsPerPage)
        : Math.ceil(archivedPlans?.length / itemsPerPage)
    );
  }, [active, plans, archivedPlans]);

  const handleActionsClick = (event, row) => {
    setActiveRow(row);
    setActionsAnchorEl(event.currentTarget);
  };
  const handleActionsClose = () => setActionsAnchorEl(null);

  // const handleTemplateModalOpen = () => setShowTemplateModal(true);
  const handleTemplateModalClose = () => {
    setShowTemplateModal(false);
  };

  // const handleArchivePlanModalOpen = () => setShowArchivePlanModal(true);
  const handleArchivePlanModalClose = () => {
    setShowArchivePlanModal(false);
    setSelectedArchiveOption('');
    if (selectedArchiveOption === 'Other') setArchiveReason('');
  };

  const handleDeletePlanModalOpen = () => setShowDeletePlanModal(true);
  const handleDeletePlanModalClose = () => setShowDeletePlanModal(false);

  const handleArchiveOptionChange = ({ target }) => {
    setSelectedArchiveOption(target.value);
    if (selectedArchiveOption !== 'Other' && target.value !== 'Other')
      setArchiveReason(target.value);
    else setArchiveReason('');
  };

  const handleArchiveReasonChange = ({ target }) => {
    setArchiveReason(target.value);
  };

  const removeSelectedItem = (array, it) => {
    const withoutRemoved = array.filter((item) => item._id !== it?._id);
    return withoutRemoved;
  };

  const renderActions = (selected) => {
    const row = archivedPlans?.find((item) => item._id === selected._id);

    return (
      <>
        <HoverableOptionHor
          aria-haspopup="true"
          aria-controls={
            Boolean(actionsAnchorEl) ? 'plan-table-actions' : undefined
          }
          aria-expanded={Boolean(actionsAnchorEl) ? 'true' : undefined}
          onClick={(e) => handleActionsClick(e, row)}
        />

        <AppMenu
          id={'plan-table-actions'}
          open={Boolean(actionsAnchorEl)}
          anchorEl={actionsAnchorEl}
          options={[
            {
              name: 'View plan',
              handleClick: () => {
                navigate(`/plan/${activeRow?._id}`, {
                  state: { data: activeRow },
                });
                handleActionsClose();
              },
            },
            {
              name: 'Unarchive plan',
              handleClick: () => {
                handleUnarchivePlan();
                handleActionsClose();
              },
            },
            {
              name: 'Delete plan',
              handleClick: () => {
                handleActionsClose();
                handleDeletePlanModalOpen();
              },
              color: warning,
            },
          ]}
          handleClose={handleActionsClose}
        />
      </>
    );
  };

  const handleUnarchivePlan = async () => {
    httpClient
      .patch('api/plan/archive', {
        id: activeRow._id,
        archived: false,
        archiveReason: '',
      })
      .then(() => {
        const withoutRemoved = removeSelectedItem(archivedPlans, activeRow);
        setArchivedPlans(withoutRemoved);
        showNotification('success', 'Plan successfully unarchived!');
        setUpdate(!update);
      })
      .catch((err) => showNotification('error', err?.response?.data?.message));
  };

  const renderSharedActions = (selectedPlan) => {
    return (
      <VisibilityOutlinedIcon
        onClick={() => navigate(`/plan/${selectedPlan._id}`)}
        sx={{ cursor: 'pointer' }}
      />
    );
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (actionsAnchorEl && !actionsAnchorEl.contains(event.target)) {
        handleActionsClose();
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [actionsAnchorEl]);

  const renderOpportunitiesActions = (selected) => {
    const row = plans.find((item) => item._id === selected._id);

    return (
      <>
        <HoverableOptionHor
          aria-haspopup="true"
          aria-controls={
            Boolean(actionsAnchorEl) ? 'plan-table-actions' : undefined
          }
          aria-expanded={Boolean(actionsAnchorEl) ? 'true' : undefined}
          onClick={(e) => handleActionsClick(e, row)}
        />

        <AppMenu
          id={'plan-table-actions'}
          open={Boolean(actionsAnchorEl)}
          anchorEl={actionsAnchorEl}
          options={[
            {
              name: 'View plan',
              handleClick: () => {
                navigate(`/plan/${activeRow?._id}`, {
                  state: { data: activeRow },
                });
                handleActionsClose();
              },
            },
            {
              name: 'Save as template',
              handleClick: () => {
                setPlanForTemplate(activeRow);
                setShowTemplateModal(true);
              },
            },
            {
              name: 'Share plan',
              handleClick: (e, index) => {
                setPlanForTemplate(activeRow);
                setShowSharePlanModal(true);
              },
            },
            {
              name: 'Archive plan',
              handleClick: () => {
                setSelectedRow(selectedRow);
                setShowArchivePlanModal(true);
              },
            },
            {
              name: 'Delete plan',
              handleClick: () => {
                handleActionsClose();
                handleDeletePlanModalOpen();
              },
              color: warning,
            },
          ]}
          handleClose={handleActionsClose}
        />
      </>
    );
  };

  const getPlans = () => {
    if (active === PLAN_TAB.maps && plans?.length > 0) {
      const updatedPlans = plans.map((plan) => ({
        ...planDecorator(plan),
      }));
      setPlans(updatedPlans);
    } else if (active === PLAN_TAB.share && sharedPlans?.length > 0) {
      const updatedPlans = sharedPlans?.map((plan) => ({
        ...planDecorator(plan),
      }));
      setSharedPlans(updatedPlans);
    } else if (active === PLAN_TAB.archived && archivedPlans?.length > 0) {
      const updatedPlans = archivedPlans.map((plan) => ({
        ...planDecorator(plan),
      }));
      setArchivedPlans(updatedPlans);
    }

    setTimeout(() => {
      setPlansLoading(false);
    }, 2000);
  };

  useEffect(() => {
    getPlans();
  }, [active, update]);

  const handleDelete = () => {
    if (active === PLAN_TAB.maps) {
      httpClient
        .delete('api/plan/delete', {
          data: {
            id: activeRow._id,
          },
        })
        .then(() => {
          const withoutRemoved = plans.filter(
            (item) => item._id !== activeRow?._id
          );
          setPlans(withoutRemoved);
          showNotification('success', 'Plan successfully deleted!');
          setShowDeletePlanModal(false);
          setUpdate(!update);
        })
        .catch((err) => {
          // showNotification('error', err?.response?.data?.message);
        });
    } else if (active === PLAN_TAB.archived) {
      httpClient
        .delete('api/plan/delete', { data: { id: activeRow._id } })
        .then(() => {
          const withoutRemoved = archivedPlans.filter(
            (item) => item._id !== activeRow?._id
          );
          setArchivedPlans(withoutRemoved);
          showNotification('success', 'Archived plan successfully deleted!');
          setShowDeletePlanModal(false);
          setUpdate(!update);
        })
        .catch((err) => {
          showNotification('error', err?.response?.data?.message);
        });
    }
  };

  const archivePlan = () => {
    httpClient
      .patch('api/plan/archive', {
        id: activeRow._id,
        archived: true,
        archiveReason: archiveReason,
      })
      .then(() => {
        showNotification('success', 'Plan successfully archived!');
        setShowArchivePlanModal(false);
        setUpdate(!update);
      })
      .catch((err) => showNotification('error', err?.response?.data?.message));
  };

  const handleChangeSaveAsTemplate = (e, name) => {
    if (name !== 'checked') {
      setTemplate({ ...template, [name]: e.target.value });
    } else {
      setTemplate({ ...template, checked: e.target.checked });
    }
  };

  const saveAsTemplate = async () => {
    let newTemplate;
    let lastObj;
    try {
      const createTemplateResponse = await httpClient.post('api/template/new', {
        name: template.name,
        description: template.description,
      });

      if (createTemplateResponse) {
        const plan = await httpClient.get('api/plan/get', {
          params: { id: planForTemplate._id },
        });

        if (plan) {
          newTemplate = createTemplateResponse.data;
          const newObj = returnTemplateObj(plan.data, template, newTemplate);
          newObj.shareList = [];
          newObj.userId = state?.data?.user._id;
          // newObj.vendorRepresentative = state?.data?.user.fullName;
          newObj.vendor = state?.data?.user.company;
          newObj.vendorColor = randomColor();

          if (newTemplate) {
            await httpClient
              .patch('api/template/update', newObj)
              .then((res) => {
                lastObj = res.data;
                showNotification('success', 'Successfully saved as template');
              })
              .catch((err) =>
                showNotification('error', err?.response?.data?.message)
              );

            setShowTemplateModal(false);
          }
        }
        // else {
        //   toast.error(plan.data.message);
        // }
      }
      // else {
      //   toast.error(createTemplateResponse.data.message);
      // }
    } catch (error) {
      // If there's an error during the update, delete the template
      // if (error?.response?.data?.message) {
      //   showNotification('error', error.response.data.message);
      // }

      if (lastObj?._id) {
        try {
          // Make a DELETE request to delete the template
          await httpClient.delete(`api/template/delete`, {
            data: { _id: newTemplate._id },
          });
          showNotification('error', 'There is some error. ');
        } catch (deleteError) {
          toast.error('Failed to delete the template');
        }
      }
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Container maxWidth="xxl" className={styles.container}>
      <Box className={styles.table}>
        {active === PLAN_TAB.maps && (
          <AppTable
            data={displayedPlans ?? plans ?? []}
            headers={planTableHeaders}
            actions={opportunities ? renderOpportunitiesActions : renderActions}
            loading={plansLoading}
            active={active}
            setSelectedSortOption={setSelectedSortOption}
            handleSortChange={handleSortChange}
            handleDirectionChange={handleDirectionChange}
            sortDirectionMaps={sortDirectionMaps}
            setDirectionMaps={setDirectionMaps}
            handleCreate={handleCreate}
            viewOnly={viewOnly}
            viewAndEdit={viewAndEdit}
          />
        )}
        {active === PLAN_TAB.share && (
          <AppTable
            data={sharedPlans}
            headers={sharedPlanTableHeaders}
            actions={renderSharedActions}
            loading={plansLoading}
            isShare={true}
          />
        )}
        {active === PLAN_TAB.archived && (
          <AppTable
            data={displayedArchived ?? archivedPlans ?? []}
            headers={archivedPlansHeader}
            actions={renderActions}
            loading={plansLoading}
            isShare={true}
            handleCreate={handleCreate}
            setSelectedRow={setSelectedRow}
            viewOnly={viewOnly}
            viewAndEdit={viewAndEdit}
            setSelectedSortOption={setSelectedSortOption}
            handleSortChange={handleSortChange}
            handleDirectionChange={handleDirectionChange}
            sortDirectionMaps={sortDirectionMaps}
            setDirectionMaps={setDirectionMaps}
          />
        )}
      </Box>
      <SaveAsTemplateModal
        open={showTemplateModal}
        handleClose={handleTemplateModalClose}
        saveAsTemplate={saveAsTemplate}
        handleChangeSaveAsTemplate={handleChangeSaveAsTemplate}
        template={template}
      />

      <DeletePlanModal
        open={showDeletePlanModal}
        handleClose={handleDeletePlanModalClose}
        handleDelete={handleDelete}
      />

      <ArchivePlanModal
        open={showArchivePlanModal}
        archiveReason={archiveReason}
        selectedOption={selectedArchiveOption}
        handleChange={handleArchiveOptionChange}
        handleReasonChange={handleArchiveReasonChange}
        handleClose={handleArchivePlanModalClose}
        selectedRow={selectedRow}
        archivePlan={archivePlan}
      />
      <SharePlanModal
        plan={planForTemplate}
        setPlan={setPlanForTemplate}
        open={showSharePlanModal}
        handleClose={() => setShowSharePlanModal(false)}
        type="plan"
      />
      {active === PLAN_TAB.maps && plans?.length > 0 && (
        <Box sx={{ marginTop: '20px', marginBottom: '20px' }}>
          <Divider sx={internStyles.paginationDivider} />
          <Box style={internStyles.pagination_row}>
            {page > 1 ? (
              <DynamicButton
                type="text"
                style={{
                  borderColor: '#EBEBEB',
                  backgroundColor: 'rgb(246, 245, 244)',
                }}
                onClick={() => setPage(page - 1)}
              >
                Previous
              </DynamicButton>
            ) : (
              <div style={{ width: '130px' }}></div>
            )}
            <Pagination
              // count={data.length}
              count={totalPages}
              page={page}
              hidePrevButton
              hideNextButton
              style={internStyles.pagination}
              // className={internStylesPlan.bgPagination}
              sx={{
                // backgroundColor: 'rgb(246, 245, 244)',
                '.Mui-selected': {
                  backgroundColor: `${primary} !important`,
                  color: 'white',
                },
              }}
              onChange={handlePageChange}
            />
            {page < totalPages ? (
              <DynamicButton
                type="text"
                style={{
                  borderColor: '#EBEBEB',
                  backgroundColor: 'rgb(246, 245, 244)',
                }}
                onClick={() => setPage(page + 1)}
              >
                Next
              </DynamicButton>
            ) : (
              <div style={{ width: '86px', marginLeft: '20px' }}></div>
            )}
          </Box>
        </Box>
      )}
      {active === PLAN_TAB.archived && archivedPlans?.length > 0 && (
        <Box sx={{ marginTop: '20px', marginBottom: '20px' }}>
          <Divider sx={internStyles.paginationDivider} />
          <Box style={internStyles.pagination_row}>
            {page > 1 ? (
              <DynamicButton
                type="text"
                style={{
                  borderColor: '#EBEBEB',
                  backgroundColor: 'rgb(246, 245, 244)',
                }}
                onClick={() => setPage(page - 1)}
              >
                Previous
              </DynamicButton>
            ) : (
              <div style={{ width: '130px' }}></div>
            )}
            <Pagination
              // count={data.length}
              count={totalPages}
              page={page}
              hidePrevButton
              hideNextButton
              style={internStyles.pagination}
              // className={internStylesPlan.bgPagination}
              sx={{
                // backgroundColor: 'rgb(246, 245, 244)',
                '.Mui-selected': {
                  backgroundColor: `${primary} !important`,
                  color: 'white',
                },
              }}
              onChange={handlePageChange}
            />
            {page < totalPages ? (
              <DynamicButton
                type="text"
                style={{
                  borderColor: '#EBEBEB',
                  backgroundColor: 'rgb(246, 245, 244)',
                }}
                onClick={() => setPage(page + 1)}
              >
                Next
              </DynamicButton>
            ) : (
              <div style={{ width: '86px', marginLeft: '20px' }}></div>
            )}
          </Box>
        </Box>
      )}
    </Container>
  );
};

const internStyles = {
  paginationDivider: { mt: '20px', border: '1px solid #E9ECEC' },
  paginationBox: { display: 'flex', justifyContent: 'center', mt: '20px' },
  pagination: {
    '.MuiSelected': {
      backgroundColor: `${primary} !important`,
      color: 'white',
    },
  },
  pagination_row: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
};

import { SORT_DIRECTION } from './enums';
import moment from 'moment';

export const isObjectEmpty = (obj) => {
  if (!obj) return true;
  return Object.keys(obj).length === 0;
};

export const getInitials = (firstName, lastName) => {
  const initials = firstName?.charAt(0) + lastName?.charAt(0);

  if (initials) return initials.toUpperCase();
  else return '';
};

export const validateHttpLink = (link) => {
  const httpLinkPattern = /^(http:\/\/|https:\/\/)/;

  return httpLinkPattern.test(link);
};

export const countUnreadObjects = (arr) => {
  const unreadObjects = arr.filter((obj) => obj.opened === false);

  return unreadObjects.length;
};

export const getInitial = (string) => {
  const initials = string.charAt(0).toUpperCase();

  return initials;
};

export const getInitialsFromString = (string) => {
  var names = string.split(' '),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const randomColor = () => {
  const hue = Math.floor(Math.random() * 251); // Random hue in the range of 50-90 (yellow-green)
  const saturation = Math.floor(Math.random() * 21) + 70; // Random saturation in the range of 70-90 (more vibrant)
  const lightness = Math.floor(Math.random() * 21) + 70; // Random lightness in the range of 70-90 (lighter shade)

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

export const randomColorAndBg = () => {
  const hue = Math.floor(Math.random() * 41) + 50; // Random hue in the range of 50-90 (yellow-green)
  const saturation = Math.floor(Math.random() * 21) + 70; // Random saturation in the range of 70-90 (more vibrant)
  const lightness = Math.floor(Math.random() * 21) + 70; // Random lightness in the range of 70-90 (lighter shade)

  const background = `hsl(${hue}, ${saturation}%, ${lightness}%)`;

  // Generate darker text color
  const textColor = `hsl(${hue}, ${saturation}%, ${lightness - 20}%)`;

  return { background, textColor };
};

export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const sortCollaboratorHistory = (choosenUser, history, date) => {
  const filteredArray = history.filter((obj) => {
    // Check if the collaborator's name matches and its not all members selected
    if (obj.user != choosenUser.name && choosenUser.key !== 'all') {
      return false;
    }

    // Check if the date is within the selected range a
    if (!date.all) {
      const currentDate = new Date();
      const endDate = date.endDate;
      const objDate = new Date(obj.day);

      if (endDate <= objDate && objDate <= currentDate) return obj;
      else return false;
    }

    return obj;
  });

  return filteredArray;
};

export const sortPlans = (sortOption, plans, sortDirection, owner = false) => {
  if (plans?.length > 0) {
    const sortedPlans = [...plans];
    switch (sortOption) {
      case 'title':
        if (sortDirection === SORT_DIRECTION.asc)
          sortedPlans.sort((a, b) => a.title.localeCompare(b.title));
        else sortedPlans.sort((a, b) => b.title.localeCompare(a.title));

        break;
      case 'name':
        if (sortDirection === SORT_DIRECTION.asc)
          sortedPlans.sort((a, b) => a.name.localeCompare(b.name));
        else sortedPlans.sort((a, b) => b.name.localeCompare(a.name));

        break;
      case 'customer':
        if (owner) {
          if (sortDirection === SORT_DIRECTION.asc)
            sortedPlans.sort((a, b) => a.owner.localeCompare(b.owner));
          else sortedPlans.sort((a, b) => b.owner.localeCompare(a.owner));
        } else {
          sortedPlans.sort((a, b) => {
            const aCustomerCount = Object.values(a.stakeholders).reduce(
              (sum, count) => sum + count,
              0
            );
            const bCustomerCount = Object.values(b.stakeholders).reduce(
              (sum, count) => sum + count,
              0
            );

            return sortDirection === SORT_DIRECTION.asc
              ? aCustomerCount - bCustomerCount
              : bCustomerCount - aCustomerCount;
          });
        }

        break;
      case 'progress':
        if (sortDirection === SORT_DIRECTION.asc)
          sortedPlans.sort((a, b) => a.progress - b.progress);
        else sortedPlans.sort((a, b) => b.progress - a.progress);
        break;
      case 'value':
        if (!owner) {
          sortedPlans.sort((a, b) => {
            const aNum = parseFloat(a.value.amount);
            const bNum = parseFloat(b.value.amount);
            return sortDirection === SORT_DIRECTION.asc
              ? aNum - bNum
              : bNum - aNum;
          });
        }

        break;

      case 'closeDate':
        if (sortDirection === SORT_DIRECTION.asc) {
          sortedPlans.sort(
            (a, b) => new Date(a.closeDate) - new Date(b.closeDate)
          );
        } else
          sortedPlans.sort(
            (a, b) => new Date(b.closeDate) - new Date(a.closeDate)
          );

        break;
      case 'updatedAt':
        if (sortDirection === SORT_DIRECTION.asc) {
          sortedPlans.sort(
            (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
          );
        } else
          sortedPlans.sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          );

        break;
      default:
        break;
    }

    return sortedPlans;
  }
};

export const getDarkerColor = (color) => {
  // Function to convert HSL to RGB
  const hslToRgb = (h, s, l) => {
    h /= 360;
    s /= 100;
    l /= 100;

    let r, g, b;

    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  };

  // Parse the HSL color values
  const matches = color?.match(/hsl\((\d+),\s*([\d.]+)%,\s*([\d.]+)%\)/);
  if (!matches) {
    return color;
  }

  const hue = parseFloat(matches[1]);
  const saturation = parseFloat(matches[2]);
  const lightness = parseFloat(matches[3]);

  // Calculate darker lightness value
  const newLightness = Math.max(0, lightness - 0.3 * 100);

  // Convert back to RGB
  const [r, g, b] = hslToRgb(hue, saturation, newLightness);

  // Format the resulting RGB color
  return `rgb(${r}, ${g}, ${b})`;
};

export const getDateRanges = (item) => {
  let targetDates = [];

  if (item.activities && item.activities.length > 0) {
    item.activities.forEach((activity) => {
      if (activity.targetDate) {
        targetDates.push(activity.targetDate);
      }

      if (activity.subActivities && activity.subActivities.length > 0) {
        activity.subActivities.forEach((subActivity) => {
          if (subActivity.targetDate) {
            targetDates.push(subActivity.targetDate);
          }
        });
      }
    });
  }
  if (targetDates.length > 0) {
    const sortedDates = targetDates.sort((a, b) => new Date(a) - new Date(b));
    const startDate = moment(sortedDates[0] || sortedDates[0].$d).format(
      'MMM DD'
    );
    const endDate = moment(
      sortedDates[sortedDates.length - 1] ||
        sortedDates[sortedDates.length - 1].$d
    ).format('MMM DD');

    if (
      targetDates.length === 1 ||
      moment(startDate).valueOf() === moment(endDate).valueOf()
    ) {
      return { startDate };
    }

    return {
      startDate: startDate,
      endDate: item.activities.length > 0 ? endDate : null,
    };
  }

  return null;
};

export const getNumberOfCompletedActivitiesAndSubactivities = (
  data,
  isVendor
) => {
  let completedCount = 0;

  if (data?.activities) {
    data.activities.map((sub) => {
      if (
        sub.status === 'completed' &&
        ((isVendor && sub.tag === 'vendor_internal_not_shared') ||
          sub.tag !== 'vendor_internal_not_shared')
      ) {
        completedCount++;
      }

      if (sub.subActivities) {
        sub.subActivities.map((subActivity) => {
          if (
            subActivity.status === 'completed' &&
            ((isVendor && sub.tag === 'vendor_internal_not_shared') ||
              sub.tag !== 'vendor_internal_not_shared')
          ) {
            completedCount++;
          }
        });
      }
    });
  }

  return completedCount;
};

export const formatToTableDate = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  return formattedDate;
};

export const formatToCompletionDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString('en-US', { month: 'short' });
  const year = date.getFullYear();

  return `${day}, ${month} ${year}`;
};

export const getAccessList = (el) => {
  if (el.shareList && el.shareList.length === 1 && el.shareList[0].email === '')
    return 'Only you';
  else if (
    el.shareList &&
    el.shareList.length === 1 &&
    el.shareList[0].email !== ''
  )
    return '2 members';
  else if (el.shareList && el.shareList.length > 1)
    return `${el.shareList.length + 1} members`;
};

export const formatToHeaderAutosave = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  return formattedDate;
};

export const formatTimeTo12HourFormat = (isoDate) => {
  const date = new Date(isoDate);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? 'pm' : 'am';

  // Convert to 12-hour format
  if (hours > 12) {
    hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }

  // Format the time
  const formattedTime = `${hours}:${minutes
    .toString()
    .padStart(2, '0')}${amOrPm}`;
  return formattedTime;
};

export const groupByActivityAndStage = (data) => {
  return data.reduce((groupedData, item) => {
    const activity = item.activity || 'No Activity'; // Set a default value if "activity" is missing
    const { stage } = item;
    const key = `${activity}}`;

    if (groupedData[key]) {
      groupedData[key].push(item);
    } else {
      groupedData[key] = [item];
    }

    return groupedData;
  }, {});
};

export const formatToTableDateWithHours = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();

  if (isSameDay(date, now)) {
    return 'Today';
  }

  const timeDiff = Math.abs(now.getTime() - date.getTime());
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

  if (diffDays === 1) {
    return '1 day ago';
  } else {
    return `${diffDays} days ago`;
  }
};

const isSameDay = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const formatCurrency = (obj) => {
  if (obj.currency === 'dollar') {
    return `$${obj.amount}`;
  } else if (obj.currency === 'euro') {
    return `€${obj.amount}`;
  } else if (obj.currency === 'euro') {
    return `£${obj.amount}`;
  }
};

export const countProgress = (data) => {
  if (data?.stages) {
    const counts = data.stages.map((stage) => {
      if (stage) {
        if (stage.postSale) return;

        const completedActivities = stage.activities.filter(
          (activity) => activity.status === 'completed'
        );
        const completedSubActivities = stage.activities.flatMap((activity) =>
          activity.subActivities.filter(
            (subActivity) => subActivity.status === 'completed'
          )
        );

        return {
          stageName: stage.name,
          completed: completedActivities.length + completedSubActivities.length,
          incompleted:
            stage.activities.length -
            completedActivities.length +
            (stage.activities.flatMap((activity) => activity.subActivities)
              .length -
              completedSubActivities.length),
          sum:
            completedActivities.length +
            completedSubActivities.length +
            (stage.activities.length -
              completedActivities.length +
              (stage.activities.flatMap((activity) => activity.subActivities)
                .length -
                completedSubActivities.length)),
        };
      }
    });

    let completed = 0;
    let allStatuses = 0;
    counts.map((stage) => {
      if (stage) {
        completed += stage.completed;
        allStatuses += stage.sum;
      }
    });

    return (completed / allStatuses) * 100;
  }
};

export const notificationTime = (timestamp) => {
  const currentDate = new Date();
  const currentTimestamp = currentDate.getTime();
  const timeDiff = currentTimestamp - timestamp;

  const minutesDiff = Math.floor(timeDiff / (1000 * 60));
  const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));
  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const monthsDiff = Math.floor(daysDiff / 30);

  if (minutesDiff < 60) {
    return `${minutesDiff} minutes ago`;
  } else if (hoursDiff < 24) {
    return `${hoursDiff} hours ago`;
  } else if (daysDiff === 1) {
    return '1 day ago';
  } else if (monthsDiff === 1) {
    return '1 month ago';
  } else if (monthsDiff > 1) {
    return `${monthsDiff} months ago`;
  } else {
    return `${daysDiff} days ago`;
  }
};

export const getTotalCount = (data, isVendor) => {
  let totalCount = 0;
  if (data?.activities) {
    data.activities.map((activity) => {
      if (
        (isVendor && activity.tag === 'vendor_internal_not_shared') ||
        activity.tag !== 'vendor_internal_not_shared'
      )
        totalCount++;

      if (activity.subActivities) {
        activity.subActivities?.map((subActivity) => {
          if (
            (isVendor && activity.tag === 'vendor_internal_not_shared') ||
            activity.tag !== 'vendor_internal_not_shared'
          )
            totalCount++;
        });
      }
    });
  }

  return totalCount;
};

export const planAccessColumns = [
  { id: 'avatar', title: '', minWidth: '60px', align: 'center' },
  { id: 'fullName', label: 'Name', minWidth: '170px', type: 'string' },
  {
    id: 'title',
    label: 'Job title',
    minWidth: '100p',
    align: 'left',
    type: 'string',
  },
  // {
  //   id: 'userType',
  //   label: 'User type',
  //   minWidth: '122px',
  //   align: 'left',
  //   type: 'string',
  // },
  {
    id: 'company',
    label: 'Company name',
    minWidth: '100px',
    align: 'left',
    type: 'string',
  },
  {
    id: 'viewed',
    label: 'Viewed',
    minWidth: '50px',
    align: 'center',
    type: 'boolean',
  },
  {
    id: 'viewNumber',
    label: 'View count',
    minWidth: 70,
    align: 'right',
    type: 'number',
  },
  {
    id: 'lastViewed',
    label: 'Last viewed',
    minWidth: '80px',
    align: 'left',
    format: (value) => value.toFixed(2),
    type: 'date',
  },
  { key: 'actions', title: 'Actions', minWidth: '100px', align: 'center' },
];

export const planAccessRows = [
  {
    name: 'Manuel Snr',
    email: 'manuel@manuelinc.com',
    title: 'Vendor Representative',
    userType: 'Vendor',
    companyName: 'Manuel Inc',
    status: true,
    count: 32,
    lastViewed: '23 Jan, 2022',
    time: '2:30pm',
    color: '#E7F6F9',
  },
  {
    name: 'James Banks',
    email: 'jamesbank@walmart.com',
    title: 'Marketing Coordinator',
    userType: 'Customer',
    companyName: 'Walmart',
    status: true,
    count: 12,
    lastViewed: '20 Jan, 2022',
    time: '12:30pm',
    color: '#F9E7E7',
  },
  {
    name: 'Tanner Stafford',
    email: 'tanstaf@walmart.com',
    title: 'Sales Representative',
    userType: 'Customer',
    companyName: 'Walmart',
    status: true,
    count: 23,
    lastViewed: '05 Jan, 2022',
    time: '7:30am',
    color: '#F3EBFF',
  },
  {
    name: 'Geoffrey Mott',
    title: 'Account Executive',
    userType: 'Customer',
    companyName: 'Walmart',
    status: true,
    count: 6,
    lastViewed: '18 Jan, 2022',
    time: '12:30pm',
    color: '#EDF9E7',
  },
  {
    name: 'Ronald Richman',
    title: 'Regional Sales Account Manager',
    userType: 'Customer',
    companyName: 'Walmart',
    status: false,
    count: 0,
    lastViewed: '',
    color: '#E7F8F9',
  },
  {
    name: 'Florencion Dorrance',
    title: 'Marketing Manager',
    userType: 'Customer',
    companyName: 'Walmart',
    status: false,
    count: 0,
    lastViewed: '',
    color: '#FFEBFF',
  },
  {
    name: 'Sam Smith',
    title: 'Sales Representative',
    userType: 'Partner',
    companyName: 'Innoson Motors',
    status: true,
    count: 23,
    lastViewed: '18 Jan, 2022',
    time: '12:30pm',
    color: '#FFFDEB',
  },
  {
    name: 'Samar Syed',
    title: 'Marketing Manager',
    userType: 'Others',
    companyName: 'Samar Enterprise',
    status: true,
    count: 23,
    lastViewed: '18 Jan, 2022',
    time: '2:30pm',
    color: '#FFF6EB',
  },
];

export const returnTemplateObj = (plan, template, createdTemplate) => {
  plan.stages?.map((stage) => {
    if (stage.activities?.length > 0) {
      stage.activities.map((activity) => {
        activity.customerTeam = [{ name: '', email: '', title: '' }];
        activity.vendorTeam = [{ name: '', email: '', title: '' }];
        activity.targetDate = '';
        activity.status = '';

        activity.subActivities?.map((subactivity) => {
          subactivity.status = '';
          subactivity.targetDate = '';
        });
      });
    }
  });
  const newObj = {
    _id: createdTemplate._id,
    name: template.name,
    description: template.description,
    // introduction: plan.introduction,
    // customerCompany: '',
    // customerChampion: '',
    // vendorRepresentative: '',
    // vendor: '',
    stages: plan.stages,
    // closeDate: plan.closeDate,
    // value: plan.value,
    // stakeholders: plan.stakeholders,
    // archived: plan.archived,
    // hasAvatar: plan.hasAvatar,
    createdAt: new Date(),
    updatedAt: new Date(),
    // userId: plan.userId,
    // defaultTemplate: template.checked,
  };
  return newObj;
};

export const returnArrayOfUsers = (users) => {
  return users.map(({ firstName, lastName, email, jobTitle }) => ({
    user: `${firstName} ${lastName}`,
    email,
    jobTitle,
  }));
};

export const removeDuplicates = (arr) => {
  const uniqueIds = new Set();
  return arr.filter((obj) => {
    if (!uniqueIds.has(obj._id)) {
      uniqueIds.add(obj._id);
      return true;
    }
    return false;
  });
};

export const removeDuplicatesUsersByFullName = (arr) => {
  const uniqueIds = new Set();
  return arr.filter((obj) => {
    if (!uniqueIds.has(obj.fullName)) {
      uniqueIds.add(obj.fullName);
      return true;
    }
    return false;
  });
};

export const checkDoesExist = (arr, user) => {
  let exist = [];
  if (!arr.length) return false;
  const { fullName, jobTitle } = user;
  arr.forEach((one) => {
    if (one?.fullName === fullName && one?.title === jobTitle) {
      exist.push(true);
    } else {
      exist.push(false);
    }
  });

  return exist.includes(true);
};

export const removeDuplicatesUsersByEmail = (arr) => {
  const uniqueIds = new Set();
  return arr.filter((obj) => {
    if (!uniqueIds.has(obj.value)) {
      uniqueIds.add(obj.value);
      return true;
    }
    return false;
  });
};

export const getShareList = (plans) => {
  let newList = [];
  plans?.map((el) => {
    let plan = { planId: el._id, users: [] };
    el.stages.map((stage) => {
      stage.activities.map((activity) => {
        activity.customerTeam.map((customer) => {
          plan.users.push(customer);
        });
        activity.vendorTeam.map((vendor) => {
          plan.users.push(vendor);
        });
      });
    });
    const withoutDuplicates = removeDuplicates(plan.users);
    plan.users = withoutDuplicates;

    newList.push(plan);
  });
  return newList;
};

export const getShareListForPlan = (sharedPlan) => {
  if (sharedPlan) {
    let plan = [];
    let customers = [];
    let vendors = [];
    sharedPlan.stages.map((stage) => {
      stage.activities.map((activity) => {
        activity.customerTeam.map((customer) => {
          const newObj = {
            email: customer.email,
            fullName: customer.name,
            title: customer.title,
          };
          plan.push(newObj);
          customers.push(newObj);
        });
        activity.vendorTeam.map((vendor) => {
          const newObj = {
            email: vendor.email,
            fullName: vendor.name,
            title: vendor.title,
          };
          plan.push(newObj);
          vendors.push(newObj);
        });
      });
    });
    // const withoutDuplicates = removeDuplicatesUsersByFullName(plan);
    // const withoutEmpty = withoutDuplicates.filter((el) => el.fullName !== '');

    // const withoutDuplicatesCustomers =
    //   removeDuplicatesUsersByFullName(customers);
    // const withoutEmptyCustomers = withoutDuplicatesCustomers.filter(
    //   (el) => el.fullName !== ''
    // );

    // const withoutDuplicatesVendors = removeDuplicatesUsersByFullName(vendors);
    // const withoutEmptyVendors = withoutDuplicatesVendors.filter(
    //   (el) => el.fullName !== ''
    // );

    // return {
    //   all: withoutEmpty,
    //   customers: withoutEmptyCustomers,
    //   vendors: withoutEmptyVendors,
    // };
    return {
      all: plan,
      customers,
      vendors,
    };
  } else {
    return {
      all: [],
      customers: [],
      vendors: [],
    };
  }
};

export const editShareList = (list, plan) => {
  let newList = [...list];
  newList?.map((el) => {
    if (el.planId === plan._id) {
      let planUsers = [];
      plan.stages.map((stage) => {
        stage.activities.map((activity) => {
          activity.customerTeam.map((customer) => {
            planUsers.push(customer);
          });
          activity.vendorTeam.map((vendor) => {
            planUsers.push(vendor);
          });
        });
      });
      el.users = planUsers;
      const withoutDuplicates = removeDuplicates(el.users);
      el.users = withoutDuplicates;
    }
  });
  return newList;
};

export const formatAmount = (amount, currency) => {
  if (amount && amount > 0 && currency) {
    const formattedNumber = amount?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return currency + formattedNumber?.replace(/\.00$/, '');
  } else {
    return '-';
  }
};

export const getNumericValue = (value) => {
  if (value) return value.replace(/[^0-9.-]+/g, '');
  else return value;
};

export const formatNumberWithCurrency = (value, currency = '€') => {
  if (value && value !== '') {
    // Remove any non-digit characters from the value
    const number = Number(value.toString().replace(/[^0-9.-]+/g, ''));
    // const number = Number(value);

    // Check if the number is valid
    if (isNaN(number)) {
      return value; // Return the original value if it's not a valid number
    }

    // Convert the number to a string
    let formattedNumber = String(number);

    // Check if the number contains a decimal point
    if (formattedNumber.includes('.')) {
      // Split the number into integer and decimal parts
      const [integerPart, decimalPart] = formattedNumber.split('.');

      // Add commas or dots as thousands separator to the integer part
      formattedNumber =
        integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + decimalPart;
    } else {
      // Add commas or dots as thousands separator to the whole number
      formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    // Add the currency symbol to the formatted number
    formattedNumber = currency + formattedNumber;

    return formattedNumber;
  }
};

export const getCustomOptions = (plan, timeOptions) => {
  const allStages = plan.stages;

  const newOptions = [];
  allStages.map((stage) => {
    stage.activities.map((activity) => {
      const exist = timeOptions.find(
        (option) => option.value === activity.duration
      );
      if (!exist) {
        let newName = '';
        if (activity.duration.includes('m'))
          newName = `${activity.duration.toString().split('m')[0]} mins`;
        else if (activity.duration.includes('h'))
          newName = `${activity.duration.toString().split('h')[0]} hours`;
        else if (activity.duration.includes('d'))
          newName = `${activity.duration.toString().split('d')[0]} days`;
        newOptions.push({ name: newName, value: activity.duration });
      }
    });
  });

  if (newOptions.length > 0) return [...timeOptions, ...newOptions];
  else return timeOptions;
};

export const getUniqueObjectsByProperty = (arr, property) => {
  const uniqueObjects = [];
  const valuesSet = new Set();

  for (let i = 0; i < arr.length; i++) {
    const obj = arr[i];
    const value = obj[property]?.trim();

    if (!valuesSet.has(value)) {
      valuesSet.add(value);
      uniqueObjects.push(obj);
    }
  }

  return uniqueObjects;
};

export const removeDuplicatesWithException = (arr, property, exceptionType) => {
  const uniqueObjects = [];
  const exceptionIndex = arr.reduceRight((index, obj, currentIndex) => {
    if (
      obj[property] &&
      obj[property].toLowerCase().includes(exceptionType.toLowerCase())
    ) {
      return index !== -1 ? index : currentIndex;
    }
    return index;
  }, -1);

  for (let i = 0; i < arr.length; i++) {
    const obj = arr[i];

    if (
      i === exceptionIndex ||
      !obj[property] ||
      !obj[property].toLowerCase().includes(exceptionType.toLowerCase())
    ) {
      uniqueObjects.push(obj);
    }
  }

  return uniqueObjects;
};

export const darkenColor = (color) => {
  if (color.startsWith('#')) {
    const hexToRgba = (hexColor) => {
      const hex = hexColor.replace('#', '');
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      return `rgba(${r}, ${g}, ${b}, 1)`;
    };

    color = hexToRgba(color);
  }

  const [r, g, b, a] = color.match(/\d+/g);
  const amt = Math.round(255 * 0.9); // Change the percentage here (e.g., 0.2 for 20% darker)
  return `rgba(${Math.max(r - amt, 0)}, ${Math.max(g - amt, 0)}, ${Math.max(
    b - amt,
    0
  )}, ${a})`;
};

import { Box, Dialog, Typography } from '@mui/material';
import React from 'react';
import { black, mercury, warning, white } from '../../constants/colors';
import DynamicButton from '../new/Button';

export const RemoveFromShareListModal = ({
  open,
  handleClose,
  handleDelete,
  userToRemove,
  type,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: styles.paper }}>
      <Typography variant="h5" sx={styles.heading}>
        Remove From Share List
      </Typography>
      <Typography variant="h5" sx={styles.body}>
        Are you sure you want to remove{' '}
        <span style={{ fontWeight: 700 }}>
          {userToRemove ? userToRemove : '“Jack Pope”'}
        </span>{' '}
        from the share list? <br />
        {type === 'Resend'
          ? 'They won’t be able to access this plan anymore.'
          : ' You’ll have to re-enter their details into the share page before inviting them in future.'}
      </Typography>
      <Box sx={styles.btns}>
        <DynamicButton
          type="profile"
          style={styles.cancel}
          onClick={handleClose}
        >
          Cancel
        </DynamicButton>
        <DynamicButton
          type="remove"
          style={styles.delete}
          onClick={handleDelete}
        >
          Remove
        </DynamicButton>
      </Box>{' '}
    </Dialog>
  );
};

const styles = {
  paper: {
    borderRadius: '8px',
    padding: '16px 24px',
    width: '560px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: black,
  },
  heading: {
    fontFamily: 'InterBold',
    fontSize: '20px',
    lineHeight: '32px',
    marginBottom: '12px',
    width: '100%',
    textAlign: 'left',
  },
  body: {
    fontSize: '14px',
    lineHeight: '24px',
    textAlign: 'left',
    marginBottom: '40px',
    width: '100%',
  },
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px',
    width: '100%',
  },
  cancel: {
    padding: '8px 16px',
    border: ` 1px solid rgba(0, 0, 0, 0.6)`,
    height: '40px',
    fontFamily: 'InterRegular',
    color: 'rgba(0, 0, 0, 0.7)',
    '.MuiButton-outlined:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2) !important',
      color: 'rgba(0, 0, 0, 0.88) !important',
    },
  },
  delete: {
    color: 'white',
    padding: '8px 16px',
    border: `none`,
    boxShadow: 'none',
    background: warning,
    '&:hover': { background: warning },
  },
};

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Typography, Container } from '@mui/material';
import InputWithLabel from '../components/common/InputWithLabel';
import styles from '../styles/resetPassword/resetPassword.module.css';
import { toast } from 'react-toastify';
import axios from 'axios';
import { showNotification } from '../components/common/Toast';
import DynamicButton from '../components/new/Button';

const validateEmail = (email) => {
  const expression =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isValid = expression.test(String(email));
  if (isValid) {
    return true;
  } else {
    showNotification('error', 'Please enter a valid email address.');
  }
};

export const ResetPassword = () => {
  const [email, setEmail] = useState('');

  const sendForgotPasswordEmail = async () => {
    if (validateEmail(email)) {
      await axios
        .post(`${process.env.REACT_APP_API_URL}api/user/send-forgot-password`, {
          email: email,
        })
        .then(() => {
          showNotification('success', `Successfully sent email to ${email}`);
        })
        .catch((err) => {
          toast.error('User with that email is not exist');
        });
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      if (validateEmail(email)) {
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}api/user/send-forgot-password`,
            {
              email: email,
            }
          )
          .then(() => {
            showNotification('success', `Successfully sent email to ${email}`);
          })
          .catch((err) => {
            toast.error('User with that email is not exist');
          });
      }
    }
  };

  return (
    <Container maxWidth="xl">
      <Box className={styles.container}>
        <Box className={styles.main_container}>
          <Box className={styles.reset_box}>
            <Typography variant="h4" align="center" className={styles.heading}>
              Reset Password
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              sx={{ marginTop: '30px' }}
            >
              Enter your email address and we will send you instructions to
              reset your password.
            </Typography>

            <form className={styles.form}>
              <InputWithLabel
                type="email"
                label="Email Address"
                name="email"
                value={email}
                placeholder="Email Address"
                handleChange={(e) => setEmail(e.target.value)}
                onKeyDown={handleKeyDown}
              />

              <DynamicButton
                type="containedCenter"
                className={styles.reset_btn}
                fullWidth
                onClick={sendForgotPasswordEmail}
              >
                Reset Password
              </DynamicButton>

              <Box className={styles.login}>
                <Link to="/login" className={styles.login_btn}>
                  Login
                </Link>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

import React, { useContext } from 'react';
import { Box, Button, Link, Typography } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { black, chardon, red, white, wildSand } from '../../constants/colors';
import { useNavigate, useParams } from 'react-router-dom';
import { store } from '../../Store';
import DynamicButton from '../new/Button';

const MenuNavTemplate = ({
  type = 'add',
  handleShare,
  name = 'High Staking sales',
  deleteModal,
  handlePreview,
}) => {
  const { state } = useContext(store);
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <Box sx={styles.main}>
      <Typography variant="h6" sx={styles.heading}>
        {!state?.data?.isAuthenticated && (
          <DynamicButton
            type="contained"
            sx={{
              ...styles.exitBtn,
              '&:hover': {
                color: white,
              },
              '&:hover .MuiSvgIcon-root': {
                color: white,
              },
            }}
            startIcon={<ArrowBackOutlinedIcon />}
          >
            Exit
          </DynamicButton>
        )}
        {name}
      </Typography>

      {state?.data?.isAuthenticated ? (
        type === 'edit' || type === 'add' ? (
          <Box sx={styles.btnBox}>
            <Link
              to="/dashboard"
              style={styles.viewBtn}
              onClick={handlePreview}
            >
              Preview
            </Link>
            <DynamicButton
              type="contained"
              sx={styles.deleteBtn}
              disableElevation
              onClick={() => deleteModal(type)}
            >
              Delete
            </DynamicButton>
            <DynamicButton
              type="contained"
              sx={styles.saveBtn}
              onClick={handleShare}
            >
              Share
            </DynamicButton>
          </Box>
        ) : type === 'share' ? (
          <Box sx={styles.btnBox}>
            <DynamicButton
              type="contained"
              sx={styles.deleteBtn}
              disableElevation
              onClick={() => deleteModal(type, true)}
            >
              Delete From me
            </DynamicButton>
            <DynamicButton
              type="contained"
              sx={styles.saveBtn}
              onClick={() =>
                navigate(`/plan/add`, { state: { templateId: id } })
              }
            >
              Create MAP From Template
            </DynamicButton>
          </Box>
        ) : null
      ) : null}
    </Box>
  );
};

export default MenuNavTemplate;

const styles = {
  main: {
    bgcolor: white,
    borderRadius: '8px',
    minHeight: '56px',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: '16px',
    padding: { xs: '10px 16px', sm: '0px 16px' },
  },
  heading: {
    color: black,
    fontFamily: 'SoehneBold',
    fontSize: '20px',
    lineHeight: '32px',
    marginRight: '30px',
  },
  btnBox: { marginTop: { xs: '14px', sm: '0' } },
  viewBtn: {
    color: black,
    lineHeight: '24px',
    fontSize: '16px',
    fontFamily: 'SoehneMedium',
    minWidth: '96px',
  },
  saveBtn: {
    padding: '10px 24px',
    height: '40px',
    borderRadius: '100px',
    marginLeft: '24px',
    fontFamily: 'SoehneBold',
    minWidth: '96px',
  },
  deleteBtn: {
    background: chardon,
    color: red,
    height: '40px',
    padding: '10px 24px',
    borderRadius: '100px',
    marginLeft: '24px',
    fontFamily: 'SoehneBold',
    minWidth: '96px',
    '&:hover': { background: chardon },
  },
  exitBtn: {
    backgroundColor: wildSand,
    padding: '8px 16px',
    marginRight: '24px',
    color: 'black',
    borderRadius: '52px',
    fontSize: '14px',
    minWidth: '96px',
    boxShadow: 'none',
  },
};

import React from 'react';
import { Box, Dialog, Typography } from '@mui/material';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import InputWithLabel from '../common/InputWithLabel';
import { black, mercury, shark, wildSand } from '../../constants/colors';
import DynamicButton from '../new/Button';

const styles = {
  paper: {
    borderRadius: '8px',
    padding: '32px',
    width: '458px',
  },
  heading: {
    color: black,
    fontFamily: 'SoehneBold',
    fontSize: '20px',
    lineHeight: '32px',
    marginBottom: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeIcon: {
    color: shark,
    fontSize: '30px',
    background: wildSand,
    borderRadius: '50%',
    padding: '5px',
    cursor: 'pointer',
  },
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px',
    marginTop: '25px',
  },
  cancel: {
    color: black,
    width: '81px',
    height: '40px',
    background: mercury,
    borderRadius: '100px',
    boxShadow: 'none',
    border: `1px solid ${mercury}`,
    fontSize: '16px',
    '&:hover': { background: mercury },
  },
  template: {
    color: 'white',
    width: '138px',
    height: '40px',
    borderRadius: '100px',
    boxShadow: 'none',
    fontSize: '16px',
  },
};

const ChangePasswordModal = ({
  open,
  handleClose,
  updatePassword,
  oldPassword,
  newPassword,
  confirmPassword,
  handleChange,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: styles.paper }}>
      <Typography variant="h5" sx={styles.heading}>
        Change Password
        <HighlightOffOutlinedIcon sx={styles.closeIcon} onClick={handleClose} />
      </Typography>

      <InputWithLabel
        type="password"
        label="Old Password"
        name="old_password"
        value={oldPassword}
        placeholder="********"
        handleChange={(e) => handleChange(e, 'old')}
      />

      <InputWithLabel
        type="password"
        label="New Password"
        name="new_password"
        value={newPassword}
        placeholder="********"
        handleChange={(e) => handleChange(e, 'new')}
      />

      <InputWithLabel
        type="password"
        label="Confirm Password"
        name="confirm_password"
        value={confirmPassword}
        placeholder="********"
        handleChange={(e) => handleChange(e, 'confirm')}
      />

      <Box sx={styles.btns}>
        <DynamicButton
          type="contained"
          sx={styles.cancel}
          onClick={handleClose}
        >
          Cancel
        </DynamicButton>
        <DynamicButton
          type="contained"
          sx={styles.template}
          onClick={updatePassword}
        >
          Update
        </DynamicButton>
      </Box>
    </Dialog>
  );
};

export default ChangePasswordModal;

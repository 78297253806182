import React, { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import { getCroppedCanvas, getCroppedImg } from './canvasUtils';
import { Select, Slider, Typography } from '@mui/material';
import DynamicButton from '../components/new/Button';
import httpClient from '../config/http.service';
import { showNotification } from '../components/common/Toast';
import { Box, MenuItem } from '@material-ui/core';
import { black } from '../constants/colors';

const aspectRatios = [
  { value: 1 / 1, text: '1/1' },
  { value: 4 / 3, text: '4/3' },
  { value: 16 / 9, text: '16/9' },
  { value: 1 / 2, text: '1/2' },
];

export function getFileFromImage(dataurl, filename) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const ResizeImage = ({
  imageSrc,
  cancelResizing,
  setCustomerPhoto,
  setCustomerPhotoDetails,
  data,
  setData,
  aspectInit,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  if (aspectInit == null) {
    aspectInit = aspectRatios[0];
  }
  const [aspect, setAspect] = useState(aspectInit);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    if (data?._id) {
      try {
        const croppedImage = await getCroppedImg(
          imageSrc,
          croppedAreaPixels,
          rotation
        );

        const getCanvas = await getCroppedCanvas(
          imageSrc,
          croppedAreaPixels,
          rotation
        );
        const getFile = getFileFromImage(getCanvas, 'customerimage');
        setCustomerPhoto(croppedImage);
        setCustomerPhotoDetails(getFile);

        const newFile = new FormData();
        const newName = new File([getFile], 'customerimage2', {
          type: getFile.type,
        });
        newFile.append('image', newName);
        newFile.append('plan_id', data._id);

        httpClient
          .post('api/plan/add-avatar', newFile)
          .then((res) => {
            const newPlan = data;
            newPlan.hasAvatar = true;
            setData(newPlan);
          })
          .catch((err) =>
            showNotification('error', err?.response?.data?.message)
          );
      } catch (e) {
        console.error(e);
      }
    } else {
      try {
        const croppedImage = await getCroppedImg(
          imageSrc,
          croppedAreaPixels,
          rotation
        );

        const getCanvas = await getCroppedCanvas(
          imageSrc,
          croppedAreaPixels,
          rotation
        );
        const getFile = getFileFromImage(getCanvas, 'customerimage');
        setCustomerPhoto(croppedImage);
        setCustomerPhotoDetails(getFile);
      } catch (e) {
        console.error(e);
      }
    }
  }, [imageSrc, croppedAreaPixels, rotation]);

  const onAspectChange = (e) => {
    const value = e.target.value;
    const ratio = aspectRatios.find((ratio) => ratio.value == value);
    setAspect(ratio);
  };

  return (
    <React.Fragment>
      <div
        style={{
          position: 'relative',
          width: '100%',
          minHeight: '400px',
          objectFit: 'cover',
        }}
      >
        <Cropper
          image={imageSrc}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          restrictPosition={false}
          aspect={aspect.value}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          onRotationChange={setRotation}
          style={{
            containerStyle: {
              backgroundColor: 'transparent',
            },
            cropAreaStyle: {
              borderRadius: '6px',
            },
          }}
        />
      </div>
      <div>
        <div>
          <Typography
            style={{
              fontFamily: 'InterBold',
              fontStyle: 'normal',
              fontSize: '12px',
              lineHeight: '20px',
              display: 'flex',
              alignItems: 'center',
              color: black,
            }}
          >
            Zoom
          </Typography>
          <Slider
            value={zoom}
            min={0.1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => {
              setZoom(zoom);
            }}
          />
        </div>
        <div>
          <Typography
            style={{
              fontFamily: 'InterBold',
              fontStyle: 'normal',
              fontSize: '12px',
              lineHeight: '20px',
              display: 'flex',
              alignItems: 'center',
              color: black,
            }}
          >
            Rotation
          </Typography>
          <Slider
            value={rotation}
            min={0}
            max={360}
            step={1}
            aria-labelledby="Rotation"
            onChange={(e, rotation) => setRotation(rotation)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            gap: '20px',
            justifyContent: 'space-between',
          }}
        >
          <Box style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <DynamicButton
              onClick={cancelResizing}
              type="outlined"
              color="primary"
            >
              Cancel
            </DynamicButton>
            <DynamicButton
              onClick={showCroppedImage}
              type="contained"
              color="primary"
            >
              Save
            </DynamicButton>
          </Box>

          <Select
            value={aspect.value}
            name="aspect"
            onChange={onAspectChange}
            style={{
              backgroundColor: 'rgb(250, 250, 250)',
              height: '40px',
              marginRight: '24px',
            }}
          >
            {aspectRatios.map((ratio) => (
              <MenuItem
                key={ratio.value}
                value={ratio.value}
                style={{ color: 'black' }}
              >
                {ratio?.text}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    </React.Fragment>
  );
};

export const styles = (theme) => ({
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    backgroundColor: 'transparent',
    borderRadius: '100px',
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 16,
  },
});

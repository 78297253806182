import React from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { FormHelperText } from '@mui/material';
import { black20, gallery } from '../../constants/colors';

const AppDatePicker = ({
  name,
  value,
  disabled,
  disablePast = false,
  handleChange,
  slotPropsStyles,
  inputStyles,
  helperText,
  overdue = false,
  readOnly = false,
  createOrEditModal = false,
}) => {
  return (
    <>
      <DesktopDatePicker
        value={value ? dayjs(value) : null}
        format="DD MMM, YYYY"
        disabled={disabled}
        readOnly={readOnly}
        className="multiColorSelectInput"
        sx={{
          minWidth: '150px',
          width: '100%',
          marginBottom: '8px',
          height: createOrEditModal ? 'auto' : '38px',
          '& .MuiInputBase-root': {
            height: '40px !important',
            fontSize: '14px !important',
            fontFamily: 'InterRegular !important',
            paddingRight: createOrEditModal || disabled ? '14px' : '0',
            ...inputStyles,
          },
          '& .Mui-focused fieldset': {
            border: overdue ? 'none' : '1px solid black !important',
            marginTop: '1px',
          },
          '.MuiOutlinedInput-notchedOutline': {
            marginTop: '1px',
            border: overdue
              ? `none !important`
              : `1px solid ${black20} !important`,
          },
          '& .MuiInputAdornment-root': {
            width: '35px !important',
          },
          '& .MuiPickersDay-root': {
            '&.Mui-selected': {
              backgroundColor: 'red',
            },
          },
        }}
        disablePast={disablePast}
        onChange={(newValue) =>
          handleChange({ target: { name, value: newValue } })
        }
        slotProps={{
          button: { padding: '10px' },
          openPickerIcon: {
            sx: {
              fontSize: '18px',
              padding: '0',
              paddingRight: '0',
              color: disabled ? 'rgba(0, 0, 0, 0.2)' : 'rgb(31, 31, 31)',
            },
          },
          textField: { placeholder: 'Enter date' },
          ...slotPropsStyles,
        }}
      />
      {helperText && (
        <FormHelperText sx={{ fontSize: '12px' }}>{helperText}</FormHelperText>
      )}
    </>
  );
};

export default AppDatePicker;

import React, { useContext, useEffect, useState } from 'react';
import { Box, Divider, Grid, Pagination } from '@mui/material';
import DashboardHeader from '../components/common/DashboardHeader';
import AppTable from '../components/common/AppTable';
import { sortDirections, templateHeaders } from '../utils/constants';
import { templateDecorator } from '../utils/templateDecorator';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Menu, MenuItem } from '@mui/material';
import { CreateNewTemplate } from '../components/Plans/CreateNewTemplate';
import { ShareTemplateModal } from '../components/Plans/ShareTemplateModal';
import { useNavigate } from 'react-router-dom';
import { DeletePlanModal } from '../components/Plans/DeletePlanModal';
import {
  black,
  lightGray,
  primary,
  scorpion,
  silver,
  text,
  warning,
  white,
} from '../constants/colors';
import { TemplateIcon } from '../assets';
import { TEMPLATES_TAB } from '../utils/enums';
import httpClient from '../config/http.service';
import { store } from '../Store';
import {
  formatToTableDate,
  getAccessList,
  randomColor,
  sortPlans,
} from '../utils/utils';
import DashboardSidebar from '../components/common/DashboardSidebar';
import withDashboardHeader from '../hoc/withDashboardHeader';
import DynamicButton from '../components/new/Button';
import AddIcon from '@mui/icons-material/Add';
import { showNotification } from '../components/common/Toast';
import { CreatePlanStep1 } from '../modals/CreatePlanStep1';
import { Container } from '@mui/system';
import useDebounce from '../utils/hooks/useDebounce';

const data = [
  {
    _id: '1',
    name: 'Adarn & Manuel Inc Mutual Action Plan',
    description: 'This is for when I want to clear my Q1 goals 2 months ahead.',
    updatedAt: 'Aug 2, 2019 19:28',
    isDefault: true,
    logo: TemplateIcon,
  },
  {
    _id: '2',
    name: 'Adarn & Manuel Inc Mutual Action Plan',
    description:
      'This is for when I want to clear my Q1 goals 2 months ahead and my manager set a target for Q2',
    updatedAt: 'Aug 2, 2019 19:28',
    isDefault: false,
    logo: TemplateIcon,
  },
  {
    _id: '3',
    name: 'Adarn & Manuel Inc Mutual Action Plan',
    description: '',
    updatedAt: 'Aug 2, 2019 19:28',
    isDefault: false,
    logo: TemplateIcon,
  },
];

const Templates = () => {
  const { state } = useContext(store);
  const [templates, setTemplates] = useState([]);
  const [defCurrency, setDefCurrency] = useState('')
  const [sharedTemplates, setSharedTemplates] = useState([]);
  const [templatesLoading, setTemplatesLoading] = useState(true);
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [openCreateTemplateModal, setOpenCreateTemplateModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [shared, setShared] = useState([]);
  const [active, setActive] = useState('myTemplates');
  const [openDeleteTemplateModal, setOpenDeleteTemplateModal] = useState(false);
  const [activeRow, setActiveRow] = useState(null);
  const [openCreatePlan, setOpenCreatePlan] = useState(false);
  const [customerImage, setCustomerImage] = useState(null);
  const [customerImageDetails, setCustomerImageDetails] = useState(null);
  const [planData, setPlanData] = useState({
    value: { currency: defCurrency, amount: 0 },
  });
  const [stagesForNewPlan, setStagesForNewPlan] = useState(null);
  const [duplicate, setDuplicate] = useState(null);
  const [company, setCompany] = useState(null);
  const [page, setPage] = useState(1);
  const itemsPerPage = 8;
  const totalPages = Math.ceil(templates?.length / itemsPerPage);
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  let displayedPlans = templates?.slice(startIndex, endIndex) ?? [];
  const [isSharing, setIsSharing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [initialPlans, setInitialPlans] = useState(null);
  const [selectedSortOption, setSelectedSortOption] = useState({
    name: 'Title',
    key: 'title',
  });
  const [selectedSortDirection, setSelectedSortDirection] = useState({
    name: 'Ascending',
    key: 'asc',
  });

  useEffect(() => {
    if(state?.data?.user?.defaultCurrency){
      setDefCurrency(state.data.user.defaultCurrency)
      const planWithNewCurrency = {...planData, value: { currency: state.data.user.defaultCurrency }}
      setPlanData(planWithNewCurrency)
    }
  }, [state?.data?.user?.defaultCurrency])

  useEffect(() => {
    if (searchTerm !== '') {
      getPlansWithSearch();
    } else {
      setTemplates(initialPlans);
    }
  }, [debouncedSearchTerm]);

  const getPlansWithSearch = () => {
    const filteredTemplates = initialPlans.filter((temp) =>
      temp.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setTemplates(filteredTemplates);
  };

  const getCompany = async (companyId) => {
    if (companyId) {
      httpClient
        .get(`api/company/get`, { params: { _id: companyId } })
        .then(async (res) => {
          setCompany(res.data);
        })
        .catch((err) => {
          // showNotification('error', err?.response?.data?.message)
        });
    }
  };

  const getPlans = async (companyUser) => {
    if (companyUser.company) {
      try {
        const res = await httpClient.get(`api/template/all`, {
          params: { _id: companyUser },
        });
        if (res.status === 200) {
          const valueToString = res.data;

          let newTemplates = [];
          let sharedlist = [];
          for (let el of valueToString) {
            let obj = {};
            obj._id = el._id + '';
            obj.description = el.introduction
              ? el.introduction
              : el.description;
            obj.updatedAt = el.updatedAt
              ? formatToTableDate(el.updatedAt)
              : '-';
            obj.access = getAccessList(el) || '-';
            const getOwner = await httpClient.get(`api/user/get`, {
              params: { _id: el.userId },
            });
            obj.userId = getOwner.status === 200 ? getOwner.data : null;
            obj.shareList = el.shareList;
            obj.logo = '';
            obj.name = el.name;

            sharedlist.push(el.shareList);
            newTemplates.push(obj);
          }
          setTemplates(newTemplates);
          setInitialPlans(newTemplates);
        }
      } catch (err) {
        console.log('______ERR', err);
        // showNotification('error', err?.response?.data?.message);
      }
    }
  };
  useEffect(() => {
    if (state?.data?.user?.company) {
      getPlans(state.data.user);
      getCompany(state.data.user.company);
    }
  }, [state?.data?.user, activeRow]);

  const handleActionsClick = (event, row) => {
    setActiveRow(row);
    setActionsAnchorEl(event.currentTarget);
  };

  const handleActionsClose = () => {
    setOpenDeleteTemplateModal(false);
    setActionsAnchorEl(null);
  };

  const optionsForSharedUsers = [
    {
      name: 'Create plan from template',
      handleClick: () => {
        getTemplate(activeRow?._id);
        setOpenCreatePlan(true);
      },
    },
    {
      name: 'View template',
      handleClick: (e) => {
        navigate(`/view-template/${activeRow?._id}`, {
          state: { templateId: activeRow?._id },
        });
      },
    },
    {
      name: 'Duplicate',
      handleClick: (e) => {
        getTemplate(activeRow?._id);
        setDuplicate(true);
        setOpenCreateTemplateModal(true);
      },
    },
  ];

  const options = [
    {
      name: 'Edit template',
      handleClick: (e) => navigate(`/view-template/${activeRow?._id}/edit`),
    },
    {
      name: 'Create plan from template',
      handleClick: () => {
        getTemplate(activeRow?._id);
        setOpenCreatePlan(true);
      },
    },
    {
      name: 'Share template',
      handleClick: () => setOpenShareModal(true),
    },
    {
      name: 'Duplicate',
      handleClick: (e) => {
        getTemplate(activeRow?._id);
        setDuplicate(true);
        setOpenCreateTemplateModal(true);
      },
    },
    {
      name: 'Delete template',
      handleClick: () => setOpenDeleteTemplateModal(true),
      color: warning,
    },
  ];

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (actionsAnchorEl && !actionsAnchorEl.contains(event.target)) {
        handleActionsClose();
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [actionsAnchorEl]);

  const renderActions = (renderItem, ind) => {
    const row = templates.find((item) => item._id === renderItem?._id);
    return (
      <Box sx={styles.actions}>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <MoreHorizIcon
            sx={{ cursor: 'pointer' }}
            aria-haspopup="true"
            aria-controls={
              Boolean(actionsAnchorEl) ? 'plan-table-actions' : undefined
            }
            aria-expanded={Boolean(actionsAnchorEl) ? 'true' : undefined}
            onClick={(e) => handleActionsClick(e, row)}
          />
          <Menu
            id={'plan-table-actions'}
            sx={{
              color: 'white',
              boxShadow:
                '0px 1px 5px -13px rgba(0,0,0,0.05), 0px 4px 5px -13px rgba(0,0,0,0.04), 0px 3px 5px -1px rgba(0,0,0,0.02)',
            }}
            open={Boolean(actionsAnchorEl)}
            anchorEl={actionsAnchorEl}
            onClose={() => handleActionsClose}
            PaperProps={{ sx: styles.paper }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            {isSharing
              ? optionsForSharedUsers?.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={item.handleClick}
                    sx={{
                      ...styles.menuItem,
                      color: item.color ? item.color : black,
                    }}
                  >
                    {item.name}
                  </MenuItem>
                ))
              : options.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={item.handleClick}
                    sx={{
                      ...styles.menuItem,
                      color: item.color ? item.color : black,
                    }}
                  >
                    {item.name}
                  </MenuItem>
                ))}
          </Menu>
        </Box>
      </Box>
    );
  };

  const getTemplates = () => {
    const updatedPlans = templates.map((template) => ({
      ...templateDecorator(template),
    }));
    setTemplates(updatedPlans);

    setTimeout(() => {
      setTemplatesLoading(false);
    }, 2000);
  };

  const getSharedTemplates = async (userEmail) => {
    try {
      const res = await httpClient.get(`api/template/shared`, {
        params: { email: userEmail },
      });
      const valueToString = res.data;
      let newTemplates = [];
      for (let el of valueToString) {
        let obj = {};
        obj._id = el._id + '';
        obj.name = el.name;
        obj.description = el.description;
        const getOwner = await httpClient.get(`api/user/get`, {
          params: { _id: el.userId },
        });
        obj.owner = getOwner?.data?.fullName ? getOwner.data.fullName : 'N/A';
        obj.sharedWith = el.shareList;
        obj.updatedAt = formatToTableDate(el.updatedAt);
        // if (el.hasAvatar) {
        //   obj.logo = await getCustomerPhoto(el._id);
        // } else {
        //   obj.logo = '';
        // }

        newTemplates.push(obj);
      }
      const updatedPlans = newTemplates.map((template) => ({
        ...templateDecorator(template),
      }));
      setSharedTemplates(updatedPlans);

      setTimeout(() => {
        setTemplatesLoading(false);
      }, 2000);
    } catch (err) {
      // showNotification('error', err?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (active === TEMPLATES_TAB.myTemplates) getTemplates();
    else {
      if (state?.data?.user?.email) getSharedTemplates(state.data.user.email);
    }
  }, [active]);

  const handleClose = () => setOpenCreateTemplateModal(false);
  const handleCloseShareModal = () => setOpenShareModal(false);

  const onClickRow = (item) => {
    setSelectedRow(item);
  };

  const handleDelete = () => {
    if (active === TEMPLATES_TAB.myTemplates) {
      httpClient
        .delete('api/template/delete', { data: { _id: activeRow?._id } })
        .then((response) => {
          const withoutRemoved = templates.filter(
            (item) => item._id !== activeRow?._id
          );
          setTemplates(withoutRemoved);
          showNotification('success', 'Template successfully deleted!');
        })
        .catch((err) => {
          // showNotification('error', err?.response?.data?.message)
        });
    } else {
      const withoutRemoved = sharedTemplates.filter(
        (item) => item._id !== activeRow?._id
      );
      httpClient
        .get('api/template/get', { params: { _id: activeRow._id } })
        .then((temp) => {
          let newShareList = temp.data.shareList.filter(
            (el) => el.email !== state?.data?.user?.email
          );

          httpClient
            .patch('api/template/update-share-list', {
              _id: activeRow._id,
              shareList: newShareList,
            })
            .then(() => {
              showNotification(
                'success',
                'Template successfully deleted from me!'
              );
              navigate('/templates');
              setSharedTemplates(withoutRemoved);
            })
            .catch((err) => {
              // showNotification('error', err?.response?.data?.message)
            });
        })
        .catch((err) => {
          // showNotification('error', err?.response?.data?.message)
        });
      // get template with id activeRow._id and remove that uesr from shareList and send newList to patch
    }

    setOpenDeleteTemplateModal(false);
  };

  const getTemplate = (templateId) => {
    if (templateId) {
      httpClient
        .get(`api/template/get`, { params: { _id: templateId } })
        .then(async (res) => {
          setStagesForNewPlan(res.data.stages);
          setDuplicate(res.data);
          return res.data;
        })
        .catch((err) => {
          // showNotification('error', err?.response?.data?.message)
        });
    }
  };

  const createPlan = async () => {
    const obj = {
      ...planData,
      // name: activeRow.name,
      description: activeRow.description,
    };
    obj.stages = [];
    obj.stages = stagesForNewPlan.stages;
    obj.shareList = [];
    obj.vendor = state?.data?.user?.company;
    obj.vendorRepresentative = state?.data?.user.fullName;
    obj.vendorColor = randomColor();
    obj.templateId = activeRow?._id;
    let newFile = new FormData();
    if (customerImageDetails) {
      const newName = new File([customerImageDetails], 'customerimage', {
        type: customerImage.type,
      });
      newFile.append('image', newName);
      newFile.append('plan', JSON.stringify(obj));
    } else {
      newFile.append('image', {});
      newFile.append('plan', JSON.stringify(obj));
    }

    httpClient
      .post('api/plan/new', newFile)
      .then((res) => {
        showNotification(
          'success',
          'Successfully created plan!',
          'dark',
          'bottom-right'
        );
        navigate(`/plan/${res.data._id}`);
        setOpenCreateTemplateModal(false);
      })
      .catch((err) => showNotification('error', err?.response?.data?.message));
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearch = (e) => setSearchTerm(e.target.value);

  const handleSortChange = (item) => {
    setSelectedSortOption(item);
    const sortedArray = sortPlans(
      item.key,
      displayedPlans,
      selectedSortDirection.key
    );
    displayedPlans = sortedArray;
    setTemplates(sortedArray);
  };

  const handleDirectionChange = (item) => {
    setSelectedSortDirection(item);
    const sortedArray = sortPlans(
      selectedSortOption.key,
      displayedPlans,
      item.key
    );
    displayedPlans = sortedArray;
    setTemplates(sortedArray);
  };

  return (
    <Grid sx={styles.main} container>
      <Grid item xs={1} sx={{ width: '96px', maxWidth: '96px !important' }}>
        <DashboardSidebar active="templates" />
      </Grid>
      <Grid
        item
        xs={11}
        sx={{
          padding: '0 30px',
          marginTop: '15px',
          borderLeft: '1px solid #DBDFDF',
        }}
      >
        <Box sx={styles.header}>
          <DashboardHeader
            title={'Templates'}
            handleSearch={handleSearch}
            listOfPlans={true}
          />
          <DynamicButton
            type="contained"
            onClick={() => setOpenCreateTemplateModal(true)}
            disabled={false}
            startIcon={<AddIcon />}
            style={{ marginTop: '20px' }}
          >
            Create new
          </DynamicButton>
        </Box>
        <Divider sx={{ margin: '32px 0' }} />
        <CreateNewTemplate
          open={openCreateTemplateModal}
          handleClose={handleClose}
          duplicate={duplicate ? true : false}
          template={duplicate}
          company={company}
        />
        <CreatePlanStep1
          data={planData}
          setData={setPlanData}
          open={openCreatePlan}
          handleClose={() => setOpenCreatePlan(false)}
          customerPhoto={customerImage}
          setCustomerPhoto={setCustomerImage}
          customerPhotoDetails={customerImageDetails}
          setCustomerPhotoDetails={setCustomerImageDetails}
          createFromTemplate={false}
          edit={false}
          createPlan={createPlan}
          currency={defCurrency}
        />
        <ShareTemplateModal
          open={openShareModal}
          handleClose={handleCloseShareModal}
          shared={shared}
          setShared={setShared}
          template={activeRow}
          setTemplate={setActiveRow}
        />
        <Container maxWidth="xxl" sx={styles.tableContainer}>
          <AppTable
            data={displayedPlans ?? templates ?? []}
            headers={templateHeaders}
            actions={renderActions}
            loading={templatesLoading}
            onClickRow={onClickRow}
            template={true}
            handleCreate={() => setOpenCreateTemplateModal(true)}
            setSelectedSortOption={setSelectedSortOption}
            handleSortChange={handleSortChange}
            handleDirectionChange={handleDirectionChange}
            sortDirectionMaps={selectedSortDirection}
            setDirectionMaps={setSelectedSortDirection}
          />
          {templates?.length > 0 && (
            <Box sx={{ marginTop: '20px' }}>
              <Divider sx={styles.paginationDivider} />
              <Box style={styles.pagination_row}>
                {page > 1 ? (
                  <DynamicButton
                    type="text"
                    style={{
                      borderColor: '#EBEBEB',
                      backgroundColor: 'rgb(246, 245, 244)',
                    }}
                    onClick={() => setPage(page - 1)}
                  >
                    Previous
                  </DynamicButton>
                ) : (
                  <div style={{ width: '130px' }}></div>
                )}
                <Pagination
                  // count={data.length}
                  count={totalPages}
                  page={page}
                  hidePrevButton
                  hideNextButton
                  style={styles.pagination}
                  // className={stylesPlan.bgPagination}
                  sx={{
                    // backgroundColor: 'rgb(246, 245, 244)',
                    '.Mui-selected': {
                      backgroundColor: `${primary} !important`,
                      color: 'white',
                    },
                  }}
                  onChange={handlePageChange}
                />
                {page < totalPages ? (
                  <DynamicButton
                    type="text"
                    style={{
                      borderColor: '#EBEBEB',
                      backgroundColor: 'rgb(246, 245, 244)',
                    }}
                    onClick={() => setPage(page + 1)}
                  >
                    Next
                  </DynamicButton>
                ) : (
                  <div style={{ width: '86px', marginLeft: '20px' }}></div>
                )}
              </Box>
            </Box>
          )}
        </Container>
        <DeletePlanModal
          plan={false}
          open={openDeleteTemplateModal}
          handleClose={handleActionsClose}
          handleDelete={handleDelete}
        />
      </Grid>
    </Grid>
  );
};

export default withDashboardHeader(Templates);

const styles = {
  main: {
    backgroundColor: `#F6F5F4 !important`,
    minHeight: '100vh !important',
    display: 'flex',
    flexWrap: 'nowrap',
    // maxWidth: '100%',
    width: '100%',
  },
  paper: {
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.06)',
  },
  container: { marginTop: '44px' },
  tableContainer: {
    marginTop: '25px',
    padding: '0 !important',
    marginLeft: '0',
  },
  header: {
    display: 'flex !important',
    justifyContent: 'space-between !important',
    flexWrap: 'wrap !important',
    alignItems: 'center',
    marginBottom: '25px',
  },
  heading: {
    fontFamily: 'SoehneBold',
    fontSize: '20px',
    lineHeight: '24px',
    color: primary,
  },
  subheading: {
    background: white,
    boxShadow:
      '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
    borderRadius: 6,
    padding: '10px 14px',
    gap: 8,
    cursor: 'pointer',
  },
  subheadingText: {
    fontFamily: 'SoehneBold',
    fontSize: '16px',
    lineHeight: '32px',
    color: primary,
    cursor: 'pointer',
  },
  inactive: {
    fontSize: '14px',
    lineHeight: '32px',
    color: lightGray,
    cursor: 'pointer',
  },
  addBtn: {
    fontSize: '14px',
    lineHeight: '32px',
    borderRadius: '24px',
    height: '40px',
    padding: '10px 20px',
    boxShadow: 'none',
    marginRight: '10px',
    gap: '13px',
    marginTop: '20px',
    '& svg': { fontSize: '18px' },
    '@media screen and (min-width: 670px)': {
      marginTop: '0',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px',
  },
  editIcon: { width: '22px', height: '22px', cursor: 'pointer' },
  outlinedStarIcon: { color: scorpion, fontSize: '30px', cursor: 'pointer' },
  deleteIcon: { color: warning, fontSize: '26px', cursor: 'pointer' },
  opportunities: {
    borderRight: `1px solid ${silver}`,
    paddingRight: 20,
    marginRight: 20,
    color: text,
    pointer: 'cursor',
    fontSize: '20px',
  },
  tabs: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    paddingVertical: '20px',
    marginBottom: '20px',
    padding: '9px',
    '@media screen and (min-width: 500px)': {
      justifyContent: 'space-between',
    },
  },
  tabsItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media screen and (min-width: 500px)': {
      flexDirection: 'row',
    },
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '0',
    background: white,
    borderRadius: '8px',
    padding: '10px 14px',
    gap: '8px',
    '@media screen and (min-width: 500px)': {
      marginLeft: '20px',
    },
  },
  paginationDivider: { mt: '20px', border: '1px solid #E9ECEC' },
  paginationBox: { display: 'flex', justifyContent: 'center', mt: '20px' },
  pagination: {
    '.MuiSelected': {
      backgroundColor: `${primary} !important`,
      color: 'white',
    },
  },
  pagination_row: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
};

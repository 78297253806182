import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { ReactComponent as Check } from '../../assets/imgs/check_circle.svg';
import { ReactComponent as StageStartIcon } from '../../assets/imgs/StageStart.svg';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { white } from '../../constants/colors';
import { useState, useEffect } from 'react';
import { Tooltip } from '@mui/material';
import {
  getDateRanges,
  getNumberOfCompletedActivitiesAndSubactivities,
  getTotalCount,
} from '../../utils/utils';

const CustomStepIcon = (props) => {
  const { active, completed, className, icon } = props;
  return (
    <div
      className={`${className} ${className}-custom`}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 24,
        height: 24,
        borderRadius: '50%',
        backgroundColor:
          icon === 1
            ? 'white'
            : active
            ? `rgba(0, 155, 119, ${
                props.percentage !== 0 ? props.percentage : 0.1
              })`
            : completed
            ? 'white'
            : 'rgba(230, 230, 230, 1)',
      }}
    >
      {icon === 1 ? (
        <StageStartIcon style={styles.startLabel} />
      ) : completed ? (
        <Check style={{ width: '100%', height: '100%' }} />
      ) : active ? (
        <span
          style={{
            backgroundColor: 'rgba(0, 155, 119, 1)',
            width: '15px',
            height: '15px',
            borderRadius: '50%',
          }}
        ></span>
      ) : null}
    </div>
  );
};

const QontoConnector = styled(StepConnector)(({ theme, completed }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderRadius: '100px',
      backgroundImage: `linear-gradient(to right, rgb(0, 155, 119) ${completed}%, rgb(234, 234, 240) 0%)`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: 'rgba(0, 155, 119, 1)',
      borderRadius: '100px',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgb(230, 230, 230)'
        : 'rgb(230, 230, 230)',
    height: 5,
    border: 'none',
    borderRadius: '100px',
  },
}));

const findCurrentActivityIndex = (arrayOfObj) => {
  for (let i = 0; i < arrayOfObj.length; i++) {
    const data = arrayOfObj[i];

    if (data?.activities) {
      for (let j = 0; j < data.activities.length; j++) {
        const activity = data.activities[j];
        let isCompleted = true;

        if (activity.status !== 'completed') isCompleted = false;

        if (activity.subActivities) {
          for (let k = 0; k < activity.subActivities.length; k++) {
            if (activity.subActivities[k].status !== 'completed') {
              isCompleted = false;
              break;
            }
          }
        }

        if (!isCompleted) {
          return { dataIndex: i };
        }
      }
    }
  }

  return -1;
};

export const HorizontalLabelPositionBelowStepper = ({
  steps,
  isVendor = false,
}) => {
  const [newSteps, setNewSteps] = useState(steps);
  const [connectorWidth, setConnectorWidth] = useState('100px');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    setNewSteps(steps);
  }, [steps]);

  const currentActivityIndex = findCurrentActivityIndex(steps);
  const currectActivity = newSteps
    ? newSteps[currentActivityIndex.dataIndex]
    : null;

  const connectorRef = React.useRef(null);

  const calculateConnectorWidth = () => {
    if (connectorRef.current) {
      const connectorWidth =
        connectorRef.current?.getBoundingClientRect().width;

      setConnectorWidth(`${connectorWidth}px`);
      return connectorWidth;
    }
  };

  useEffect(() => {
    calculateConnectorWidth();

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [newSteps?.length]);

  useEffect(() => {
    calculateConnectorWidth();
  }, [windowWidth]);

  let completed = 0;
  completed = getNumberOfCompletedActivitiesAndSubactivities(
    currectActivity,
    isVendor
  );

  return (
    <Box sx={styles.stepperBox}>
      <Stepper
        sx={styles.stepper}
        activeStep={
          currectActivity
            ? currentActivityIndex.dataIndex + 1
            : steps.length + 1
        }
        connector={
          <QontoConnector
            ref={connectorRef}
            style={{
              width:
                steps.length > 4
                  ? windowWidth >= 1800
                    ? `${100 / steps.length - 2.4}%`
                    : windowWidth >= 1700
                    ? `${100 / steps.length - 2.6}%`
                    : windowWidth >= 1600
                    ? `${100 / steps.length - 2.6}%`
                    : windowWidth >= 1275
                    ? `${100 / steps.length - 3.3}%`
                    : `${100 / steps.length - 3.7}%`
                  : steps.length === 4
                  ? windowWidth >= 1800
                    ? `${100 / steps.length - 2.7}%`
                    : windowWidth >= 1700
                    ? `${100 / steps.length - 2.7}%`
                    : windowWidth >= 1570
                    ? `${100 / steps.length - 3}%`
                    : windowWidth >= 1170
                    ? `${100 / steps.length - 3.6}%`
                    : windowWidth >= 870
                    ? `${100 / steps.length - 4}%`
                    : `${100 / steps.length - 5.7}%`
                  : steps.length === 3
                  ? windowWidth >= 1800
                    ? `${100 / steps.length - 2.7}%`
                    : windowWidth >= 1700
                    ? `${100 / steps.length - 2.8}%`
                    : windowWidth >= 1570
                    ? `${100 / steps.length - 3}%`
                    : windowWidth >= 1170
                    ? `${100 / steps.length - 4}%`
                    : windowWidth >= 870
                    ? `${100 / steps.length - 5}%`
                    : `${100 / steps.length - 7}%`
                  : windowWidth >= 1800
                  ? `${100 / steps.length - 3}%`
                  : windowWidth >= 1700
                  ? `${100 / steps.length - 3.2}%`
                  : windowWidth >= 1550
                  ? `${100 / steps.length - 3.7}%`
                  : windowWidth >= 1170
                  ? `${100 / steps.length - 4.5}%`
                  : windowWidth >= 870
                  ? `${100 / steps.length - 5.5}%`
                  : `${100 / steps.length - 6}%`,
            }}
            completed={
              !currectActivity
                ? 100
                : (completed / getTotalCount(currectActivity, isVendor)) * 100
            }
          />
        }
      >
        {newSteps?.map((item, index) => {
          return (
            <Step key={index} style={styles.singleStep}>
              <StepLabel
                sx={styles.label}
                StepIconComponent={CustomStepIcon}
                StepIconProps={{
                  percentage: currectActivity
                    ? getNumberOfCompletedActivitiesAndSubactivities(
                        currectActivity
                      ) / getTotalCount(currectActivity, isVendor)
                    : 0,
                }}
                classes={{
                  active: 'active',
                  completed: 'completed',
                  root: 'root',
                }}
              >
                <span
                  style={{
                    ...styles.spanRange,
                    position: 'absolute',
                    fontFamily: 'InterMedium',
                    width: connectorWidth,
                    color:
                      currentActivityIndex.dataIndex > index
                        ? 'rgba(0, 0, 0, 0.7)'
                        : currentActivityIndex.dataIndex === index
                        ? 'rgba(0, 0, 0, 0.88)'
                        : 'rgba(0, 0, 0, 0.6)',
                  }}
                >
                  {getDateRanges(item) && getDateRanges(item).startDate}
                  {getDateRanges(item)?.endDate &&
                    ' - ' + getDateRanges(item).endDate}
                </span>
                <span
                  style={{
                    ...styles.spanLabel,
                    position: 'absolute',
                    left: 'calc(100% + 3px)',
                    width: connectorWidth,
                    fontFamily: 'InterMedium',
                    textAlign: 'center',
                    top: '22px',
                    color:
                      currentActivityIndex.dataIndex > index
                        ? 'rgba(0, 0, 0, 0.7)'
                        : currentActivityIndex.dataIndex === index
                        ? 'rgba(0, 0, 0, 0.88)'
                        : 'rgba(0, 0, 0, 0.6)',
                  }}
                >
                  <Tooltip title={item.name} enterDelay={500}>
                    <span>
                      {item.name.substring(0, 15)}
                      {item.name?.length > 15 && '...'}
                    </span>
                  </Tooltip>
                </span>
              </StepLabel>
            </Step>
          );
        })}
        <Step key={'lastEmpty'}>
          <StepLabel
            sx={styles.label}
            StepIconComponent={CustomStepIcon}
            StepIconProps={{
              percentage: currectActivity
                ? getNumberOfCompletedActivitiesAndSubactivities(
                    currectActivity
                  ) / getTotalCount(currectActivity, isVendor)
                : 0,
            }}
            classes={{
              active: 'active',
              completed: 'completed',
              root: 'root',
            }}
          >
            <span
              style={{
                ...styles.spanRange,
                position: 'absolute',
                fontFamily: 'InterMedium',
                width: connectorWidth,
              }}
            ></span>
            <span
              style={{
                ...styles.spanLabel,
                position: 'absolute',
                left: 'calc(100% + 3px)',
                width: connectorWidth,
                textAlign: 'center',
                top: '22px',
              }}
            ></span>
          </StepLabel>
        </Step>
      </Stepper>
    </Box>
  );
};

const styles = {
  stepperBox: {
    height: '88px',
    marginTop: '20px',
    overflow: 'auto',
    width: '100%',
    overflowX: 'auto',
    marginBottom: '20px',
    backgroundColor: white,
    // padding: '0 16px',
    border: '1px solid #F2F4F7',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2)',

    borderRadius: '8px',
    '& .MuiStep-root': {
      paddingRight: '3.5px',
      paddingLeft: '3.5px',
    },
    '&::-webkit-scrollbar': {
      height: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
  },
  startLabel: {
    fontSize: '6px',
    color: '#009B77',
    width: '24px',
    height: '24px',
    background: '#FFFFFF',
    boxShadow: '0px 2px 4px rgba(0, 129, 140, 0.05)',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stepper: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    justifyContent: 'center',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .MuiStepLabel-label': {
      marginTop: 1,
      fontWeight: 'inherit',
    },
    '& .MuiStepLabel-iconContainer': {
      paddingRight: 0,
    },
    position: 'relative',
    backgroundColor: white,
    marginTop: '7px',
  },
  spanRange: {
    position: 'absolute',
    left: 'calc(100% + 3px)',
    bottom: '26px',
    // width: '151px',
    textAlign: 'center',
    fontFamily: 'InterMedium',
    fontSize: '12px',
    lineHeight: '16px',
  },
  spanLabel: {
    position: 'absolute',
    left: 'calc(100% + 20px)',
    width: '160px',
    textAlign: 'center',
    fontWeight: '600',
    top: '25px',
  },
};

import React, { useRef, useState } from 'react';
import { Box, InputAdornment, MenuItem, Select } from '@mui/material';
import { black88, lightGray, primary } from '../../constants/colors';
import '../../styles/plans/customeTextarea.css';
import emailSvg from '../../assets/imgs/listEmail.svg';
import phoneSvg from '../../assets/imgs/listPhoneCall.svg';
import zoomTeamsSvg from '../../assets/imgs/listZoomTeams.svg';
import inPersonSvg from '../../assets/imgs/listInPersonMeeting.svg';
import onlyVendorSvg from '../../assets/imgs/listOnlyVendor.svg';
import vendorInternalSvg from '../../assets/imgs/listVendorInternal.svg';
import listCustomTag from '../../assets/imgs/listCustomTag.svg';
import whiteEye from '../../assets/imgs/white-eye.svg';
import customerTask from '../../assets/imgs/listCustomerTask.svg';
import customTag from '../../assets/imgs/customTag.svg';

export const ActivityTag = ({
  name,
  value = '',
  options,
  styles,
  menuItemStyles,
  handleChange,
  fullWidth = false,
  minutesShorthand = false,
  disabled = false,
  readOnly = false,
}) => {
  const [open, setOpen] = useState(false);

  const getIcon = (image, leftIcon = false) => {
    if (image === 'vendor_internal_not_shared') return vendorInternalSvg;
    else if (image === 'customer_internal') return customerTask;
    else if (image === 'in_person_meeting') return zoomTeamsSvg;
    else if (image === 'zoom_teams_meeting') return inPersonSvg;
    else if (image === 'phone_call') return phoneSvg;
    else if (image === 'email') return emailSvg;
    else if (image === 'vendor_internal') return onlyVendorSvg;
    else {
      if (leftIcon) return customTag;
      else return listCustomTag;
    }
  };

  return (
    <>
      <Select
        open={disabled || readOnly ? false : open}
        disabled={disabled}
        displayEmpty
        variant="outlined"
        name={name}
        value={value}
        readOnly={readOnly}
        onChange={handleChange}
        fullWidth={fullWidth}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        sx={{
          ...selectStyles.select,
          ...styles,
          paddingLeft: value === '' ? '0' : '8px',
          '.MuiSelect-select': {
            borderRadius: '16px',
            paddingRight: '0 !important',
            backgroundColor:
              value === 'vendor_internal' && `${black88} !important`,
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: 'none !important',
          },
          backgroundColor:
            value === 'vendor_internal' && `${black88} !important`,
        }}
        MenuProps={selectStyles.menuProps}
        onClick={() => setOpen(!open)}
        inputProps={{ IconComponent: () => null }}
        startAdornment={
          <img
            src={value === 'vendor_internal' ? whiteEye : getIcon(value, true)}
            alt="Tag"
            className="small-image"
          />
        }
        renderValue={(selected) => {
          const option = options.find((o) => o.value === selected);
          return (
            <Box
              sx={{
                ...selectStyles.sx,
                padding: value === '' ? '0' : '6px 8px 6px 8px',
                backgroundColor:
                  value === 'vendor_internal'
                    ? `${black88} !important`
                    : 'rgba(0, 0, 0, 0.05) !important',
                color:
                  option.value !== ''
                    ? value === 'vendor_internal'
                      ? 'white'
                      : 'rgba(0, 0, 0, 0.88)'
                    : 'rgba(0, 0, 0, 0.45)',
              }}
            >
              {option?.name === 'No category' ? '' : option?.name}
            </Box>
          );
        }}
      >
        {options.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            sx={{
              ...selectStyles.menuItem,
              ...menuItemStyles,
            }}
          >
            <img src={getIcon(item.value)} alt="Tag" />
            <span
              style={{
                color: item.value === '' ? 'rgba(0, 0, 0, 0.45)' : black88,
              }}
            >
              {item?.name}
            </span>
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

const selectStyles = {
  sx: {
    fontFamily: 'InterRegular',
    fontSize: '14px',
    lineHeight: '20px',
  },
  select: {
    marginRight: '5px',
    color: lightGray,
    '.MuiOutlinedInput-input': {
      padding: '0',
    },
    borderRadius: '16px',
    border: 'none',
    justifyContent: 'center',
  },
  menuItem: {
    padding: '10px, 12px, 10px, 12px',
    mb: '10px',
    gap: '10px',
    justifyContent: 'center',
    borderRadius: '8px',
    fontSize: '14px',
    fontFamily: 'InterRegular',
    lineHeight: '20px',
    '&.Mui-selected': {
      color: primary,
      backgroundColor: 'transparent',
    },
    '&:hover': {
      backgroundColor: 'rgba(142, 128, 113, 0.08) !important',
    },
  },
  menuProps: {
    MenuListProps: { sx: { minHeight: 'auto' } },
    IconComponent: { sx: { padding: '0 !important' } },
  },
  helperText: { fontSize: '11px' },
};

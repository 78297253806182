import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Container } from '@mui/material';
import InputWithLabel from '../components/common/InputWithLabel';
import styles from '../styles/resetPassword/resetPassword.module.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import DynamicButton from '../components/new/Button';
import { showNotification } from '../components/common/Toast';

const PasswordRegex =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?!.*\W)(?!.* ).{8,16}$/;

export const CreatePassword = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const location = useLocation();

  useEffect(() => {
    const params = location.search;
    const urlParams = new URLSearchParams(params);
    const token = urlParams.get('token');
    if (params.length > 1) {
      const encodedString = token;
      if (encodedString) {
        const decodedString = atob(encodedString);
        const obj = JSON.parse(decodedString);
        setToken(token);
      }
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'password') setPassword(value);
    else setConfirmPassword(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (password === confirmPassword && PasswordRegex.test(password)) {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}api/user/admin-register-user`,
            {
              token: token,
              password: password,
            }
          )
          .then(() => {
            showNotification('success', 'Successfully created password!');
            navigate('/login');
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message)
            navigate('/login');
          });
      } else if (!PasswordRegex.test(password)) {
        toast.info(
          'Password must be at 8-16 characters, 1 uppercase, 1 lowercase letter and 1 number and no special characters'
        );
      } else if (password !== confirmPassword) {
        toast.info('Password must match');
      }
    }
  };

  const createPassword = () => {
    if (password === confirmPassword && PasswordRegex.test(password)) {
      axios
        .post(`${process.env.REACT_APP_API_URL}api/user/admin-register-user`, {
          token: token,
          password: password,
        })
        .then(() => {
          showNotification('success', 'Successfully created password!');
          navigate('/login');
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message)
          navigate('/login');
        });
    } else if (!PasswordRegex.test(password)) {
      toast.info(
        'Password must be at 8-16 characters, 1 uppercase, 1 lowercase letter and 1 number and no special characters'
      );
    } else if (password !== confirmPassword) {
      toast.info('Password must match');
    }
  };

  useEffect(() => {
    const existingMetaTag = document.querySelector('meta[name="description"]');

    const newMetaTag = document.createElement('meta');
    newMetaTag.name = 'description';
    newMetaTag.content = 'Create a password';

    if (existingMetaTag) {
      existingMetaTag.parentNode.replaceChild(newMetaTag, existingMetaTag);
    } else {
      document.head.appendChild(newMetaTag);
    }
  }, []);

  return (
    <Container maxWidth="xl">
      <Box className={styles.container}>
        <Box className={styles.main_container}>
          <Box className={styles.reset_box}>
            <Typography variant="h4" align="center" className={styles.heading}>
              Create Password
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              sx={{ marginTop: '30px' }}
            >
              You are invited to Inquarter! Please enter your new password.
            </Typography>

            <form className={styles.form}>
              <InputWithLabel
                type="password"
                label="New Password"
                name="password"
                value={password}
                placeholder="Enter password"
                handleChange={(e) => handleChange(e)}
                onKeyDown={handleKeyDown}
              />
              <InputWithLabel
                type="password"
                label="Confirm Password"
                name="confirmPassword"
                value={confirmPassword}
                placeholder="Enter password"
                handleChange={(e) => handleChange(e)}
                onKeyDown={handleKeyDown}
              />
              <DynamicButton
                type="containedCenter"
                className={styles.reset_btn}
                fullWidth
                onClick={createPassword}
              >
                Create Password
              </DynamicButton>

              <Box className={styles.login}>
                <Link to="/login" className={styles.login_btn}>
                  Login
                </Link>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ContactPageIcon from '@mui/icons-material/ContactPage';

export const sortDirections = [
  { name: 'Ascending', key: 'asc' },
  { name: 'Descending', key: 'desc' },
];

export const sortPinned = (a, b) => {
  if (a.pinned && !b.pinned) {
    return -1;
  }
  if (!a.pinned && b.pinned) {
    return 1;
  }
  return 0;
};

export const planTableHeaders = [
  { key: 'avatar', title: '', minWidth: '40px', align: 'center' },
  { key: 'title', title: 'Title', minWidth: '120px' },
  {
    key: 'progress',
    title: 'Progress',
    minWidth: '80px',
    align: 'left',
  },
  {
    key: 'status',
    title: 'Status',
    minWidth: '80px',
    align: 'left',
  },
  { key: 'value', title: 'Value', minWidth: '80px' },
  { key: 'closeDate', title: 'Close Date', minWidth: '80px', align: 'left' },
  {
    key: 'stakeholders',
    title: 'Viewed',
    minWidth: '80px',
    align: 'left',
  },
  {
    key: 'updatedAt',
    title: 'Last Updated',
    minWidth: '60px',
    align: 'left',
  },
  { key: 'actions', title: '', minWidth: '20px', align: 'right' },
];

export const sharedPlanTableHeaders = [
  { key: 'title', title: 'Title', minWidth: '360px' },
  {
    key: 'owner',
    title: 'Owner',
    minWidth: '120px',
    align: 'center',
  },
  {
    key: 'progress',
    title: 'MAP progress',
    minWidth: '180px',
    align: 'center',
  },

  {
    key: 'updatedAt',
    title: 'Last Updated',
    minWidth: '170px',
    align: 'center',
  },
  { key: 'permission', title: 'Permission', minWidth: '80px' },
  { key: 'actions', title: 'Actions', minWidth: '60px', align: 'center' },
];

export const archivedPlansHeader = [
  { key: 'avatar', title: '', minWidth: '40px', align: 'center' },
  { key: 'title', title: 'Title', minWidth: '150px' },
  {
    key: 'progress',
    title: 'Progress',
    minWidth: '100px',
    align: 'left',
  },
  {
    key: 'archiveReason',
    title: 'Archive reason',
    minWidth: '100px',
    align: 'left',
  },
  { key: 'value', title: 'Value', minWidth: '80px' },
  {
    key: 'archiveDate',
    title: 'Archive Date',
    minWidth: '100px',
    align: 'left',
  },
  {
    key: 'stakeholders',
    title: 'Stakeholders',
    minWidth: '120px',
    align: 'left',
  },
  { key: 'actions', title: 'Actions', minWidth: '60px', align: 'center' },
];

export const templateHeaders = [
  { key: 'avatar', title: '', minWidth: '40px', align: 'center' },
  { key: 'name', title: 'Title', minWidth: '200px' },
  { key: 'description', title: 'Description', minWidth: '200px' },
  { key: 'access', title: 'Access', minWidth: '150px' },
  {
    key: 'updatedAt',
    title: 'Last Updated',
    minWidth: '170px',
    align: 'center',
  },
  { key: 'actions', title: 'Actions', minWidth: '60px', align: 'center' },
];

export const sharedTemplateHeaders = [
  { key: 'name', title: 'Name', minWidth: '450px' },
  { key: 'description', title: 'Description', minWidth: '250px' },
  { key: 'owner', title: 'Owner', minWidth: '170px' },
  { key: 'sharedWith', title: 'Shared with', minWidth: '170px' },
  {
    key: 'updatedAt',
    title: 'Last Updated',
    minWidth: '170px',
    align: 'center',
  },
  { key: 'actions', title: 'Actions', minWidth: '100px', align: 'center' },
];

export const planArchiveOptions = [
  { name: 'Select archive reason', value: '' },
  { name: 'Opportunity Stalled', value: 'Opportunity Stalled' },
  { name: 'Opportunity Won', value: 'Opportunity Won' },
  { name: 'Opportunity Lost', value: 'Opportunity Lost' },
  { name: 'Qualified Out', value: 'Qualified Out' },
  { name: 'Other', value: 'Other' },
];

export const planStatusOptions = [
  { name: 'No status', value: '' },
  {
    name: 'To be booked',
    value: 'to_be_booked',
    color: 'rgba(255, 242, 229, 1)',
    fontColor: 'rgba(184, 90, 0, 1)',
  },
  {
    name: 'To do',
    value: 'to_do',
    color: 'rgba(255, 245, 214, 1)',
    fontColor: 'rgba(128, 97, 0, 1)',
  },
  {
    name: 'Ongoing',
    value: 'ongoing',
    color: 'rgba(240, 248, 255, 1)',
    fontColor: 'rgba(0, 87, 153, 1)',
  },
  {
    name: 'Booked',
    value: 'booked',
    color: 'rgba(250, 235, 255, 1)',
    fontColor: 'rgba(117, 0, 153, 1)',
  },
  {
    name: 'Completed',
    value: 'completed',
    color: 'rgba(240, 255, 241, 1)',
    fontColor: 'rgba(0, 122, 94, 1)',
  },
];

export const planTagOptions = [
  { name: 'No category', value: '' },
  { name: 'Vendor Internal', value: 'vendor_internal' },
  { name: 'Customer Internal', value: 'customer_internal' },
  { name: 'In-Person Meeting', value: 'in_person_meeting' },
  { name: 'Zoom call', value: 'zoom_teams_meeting' },
  { name: 'Phone call', value: 'phone_call' },
  { name: 'Email', value: 'email' },
];

export const planTagOptionsVendor = [
  { name: 'No category', value: '' },
  {
    name: 'Vendor Internal',
    value: 'vendor_internal_not_shared',
    shortName: 'Not Shared',
  },
  { name: 'Only visible to vendor', value: 'vendor_internal' },
  { name: 'Customer Internal', value: 'customer_internal' },
  { name: 'In-Person Meeting', value: 'in_person_meeting' },
  { name: 'Zoom call', value: 'zoom_teams_meeting' },
  { name: 'Phone call', value: 'phone_call' },
  { name: 'Email', value: 'email' },
];

export const planDurationOptions = [
  { name: 'Duration', value: '' },
  { name: '15 minutes', value: '15m' },
  { name: '30 minutes', value: '30m' },
  { name: '1 hour', value: '1h' },
  { name: '2 hours', value: '2h' },
  { name: '4 hours', value: '4h' },
  { name: '1 day', value: '1d' },
  { name: 'Custom', value: 'custom' },
];

export const durationOptions = [
  { name: 'Minutes', value: 'm' },
  { name: 'Hours', value: 'h' },
  { name: 'Days', value: 'd' },
];

export const currencyOptions = [
  { name: '$', value: 'dollar' },
  { name: '€', value: 'euro' },
  { name: '£', value: 'pound' },
];

export const mockUsers = [
  { firstName: 'Pera', lastName: 'Peric', rights: 'Can View' },
  { firstName: 'Vukasin', lastName: 'Macura', rights: 'Can View' },
];

export const mockSteps = [
  { label: '🔬 DISCOVER', range: 'Jan 05 -  Jan 20', icon: RocketLaunchIcon },
  { label: '🚀 ENGAGE', range: 'Jan 24 -  Feb 27', icon: HandshakeIcon },
  { label: '🤝 DECIDE', range: 'Mar 12  -  Apr 21', icon: ContactPageIcon },
  { label: '📄 CONTRACT', range: 'Apr 30 -  May 10', icon: RocketLaunchIcon },
  { label: 'Stage 5', range: 'Apr 30 -  May 10', icon: HandshakeIcon },
  { label: 'Stage 6', range: 'Apr 30 -  May 10', icon: ContactPageIcon },
  { label: 'Stage 7', range: 'Apr 30 -  May 10', icon: RocketLaunchIcon },
  { label: 'Stage 8', range: 'Apr 30 -  May 10', icon: HandshakeIcon },
  { label: '', range: '', icon: HandshakeIcon },
];

export const jobTitlesList = [
  { value: 'CEO', label: 'CEO' },
  { value: 'CFO', label: 'CFO' },
  { value: 'CTO', label: 'CTO' },
  { value: 'COO', label: 'COO' },
  { value: 'HR Manager', label: 'HR Manager' },
  { value: 'Marketing Director', label: 'Marketing Director' },
  { value: 'Sales Representative', label: 'Sales Representative' },
  { value: 'Software Engineer', label: 'Software Engineer' },
  { value: 'Project Manager', label: 'Project Manager' },
  { value: 'Accountant', label: 'Accountant' },
  { value: 'Administrative Assistant', label: 'Administrative Assistant' },
  { value: 'Data Analyst', label: 'Data Analyst' },
  { value: 'Operations Manager', label: 'Operations Manager' },
  { value: 'Product Manager', label: 'Product Manager' },
];

export const keysFromDb = {
  _id: 'Id',
  name: 'Name',
  vendor: 'Vendor',
  vendorRepresentative: 'Vendor Representative',
  activities: 'Activities',
  description: 'Description',
  status: 'Completion Status',
  customerTeam: 'Customer team',
  vendorTeam: 'Vendor team',
  targetDate: 'Target Date',
  tag: 'Tag',
  notes: 'Notes',
  stages: 'Stages',
  duration: 'Duration',
  completionDate: 'Completion Date',
  subActivities: 'Sub activities',
  title: 'Title',
  email: 'Email',
  color: 'Color',
  archived: 'Archived',
  shared: 'Shared',
  fullName: 'Full name',
  company: 'Company',
  to_be_booked: 'To be booked',
  to_do: 'To do',
  ongoing: 'Ongoing',
  booked: 'Booked',
  completed: 'Completed',
  Completed: 'Completed',
  Overdue: 'Overdue',
  'On track': 'On track',
  vendor_internal: 'Vendor interval',
  vendor_internal_not_shared: 'Vendor interval',
  customer_internal: 'Customer Internal',
  in_person_meeting: 'In person meeting',
  zoom_teams_meeting: 'Zoom teams meeting',
  phone_call: 'Phone call',
};

export const colorFromKey = {
  '': 'black',
  to_be_booked: 'rgba(184, 90, 0, 1)',
  to_do: 'rgba(128, 97, 0, 1)',
  ongoing: 'rgba(0, 87, 153, 1)',
  booked: 'rgba(117, 0, 153, 1)',
  completed: 'rgba(0, 122, 94, 1)',
  Completed: 'rgba(0, 122, 94, 1)',
};

export const bgColorFromKey = {
  to_be_booked: 'rgba(255, 242, 229, 1)',
  to_do: 'rgba(255, 245, 214, 1)',
  ongoing: 'rgba(240, 248, 255, 1)',
  booked: 'rgba(250, 235, 255, 1)',
  completed: 'rgba(240, 255, 241, 1)',
  Completed: 'rgba(240, 255, 241, 1)',
};

export const colorsForShareList = [
  {
    backgroundColor: 'rgba(240, 255, 241, 1)',
    color: 'rgba(0, 155, 119, 1)',
  },
  {
    backgroundColor: 'rgba(255, 242, 229, 1)',
    color: 'rgba(184, 90, 0, 1)',
  },
  {
    backgroundColor: 'rgba(250, 235, 255, 1)',
    color: 'rgba(117, 0, 153, 1)',
  },
  {
    backgroundColor: 'rgba(255, 245, 214, 1)',
    color: 'rgba(128, 97, 0, 1)',
  },
  {
    backgroundColor: 'rgba(240, 248, 255, 1)',
    color: 'rgba(0, 87, 153, 1)',
  },
];

export const colorsForTeam = [
  '#FFC300',
  '#007A5E',
  '#FF7D00',
  '#005799',
  '#750099',
];

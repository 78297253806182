import React from 'react';
import ActivitySection from './ActivitySection';

const MutualActionPlan = ({
  data,
  durationOptions,
  handleExpand,
  onDragEnd,
  onActivityDragEnd,
  onSubActivityDragEnd,
  handleAddNewActivity,
  handleRemoveActivity,
  handleDeleteStage,
  handleDuplicateStage,
  handleAddTeamMember,
  handleStageActionsOpen,
  handleStageActionsClose,
  handleActivityFieldsChange,
  handleSubActivityChange,
  handleAddNewSubActivity,
  handleRemoveSubActivity,
  handleExpandSubActivities,
  handleRemoveTeamMember,
  handleTeamMembersModalOpen,
  handleEditStageTitle,
  template,
  handleCreateNewStage,
  userExist,
  isSharing,
  addUserToTeam,
  handleServiceRemove,
  handleClearInput,
  vendorCompany,
  handlePostSale,
  isPageLoaded,
  isView = false,
  handleLoginAndRedirect = null,
  isVendor = false,
}) => {
  return (
    <>
      <ActivitySection
        data={data}
        stages={data?.stages}
        durationOptions={durationOptions}
        handleExpand={handleExpand}
        onDragEnd={onDragEnd}
        onActivityDragEnd={onActivityDragEnd}
        onSubActivityDragEnd={onSubActivityDragEnd}
        handleAddNewActivity={handleAddNewActivity}
        handleRemoveActivity={handleRemoveActivity}
        handleDeleteStage={handleDeleteStage}
        handleEditStageTitle={handleEditStageTitle}
        handleDuplicateStage={handleDuplicateStage}
        handleAddTeamMember={handleAddTeamMember}
        handleStageActionsOpen={handleStageActionsOpen}
        handleStageActionsClose={handleStageActionsClose}
        handleActivityFieldsChange={handleActivityFieldsChange}
        handleSubActivityChange={handleSubActivityChange}
        handleAddNewSubActivity={handleAddNewSubActivity}
        handleRemoveSubActivity={handleRemoveSubActivity}
        handleExpandSubActivities={handleExpandSubActivities}
        handleRemoveTeamMember={handleRemoveTeamMember}
        handleTeamMembersModalOpen={handleTeamMembersModalOpen}
        template={template}
        handleCreateNewStage={handleCreateNewStage}
        userExist={userExist}
        isSharing={isSharing}
        addUserToTeam={addUserToTeam}
        handleServiceRemove={handleServiceRemove}
        handleClearInput={handleClearInput}
        vendorCompany={vendorCompany}
        handlePostSale={handlePostSale}
        isPageLoaded={isPageLoaded}
        isView={isView}
        handleLoginAndRedirect={handleLoginAndRedirect}
        isVendor={isVendor}
      />
    </>
  );
};

export default MutualActionPlan;

import React from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as HighlightOffOutlinedIcon } from '../../assets/imgs/close.svg';
import InputEmoji from 'react-input-emoji';
import { black, mercury, primary, shark } from '../../constants/colors';
import DynamicButton from '../new/Button';

export const CreateNewStageModal = ({
  stageTitle,
  handleChange,
  handleClose,
  createStage,
  handleEnter,
}) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.paper}>
        <Typography variant="h5" sx={styles.heading}>
          Create new stage
          <HighlightOffOutlinedIcon
            style={styles.closeIcon}
            onClick={handleClose}
          />
        </Typography>
        <Box style={{ height: '100px' }}>
          <Typography variant="h6" sx={styles.subheading}>
            Stage title
          </Typography>
          <InputEmoji
            onEnter={handleEnter}
            value={stageTitle}
            onChange={handleChange}
            placeholder={stageTitle.length > 0 ? '' : 'Enter stage title'}
          />
          <style>
            {`
            .react-input-emoji--button {
              position: absolute;
              top: 25px;
              right: 10px;
              z-index: 11 !important; 
            }
          .react-emoji-picker--wrapper {
            position: absolute;
            top: 50px;
            right: 0;
            height: 435px;
            width: 352px;
            overflow: hidden;
            z-index: 10 !important;     
          }
        `}
          </style>
        </Box>

        <Box sx={styles.btns}>
          <DynamicButton
            type="outlined"
            sx={styles.cancel}
            onClick={handleClose}
          >
            Cancel
          </DynamicButton>
          <DynamicButton
            type="contained"
            sx={styles.submit}
            disabled={stageTitle === ''}
            onClick={() => {
              createStage();
              // Your logic to update state goes here
              handleClose();
            }}
          >
            Create
          </DynamicButton>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    width: '100%',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    opacity: 1,
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  paper: {
    borderRadius: '8px',
    padding: '16px 24px',
    width: '560px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
  },
  heading: {
    color: black,
    fontFamily: 'InterBold',
    fontWeight: 'bolder',
    fontSize: '20px',
    lineHeight: '32px',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subheading: {
    color: black,
    fontSize: '14px',
    fontFamily: 'InterRegular',
  },
  closeIcon: {
    color: shark,
    fontSize: '30px',
    borderRadius: '50%',
    padding: '5px',
    cursor: 'pointer',
  },
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px',
    marginTop: '0px',
  },
  cancel: {
    color: black,
    width: '81px',
    height: '40px',
    background: mercury,
    borderRadius: '100px',
    boxShadow: 'none',
    border: `1px solid ${mercury}`,
    '&:hover': { background: mercury },
  },
  submit: {
    color: 'white',
    width: '138px',
    height: '40px',
    borderRadius: '100px',
    fontFamily: 'SoehneMedium',
    boxShadow: 'none',
    background: primary,
    '&:hover': { background: primary },
  },
};

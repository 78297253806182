import React, { useEffect, useState } from 'react';
import { Box, Typography, Container, Button } from '@mui/material';
import { toast } from 'react-toastify';
import { BlackTextLogo } from '../assets';
import { useLocation, useNavigate } from 'react-router-dom';
import httpClient from '../config/http.service';
import { black, mercury, primary } from '../constants/colors';
import { showNotification } from '../components/common/Toast';
import DynamicButton from '../components/new/Button';

export const ChangeCompanyDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState('');

  useEffect(() => {
    const params = location.search;
    const urlParams = new URLSearchParams(params);
    const tok = urlParams.get('token');
    setToken(tok);
  }, []);
  const handleSubmit = () => {
    httpClient
      .patch(`api/company/update`, {
        token: token,
      })
      .then((response) => {
        showNotification(
          'success',
          `Successfully changed business details! We are redirecting you to the dashbaord page!`
        );
        setTimeout(() => {
          navigate(`/dashboard`);
        }, 3000);
      })
      .catch((err) => showNotification('error', err?.response?.data?.message));
  };

  return (
    <Container maxWidth="xl">
      <Box style={styles.container}>
        <Box style={styles.main_container}>
          <Box style={styles.verify_box}>
            <img src={BlackTextLogo} alt="Logo" />

            <Typography
              variant="subtitle1"
              align="center"
              sx={{ marginTop: '30px', fontSize: '16px' }}
            >
              Please confirm changes and we will redirect you to the dashboard
              page!
            </Typography>
            <Box sx={styles.btns}>
              <DynamicButton
                type="contained"
                sx={styles.confirm}
                onClick={handleSubmit}
              >
                Confirm
              </DynamicButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

const styles = {
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px',
    marginTop: '40px',
  },
  cancel: {
    color: black,
    width: '81px',
    height: '40px',
    background: mercury,
    borderRadius: '100px',
    boxShadow: 'none',
    border: `1px solid ${mercury}`,
    '&:hover': { background: mercury },
  },
  confirm: {
    color: 'white',
    minWidth: '115px',
    height: '40px',
    borderRadius: '100px',
    boxShadow: 'none',
    background: primary,
    '&:hover': { background: primary },
  },
  container: {
    minHeight: '100vh',
  },
  main_container: {
    height: '80vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  verify_box: {
    padding: '30px 30px',
    width: '550px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow:
      '0px 32px 64px rgba(0, 0, 0, 0.02), ' +
      '0px 16px 32px rgba(0, 0, 0, 0.02), ' +
      '0px 8px 16px rgba(0, 0, 0, 0.02), ' +
      '0px 4px 8px rgba(0, 0, 0, 0.02), ' +
      '0px 2px 4px rgba(0, 0, 0, 0.02), ' +
      '1px 0px 2px rgba(0, 0, 0, 0.02)',
  },
};

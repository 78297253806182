import React, { useContext, useEffect, useState } from 'react';
import _, { isBoolean } from 'lodash';
import { Container } from '@mui/material';
import withDashboardHeader from '../hoc/withDashboardHeader';
import AppBreadCrumbs from '../components/common/AppBreadCrumbs';
import MutualActionPlan from '../components/Plan/MutualActionPlan';
import DeleteModal from '../components/common/DeleteModal';
import ActivityDurationModal from '../components/Plan/ActivityDurationModal';
import TeamMembersModal from '../components/Plan/TeamMembersModal';
import { reorderOnDrag } from '../utils/reorderOnDrag';
import {
  planDurationOptions,
  durationOptions as timeOptions,
} from '../utils/constants';
import { planObjTemplate } from '../mock/plan';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import MenuNavTemplate from '../components/Plan/MenuNavTemplate';
import { ShareTemplateModal } from '../components/Plans/ShareTemplateModal';
import { EditStageTitleModal } from '../components/Plans/EditStageTitleModal';
import { PLAN_FIELDS } from '../utils/enums';
import { CreateNewStageModal } from '../components/Plans/CreateNewStageModal';
import { DeletePlanModal } from '../components/Plans/DeletePlanModal';
import httpClient from '../config/http.service';
import { store } from '../Store';
import { showNotification } from '../components/common/Toast';

const initialBreadcrumbs = [
  { url: '/templates', text: 'Templates' },
  { text: 'Shared templates' },
  { text: 'High staking sales' },
];

const initialBreadcrumbsEdit = [
  { url: '/templates', text: 'Templates' },
  { text: 'High staking sales' },
];

const Template = () => {
  const pathname = useLocation();
  const navigate = useNavigate();
  const { state } = useContext(store);
  const [plan, setPlan] = useState(planObjTemplate);
  const [initialPlan, setInitialPlan] = useState(null);
  const [selectedStage, setSelectedStage] = useState('');
  const [durationOptions, setDurationOptions] = useState(planDurationOptions);
  const [editStageTitleModal, setEditStageTitleModal] = useState(false);
  const [expandPlanDetails, setExpandPlanDetails] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteActivityModalOpen, setIsDeleteActivityModalOpen] =
    useState(false);
  const [activityDurationModal, setActivityDurationModal] = useState(false);
  const [teamMembersModal, setTeamMembersModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [shared, setShared] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState({
    stageIndex: '',
    activityIndex: '',
  });
  const [selectedTeam, setSelectedTeam] = useState('');
  const [activityDuration, setActivityDuration] = useState({
    time: '10',
    duration: 'm',
  });
  const params = useParams();
  const { id: templateId, type: action } = useParams();
  const [userExist, setUserExist] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState(
    action === 'share' ? initialBreadcrumbs : initialBreadcrumbsEdit
  );
  const [stageTitle, setStageTitle] = useState('');
  const [openCreateNewStageModal, setOpenCreateNewStageModal] = useState(false);
  const [openedStage, setOpenedStage] = useState({ index: 0, name: '' });
  const [deleteTemplateModal, setDeleteTemplateModal] = useState(false);
  const AUTOEDIT_INTERVAL = 2000;
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [isShared, setIsShared] = useState(false);
  const [company, setCompany] = useState(null);
  let debounceTimeout;
  const getCompany = async (companyId) => {
    if (companyId) {
      httpClient
        .get(`api/company/get`, { params: { _id: companyId } })
        .then((res) => {
          setCompany(res.data);
          setPlan({
            ...planObjTemplate,
            name: `Customer & ${res.data.name} Mutual Action Plan`,
            vendor: '',
            vendorRepresentative: '',
          });
        })
        .catch((err) =>
          showNotification('error', err?.response?.data?.message)
        );
    }
  };

  const getTemplate = async () => {
    if (templateId && state?.data) {
      httpClient
        .get(`api/template/get`, { params: { _id: templateId } })
        .then(async (res) => {
          if (state.data.user.company) {
            httpClient
              .get(`api/company/get`, {
                params: { _id: state.data.user.company },
              })
              .then((resCompany) => {
                setShared(res.data.shareList);
                setCompany(resCompany.data);
                const obj = res.data;
                obj.value = {
                  amount: res.data.value?.amount || 0,
                  currency: res.data.value?.currency || 'dollar',
                };
                obj.vendor = '';
                obj.vendorRepresentative = '';
                setPlan(obj);
                setInitialPlan(obj);
              })
              .catch((err) =>
                showNotification('error', err?.response?.data?.message)
              );
          } else {
            setShared(res.data.shareList);
            const obj = res.data;
            obj.value = {
              amount: res.data.value?.amount || 0,
              currency: res.data.value?.currency || 'dollar',
            };
            obj.vendor = '';
            obj.vendorRepresentative = '';
            setPlan(obj);
            setInitialPlan(obj);
          }
        })
        .catch((err) =>
          showNotification('error', err?.response?.data?.message)
        );
    }
  };

  useEffect(() => {
    if (state?.data?.user && !templateId) {
      getCompany(state.data.user.company);
      setUserExist(true);
    }
  }, [state?.data]);

  useEffect(() => {
    if (templateId && state?.data) getTemplate();
  }, [templateId, state?.data, action]);

  const handleStageTitle = (title) => setStageTitle(title);
  const handleCloseStage = () => {
    setEditStageTitleModal(false);
  };

  useEffect(() => {
    if (pathname?.state?.name) {
      const newBreadcrumbs = [...breadcrumbs];
      newBreadcrumbs[newBreadcrumbs.length - 1].text = pathname.state.name;
      setBreadcrumbs(newBreadcrumbs);
    }
  }, [pathname]);

  const handleChange = ({ target }) => {
    const planData = _.cloneDeep(plan);
    const { name, value } = target;
    const splitName = name.split('.');

    if (splitName.length > 1 && splitName[0] === 'value') {
      planData[splitName[0]][splitName[1]] = value;
    } else {
      planData[name] = value;
    }

    if (name === PLAN_FIELDS.customerCompany) {
      planData.name =
        (value.length > 0 ? value : `Customer`) +
        ' &' +
        planData.name.split('&')[1];
    }
    setPlan(planData);
  };

  const updateTemplate = async () => {
    if (templateId) {
      httpClient
        .patch('api/template/update', plan)
        .then((response) => {
          // showNotification('success', 'Successfully edited stage title!');
          setInitialPlan(response.data);
        })
        .catch((err) =>
          showNotification('error', err?.response?.data?.message)
        );
    }
  };

  useEffect(() => {
    setIsPageLoaded(true);
  }, []);

  useEffect(() => {
    if (!isPageLoaded) return;

    const timeout = setTimeout(() => {
      if (initialPlan !== plan) {
        if (plan.userId === state?.data?.user?._id) {
          updateTemplate();
        }
        setInitialPlan(plan);
      }
    }, AUTOEDIT_INTERVAL);
    return () => clearTimeout(timeout);
  }, [plan, isPageLoaded]);

  const handleActivityFieldsChange = (
    { target },
    stageIndex,
    activityIndex,
    teamMemberIndex,
    teamField = 'name'
  ) => {
    const { name, value } = target;
    const planData = { ...plan };
    const activity = planData.stages[stageIndex].activities[activityIndex];
    const previousValue = activity[name];

    if (teamMemberIndex !== undefined)
      activity[name][teamMemberIndex][teamField] = value;
    else activity[name] = value;

    if (name === PLAN_FIELDS.status && value === PLAN_FIELDS.completed)
      activity.completionDate = new Date().toISOString();
    else if (name === PLAN_FIELDS.status && value !== PLAN_FIELDS.completed)
      activity.completionDate = '';

    if (name === PLAN_FIELDS.duration && value === PLAN_FIELDS.custom) {
      setActivityDurationModal(true);
      setSelectedActivity({ stageIndex, activityIndex });
      activity.duration = previousValue;
    }

    setPlan(planData);

    clearTimeout(debounceTimeout);
  };

  const handleSubActivityChange = (
    { target },
    stageIndex,
    activityIndex,
    subActivityIndex
  ) => {
    const { name, value } = target;
    const planData = { ...plan };

    const subActivity =
      planData.stages[stageIndex].activities[activityIndex].subActivities[
        subActivityIndex
      ];

    if (name === PLAN_FIELDS.status && value === PLAN_FIELDS.completed)
      subActivity.completionDate = new Date().toISOString();
    else if (name === PLAN_FIELDS.status && value !== PLAN_FIELDS.completed)
      subActivity.completionDate = '';

    subActivity[name] = value;
    setPlan(planData);
  };

  const handleExpand = (index) => {
    const allStages = [...plan.stages];
    allStages[index].open = !allStages[index].open;
    setPlan({ ...plan, stages: allStages });
  };

  const handleStageActionsOpen = (event, index) => {
    const allStages = [...plan.stages];
    allStages[index].actionsEl = event.currentTarget;
    setPlan({ ...plan, stages: allStages });
    setOpenedStage({ index: index, name: allStages[index].name });
  };

  const handleStageActionsClose = (newStages) => {
    const allStages = newStages ? [...newStages] : [...plan.stages];
    const index = allStages.findIndex((s) => s.actionsEl !== null);
    if (index < 0) return;
    allStages[index].actionsEl = null;
    setPlan({ ...plan, stages: allStages });
  };

  const handleAddNewActivity = (index) => {
    const allStages = [...plan.stages];
    allStages[index].activities.push({
      name: '',
      description: '',
      duration: '',
      tag: '',
      status: '',
      notes: '',
      customerTeam: [],
      vendorTeam: [],
      targetDate: '',
      subActivities: [],
    });
    setPlan({ ...plan, stages: allStages });
  };

  const handleRemoveActivity = () => {
    const { activityIndex, stageIndex } = selectedActivity;
    const allStages = [...plan.stages];
    allStages[stageIndex].activities.splice(activityIndex, 1);
    setPlan({ ...plan, stages: allStages });
    handleDeleteActivityModalClose();
  };

  const handleDuplicateStage = (index) => {
    if (plan?.stages?.length >= 8) {
      showNotification(
        'error',
        'You have created the maximum number of stages!'
      );
    } else {
      const allStages = [...plan.stages];
      const newStage = _.cloneDeep(allStages[index]);
      newStage.actionsEl = null;
      allStages.splice(index, 0, newStage);

      setPlan({ ...plan, stages: allStages });
      handleStageActionsClose(allStages);
    }
  };

  const handleDeleteStage = () => {
    const allStages = [...plan.stages];
    allStages.splice(selectedStage, 1);
    setPlan({ ...plan, stages: allStages });
    handleStageActionsClose(allStages);
    handleDeleteStageModalClose(true);
  };

  const handleAddTeamMember = (stageIndex, activityIndex, teamName) => {
    const allStages = [...plan.stages];
    if (allStages[stageIndex].activities[activityIndex][teamName].length >= 3) {
      handleTeamMembersModalOpen(stageIndex, activityIndex, teamName);
    }
    allStages[stageIndex].activities[activityIndex][teamName].push({
      name: '',
      email: '',
      title: '',
    });
    setPlan({ ...plan, stages: allStages });
  };

  const handleAddNewSubActivity = (stageIndex, activityIndex) => {
    const allStages = [...plan.stages];
    const subActivity =
      allStages[stageIndex].activities[activityIndex].collapseSubActivity;

    if (!subActivity)
      allStages[stageIndex].activities[
        activityIndex
      ].collapseSubActivity = true;

    allStages[stageIndex].activities[activityIndex].subActivities.push({
      name: '',
      status: '',
      targetDate: '',
      notes: '',
    });
    setPlan({ ...plan, stages: allStages });
  };

  const handleRemoveSubActivity = (
    stageIndex,
    activityIndex,
    subActivityIndex
  ) => {
    const allStages = [...plan.stages];
    allStages[stageIndex].activities[activityIndex].subActivities.splice(
      subActivityIndex,
      1
    );

    setPlan({ ...plan, stages: allStages });
  };

  const handleExpandSubActivities = (stageIndex, activityIndex) => {
    const allStages = [...plan.stages];
    allStages[stageIndex].activities[activityIndex].collapseSubActivity =
      !allStages[stageIndex].activities[activityIndex].collapseSubActivity;
    setPlan({ ...plan, stages: allStages });
  };

  const handleDeleteStageModalOpen = (stageIndex) => {
    setSelectedStage(stageIndex);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteStageModalClose = (isDeleted) => {
    setSelectedStage('');
    setIsDeleteModalOpen(false);
    if (!isBoolean(isDeleted)) {
      const allStages = [...plan.stages];
      const index = allStages.findIndex((s) => s.actionsEl !== null);
      if (index < 0) return;
      allStages[index].actionsEl = null;
      setPlan({ ...plan, stages: allStages });
    }
  };

  const handleDeleteActivityModalOpen = (activityIndex, stageIndex) => {
    setSelectedActivity({ stageIndex, activityIndex });
    setIsDeleteActivityModalOpen(true);
  };

  const handleDeleteActivityModalClose = () => {
    setSelectedActivity({ stageIndex: '', activityIndex: '' });
    setIsDeleteActivityModalOpen(false);
  };

  const handleRemoveTeamMember = (
    stageIndex,
    activityIndex,
    memberIndex,
    teamName
  ) => {
    const allStages = [...plan.stages];
    allStages[stageIndex].activities[activityIndex][teamName].splice(
      memberIndex,
      1
    );
    setPlan({ ...plan, stages: allStages });
  };

  const handleDurationModalClose = () => {
    setActivityDurationModal(false);
    setSelectedActivity({ stageIndex: '', activityIndex: '' });
    setActivityDuration({ time: '10', duration: 'm' });
  };

  const handleDurationChange = ({ target }) => {
    const duration = { ...activityDuration };
    const { name, value } = target;
    duration[name] = value;
    setActivityDuration(duration);
  };

  const handleDurationSubmit = () => {
    const { time, duration } = activityDuration;
    const { stageIndex, activityIndex } = selectedActivity;
    const allStages = _.cloneDeep(plan.stages);
    const durationExists = timeOptions.find((t) => t.value === duration);

    const name = time + ' ' + durationExists.name.toLowerCase();
    const value = time + duration;
    const newItem = { name, value };

    if (!durationOptions.find((d) => d.value === value)) {
      setDurationOptions([...durationOptions, newItem]);
    }

    allStages[stageIndex].activities[activityIndex].duration = value;
    setPlan({ ...plan, stages: allStages });
    handleDurationModalClose();
  };

  const handleTeamMembersModalOpen = (stageIndex, activityIndex, teamName) => {
    setTeamMembersModal(true);
    setSelectedActivity({ stageIndex, activityIndex });
    setSelectedTeam(teamName);
  };
  const handleTeamMembersModalClose = () => {
    setTeamMembersModal(false);
    setSelectedActivity({ stageIndex: '', activityIndex: '' });
    setSelectedTeam('');
  };

  const handlePlanDetailsExpansion = () =>
    setExpandPlanDetails((prev) => !prev);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const stages = [...plan.stages];
    const items = reorderOnDrag(
      stages,
      result.source.index,
      result.destination.index
    );

    setPlan({ ...plan, stages: items });
  };

  const onActivityDragEnd = (result, stageIndex) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const allStages = [...plan.stages];
    const items = reorderOnDrag(
      allStages[stageIndex].activities,
      result.source.index,
      result.destination.index
    );

    allStages[stageIndex].activities = [...items];
    setPlan({ ...plan, stages: allStages });
  };

  const onSubActivityDragEnd = (result, stageIndex, activityIndex) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const allStages = _.cloneDeep(plan.stages);
    const items = reorderOnDrag(
      allStages[stageIndex].activities[activityIndex].subActivities,
      result.source.index,
      result.destination.index
    );

    allStages[stageIndex].activities[activityIndex].subActivities = [...items];
    setPlan({ ...plan, stages: allStages });
  };

  const handleEditStageTitleModal = () => {
    setEditStageTitleModal(true);
  };

  const editTitle = () => {
    const newStage = plan;
    newStage?.stages?.map((el, index) => {
      if (el.name === openedStage.name && index === openedStage.index) {
        el.name = stageTitle;
      }
    });
    setPlan(newStage);
  };

  const createStage = () => {
    const newStage = plan;
    newStage.stages.push({
      name: stageTitle,
      open: false,
      activities: [
        {
          name: '',
          description: '',
          duration: '',
          tag: '',
          status: '',
          notes: '',
          customerTeam: [],
          vendorTeam: [],
          targetDate: '',
          collapseSubActivity: false,
          completionDate: '',
          subActivities: [],
        },
      ],
      actionsEl: null,
    });
    setPlan(newStage);
  };

  const handleCreateNewStage = () => {
    if (plan?.stages?.length >= 8) {
      showNotification(
        'error',
        'You have created the maximum number of stages!'
      );
    } else {
      setOpenCreateNewStageModal(true);
    }
  };
  const handleDeleteTemplate = () => {
    const removeFromShareList = plan.shareList?.filter(
      (el) => el.email !== state.data.user.email
    );
    if (isShared) {
      httpClient
        .patch('api/template/update-share-list', {
          _id: templateId,
          shareList: removeFromShareList,
        })
        .then((res) => {
          const obj = res.data;
          obj.value = {
            amount: res.data.value?.amount || 0,
            currency: res.data.value?.currency || 'dollar',
          };
          obj.vendor = '';
          obj.vendorRepresentative = '';
          setPlan(obj);
        })
        .catch((err) =>
          showNotification('error', err?.response?.data?.message)
        );
      showNotification('success', 'Template successfully deleted from me!');
      setDeleteTemplateModal(false);
      navigate('/templates');
    } else {
      httpClient
        .delete('api/template/delete', {
          data: {
            _id: templateId._id,
          },
        })
        .then(() => {})
        .catch((err) =>
          showNotification('error', err?.response?.data?.message)
        );
      showNotification('success', 'Template successfully deleted!');
      setDeleteTemplateModal(false);
      navigate('/templates');
    }
  };

  return (
    <Container maxWidth="xl" sx={styles.container}>
      {userExist && <AppBreadCrumbs breadcrumbs={breadcrumbs} />}
      <MenuNavTemplate
        name={pathname?.state?.name ?? 'High Staking sales'}
        type={action}
        handleShare={() => setOpenShareModal(true)}
        deleteModal={(type, share) => {
          setIsShared(share);
          setDeleteTemplateModal(true);
        }}
        handlePreview={() => console.log('preview')}
      />
      <MutualActionPlan
        data={plan}
        durationOptions={durationOptions}
        expandPlanDetails={expandPlanDetails}
        handleChange={handleChange}
        handleExpand={handleExpand}
        onDragEnd={onDragEnd}
        onActivityDragEnd={onActivityDragEnd}
        onSubActivityDragEnd={onSubActivityDragEnd}
        handleAddNewActivity={handleAddNewActivity}
        handleRemoveActivity={handleDeleteActivityModalOpen}
        handleDeleteStage={handleDeleteStageModalOpen}
        handleDuplicateStage={handleDuplicateStage}
        handleAddTeamMember={handleAddTeamMember}
        handleStageActionsOpen={handleStageActionsOpen}
        handleStageActionsClose={handleStageActionsClose}
        handleActivityFieldsChange={handleActivityFieldsChange}
        handleSubActivityChange={handleSubActivityChange}
        handleAddNewSubActivity={handleAddNewSubActivity}
        handleRemoveSubActivity={handleRemoveSubActivity}
        handleExpandSubActivities={handleExpandSubActivities}
        handleRemoveTeamMember={handleRemoveTeamMember}
        handleTeamMembersModalOpen={handleTeamMembersModalOpen}
        handlePlanDetailsExpansion={handlePlanDetailsExpansion}
        handleEditStageTitle={handleEditStageTitleModal}
        template={true}
        handleCreateNewStage={handleCreateNewStage}
        userExist={userExist}
      />

      <DeleteModal
        heading="Delete Stage"
        submitBtn="Continue"
        text="Are you sure you want to delete this stage and its activities?"
        open={isDeleteModalOpen}
        handleClose={handleDeleteStageModalClose}
        handleSubmit={handleDeleteStage}
      />

      <DeleteModal
        heading="Delete Activity"
        submitBtn="Continue"
        text="Are you sure you want to remove this activity and its sub activities?"
        open={isDeleteActivityModalOpen}
        handleClose={handleDeleteActivityModalClose}
        handleSubmit={handleRemoveActivity}
      />
      <ShareTemplateModal
        open={openShareModal}
        handleClose={() => setOpenShareModal(false)}
        shared={shared}
        setShared={setShared}
        template={plan}
        setTemplate={setPlan}
      />
      <ActivityDurationModal
        time={activityDuration.time}
        duration={activityDuration.duration}
        open={activityDurationModal}
        selectedActivity={selectedActivity}
        handleChange={handleDurationChange}
        handleClose={handleDurationModalClose}
        handleSubmit={handleDurationSubmit}
      />

      {plan && (
        <TeamMembersModal
          plan={plan}
          open={teamMembersModal}
          selectedTeam={selectedTeam}
          stageIndex={selectedActivity.stageIndex}
          activityIndex={selectedActivity.activityIndex}
          handleAddTeamMember={handleAddTeamMember}
          handleRemoveTeamMember={handleRemoveTeamMember}
          handleChange={handleActivityFieldsChange}
          handleClose={handleTeamMembersModalClose}
          handleSubmit={handleTeamMembersModalClose}
        />
      )}
      {editStageTitleModal && (
        <EditStageTitleModal
          stageTitle={openedStage ? openedStage.name : stageTitle}
          handleChange={handleStageTitle}
          handleClose={handleCloseStage}
          editStageTitle={editTitle}
        />
      )}
      {openCreateNewStageModal && (
        <CreateNewStageModal
          stageTitle={stageTitle}
          handleChange={handleStageTitle}
          handleClose={() => setOpenCreateNewStageModal(false)}
          createStage={createStage}
        />
      )}
      {deleteTemplateModal && (
        <DeletePlanModal
          plan={false}
          open={deleteTemplateModal}
          handleClose={() => setDeleteTemplateModal(false)}
          handleDelete={handleDeleteTemplate}
        />
      )}
    </Container>
  );
};

export default withDashboardHeader(Template);

const styles = { container: { mt: '40px' } };

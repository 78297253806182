import React from 'react';
import { Box, Dialog, Grid, Typography } from '@mui/material';
import { ReactComponent as HighlightOffOutlinedIcon } from '../../assets/imgs/close.svg';
import DurationSelectInput from '../common/DurationSelectInput';
import InputWithLabel from '../common/InputWithLabel';
import { durationOptions } from '../../utils/constants';
import {
  black,
  gallery,
  mercury,
  primary,
  shark,
  wildSand,
} from '../../constants/colors';
import DynamicButton from '../new/Button';

const ActivityDurationModal = ({
  open,
  time,
  duration,
  selectedActivity,
  handleChange,
  handleClose,
  handleSubmit,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: styles.paper }}>
      <Typography variant="h5" sx={styles.heading}>
        Activity duration
        <HighlightOffOutlinedIcon sx={styles.closeIcon} onClick={handleClose} />
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <InputWithLabel
            type="number"
            name="time"
            min={1}
            value={time}
            styles={styles.input}
            handleChange={(e) =>
              handleChange(
                e,
                selectedActivity.stageIndex,
                selectedActivity.activityIndex
              )
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DurationSelectInput
            name="duration"
            value={duration}
            fullWidth={true}
            options={durationOptions}
            styles={{
              ...styles.select,
              border: `1px solid ${gallery}`,
              '.MuiOutlinedInput-input.MuiSelect-select': {
                height: '44px',
                display: 'flex',
                alignItems: 'center',
              },
            }}
            handleChange={(e) =>
              handleChange(
                e,
                selectedActivity.stageIndex,
                selectedActivity.activityIndex
              )
            }
          />
        </Grid>
      </Grid>

      <Box sx={styles.btns}>
        <DynamicButton type="outlined" sx={styles.cancel} onClick={handleClose}>
          Cancel
        </DynamicButton>
        <DynamicButton
          type="contained"
          sx={styles.submit}
          onClick={handleSubmit}
        >
          Save
        </DynamicButton>
      </Box>
    </Dialog>
  );
};

export default ActivityDurationModal;

const styles = {
  paper: {
    borderRadius: '8px',
    padding: '32px',
    width: '322px',
  },
  heading: {
    color: black,
    fontFamily: 'SoehneBold',
    fontSize: '20px',
    lineHeight: '32px',
    marginBottom: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeIcon: {
    color: shark,
    fontSize: '20px',
    background: wildSand,
    borderRadius: '50%',
    padding: '5px',
    cursor: 'pointer',
  },
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px',
    marginTop: '40px',
  },
  cancel: {
    color: black,
    width: '81px',
    height: '40px',
    background: mercury,
    borderRadius: '100px',
    boxShadow: 'none',
    border: `1px solid ${mercury}`,
    '&:hover': { background: mercury },
  },
  submit: {
    color: 'white',
    width: '67px',
    height: '40px',
    borderRadius: '100px',
    fontFamily: 'SoehneMedium',
    boxShadow: 'none',
    background: primary,
    '&:hover': { background: primary },
  },
  input: {
    height: '26px',
    marginBottom: '0px',
    fontSize: '14px',
    marginTop: '0',
  },
  select: { height: '52px', marginBottom: '0px', fontSize: '14px' },
};

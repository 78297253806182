import React, { useContext, useState } from 'react';
import { Box, Dialog, Typography } from '@mui/material';
import { ReactComponent as HighlightOffOutlinedIcon } from '../../assets/imgs/close.svg';
import { useNavigate } from 'react-router-dom';
import { shark } from '../../constants/colors';
import httpClient from '../../config/http.service';
import { showNotification } from '../common/Toast';
import DynamicButton from '../new/Button';
import _ from 'lodash';
import { store } from '../../Store';
import InputWithLabel from '../common/InputWithLabel';
import { randomColor } from '../../utils/utils';
import TextAreaLabel from '../common/TextArea';

export const CreateNewTemplate = ({
  open,
  handleClose,
  duplicate = false,
  template = null,
  company = null,
  edit = false,
  templateId = '',
  update,
  setUpdate,
}) => {
  const { state } = useContext(store);
  const navigate = useNavigate();
  const [data, setData] = useState({
    name: edit ? template?.name : '',
    description: edit ? template?.description : '',
  });

  const createTemplate = () => {
    httpClient
      .post('api/template/new', {
        name: data?.name,
        description: data?.description,
      })
      .then((response) => {
        if (duplicate && template) {
          const clone = _.cloneDeep(response.data);
          clone.stages = template.stages;
          clone.stages?.map((el) => {
            delete el.open;
            if (el.activities?.length) {
              el.activities.map((act) => delete act.collapseSubActivity);
            }
          });
          clone.vendor = state?.data?.user?.company;
          delete clone.shareList;

          httpClient
            .patch('api/template/update', clone)
            .then((res) => {
              const obj = res.data;
              obj.vendor = company?.name;
              obj.vendorColor = randomColor();
              obj.vendorRepresentative =
                res.data.vendorRepresentative === state?.data?.user?._id
                  ? state?.data?.user?.firstName +
                    ' ' +
                    state?.data?.user?.lastName
                  : '';
              showNotification('success', 'Successfully duplicated template!');
              navigate(`/view-template/${response.data._id}`, {
                state: {
                  name: data?.name,
                  description: data?.description,
                },
              });
              handleClose();
            })
            .catch((err) => {
              // if (err?.response?.data?.code !== 401)
              //   showNotification('error', err?.response?.data?.message);
            });
        } else {
          navigate(`/view-template/${response.data._id}`, {
            state: {
              name: data?.name,
              description: data?.description,
            },
          });
        }
      })
      .catch((err) => {
        // if (err?.response?.data?.code !== 401)
        //   showNotification('error', err?.response?.data?.message);
      });
  };

  const editTemplateDetails = () => {
    httpClient
      .patch('api/template/update-details', {
        _id: templateId,
        name: data.name,
        description: data.description,
      })
      .then((res) => {
        handleClose();
        setUpdate(!update);
        showNotification(
          'success',
          'Template changes saved!',
          'dark',
          'bottom-right'
        );
      })
      .catch((err) => {
        if (err?.response?.data?.code !== 401)
          showNotification('error', err?.response?.data?.message);
      });
  };

  const handleChange = (e) => {
    setData({ ...data, description: e.target.value });
  };
  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: styles.paper }}>
      <Typography variant="h5" sx={styles.heading}>
        {duplicate
          ? 'Duplicate template'
          : edit
          ? 'Edit template details'
          : 'Create New Template'}
        <Box sx={styles.closeIconBox}>
          <HighlightOffOutlinedIcon
            style={styles.closeIcon}
            onClick={handleClose}
          />
        </Box>
      </Typography>

      <InputWithLabel
        label="Template name*"
        name="name"
        value={data?.name}
        placeholder=""
        handleChange={(e) => setData({ ...data, name: e.target.value })}
      />
      <TextAreaLabel
        label="Template description*"
        name="description"
        multiline={true}
        rows="3"
        styles={{ marginBottom: '0', height: '69px' }}
        value={data?.description}
        placeholder="Enter a description to easily identify template"
        share
        handleChange={(e) => handleChange(e, 'description')}
      />
      <Box sx={styles.btns}>
        {edit ? (
          <>
            <DynamicButton
              type="outlined"
              sx={styles.template}
              onClick={handleClose}
            >
              {'Cancel'}
            </DynamicButton>
            <DynamicButton
              disabled={data?.description === '' || data?.name === ''}
              type="contained"
              sx={styles.template}
              onClick={editTemplateDetails}
            >
              {'Save'}
            </DynamicButton>
          </>
        ) : (
          <DynamicButton
            disabled={data?.description === '' || data?.name === ''}
            type="contained"
            sx={styles.template}
            onClick={() => (edit ? editTemplateDetails() : createTemplate())}
          >
            {duplicate ? 'Duplicate' : 'Continue'}
          </DynamicButton>
        )}
      </Box>
    </Dialog>
  );
};

const styles = {
  paper: {
    borderRadius: '8px',
    padding: '32px',
    width: '598px',
    overflow: 'hidden',
  },
  heading: {
    color: 'rgba(0, 0, 0, 0.88)',
    fontFamily: 'InterBold',
    fontSize: '20px',
    lineHeight: '28px',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeIconBox: {
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(142, 128, 113, 0.08)',
    },
  },
  closeIcon: {
    color: shark,
    fontSize: '30px',
    padding: '5px',
    cursor: 'pointer',
  },
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px',
    marginTop: '26px',
  },
  template: {
    color: 'white',
    height: '40px',
    borderRadius: '100px',
    boxShadow: 'none',
  },
};

import React from 'react';
import { MenuItem, Select, Typography } from '@mui/material';
import {
  alabaster,
  black,
  gallery,
  lightGray,
  swamp,
} from '../../constants/colors';

const selectStyles = {
  label: {
    color: swamp,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '8px',
  },
  select: {
    marginBottom: '25px',
    background: alabaster,
    color: lightGray,
    '&.Mui-focused fieldset': { border: '1px solid black !important' },
    '.MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${gallery} !important`,
    },
  },
  menuItem: {
    color: black,
    '&.Mui-selected': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04) !important' },
  },
};

const SelectWithLabel = ({
  label,
  name,
  value = '',
  options,
  styles,
  menuItemStyles,
  handleChange,
  fullWidth = false,
}) => {
  return (
    <>
      {label && (
        <Typography variant="subtitle1" style={selectStyles.label}>
          {label}
        </Typography>
      )}

      <Select
        displayEmpty
        variant="outlined"
        name={name}
        value={value}
        sx={{ ...selectStyles.select, ...styles }}
        onChange={handleChange}
        fullWidth={fullWidth}
      >
        {options.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            sx={{ ...selectStyles.menuItem, ...menuItemStyles }}
          >
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default SelectWithLabel;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  FormControl,
  FormHelperText,
  InputBase,
  InputLabel,
} from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ReactComponent as ClearIcon } from '../../assets/imgs/IconButtonRemove.svg';
import NumericFormatCustom from './NumericFormatCustom';
import { alabaster, gallery, primary } from '../../constants/colors';
import '../../styles/plans/customeTextarea.css';

const BootstrapInput = styled(({ inputRef, ...otherProps }) => (
  <InputBase {...otherProps} inputRef={inputRef} />
))(({ theme, disabled, multiline, activity, subActivity }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    border: `1px solid ${
      activity || subActivity ? 'rgba(0, 0, 0, 0.2)' : gallery
    }`,
    fontSize: subActivity ? '14px !important' : 16,
    width: '99%',
    padding: '12px 16px',
    marginTop: '5px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    fontFamily: multiline
      ? 'InterRegular !important'
      : 'InterMedium !important',
    '&:hover': {
      borderColor: disabled ? gallery : 'rgba(0, 0, 0, 0.45)',
    },
    '&:focus': { borderColor: disabled ? gallery : 'rgba(0, 155, 119, 1)' },
    '&:active': { borderColor: disabled ? gallery : 'rgba(0, 155, 119, 1)' },
  },
}));

const InputWithLabel = ({
  type = 'text',
  field,
  name,
  value,
  min = 0,
  label,
  styles,
  placeholderStyles = {},
  rows = 4,
  helperText,
  placeholder = '',
  required = false,
  multiline = false,
  disabled = false,
  passwordResetLink,
  handleChange,
  onKeyDown,
  labelStyle,
  clearInput,
  subActivity = false,
  activity = false,
  showClearIcon = false,
  notes = false,
  characterLimit = 200,
  description,
  readOnly = false,
  share = false,
  inputRef,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [characterCount, setCharacterCount] = useState(value?.length || 0);
  const [showIcon, setShowIcon] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (characterLimit > 0) {
      if (inputValue.length <= characterLimit) {
        setCharacterCount(inputValue.length);
        handleChange(e);
      }
    } else {
      handleChange(e);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <FormControl
      variant="standard"
      fullWidth
      sx={{
        marginBottom: styles?.marginBottom || '25px',
        position: 'relative',
        width: '100%',
        overflowX: share && 'hidden',
      }}
    >
      {label && (
        <InputLabel
          shrink
          htmlFor={name}
          sx={{
            ...labelStyle,
            '&.Mui-hover': {
              color: 'rgba(0, 0, 0, 0.45)',
            },
            '&.Mui-focused': {
              color: 'rgba(0, 155, 119, 1)',
            },
            '&.Mui-active': {
              color: 'rgba(0, 155, 119, 1)',
            },
            color: disabled ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.7)',
            width: '100%',
            fontFamily: 'InterMedium',
            fontSize: '14px',
            lineHeight: '20px',
            textAlign: 'left',
          }}
        >
          {label}
        </InputLabel>
      )}
      <BootstrapInput
        onKeyDown={onKeyDown}
        type={showPassword ? 'text' : type}
        disabled={disabled}
        value={value}
        name={name}
        id={name}
        inputRef={inputRef}
        rows={rows}
        multiline={multiline}
        required={required}
        placeholder={placeholder}
        inputProps={{
          readOnly: readOnly,
          style: {
            backgroundColor: alabaster,
            position: 'relative',
            ...styles,
          },
          sx: {
            backgroundColor: showIcon
              ? 'rgba(142, 128, 113, 0.08) !important'
              : alabaster,
          },
          min,
        }}
        activity={activity}
        subActivity={subActivity}
        {...(field === 'number' && { inputComponent: NumericFormatCustom })}
        sx={{
          width: '100%',
          '& ::placeholder': placeholderStyles,
        }}
        onChange={handleInputChange}
        endAdornment={
          !multiline && showClearIcon && !readOnly && showIcon ? (
            <ClearIcon
              onClick={clearInput}
              style={{
                position: 'absolute',
                right: '10px',
                top: subActivity ? '3px' : '20px',
                cursor: 'pointer',
                width: '32px',
                height: '32px',
              }}
            />
          ) : null
        }
        className="custom-input"
        onMouseEnter={() => setShowIcon(true)}
        onMouseLeave={() => !isFocused && setShowIcon(false)}
        onFocus={() => {
          setIsFocused(true);
          setShowIcon(true);
        }}
        onBlur={() => {
          setIsFocused(false);
          setShowIcon(false);
        }}
      />
      {helperText && (
        <FormHelperText sx={{ marginTop: '-1px' }}>{helperText}</FormHelperText>
      )}

      {type === 'password' &&
        (showPassword ? (
          <VisibilityOffIcon
            sx={{
              position: 'absolute',
              top: '50%',
              right: 0,
              marginRight: '10px',
              cursor: 'pointer',
            }}
            onClick={togglePasswordVisibility}
          />
        ) : (
          <VisibilityOutlinedIcon
            sx={{
              position: 'absolute',
              top: '50%',
              right: 0,
              marginRight: '10px',
              cursor: 'pointer',
            }}
            onClick={togglePasswordVisibility}
          />
        ))}

      {passwordResetLink && (
        <Link
          to={'/reset-password'}
          style={{
            right: 0,
            position: 'absolute',
            fontSize: '12px',
            color: primary,
          }}
        >
          Reset Password
        </Link>
      )}

      {multiline && characterLimit > 0 && (
        <FormHelperText
          sx={{
            fontSize: '12px',
            position: 'absolute',
            bottom: '5px',
            right: '10px',
          }}
        >
          {`${characterCount}/${characterLimit}`}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default InputWithLabel;

import React from 'react';
import {
  Box,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { alabaster, lightGray, primary, swamp } from '../../constants/colors';
import '../../styles/plans/customeTextarea.css';

const DurationSelectInput = ({
  label,
  name,
  value = '',
  options,
  styles,
  menuItemStyles,
  helperText,
  handleChange,
  fullWidth = false,
  minutesShorthand = false,
  disabled = false,
  readOnly = false,
}) => {
  return (
    <>
      {label && (
        <Typography variant="subtitle1" style={selectStyles.label}>
          {label}
        </Typography>
      )}

      <Select
        disabled={disabled}
        displayEmpty
        variant="outlined"
        name={name}
        value={value}
        readOnly={readOnly}
        onChange={handleChange}
        fullWidth={fullWidth}
        sx={{ ...selectStyles.select, ...styles }}
        MenuProps={selectStyles.menuProps}
        className="multiColorSelectInput"
        renderValue={(selected) => {
          const option = options.find((o) => o.value === selected);
          return (
            <Box
              sx={{
                fontFamily: 'InterRegular',
                fontSize: '14px',
                lineHeight: '20px',
                paddingTop: '5px',
                paddingBottom: '5px',
                color:
                  option.value !== ''
                    ? 'rgba(0, 0, 0, 0.88)'
                    : 'rgba(0, 0, 0, 0.45)',
              }}
            >
              {typeof selected === 'string'
                ? selected.toString().includes('m') && minutesShorthand
                  ? `${selected.toString().split('m')[0]} mins`
                  : option?.name
                : ''}
            </Box>
          );
        }}
      >
        {options.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            sx={{
              ...selectStyles.menuItem,
              ...menuItemStyles,
            }}
          >
            {item?.name}
          </MenuItem>
        ))}
      </Select>
      {helperText && (
        <FormHelperText sx={selectStyles.helperText}>
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};

export default DurationSelectInput;

const selectStyles = {
  label: {
    color: swamp,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '8px',
  },
  select: {
    fontSize: '12px',
    marginBottom: '25px',
    background: alabaster,
    color: lightGray,
    '.MuiOutlinedInput-input': {
      padding: '3px 14px',
    },
    border: '1px solid rgba(0, 0, 0, 0.2)',
    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.45)',
      transition: 'border-color  400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    justifyContent: 'center',
  },
  menuItem: {
    color: 'rgb(122, 128, 129)',
    mb: '10px',
    justifyContent: 'center',
    borderRadius: '8px',
    fontSize: '12px',
    '&.Mui-selected': {
      color: primary,
      backgroundColor: 'transparent',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08) !important',
    },
  },
  menuProps: {
    MenuListProps: { sx: { padding: '10px', minHeight: 'auto' } },
  },
  helperText: { fontSize: '11px' },
};

import React, { useEffect, useRef } from 'react';
import {
  Box,
  Dialog,
  Typography,
  FormControl,
  MenuItem,
  Select,
} from '@mui/material';
import { ReactComponent as HighlightOffOutlinedIcon } from '../assets/imgs/close.svg';
import InputWithLabel from '../components/common/InputWithLabel';
import DynamicButton from '../components/new/Button';
import { shark } from '../constants/colors';
import { ReactComponent as ArrowDown } from '../assets/imgs/arrow_drop_down.svg';

export const AddNewMember = ({
  open,
  handleClose,
  edit = false,
  saveMember,
  inviteMember,
  data,
  setData,
  typeOfRef = 'fullName',
  options = [],
}) => {
  const fullNameInputRef = useRef(null);
  const workEmailInputRef = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (typeOfRef === 'fullName' && fullNameInputRef.current) {
        // Set the cursor and focus on the Full Name input element
        fullNameInputRef.current.focus();
      } else if (typeOfRef === 'email' && workEmailInputRef.current) {
        // Set the cursor and focus on the Full Name input element
        workEmailInputRef.current.focus();
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [open, typeOfRef]);

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: styles.paper }}>
      <Typography variant="h5" sx={styles.heading}>
        {edit ? 'Edit member' : 'Add new member'}
        <HighlightOffOutlinedIcon
          style={styles.closeIcon}
          onClick={handleClose}
        />
      </Typography>

      <InputWithLabel
        label="Full name*"
        name="name"
        value={data?.fullName}
        placeholder="Enter full name"
        handleChange={(e) => setData({ ...data, fullName: e.target.value })}
        clearInput={() => setData({ ...data, fullName: '' })}
        inputRef={fullNameInputRef} // Pass the ref to the input element
        sx={{ marginBottom: '16px' }}
      />

      <InputWithLabel
        label="Job title"
        name="title"
        multiline={false}
        value={data?.title}
        placeholder="Enter job title"
        styles={{ marginBottom: '15px' }}
        handleChange={(e) => setData({ ...data, title: e.target.value })}
        clearInput={() => setData({ ...data, title: '' })}
      />

      <InputWithLabel
        label="Work email address"
        name="email"
        multiline={false}
        value={data?.email}
        placeholder="Enter email address"
        styles={{ marginBottom: '16px', marginTop: '0' }}
        handleChange={(e) => setData({ ...data, email: e.target.value })}
        clearInput={() => setData({ ...data, email: '' })}
        inputRef={workEmailInputRef} // Pass the ref to the input element
      />
      <FormControl style={{ width: '100%', height: '103px' }}>
        <Typography
          style={{
            color: 'rgba(0, 0, 0, 0.7)',
            fontFamily: 'InterMedium',
            fontSize: '14px',
            marginBottom: '10px',
          }}
        >
          Company
        </Typography>
        <Select
          value={data?.company || ''}
          name="company"
          placeholder="Select company"
          onChange={(e) => setData({ ...data, company: e.target.value })}
          IconComponent={ArrowDown}
          style={{
            backgroundColor: 'rgb(250, 250, 250)',
            height: '49px',
          }}
        >
          {options?.map((comp) => (
            <MenuItem
              key={comp.value}
              value={comp.value}
              sx={{
                padding: '12px 16px !important',
                color: 'rgba(0, 0, 0, 0.88) !important',
                fontSize: '16px !important',
                fontFamily: 'InterRegular !important',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.05) !important',
                },
              }}
            >
              {comp.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box sx={styles.btns}>
        <DynamicButton
          type="outlined"
          sx={styles.template}
          onClick={handleClose}
        >
          Cancel
        </DynamicButton>
        <DynamicButton
          disabled={data?.fullName === ''}
          type={edit ? 'contained' : 'outlined'}
          sx={styles.template}
          onClick={saveMember}
        >
          Save
        </DynamicButton>
        {!edit && (
          <DynamicButton
            disabled={data?.email === ''}
            type="contained"
            sx={styles.template}
            onClick={inviteMember}
          >
            Invite
          </DynamicButton>
        )}
      </Box>
    </Dialog>
  );
};

const styles = {
  paper: {
    borderRadius: '8px',
    padding: '32px',
    width: '620px',
    // changed from 488 to 510 beaucase of scroll 
    height: '510px',
  },
  heading: {
    color: 'rgba(0, 0, 0, 0.88)',
    fontFamily: 'InterBold',
    fontSize: '20px',
    lineHeight: '28px',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeIcon: {
    color: shark,
    fontSize: '30px',
    padding: '5px',
    cursor: 'pointer',
  },
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px',
    marginTop: '26px',
  },
  template: {
    color: 'white',
    height: '40px',
    borderRadius: '100px',
    boxShadow: 'none',
  },
};

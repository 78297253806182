import axios from 'axios';
import { isExpired } from 'react-jwt';
import { toast } from 'react-toastify';
import { showNotification } from '../components/common/Toast';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const refreshTokenApi = async (refreshToken) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}api/user/refresh-token`,
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
          'x-auth-token': refreshToken,
          'x-refresh-token': refreshToken,
        },
      }
    );
    const newToken = response.data;

    return newToken;
  } catch (error) {
    // Handle the error while refreshing the token
    console.error('Failed to refresh token:', error);
    // Throw an error to be caught and handled in the interceptor
    throw new Error('Failed to refresh token');
  }
};

httpClient.interceptors.request.use(async function (config) {
  const token =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  const refreshToken =
    localStorage.getItem('refreshToken') ||
    sessionStorage.getItem('refreshToken');

  const rememberMe = localStorage.getItem('rememberMe');
  // Set default headers
  config.headers['x-auth-token'] = token;
  config.headers['x-refresh-token'] = refreshToken;

  if (isExpired(token)) {
    // Token is expired, try refreshing
    try {
      if (refreshToken) {
        const newToken = await refreshTokenApi(refreshToken);
        // Update default headers with the new token
        if (rememberMe == 'true') {
          localStorage.setItem('token', newToken.accessToken);
          localStorage.setItem('refreshToken', newToken.refreshToken);
        } else {
          sessionStorage.setItem('token', newToken);
          sessionStorage.setItem('refreshToken', newToken.refreshToken);
        }
        config.headers['x-auth-token'] = newToken.accessToken;
      }
    } catch (error) {
      // Check if the request URL is in the exceptions
      const exemptedRoutes = [
        'api/plan/get',
        'api/template/get',
        'api/template/all',
        'api/company/get',
        'api/user/get-company-users',
        'api/user/get-participants',
        'api/file/get',
        'api/plan/update',
        'api/user/change-password',
      ];

      const requestUrl = config.url;
      if (exemptedRoutes.some((exception) => requestUrl.includes(exception))) {
        // Skip redirection for the exception routes
        return config;
      } else {
        // Failed to refresh token, redirect to login
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = '/login';
        showNotification(
          'info',
          'Your session has expired! Please log in again!'
        );
        // return Promise.reject(error);
      }
    }
  }

  // Continue with the request
  return config;
});

export default httpClient;

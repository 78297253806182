import React from 'react';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { ReactComponent as EmailSentIcon } from '../../assets/imgs/emailSent.svg';
import { black, mercury, shark, wildSand } from '../../constants/colors';
import DynamicButton from '../new/Button';

const styles = {
  paper: {
    borderRadius: '8px',
    padding: '32px',
    width: '458px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  heading: {
    color: black,
    fontFamily: 'InterBold',
    fontSize: '20px',
    lineHeight: '32px',
    marginTop: '16px',
    marginBottom: '12px',
  },
  subheading: {
    color: black,
    fontFamily: 'InterRegular',
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
  },
  cancel: {
    color: black,
    width: '81px',
    height: '40px',
    background: mercury,
    borderRadius: '100px',
    boxShadow: 'none',
    border: `1px solid ${mercury}`,
    fontSize: '16px',
    marginTop: '32px',
    '&:hover': { background: mercury },
  },
};

const EmailSentModal = ({
  open,
  handleClose,
  email = 'manuelsnr.design@manuelinc.com',
}) => {
  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: styles.paper }}>
      <EmailSentIcon />
      <Typography variant="h5" sx={styles.heading}>
        Verification Email Sent
      </Typography>
      <Typography variant="h6" sx={styles.subheading}>
        A verification email has been sent to “{email}” , kindly check your
        mailbox.
      </Typography>
      <Box sx={styles.btns}>
        <DynamicButton
          type="contained"
          sx={styles.cancel}
          onClick={handleClose}
        >
          Close
        </DynamicButton>
      </Box>
    </Dialog>
  );
};

export default EmailSentModal;
